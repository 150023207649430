import React from 'react'
import { array, string } from 'prop-types'

import Checkbox from '../../../../shared/checkbox'
import Radio from '../../../../shared/radio'
import Button from '../../../../shared/button'
import Input from '../../../../shared/input'

const getAnswerText = (answer, locale) => {
  return answer.texts?.[locale] || '-'
}

const QuestionAnswers = ({ type, locale, guideAnswers, order }) => {
  const isMulti = type === 'multiple' || type === 'checkbox'
  const isRadio = type === 'radio'
  const isLearnMore = order === 3

  if (isMulti) {
    return (
      <div className="flex flex-col">
        {guideAnswers?.map((answer) => {
          if (answer.type === 'input') {
            return <Input key={answer.id} disabled />
          }

          return (
            <Checkbox key={answer.id} text={getAnswerText(answer, locale)} disabled />
          )
        })}
      </div>
    )
  }

  if (isRadio) {
    return (
      <div className="flex flex-col">
        {guideAnswers?.map((answer) => {
          if (answer.type === 'input') {
            return <Input key={answer.id} disabled />
          }

          return <Radio key={answer.id} text={getAnswerText(answer, locale)} disabled />
        })}
      </div>
    )
  }

  if (isLearnMore) {
    return (
      <div>
        <Button mode="default">Learn More</Button>
      </div>
    )
  }

  return <span>not implemented: {type}</span>
}

QuestionAnswers.propTypes = {
  type: string,
  locale: string,
  guideAnswers: array
}

QuestionAnswers.defaultProps = {}

export default QuestionAnswers
