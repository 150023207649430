import React, { memo, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import GenerateCsvButton from '../../shared/generate-csv-btn'
import useFetchSites from '../../../hooks/market-researcher/useFetchSites'

const CategoriesExport = ({ categoriesData, loading }) => {
  const { t } = useTranslation()
  const { locale } = useParams()
  const { mrSites, mrSitesLoading } = useFetchSites()
  const dataLoading = loading || mrSitesLoading

  const csvData = useMemo(() => {
    if (dataLoading) {
      return { headers: [], data: [] }
    }

    const modifiedMrSites = [...mrSites, 'en_GB']

    let headers = modifiedMrSites.map((siteLocale) => {
      return {
        label: t(`shared:localeToCountry.${siteLocale}`),
        key: siteLocale
      }
    })

    headers = [
      ...headers,
      {
        label: t('marketResearcher:categories.items.label.systemsCountInLocale', {
          locale
        }),
        key: 'systemsCount.locale'
      },
      {
        label: t('marketResearcher:categories.items.label.systemsCountOnSite'),
        key: 'systemsCount.site'
      }
    ]

    const excelData = categoriesData.map((categoryData) => {
      const categoryNames = {}

      modifiedMrSites.forEach((siteLocale) => {
        categoryNames[siteLocale] = categoryData.locales?.[siteLocale]?.name || ''
      })

      return {
        ...categoryNames,
        systemsCount: categoryData.systemsCount
      }
    })

    return {
      headers: headers,
      data: excelData
    }
  }, [categoriesData, mrSites, dataLoading, locale, t])

  return <GenerateCsvButton csvData={csvData}>{t('content:export')}</GenerateCsvButton>
}

CategoriesExport.propTypes = {}

CategoriesExport.defaultProps = {}

export default memo(CategoriesExport)
