import React from 'react'
import { array, func } from 'prop-types'

import AssetsRenderer from './assets-renderer'
import AddVideo from './add-video'

const VideoSection = ({ onChange, assetType, assets }) => {
  return (
    <div className="flex gap-3 flex-wrap mt-2">
      <AssetsRenderer assetType={assetType} assets={assets} onChange={onChange} />
      <AddVideo type={assetType} subType="YOUTUBE" onChange={onChange} />
      <AddVideo type={assetType} subType="VIMEO" onChange={onChange} />
    </div>
  )
}

VideoSection.propTypes = {
  onChange: func.isRequired,
  assets: array.isRequired
}

VideoSection.defaultProps = {}

export default VideoSection
