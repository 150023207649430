import React from 'react'

import useFetchGuides from '../hooks/manage-guides/useFetchGuides'

import Header from '../components/admin/manage-guides/header'
import { PageBody } from '../components/page-body'

import ManageGuides from '../components/admin/manage-guides/guides/manage-guides'
import useGetCategoryName from '../hooks/useGetCategoryName'

const AdminManageGuidesPages = () => {
  const { guides, guidesLoading } = useFetchGuides('cache-and-network')

  const { getCategoriesString } = useGetCategoryName()
  const guidesWithCategoriesString = guides.map((guide) => {
    return { ...guide, categoriesString: getCategoriesString(guide.categories) }
  })

  return (
    <>
      <Header />

      <PageBody loading={guidesLoading}>
        <ManageGuides guides={guidesWithCategoriesString} />
      </PageBody>
    </>
  )
}

AdminManageGuidesPages.propTypes = {}

AdminManageGuidesPages.defaultProps = {}

export default AdminManageGuidesPages
