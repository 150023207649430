import React from 'react'
import { bool, object, func, array } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Dialog from '../../shared/dialog'

const ValidationDialog = ({
  isOpen,
  onClose,
  generalValidationErrors,
  siteDataValidationErrors
}) => {
  const { t } = useTranslation('adminPages')

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Dialog
      isOpen={isOpen}
      title={t('adminAddSystem.dialog.validation.title')}
      onClose={handleClose}
    >
      {!!generalValidationErrors.length && (
        <ul className="mb-4">
          {generalValidationErrors.map((error, index) => (
            <li key={index}>- {error}</li>
          ))}
        </ul>
      )}

      {!!Object.keys(siteDataValidationErrors).length && (
        <div>
          {Object.entries(siteDataValidationErrors).map(
            ([locale, errorsArray], index) => {
              return (
                <div key={index}>
                  {t('adminAddSystem.dialog.validation.errorInSiteLocale', { locale })}

                  <ul>
                    {errorsArray.map((error, index) => (
                      <li key={index}>- {error}</li>
                    ))}
                  </ul>
                </div>
              )
            }
          )}
        </div>
      )}
    </Dialog>
  )
}

ValidationDialog.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  generalValidationErrors: array.isRequired,
  siteDataValidationErrors: object.isRequired
}

ValidationDialog.defaultProps = {}

export default ValidationDialog
