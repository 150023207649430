import React, { useState, useCallback } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import useFetchSystems from '../../../hooks/market-researcher/useFetchSystems'
import useFetchSystemsOtherSites from '../../../hooks/market-researcher/useFetchSystemsOtherSites'
import useCreateSystem from '../../../hooks/market-researcher/useCreateSystem'
import useGetCategoryName from '../../../hooks/market-researcher/useGetCategoryName'

import SystemsFilter from './systems-filter'
import CreateSystemDialog from './create-system.dialog'
import Loader from '../../loader'
import Flag from '../../shared/flag'
import Button from '../../shared/button'

import SystemsOtherSitesVarSelect from './systems-other-sites-var-select'

import { findSystemSiteDataForLocale } from '../../../helpers/systems.helper'

const SystemsList = () => {
  const { t } = useTranslation()
  const [filteredSystems, setFilteredSystems] = useState([])
  const [compareSystemVariables, setCompareSystemVaraiables] = useState(null)
  const [filteredSystemsOtherSites, setFilteredSystemsOtherSites] = useState([])
  const { mrSystems, mrSystemsLoading } = useFetchSystems()
  const { mrCreateSystem, loading: mrCreateSystemLoading } = useCreateSystem()

  const { mrSystemsOtherSites, mrSystemsOtherSitesLoading } = useFetchSystemsOtherSites(
    compareSystemVariables?.compareLocale,
    compareSystemVariables?.compareCategory
  )

  const { getCategoryName } = useGetCategoryName()
  const navigate = useNavigate()
  const { locale, category } = useParams()

  const navigateToSystem = (systemId) => {
    navigate(`/market-researcher/${locale}/${category}/${systemId}`)
  }

  const handleAddSystem = (systemId, createdByLocale) => {
    mrCreateSystem(null, systemId, createdByLocale)
  }

  const handleFilter = useCallback((filteredSystems, filteredSystemsOtherSites) => {
    setFilteredSystems(filteredSystems)
    setFilteredSystemsOtherSites(filteredSystemsOtherSites)
  }, [])

  return (
    <div className="grid grid-cols-1 gap-4 bw-blue">
      <div className="border-b border-gray-300 pb-4 pt-3 flex flex-row gap-2 items-end">
        <SystemsFilter
          systems={mrSystems}
          systemsOtherSites={mrSystemsOtherSites}
          onFilter={handleFilter}
        />

        <CreateSystemDialog loading={mrCreateSystemLoading} />
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex flex-col flex-1 gap-4">
          {mrSystemsLoading ? (
            <div className="flex justify-center">
              <Loader />
            </div>
          ) : (
            <>
              <p className="mb-2 text-center">
                {t('marketResearcher:systems.systemsInCategory', {
                  category: getCategoryName(category, locale)
                })}
              </p>

              {!filteredSystems.length && (
                <p className="text-center text-red-600">
                  {t('marketResearcher:systems.noSystemsFound')}
                </p>
              )}

              {filteredSystems.map((system) => {
                const siteDataForLocale = findSystemSiteDataForLocale(system, locale)
                const dateTime =
                  system.createdAt && DateTime.fromISO(system.createdAt).toLocaleString()

                return (
                  <div
                    key={system.id}
                    className="p-3 border border-gray-300 shadow-sm bg-white cursor-pointer rounded"
                    onClick={navigateToSystem.bind(null, system.id)}
                  >
                    <p>{siteDataForLocale?.systemName}</p>

                    {dateTime && (
                      <p className="text-xs text-gray-500">
                        {`${t('content:created')}: ${dateTime}`}
                      </p>
                    )}
                  </div>
                )
              })}
            </>
          )}
        </div>

        <div className="border-t border-gray-300 sm:border-l" />

        <div className="flex flex-col flex-1 gap-4 ">
          <div className="flex flex-col gap-4">
            {/** Variable selector here */}
            <SystemsOtherSitesVarSelect
              onChange={setCompareSystemVaraiables}
              variables={compareSystemVariables}
            />

            {mrSystemsOtherSitesLoading ? (
              <div className="flex justify-center">
                <Loader />
              </div>
            ) : (
              <>
                {!filteredSystemsOtherSites.length && (
                  <p className="text-center text-red-600">
                    {t('marketResearcher:systems.noSystemsFound')}
                  </p>
                )}

                {filteredSystemsOtherSites.map((system) => {
                  return (
                    <div
                      key={system.id}
                      className="flex flex-col gap-2 p-3 border border-gray-300 shadow-sm bg-white rounded"
                    >
                      {Object.entries(system.systemNamesToLocales).map(
                        ([systemName, locales]) => {
                          const dateTime =
                            system.createdAt &&
                            DateTime.fromISO(system.createdAt).toLocaleString()

                          return (
                            <div
                              key={systemName}
                              className="flex justify-between gap-2 items-center"
                            >
                              <div className="flex flex-1 break-all flex-col items-start gap-1">
                                {systemName}

                                {dateTime && (
                                  <p className="text-xs text-gray-500 mb-1">
                                    {`${t('content:created')}: ${dateTime}`}
                                  </p>
                                )}

                                <div className="flex gap-1">
                                  {locales.map((locale) => (
                                    <Flag key={locale} locale={locale} height="16px" />
                                  ))}
                                </div>
                              </div>

                              <Button
                                size="small"
                                onClick={handleAddSystem.bind(
                                  null,
                                  system.id,
                                  locales[0]
                                )}
                                loading={mrCreateSystemLoading}
                              >
                                {t('marketResearcher:systems.btnAddToSite', { locale })}
                              </Button>
                            </div>
                          )
                        }
                      )}
                    </div>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

SystemsList.propTypes = {}

SystemsList.defaultProps = {}

export default SystemsList
