import { useQuery, gql } from '@apollo/client'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const GQL_FETCH_EVENT_ENUMS = gql`
  query FetchEventEnums {
    eventEnums
  }
`

export default function useFetchEventEnums() {
  const { t } = useTranslation(['vendorSettings'])
  const { data } = useQuery(GQL_FETCH_EVENT_ENUMS)
  const eventEnums = data?.eventEnums

  const eventEnumsOptions = useMemo(() => {
    if (!eventEnums) {
      return []
    }

    return eventEnums.map((eventEnum) => {
      return {
        label: t(`webhooks.triggers.${eventEnum}`),
        value: eventEnum
      }
    })
  }, [t, eventEnums])

  return {
    eventEnums: data?.eventEnums || [],
    eventEnumsOptions
  }
}
