import { gql, useQuery } from '@apollo/client'
import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'

const GQL_USER = gql`
  query RefetchUser {
    user {
      role
      appRole
      noNotificationsInquiry
      guideNotifications
      vendor {
        accessRights
      }
    }
  }
`

export default function useFetchUser(skipIfAdmin = true) {
  const { user, updateUser } = useUserContext()
  const isLoggedIn = !!user?.email
  const hasAdmin = isAdmin(user?.role)

  const { data, refetch: refetchUser } = useQuery(GQL_USER, {
    skip: !user || !isLoggedIn || (skipIfAdmin && hasAdmin),
    onCompleted: ({ user }) => {
      updateUser(user)
    },
    onError: (error) => {
      console.log('Error while refetching user', error)
    }
  })

  return {
    user: data?.user,
    refetchUser
  }
}
