import React from 'react'
import { array, bool, func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Label from '../../shared/label'
import Flag from '../../shared/flag'
import { ListItemBase, Select } from '../../shared/select'

const AddSystemInput = ({
  mrSystemsOptions,
  onChange,
  onInputChange,
  listLoading,
  value
}) => {
  const { t } = useTranslation()

  const handleSelectChange = (systemId) => {
    const systemObject = mrSystemsOptions.find((system) => system.value === systemId)
    onChange(systemObject)
  }

  const renderListItem = ({ option, handleSelect, focusedItemId }) => {
    return (
      <ListItemBase
        onClick={handleSelect}
        active={option.value === focusedItemId}
        value={option.value}
        key={`${option.id}-${option.label}`}
      >
        <div className="flex flex-col text-gray-800">
          <span className="font-medium">{option.label}</span>
          <span className="font-xs italic">{option.categories?.join(', ')}</span>

          <span className="text-xs flex gap-1">
            {option.locales.map((locale) => (
              <Flag key={locale} locale={locale} height="16px" />
            ))}
          </span>
        </div>
      </ListItemBase>
    )
  }

  return (
    <Label title={t('forms:system.systemNameName')}>
      <Select
        placeholder="marketResearcher:systems.createDialog.inputPlaceholder"
        onInputChange={onInputChange}
        options={mrSystemsOptions}
        renderListItem={renderListItem}
        onChange={handleSelectChange}
        value={value}
        rawChangeEvent={false}
        listLoading={listLoading}
        isClearable
        freeInput
        autoFocus
      />
    </Label>
  )
}

AddSystemInput.propTypes = {
  mrSystemsOptions: array,
  onChange: func.isRequired,
  onInputChange: func.isRequired,
  value: string,
  listLoading: bool
}

AddSystemInput.defaultProps = {}

export default AddSystemInput
