import React from 'react'
import { Link } from 'react-router-dom'
function Content() {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Our Terms of Use</h1>
      <p>
        Welcome to the BusinessWith Sweden AB website (businesswith.se). These terms,
        conditions, and notices (&quot;Terms&quot;) govern your access to and use of the
        BusinessWith Sweden AB website and digital services (e.g.
        &quot;Systemguiden&quot;, &quot;Leverantörsportalen&quot;), including access and
        use through any other website or platforms or mobile applications,
        (&quot;Site&quot;).
      </p>
      <p>
        By accessing or using this Site in any manner, you agree to be bound by these
        Terms. Please read these Terms carefully. If you do not accept all of these Terms,
        you may not use this Site.
      </p>
      <p>
        Be sure to return to this page periodically to review the most current version of
        these Terms. We may modify these Terms or any additional terms that apply to this
        Site to, for example, reflect changes to the law or changes to this Site. We will
        notify you about material changes to these Terms by either sending a notice to the
        email address you provided to us and/or by placing a prominent notice of
        modifications to these Terms on this page. Changes will not apply retroactively
        and will become effective no sooner than fourteen days after they are posted.
        However, changes addressing new functions or features of this Site or changes made
        for legal reasons will be effective immediately. If you do not agree to the
        modified Terms, you should discontinue your use of this Site.
      </p>
      <p>
        The terms &quot;we&quot;, &quot;us&quot;, &quot;our&quot; and
        &quot;BusinessWith&quot; refer to BusinessWith Sweden AB, a IT software promotion
        company located at Fässbergsgatan 20, 431 69, Mölndal, Sweden. The term
        &quot;you&quot; refers to the person visiting this Site and/or contributing
        content on this Site. If you are accessing or using this Site on behalf of a
        business, that business accepts these Terms, and the term &quot;you&quot; also
        refers to that business.
      </p>
      <h3>Use of Website</h3>
      <p>
        By accessing and using our Sites, you acknowledge that you are responsible for
        your actions and for all Content you post. You represent and warrant, to the best
        of your knowledge:
      </p>
      <ul>
        <li>
          That you have all necessary right, power and authority to enter into these
          General User Terms and to fulfill your contractual obligations hereunder;
        </li>
        <li>
          That any and all information you post or provide, including, without limitation,
          as part of any registration or application or to gain access to the Sites or
          Services, is true, accurate and not misleading and that you will not allow any
          other person or entity to use your Account.
        </li>
        <li>
          That the information and User Content you upload, post, e-mail, transmit, or
          otherwise make available to us or on our Sites, including without limitation
          reviews, trademarks, logos and screenshots, is accurate and free of third party
          encumbrances;
        </li>
        <li>
          That you will, when posting User Content on our Sites, bear all liability
          related to your submission of such User Content through our Sites and/or
          Services;
        </li>
        <li>
          That you will not post or otherwise provide User Content that is unlawful,
          tortious, malicious, defamatory, infringing, libelous, abusive, disparaging,
          pedophilic, pornographic, obscene, invasive of another&apos;s privacy, promotes
          illegal activities/conduct or violates local, state, national or other
          applicable laws or regulations;
        </li>
        <li>
          That you will not post or otherwise provide User Content that you do not have
          the right to make available under any law or contractual or fiduciary
          relationship (such as insider information, proprietary and confidential
          information learned or disclosed as part of employment relationships or
          information protected under nondisclosure agreements);
        </li>
        <li>
          That you will not post or otherwise provide User Content that contains a
          software virus or any other code files or programs with the ability to
          interrupt, destroy, compromise, or otherwise limit the functionality of any
          computer software or hardware or telecommunications equipment.
        </li>
        <li>
          That you will not forge headers or otherwise manipulate identifiers for the
          purpose of disguising the origin of any User Content posted on the Sites;
        </li>
        <li>
          That you will not frame or utilize framing techniques to enclose any trademark,
          logo, or other proprietary information of the Website or our Vendors without our
          express written consent;
        </li>
        <li>
          That you will not avoid, bypass, reverse engineer, interfere with, deactivate,
          impair, descramble or otherwise circumvent any technical measure implemented by
          us to administer and protect our Sites and Services;
        </li>
        <li>
          That you will not use any automated means or form of data scraping or data
          extraction to access, query, download or otherwise collect our Content or
          related information from our Site (except as expressly permitted by us) or
          otherwise without authorization use or upload our Content; or create new links,
          reposts, or referrals through the use of any engine, software, tool, agent,
          device or mechanism (including automated scripts, spiders, robots, crawlers and
          data mining tools);
        </li>
        <li>
          That you will not duplicate, download, publish, modify or otherwise distribute
          our Content for any purpose other than for your own individual use;
        </li>
        <li>
          That you will not collect or &quot;harvest&quot; from our Sites the personal
          information (&quot;Information&quot;) of other Users without their consent for
          the purpose of transmitting unsolicited commercial mass mailings,
          &quot;spamming&quot; or for any other unlawful purpose;
        </li>
        <li>
          That you will not access our Sites and Services by any means other than through
          interfaces expressly authorized by us and these General User Terms;
        </li>
      </ul>
      <h3>Account Registration</h3>
      <h4>User Accounts</h4>
      <p>
        When creating a User Account, you agree to: (i) provide and keep current accurate
        and complete information about yourself and your company (&quot;Registration
        Data&quot;); (ii) maintain the confidentiality of your Account and log-in
        credentials, if applicable; and (iii) restrict access to all others. You agree to
        accept responsibility for all activities that occur under your Account. If we have
        reasonable grounds to suspect that the information you provide is inaccurate,
        incomplete or impersonates another person, we reserve the right to suspend or
        terminate your Account. All Registration Data will be stored and used in
        accordance with our Privacy Policy.
      </p>
      <h3>Privacy Policy</h3>
      <p>
        BusinessWith believes in protecting your privacy. Please click here to review our
        current <Link to="/legal/privacy-policy/">Privacy Policy</Link>, which also
        governs your use of this Site, to understand our practices.
      </p>
      <h3>Our Reviews Program</h3>
      <p>
        How the Program Works: Our Sites and Services are powered by User-generated
        Reviews. We encourage those who desire to share their experience regarding a
        software product, service or vendor to submit reviews on our Site. You acknowledge
        and agree that we as the website operator are merely providing a platform for
        these user-generated reviews; and that all such reviews represent the opinions of
        the reviewers of those products, services or vendors and not those of us. From
        time to time, we may provide nominal incentives (such as gift cards) to encourage
        submission of reviews, regardless of whether the content of the review is positive
        or negative. We provide clear notice on our Sites when a reviewer has been offered
        a nominal incentive for submitting an honest review. Users may opt to post their
        review anonymously, but must submit sufficient information for our Quality
        Assurance team to verify their identity. While a reviewer may choose not to share
        personal information with the public, their identity is always verified by our
        Quality Assurance team before we permit their review to be published.
      </p>
      <h3>Software and Licensed Materials Available On This Site</h3>
      <p>
        Any Software or reports, additional licensed content, sponsored content, research
        reports, and/or review data (individually and collectively) that is made available
        through this Site or otherwise from BusinessWith is the copyrighted and
        proprietary work of BusinessWith, or BusinessWith affiliates. Your use of all
        Licensed Materials shall be subject to these Terms
      </p>
      <p>
        You may not install, access, or use any Software that is accompanied by or
        includes a License Agreement unless you first agree to the License Agreement
        terms. For any Software made available for download on this Site not accompanied
        by a License Agreement, we hereby grant to you a limited, personal, non
        transferable, non-sublicensable license to use the Software for viewing and
        otherwise using this Site in accordance with these Terms and for no other purpose.
      </p>
      <p>
        Please note that all Software, including, without limitation, all HTML, XML, Ruby
        code, Java code, and Active X controls, and all Licensed Materials contained on or
        made available through this Site, is owned by BusinessWith.
      </p>
      <h3>Liability Disclaimer</h3>
      <p>
        THE INFORMATION, CONTENT, SUBMISSIONS, SOFTWARE, PRODUCTS, AND/OR SERVICES
        PUBLISHED ON OR THROUGH THIS SITE MAY INCLUDE INACCURACIES OR ERRORS. BUSINESSWITH
        DOES NOT GUARANTEE THE ACCURACY OF, AND DISCLAIMS, ALL LIABILITY FOR ANY ERRORS OR
        OTHER INACCURACIES RELATING TO, THE INFORMATION AND DESCRIPTION OF THE TECHNOLOGY
        PRODUCTS AND RELATED SERVICES DISPLAYED ON THIS SITE (INCLUDING, WITHOUT
        LIMITATION, THE PRICING, SCREENSHOTS, VIDEOS, LIST OF PRODUCT AND SERVICE
        FEATURES, VENDOR COMPANY INFORMATION, GENERAL PRODUCT DESCRIPTIONS, ETC.).
        BUSINESSWITH MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION,
        CONTENT, SUBMISSIONS, SOFTWARE, PRODUCTS, AND/OR SERVICES CONTAINED OR DESCRIBED
        ON THIS SITE FOR ANY PURPOSE, AND THE INCLUSION OR OFFERING OF ANY PRODUCTS OR
        SERVICES ON OR THROUGH THIS SITE DOES NOT CONSTITUTE ANY ENDORSEMENT OR
        RECOMMENDATION OF SUCH PRODUCTS OR SERVICES BY BUSINESSWITH. ALL SUCH INFORMATION,
        CONTENT, SUBMISSIONS, SOFTWARE, PRODUCTS, AND/OR SERVICES ARE PROVIDED &quot;AS
        IS&quot; WITHOUT WARRANTY OF ANY KIND BY BUSINESSWITH. BUSINESSWITH DISCLAIMS ALL
        WARRANTIES AND CONDITIONS THAT THIS SITE, ITS SERVERS, OR ANY EMAIL SENT FROM
        BUSINESSWITH, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. BUSINESSWITH HEREBY
        DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE INFORMATION, CONTENT,
        SUBMISSIONS, SOFTWARE, PRODUCTS, AND/OR SERVICES CONTAINED OR DESCRIBED ON THIS
        SITE, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS
        FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT.
      </p>
      <p>
        IN NO EVENT SHALL BUSINESSWITH (NOR ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
        AND AFFILIATES) BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
        OR CONSEQUENTIAL DAMAGES ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, YOUR ACCESS
        TO, DISPLAY ON, OR USE OF THIS SITE OR WITH THE DELAY OR INABILITY TO ACCESS,
        DISPLAY, OR USE THIS SITE (INCLUDING, BUT NOT LIMITED TO, YOUR RELIANCE UPON
        OPINIONS APPEARING ON THIS SITE; ANY COMPUTER VIRUSES, INFORMATION, CONTENT,
        SUBMISSIONS, SOFTWARE, LINKED SITES, PRODUCTS, AND/OR SERVICES OBTAINED OR
        ACCESSED THROUGH THIS SITE; OR OTHERWISE ARISING OUT OF THE ACCESS TO, DISPLAY ON,
        OR USE OF THIS SITE) WHETHER BASED ON A THEORY OF NEGLIGENCE, CONTRACT, TORT,
        STRICT LIABILITY, OR OTHERWISE, AND EVEN IF BUSINESSWITH HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
      </p>
      <p>
        The limitation of liability reflects the allocation of risk between the parties.
        The limitations specified in this section will survive and apply even if any
        limited remedy specified in these Terms is found to have failed of its essential
        purpose. The limitations of liability provided in these Terms inure to the benefit
        of BusinessWith.
      </p>
      <h3>Links to Third-Party Sites</h3>
      <p>
        This Site may contain hyperlinks to websites or platforms operated by parties
        other than BusinessWith. Such hyperlinks are provided for your reference only. We
        do not control such websites or platforms and are not responsible for their
        content or the privacy or other practices of such websites or platforms. Further,
        it is up to you to take precautions to ensure that whatever links you select or
        software you download (whether from this Site or other websites or platforms) is
        free of such items as viruses, worms, trojan horses, defects, and other items of a
        destructive nature. Our inclusion of hyperlinks to such websites or platforms does
        not imply any endorsement of the material on such websites or platforms or any
        association with their operators. In some cases, you may be asked by a third-party
        site, platform, or mobile application (such as LinkedIn, Facebook, or Twitter) to
        link your profile on this Site to a profile on that third-party site, platform or
        mobile application. Choosing to do so is purely optional, and the decision to
        allow this information to be linked can be disabled (with the third-party site,
        platform, or mobile application) at any time.
      </p>
      <h3>Copyright Infringement Claims</h3>
      <p>
        We respect the intellectual property rights of others and will not tolerate
        infringing activity on its Sites.
      </p>
      <p>
        If you are a copyright owner or agent, and you believe your rights under
        applicable Copyright laws are being infringed by us or another person or entity
        using our Sites or Services, you may submit to the e-mail address{' '}
        <a href="mailto:info@businesswith.se">info@businesswith.se</a> a written
        notification pursuant to the Digital Millennium Copyright Act (&quot;DMCA&quot;)
        (17 U.S.C. § 512), which includes the following information:
      </p>
      <p>
        A physical or electronic signature of a person authorized to act on behalf of the
        owner of the work(s) that has/have been allegedly infringed;
      </p>
      <p>
        Identification of the work or material being infringed, or, if multiple works are
        covered by a single notification, a representative list of such works;
      </p>
      <p>
        Your contact information, including phone number and e-mail address at which you
        may be contacted;
      </p>
      <p>
        Your good faith statement that the use of the work or material in the manner
        complained of is not authorized by the copyright owner, its agent, or the law; and
      </p>
      <p>
        A statement that the information in your notification is accurate, and under
        penalty of perjury, that you are authorized to act on behalf of the owner of the
        allegedly infringed copyright.
      </p>
      <p>
        We reserve the right to seek damages from any person who knowingly, materially
        submits a DMCA notification claim under this section in violation of the law.
      </p>
      <h3>Termination</h3>
      <p>
        <strong>Termination by Us.</strong> We reserve the right in our sole discretion,
        without penalty and at any time without notice,to modify or discontinue
        (temporarily or permanently) User&apos;s access to our Sites and Services, or any
        part thereof if we believe that User is violating these General User Terms. If
        User uses or attempts to use our Sites and/or Services for any purpose that
        contravenes these General User Terms (including without limitation tampering,
        hacking, data scraping, modifying or otherwise corrupting the security or
        functionality of our Sites and Services), User may also be subject to civil and
        criminal liability.
      </p>
      <p>
        <strong>Termination for Convenience.</strong> By User. User may terminate its User
        Account at any time by sending an email request to{' '}
        <a href="mailto:info@businesswith.se">info@businesswith.se</a>. While you still
        may be able to access certain portions our Sites and Services following
        termination of your Account, you will no longer have access to the full
        functionality. By Us: We may in our sole discretion terminate your User Account,
        or impose limits on or restrict your access to parts or all of our Sites and/or
        Services at any time, without notice or liability.
      </p>
      <h3>Governing Law</h3>
      <p>
        These General Vendor Terms are governed by the laws of Sweden except for its
        conflicts of interest principles. All claims arising out of or relating to these
        General User Terms will be litigated exclusively in the federal or state courts of
        Stockholm, Sweden and each of BusinessWith and User consents to personal
        jurisdiction in those courts. Regardless of any statute or law to the contrary,
        any claim or cause of action arising out of or related to our Sites, Services or
        these General User Terms must be filed or otherwise commenced within one (1) year
        after such claim or cause of action arose or shall be forever barred.
      </p>
      <h3>Service Help</h3>
      <p>
        For answers to your questions or ways to contact us, email us at{' '}
        <a href="mailto:info@businesswith.se">info@businesswith.se</a>. Or, you can write
        to us at:
      </p>
      <p>
        BusinessWith Sweden AB
        <br />
        Fässbergsgatan 20
        <br />
        431 69 Mölndal
        <br />
        Sweden
      </p>
      <p>Last edit: 2021-06-01</p>
    </div>
  )
}

export default Content
