import React, { useEffect } from 'react'

import useFetchWebhooks from './hooks/useFetchWebhooks'
import { useTranslation } from 'react-i18next'

import WebhooksList from './webhooks-list'
import Subsection from '../shared/subsection'

const Webhooks = ({ vendorId }) => {
  const { webhooks, refetchWebhooks, fetchIsLoading } = useFetchWebhooks(vendorId)
  const { t } = useTranslation(['vendorSettings'])

  useEffect(() => {
    refetchWebhooks()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Subsection title={t('webhooks.title')}>
      <WebhooksList vendorId={vendorId} webhooks={webhooks} loading={fetchIsLoading} />
    </Subsection>
  )
}

Webhooks.propTypes = {}

Webhooks.defaultProps = {}

export default Webhooks
