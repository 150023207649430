import React, { useMemo, useState } from 'react'
import { array } from 'prop-types'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useFetchGuide from '../../../../hooks/manage-guides/useFetchGuide'
import useCreateGuideQuestion from '../../../../hooks/manage-guides/useCreateGuideQuestion'
import useGetCachedGuideQuestion from '../../../../hooks/manage-guides/useGetCachedGuideQuestion'

import QuestionItem from './question/item'
import SitesSelect from '../../../shared/sites-select'

import EditQuestionDialog from './question/edit-question.dialog'

const ITEMS_INSTR = [
  { order: 1 },
  { order: 2 },
  { order: 2.5 },
  { order: 3 },
  { order: 4, custom: true },
  { order: 4.5, custom: true },
  { order: 5 },
  { order: 6 },
  { order: 7 },
  { order: 8, custom: true },
  { order: 8.5, custom: true }
]

const QuestionsOverview = ({ guideQuestions, guideLocales }) => {
  const { t } = useTranslation()
  const { guideId } = useParams()
  const [selectedLocale, setSelectedLocale] = useState()
  const [editQuestionId, setEditQuestionId] = useState(false)
  const [itemOrderLoading, setItemOrderLoading] = useState(null)
  const { createGuideQuestion } = useCreateGuideQuestion()

  // Refetch guide on edit modal open
  useFetchGuide(!editQuestionId, 'cache-and-network')

  const editingCachedQuestion = useGetCachedGuideQuestion(editQuestionId)

  const questionsToOrderMapMemo = useMemo(() => {
    const questions = {}

    guideQuestions.forEach((question) => {
      const { order: questionOrder } = question

      if (!questions[questionOrder]) {
        questions[questionOrder] = {}
      }

      questions[questionOrder] = question
    })

    return questions
  }, [guideQuestions])

  const handleEditQuestion = (questionId) => {
    setEditQuestionId(questionId)
  }

  const handleAddQuestion = (questionOrder) => {
    setItemOrderLoading(questionOrder)

    createGuideQuestion(guideId, questionOrder, (updatedGuide) => {
      const createdQuestion = updatedGuide.guideQuestions.find(
        (guideQuestion) => guideQuestion.order === questionOrder
      )

      if (createdQuestion) {
        setEditQuestionId(createdQuestion.id)
      }
    }).finally(() => setItemOrderLoading(null))
  }

  return (
    <div className="mt-10">
      <div className="flex items-start">
        <SitesSelect
          label={t('content:sites')}
          alternativeLocales={guideLocales}
          onChange={setSelectedLocale}
          value={selectedLocale}
        />
      </div>

      <div className="grid grid-cols-2 gap-3">
        {ITEMS_INSTR.map((item, index) => {
          const { order } = item
          const currSplit = ITEMS_INSTR[index]?.order % 1 != 0
          const nextSplit = ITEMS_INSTR[index + 1]?.order % 1 != 0
          const className = nextSplit || currSplit ? 'col-span-1' : 'col-span-2'
          const question = questionsToOrderMapMemo[order]
          const editable = nextSplit || currSplit

          return (
            <QuestionItem
              className={className}
              key={order}
              itemOrder={order}
              question={question}
              editable={editable}
              locale={selectedLocale}
              onEditQuestion={handleEditQuestion}
              onAddQuestion={handleAddQuestion}
              loading={item.order === itemOrderLoading}
            />
          )
        })}
      </div>

      <EditQuestionDialog
        guideQuestion={editingCachedQuestion}
        locale={selectedLocale}
        onClose={setEditQuestionId}
      />
    </div>
  )
}

QuestionsOverview.propTypes = {
  guideQuestions: array,
  guideLocales: array
}

QuestionsOverview.defaultProps = {
  guideQuestions: []
}

export default QuestionsOverview
