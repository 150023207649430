import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Loader from '../components/loader'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import BiLead from '../components/admin/admin-list-bi-lead-item'

import useAdminQuery from '../hooks/useAdminQuery'

const QUERY = gql`
  query BiLeads(
    $companyId: ID
    $userId: ID
    $category: String
    $offset: Int
    $limit: Int
  ) {
    appAdminBuyingIntentSignalsForSystem(
      companyId: $companyId
      userId: $userId
      category: $category
      offset: $offset
      limit: $limit
    ) {
      id
      name
      locale
      founded
      alexaRank
      employeeMin
      employeeMax
      linkedInCategory
      financialsMin
      financialsMax
      naceCategory
      city
      state
      orgId
      zip
      website
      url
      createdAt
      updatedAt
      pages
      sessions
      companySize
      pageviews
      profileviews
      categoryLandingPageviews
      allCategoryviews
      compareviews
      compareviewsCategory
      compareviewsProfile
      sessionDuration
      date
    }
  }
`

function LeadsBiAdmin({ leads }) {
  const { t } = useTranslation()

  if (!leads?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  const updatedLeadsObj = leads.map((lead) => {
    return {
      ...lead,
      sessions: JSON.parse(lead.sessions),
      date: lead.date
    }
  })

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {updatedLeadsObj
          .sort((a, b) => b.date - a.date)
          .map((lead) => {
            return <BiLead key={`${lead.date}--${lead.name}`} lead={lead} />
          })}
      </ul>
    </div>
  )
}

function AdminAllBiPage() {
  const [allSignals, setAllSignals] = useState(null)

  const { loading, error } = useAdminQuery(QUERY, {
    onCompleted: (data) => {
      console.log({ data })
      if (data?.appAdminBuyingIntentSignalsForSystem.length) {
        setAllSignals([...data.appAdminBuyingIntentSignalsForSystem])
      }
    }
  })

  if (error) {
    console.error(error)
  }

  return (
    <>
      <PageHeader pageName={'Buying Intent'}>
        <p>Alla leads för valt företag inkl. admin data</p>
      </PageHeader>

      <PageBody>
        {error &&
          error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
        <div>{!loading && <LeadsBiAdmin leads={allSignals} />}</div>
        {loading && (
          <div className="text-center">
            <Loader />
          </div>
        )}
      </PageBody>
    </>
  )
}

export default AdminAllBiPage
