import React, { useMemo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUserContext } from './userContext'
import { isAdmin as isAdminUtil, isResearcher as isResearcherUtil } from '../utils'

import useListSites from '../hooks/market-researcher/useFetchSites'

const MarketResearcher = React.createContext({})

export const MarketResearcherContextProvider = ({ children }) => {
  const { user } = useUserContext()
  const { locale: selectedResearchingLocale } = useParams()
  const navigate = useNavigate()

  // Redirect to locale if none selected
  const { mrSites, mrSitesLoading } = useListSites((data) => {
    if (!selectedResearchingLocale && data.mrListSites.length > 0) {
      navigate(`/market-researcher/${data.mrListSites[0].defaultLocale}`)
    }
  })

  const rolesMemo = useMemo(() => {
    return {
      isAdmin: isAdminUtil(user?.role),
      isResearcher: isResearcherUtil(user?.role)
    }
  }, [user?.role])

  const navigateToCategory = useCallback(
    (category) => {
      navigate(`/market-researcher/${selectedResearchingLocale}/${category}`)
    },
    [navigate, selectedResearchingLocale]
  )

  const contextValues = useMemo(() => {
    return {
      ...rolesMemo,
      mrSites,
      mrSitesLoading,
      navigateToCategory
    }
  }, [rolesMemo, mrSites, mrSitesLoading, navigateToCategory])

  return (
    <MarketResearcher.Provider value={contextValues}>
      {children}
    </MarketResearcher.Provider>
  )
}

export const useMarketResearcherContext = () => React.useContext(MarketResearcher)
