import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import useOverlayNotification from '../useOverlayNotification'

import { prepareFormDataForSubmit } from '../../helpers/systemProps.helper'

const MR_CREATE_SYSTEM_PROPS_BY_TYPE_GQL = gql`
  mutation CreateSystemPropByTypeMR(
    $locale: String!
    $category: String!
    $propType: PropTypes!
    $data: CreateSystemPropByTypeInputMR!
  ) {
    mrCreateSystemPropsByType(
      locale: $locale
      category: $category
      propType: $propType
      data: $data
    )
  }
`

export default function useCreateSystemPropsByType(propType) {
  const { locale, category } = useParams()
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [mrCreateSystemPropsByTypeMutation, { loading }] = useMutation(
    MR_CREATE_SYSTEM_PROPS_BY_TYPE_GQL,
    { onError: () => showErrorNotification() }
  )

  const mrCreateSystemPropsByType = useCallback(
    (formData, onCompleted) => {
      const data = prepareFormDataForSubmit(formData, locale)

      return mrCreateSystemPropsByTypeMutation({
        variables: {
          data,
          locale,
          category,
          propType
        },
        onCompleted: (data) => {
          showSuccessNotification()
          onCompleted && onCompleted(data)
        }
      })
    },
    [mrCreateSystemPropsByTypeMutation, locale, category, showSuccessNotification]
  )

  return {
    mrCreateSystemPropsByType,
    mrCreateSystemPropsByTypeLoading: loading
  }
}
