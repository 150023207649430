export const SYSTEM_PROP_FRAGMENT = `
    id
    dbName
    category
    filter
    enabled
    section
    impact
    nameByLocale
    descriptionByLocale
`
