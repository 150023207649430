import { useCallback } from 'react'

import { useManageSystemContext } from '../context'
import useCreateSystem from '../../../hooks/useCreateSystem'
import useUpdateSystem from '../../../hooks/useUpdateSystem'

export default function useSubmitSystem() {
  const {
    fieldsData,
    mediaAssetsData,
    operationMode,
    resetSystemForm,
    changedFields,
    resetChangedFields
  } = useManageSystemContext()

  const { createSystem, loading: createSystemLoading } = useCreateSystem()
  const { updateSystem, loading: updateSystemLoading } = useUpdateSystem()

  const submitSystem = useCallback(() => {
    if (operationMode === 'CREATE') {
      return createSystem(fieldsData, mediaAssetsData, () => {
        resetSystemForm()
      })
    }

    if (operationMode === 'UPDATE') {
      return updateSystem(fieldsData.id, changedFields, mediaAssetsData, () => {
        resetChangedFields()
      })
    }
  }, [
    fieldsData,
    changedFields,
    mediaAssetsData,
    operationMode,
    updateSystem,
    createSystem,
    resetSystemForm,
    resetChangedFields
  ])

  return {
    submitSystem,
    loading: createSystemLoading || updateSystemLoading
  }
}
