import React from 'react'
import { object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useUpdateGuide from '../../../../hooks/manage-guides/useUpdateGuide'
import useGenericFormManager from '../../../../hooks/useGenericFormManager'

import Button from '../../../shared/button'
import GuideGeneralForm, { GUIDE_EDIT_FORM_INSTR } from '../guide-general-form'
import QuestionsOverview from './questions-overview'
import ToggleActiveGuide from './toggle-active-guide'
import ArchiveGuide from './archive-guide'

const ManageGuide = ({ guide }) => {
  const { t } = useTranslation()
  const { updateGuide, updateGuideLoading } = useUpdateGuide()
  const {
    formData,
    onChange,
    validate,
    validationErrors,
    changedFields,
    resetChangedFields
  } = useGenericFormManager(GUIDE_EDIT_FORM_INSTR, guide)

  const savebtnDisabled = !Object.keys(changedFields).length

  const handleSubmit = () => {
    if (!validate()) {
      return
    }

    updateGuide(formData.id, changedFields).finally(resetChangedFields)
  }

  return (
    <div className="flex flex-col">
      <ToggleActiveGuide onChange={onChange} value={!!formData?.active} />

      <div className="flex gap-3">
        <div className="flex gap-2 w-full flex-col sm:flex-row sm:items-end">
          <GuideGeneralForm
            formData={formData}
            onChange={onChange}
            validationErrors={validationErrors}
          />

          <Button
            onClick={handleSubmit}
            loading={updateGuideLoading}
            disabled={savebtnDisabled}
          >
            {t('content:save')}
          </Button>
        </div>
      </div>

      <QuestionsOverview
        guideQuestions={guide.guideQuestions}
        guideLocales={guide.locales}
      />

      <div className="flex my-4 justify-end">
        <ArchiveGuide guideActive={guide?.active} />
      </div>
    </div>
  )
}

ManageGuide.propTypes = {
  guide: object
}

ManageGuide.defaultProps = {}

export default ManageGuide
