import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const GQL_EDIT_MARKET_RESEARCHER = gql`
  mutation EditMarketResearcherMutation($id: ID!, $data: EditUserMRInput!) {
    mrEditUser(id: $id, data: $data) {
      id
      name
      email
      latestSignedIn
      marketResearcherLocales
    }
  }
`

export default function useEditMarketResearcher() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [editMarketResearcher, { loading: editMarketResearcherLoading }] = useMutation(
    GQL_EDIT_MARKET_RESEARCHER
  )

  const editMarketResearcherCallback = useCallback(
    (data) => {
      return editMarketResearcher({
        variables: {
          id: data.id,
          data: {
            name: data.name,
            marketResearcherLocales: data.marketResearcherLocales
          }
        },
        onCompleted: showSuccessNotification,
        onError: showErrorNotification
      })
    },
    [editMarketResearcher, showSuccessNotification, showErrorNotification]
  )

  return {
    editMarketResearcher: editMarketResearcherCallback,
    editMarketResearcherLoading
  }
}
