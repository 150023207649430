import React, { useCallback, useMemo } from 'react'

import { RadiobuttonsRows } from '../../form/inputs'
import AdminField from '../../shared/admin-field'
import Loader from '../../loader'

import { useManageSystemContext } from '../context'
import useFetchSystemProps from '../../../hooks/useFetchSystemProps'

const CategoryForm = () => {
  const { fieldsData, activeTab, handleFieldChange } = useManageSystemContext()
  const selectedCategory = activeTab.value
  const { systemProps, systemPropsGuide, systemPropsArea, loading } =
    useFetchSystemProps()

  const getFieldPropsObject = useCallback(
    (fieldId, nameTid, dbPropsArray, existingPropsObject) => {
      const propsObjectValues = dbPropsArray
        .filter((prop) => prop.category === selectedCategory)
        .map((prop) => {
          const { category, dbName } = prop
          const existingValue = existingPropsObject?.[category]?.[dbName]

          return {
            id: dbName,
            title: dbName,
            value: existingValue
          }
        })

      return {
        id: fieldId,
        nameTid,
        type: 'json',
        values: propsObjectValues.reverse()
      }
    },
    [selectedCategory]
  )

  const systemPropsFieldData = useMemo(() => {
    if (!systemProps) {
      return null
    }

    return getFieldPropsObject(
      'systemProps',
      'forms:system.systemPropsName',
      systemProps,
      fieldsData.systemProps
    )
  }, [getFieldPropsObject, systemProps, fieldsData.systemProps])

  const systemAreasFieldData = useMemo(() => {
    if (!systemPropsArea) {
      return null
    }

    return getFieldPropsObject(
      'systemAreas',
      'forms:system.systemAreasName',
      systemPropsArea,
      fieldsData.systemAreas
    )
  }, [getFieldPropsObject, systemPropsArea, fieldsData.systemAreas])

  const systemPropsGuideFieldData = useMemo(() => {
    if (!systemPropsGuide) {
      return null
    }

    return getFieldPropsObject(
      'systemGuideProps',
      'forms:system.systemGuidePropsName',
      systemPropsGuide,
      fieldsData.systemGuideProps
    )
  }, [getFieldPropsObject, systemPropsGuide, fieldsData.systemGuideProps])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {systemPropsGuideFieldData && !!systemPropsGuideFieldData.values?.length && (
        <AdminField>
          <RadiobuttonsRows
            key="systemGuideProps"
            field={systemPropsGuideFieldData}
            type="boolean"
            category={selectedCategory}
            handleFieldChange={handleFieldChange}
          />
        </AdminField>
      )}

      <RadiobuttonsRows
        key="systemProps"
        field={systemPropsFieldData}
        type="multiple"
        category={selectedCategory}
        handleFieldChange={handleFieldChange}
      />

      {systemAreasFieldData && !!systemAreasFieldData.values?.length && (
        <RadiobuttonsRows
          key="systemAreas"
          field={systemAreasFieldData}
          type="boolean"
          category={selectedCategory}
          handleFieldChange={handleFieldChange}
        />
      )}
    </>
  )
}

CategoryForm.propTypes = {}

CategoryForm.defaultProps = {}

export default CategoryForm
