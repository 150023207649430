import React, { memo, useCallback } from 'react'
import { array, string, func, bool, oneOfType, number } from 'prop-types'

import { useSelectContext } from '../context'
import useKeyPress from '../../../../../hooks/useKeyPress'

import Input from '../shared/input'
import VirtualizedList from '../shared/virtualized-list'
import ListItem from '../shared/list-item'

const Select = ({
  id,
  onChange,
  placeholder,
  isClearable,
  isSearchable,
  rawChangeEvent,
  value,
  renderListItem,
  listLoading,
  errorText,
  loading,
  disabled
}) => {
  const {
    wrapperRef,
    inputRef,
    dropdownOpen,
    visibleOptions,
    inputValue,
    setSearchQuery,
    onSelect,
    onFocus,
    onOpen,
    focusedItemId
  } = useSelectContext()

  const doChangeEvent = useCallback(
    (value) => {
      if (typeof onChange !== 'function') {
        return
      }

      if (rawChangeEvent) {
        onChange({
          target: {
            id,
            value
          }
        })
      } else {
        if (id) {
          onChange(id, value)
        } else {
          onChange(value)
        }
      }
    },
    [rawChangeEvent, onChange, id]
  )

  const handleSelect = useCallback(
    (value) => {
      onSelect()
      doChangeEvent(value)
    },
    [onSelect, doChangeEvent]
  )

  // Select options when pressed enter
  useKeyPress('Enter', dropdownOpen, () => {
    if (focusedItemId) {
      handleSelect(focusedItemId)
    }
  })

  const handleInputChange = (value) => {
    setSearchQuery(value)

    if (isClearable && !value) {
      doChangeEvent(undefined)
    }
  }

  const renderItemContent = (index) => {
    const option = visibleOptions[index]

    if (renderListItem) {
      return renderListItem({ option, focusedItemId, handleSelect })
    }

    return (
      <ListItem
        key={option.value}
        value={option.value}
        label={option.label}
        labelTid={option.labelTid}
        onClick={handleSelect}
        active={option.value === focusedItemId}
      />
    )
  }

  const showClearableIcon = isClearable && value?.toString()?.length > 0

  return (
    <div
      ref={wrapperRef}
      className="w-full flex flex-col items-center mx-auto shadow-sm"
      tabIndex="0"
    >
      <div className="w-full flex flex-col items-center relative">
        <Input
          onChange={handleInputChange}
          value={inputValue}
          onFocus={onFocus}
          ref={inputRef}
          onRequestOpen={onOpen}
          placeholder={placeholder}
          showClearIcon={showClearableIcon}
          readOnly={!isSearchable}
          errorText={errorText}
          loading={loading}
          disabled={disabled}
        />

        {dropdownOpen && (
          <VirtualizedList
            listLoading={listLoading}
            renderItemContent={renderItemContent}
          />
        )}
      </div>
    </div>
  )
}

Select.propTypes = {
  options: array,
  value: oneOfType([string, number, bool]),
  onChange: func,
  placeholder: string,
  rawChangeEvent: bool,
  isClearable: bool,
  renderListItem: func,
  isSearchable: bool,
  listLoading: bool,
  errorText: string
}

Select.defaultProps = {
  options: [],
  value: '',
  placeholder: 'placeholders.select.select',
  rawChangeEvent: true,
  isSearchable: true
}

export default memo(Select)
