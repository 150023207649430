import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const SET_COMMON_QUESTION_GQL = gql`
  mutation AdminSetCommonQuestion($questionId: ID!, $commonQuestionId: ID) {
    setCommonQuestion(questionId: $questionId, commonQuestionId: $commonQuestionId) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useSetCommonQuestion() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [setCommonQuestion, { loading }] = useMutation(SET_COMMON_QUESTION_GQL)

  const setCommonQuestionCallback = useCallback(
    (questionId, commonQuestionId, onCompleted) => {
      return setCommonQuestion({
        variables: {
          questionId,
          commonQuestionId
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      })
    },
    [setCommonQuestion, showSuccessNotification, showErrorNotification]
  )

  return {
    setCommonQuestion: setCommonQuestionCallback,
    setCommonQuestionLoading: loading
  }
}
