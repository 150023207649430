import { useCallback } from 'react'

import useFetchCategories from './useFetchCategories'

export default function useGetCategoryName() {
  const { mrCategories } = useFetchCategories()

  const getCategoryName = useCallback(
    (category, locale) => {
      if (!mrCategories?.length || !category) {
        return ''
      }

      const foundCategory = mrCategories.find(
        (arrCategory) => arrCategory.dbName === category
      )

      let foundName = ''

      // If locale was sent, try to use it
      if (locale) {
        foundName = foundCategory.locales?.[locale]?.name
      }

      if (foundName) {
        return foundName
      }

      // If no locale was sent, use any but pritarize according to list above
      // 1. English
      // 2. Swedish
      // 3. Any other language

      foundName = foundCategory.locales?.en_GB?.name || foundCategory.locales?.sv_SE?.name

      if (foundName) {
        return foundName
      }

      // Return anything
      const foundCat = Object.values(foundCategory.locales).find((catData) => {
        return catData.name
      })

      return foundCat?.name || ''
    },
    [mrCategories]
  )

  return {
    getCategoryName
  }
}
