import React, { memo, useState } from 'react'
import { array, bool, string } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import useFetchEventEnums from './hooks/useFetchEventEnums'
import Button from '../shared/button'
import Loader from '../loader'
import ListItem from './list-item/webhook-list-item'

const WebhooksList = ({ webhooks, loading, vendorId }) => {
  const { eventEnumsOptions } = useFetchEventEnums()
  const [showFormForId, setShowFormForId] = useState(null)
  const [creatorActive, setCreatorActive] = useState(false)
  const { t } = useTranslation(['vendorSettings'])

  const showCreatorForm = () => {
    setCreatorActive(true)
  }

  const hideCreatorForm = () => {
    setCreatorActive(false)
  }

  const renderListItem = (webhook) => (
    <ListItem
      webhook={webhook}
      vendorId={vendorId}
      key={webhook.id}
      showFormForId={showFormForId}
      setShowFormForId={setShowFormForId}
      eventEnumsOptions={eventEnumsOptions}
    />
  )

  return (
    <div className="flex flex-col">
      <div className="flex flex-row pt-4 pb-4">
        <div className="flex-rows">
          <div className=" flex-1 text-gray-500">
            {t('webhooks.subtitle')}
            {' - '}
            <a
              className="text-blue-600 font-semibold"
              href="https://businesswith.readme.io/reference/webhooks"
            >
              Docs
            </a>
          </div>
          <div className=" flex-1 text-gray-500">
            {t('webhooks.zapier')}
            {'. - '}
            <a
              className="text-blue-600 font-semibold"
              href="https://ik.imagekit.io/businesswith/document/BW_Webhooks_Zapier_vEGex9zRt.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {t('webhooks.learnHow')}
            </a>
          </div>
        </div>
        <div className="ml-8 self-end">
          <Button
            mode="default"
            disabled={creatorActive || loading}
            onClick={showCreatorForm}
          >
            {t('webhooks.button.create')}
          </Button>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {creatorActive && (
              <ListItem
                vendorId={vendorId}
                setShowFormForId={hideCreatorForm}
                creatorMode={creatorActive}
                eventEnumsOptions={eventEnumsOptions}
              />
            )}
            {webhooks.map(renderListItem)}
          </ul>
        </div>
      )}
    </div>
  )
}

WebhooksList.propTypes = {
  webhooks: array,
  vendorId: string,
  loading: bool
}

export default memo(WebhooksList)
