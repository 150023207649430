import React, { useEffect } from 'react'

import { useManageSystemContext } from '../context'

import Tabs from '../../shared/tabs'

const SystemTabs = ({ setTabDisabled, initialTab }) => {
  const { allTabs, activeTab, setActiveTab } = useManageSystemContext()

  useEffect(() => {
    if (Object.keys(activeTab).length > 0) {
      return
    }

    if (initialTab) {
      setActiveTab(initialTab)
    } else {
      setActiveTab({ value: 'SYSTEM' })
    }
  }, [activeTab, setActiveTab, initialTab])

  return (
    <Tabs
      tabs={allTabs}
      activeTab={activeTab.value}
      onChange={setActiveTab}
      setTabDisabled={setTabDisabled}
    />
  )
}

SystemTabs.propTypes = {}

SystemTabs.defaultProps = {}

export default SystemTabs
