import { useCallback } from 'react'
import { useQuery, gql } from '@apollo/client'

const GQL_FETCH_WEBHOOKS = gql`
  query FetchWebhooks($vendorId: ID!) {
    webhooks(vendorId: $vendorId) {
      id
      name
      url
      events
      transformer
    }
  }
`
export default function useFetchWebhooks(vendorId) {
  const {
    data: webhooksData,
    loading: fetchIsLoading,
    refetch: refetchWebhooks
  } = useQuery(GQL_FETCH_WEBHOOKS, {
    variables: { vendorId },
    skip: !vendorId
  })

  const webhooks = webhooksData?.webhooks
  const hasWebhooks = Boolean(webhooks)

  const refetchWebhookCallback = useCallback(() => {
    if (hasWebhooks) {
      refetchWebhooks()
    }
  }, [hasWebhooks, refetchWebhooks])

  return {
    webhooks: webhooks || [],
    fetchIsLoading: !hasWebhooks && fetchIsLoading,
    refetchWebhooks: refetchWebhookCallback
  }
}
