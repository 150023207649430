import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ArrowRightIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
const AdminSiteRedirects = ({ redirects }) => {
  const { t } = useTranslation()

  const [showRedirects, setShowRedirects] = useState(false)

  if (!redirects || redirects.length === 0) return

  // TODO: Add translations
  return (
    <div className={'my-5'}>
      <p
        className={'block text-sm font-medium text-gray-700 cursor-pointer'}
        onClick={() => setShowRedirects(!showRedirects)}
      >
        {
          showRedirects
            ? t('hideRedirects')
            : t('showRedirects') /* Replace with Accordion */
        }
      </p>
      <div
        className={`p-1 border border-gray-300 bg-white rounded-lg shadow-sm ${
          showRedirects ? 'block' : ' hidden'
        }`}
      >
        {redirects.map((redirect) => (
          <>
            <div className="flex items-center mb-2" key={redirect.id}>
              <span className="text-gray-900 p-1 rounded-md bg-gray-100 border border-gray-300">
                {redirect.from}
              </span>
              <ArrowRightIcon className="w-5 h-5 text-gray-500 mx-2" />
              <span className="text-gray-900 p-1 rounded-md bg-gray-100 border border-gray-300">
                {redirect.to}
              </span>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

AdminSiteRedirects.propTypes = {
  redirects: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      locale: PropTypes.string.isRequired,
      type: PropTypes.string
    })
  ).isRequired
}

export default AdminSiteRedirects
