import { useCallback } from 'react'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

export const GQL_FETCH_GUIDE = gql`
  query FetchGuide($guideId: ID!) {
    guide(guideId: $guideId) {
      ${GUIDE_FRAGMENT}
    }
  }
`

const EMPTY_OBJ = {}

export default function useFetchGuide(skip, fetchPolicy) {
  const { guideId } = useParams()

  const { data, loading, refetch, networkStatus } = useQuery(GQL_FETCH_GUIDE, {
    variables: {
      guideId
    },
    skip: !guideId || skip,
    fetchPolicy
  })

  const refetchGuide = useCallback(() => {
    if (data) {
      return refetch()
    }
  }, [refetch, data])

  return {
    guide: data?.guide || EMPTY_OBJ,
    guideLoading: (!data && loading) || networkStatus === NetworkStatus.refetch,
    refetchGuide
  }
}
