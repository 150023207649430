import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { array, bool, string } from 'prop-types'
import cx from 'classnames'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

import { useTranslation } from 'react-i18next'
import useUpdateAnswerText from '../../../../../hooks/manage-guides/useUpdateAnswerText'
import useReorderAnswers from '../../../../../hooks/manage-guides/useReorderAnswers'

import { MenuIcon } from '@heroicons/react/outline'

import EditableI18nTextarea from '../../../../shared/editable-textarea-i18n-input'
import AnswerEditSpecialName from '../answer/edit-special-name'
import DeleteAnswerButton from './delete-answer-btn'
import CreateAnswer from '../answer/create-answer'
import Button from '../../../../shared/button'
import Label from '../../../../shared/label'

/**
 * Possible options:
 * - hideSpecialName,
 * - disableCreator
 */
const INSTR_NAME_MAP = {
  PRODUCT_FUNCTIONALITIES: {
    hideSpecialName: true,
    disableCreator: true
  },
  SYSTEM_CHARACTERISTICS: {
    hideSpecialName: true
  },
  AREAS_OF_USE: {
    hideSpecialName: true
  }
}

const portal = document.createElement('div')
document.body.appendChild(portal)

const PortalAwareItem = ({
  readOnly,
  disableDrag,
  locale,
  questionId,
  questionName,
  questionFilter,
  forceShowSpecialName,
  provided,
  snapshot,
  item,
  isLast
}) => {
  const { t } = useTranslation()
  const { updateAnswerText, updateAnswerTextLoading } = useUpdateAnswerText()

  const loading = updateAnswerTextLoading

  const handleEditAnswerTitle = (answerId, data, doneCallback) => {
    updateAnswerText(answerId, undefined, data, doneCallback)
  }

  const handleEditAnswerDescription = (answerId, data, doneCallback) => {
    updateAnswerText(answerId, 'help', data, doneCallback)
  }

  const getShowSpecialName = () => {
    if (forceShowSpecialName) {
      return true
    }

    return !INSTR_NAME_MAP[questionName]?.hideSpecialName && questionFilter
  }

  const usePortal = snapshot?.isDragging

  const component = (
    <div
      className={cx('flex py-2 pr-2 gap-2 border even:border-y-0', {
        'bg-slate-200': snapshot?.isDragging,
        'px-2': readOnly || disableDrag,
        '!border-b': isLast
      })}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      {!disableDrag && (
        <div
          className="flex items-center border-r px-2 cursor-move"
          {...provided?.dragHandleProps}
        >
          <MenuIcon className="w-4 h-4 text-gray-600" />
        </div>
      )}

      <div className="flex w-full flex-row gap-1 flex-col">
        <Label title={t('content:title')} marginless>
          <EditableI18nTextarea
            id={item.id}
            texts={item.texts}
            locale={locale}
            disabled={readOnly}
            loading={loading}
            onSave={handleEditAnswerTitle}
          />
        </Label>

        <Label title={t('content:description')} marginless>
          <EditableI18nTextarea
            id={item.id}
            texts={item.textsHelp}
            locale={locale}
            disabled={readOnly}
            loading={loading}
            onSave={handleEditAnswerDescription}
            allowSaveEmpty={true}
          />
        </Label>

        {getShowSpecialName() && (
          <AnswerEditSpecialName answer={item} readOnly={readOnly} />
        )}
      </div>

      {!readOnly && (
        <div className="flex items-center border-l pl-2">
          <DeleteAnswerButton questionId={questionId} answerId={item.id} />
        </div>
      )}
    </div>
  )

  if (!usePortal) {
    return component
  }

  // if dragging - put the item in a portal
  return createPortal(component, portal)
}

const ManageAnswersList = ({
  guideAnswers,
  locale,
  questionId,
  questionName,
  readOnly,
  questionFilter,
  hideCreator,
  forceShowSpecialName
}) => {
  const { t } = useTranslation('adminPages')
  const { reorderAnswers, reorderAnswersLoading } = useReorderAnswers()
  const [editingOrder, setEditingOrder] = useState(false)
  const [reorderedAnswers, setReorderedAnswers] = useState([])
  const disableCreator = editingOrder

  const guideAnswersMemo = useMemo(() => {
    if (reorderedAnswers.length) {
      return reorderedAnswers
    }

    if (!guideAnswers) {
      return []
    }

    return guideAnswers
  }, [guideAnswers, reorderedAnswers])

  const reorder = (list, startIndex, endIndex) => {
    setEditingOrder(true)

    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const updatedItems = reorder(
      guideAnswersMemo,
      result.source.index,
      result.destination.index
    )

    setReorderedAnswers(updatedItems)
  }

  const handleResetReorder = () => {
    setReorderedAnswers([])
    setEditingOrder(false)
  }

  const handleSaveReorder = () => {
    const orderedAnswerIds = reorderedAnswers.map((answer) => answer.id)
    reorderAnswers(orderedAnswerIds, handleResetReorder)
  }

  if (readOnly) {
    return (
      <>
        {guideAnswersMemo.map((item, index) => (
          <PortalAwareItem
            key={item.id}
            readOnly={true}
            disableDrag={true}
            locale={locale}
            questionId={questionId}
            questionName={questionName}
            item={item}
            questionFilter={questionFilter}
            isLast={guideAnswersMemo.length === index + 1}
            forceShowSpecialName={forceShowSpecialName}
          />
        ))}

        {!hideCreator && (
          <CreateAnswer
            questionId={questionId}
            locale={locale}
            disabled={editingOrder || disableCreator}
          />
        )}
      </>
    )
  }

  return (
    <div className="flex flex-col">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {guideAnswersMemo.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <PortalAwareItem
                      readOnly={readOnly || editingOrder}
                      disableDrag={readOnly}
                      locale={locale}
                      questionId={questionId}
                      questionName={questionName}
                      provided={provided}
                      snapshot={snapshot}
                      item={item}
                      questionFilter={questionFilter}
                      isLast={guideAnswersMemo.length === index + 1}
                      forceShowSpecialName={forceShowSpecialName}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {editingOrder && (
        <div className="flex justify-end gap-2 my-4">
          <Button
            mode="default"
            onClick={handleResetReorder}
            disabled={reorderAnswersLoading}
          >
            {t('adminManageGuide.guide.question.edit.answers.resetReorder')}
          </Button>
          <Button
            className="min-w-10!"
            onClick={handleSaveReorder}
            loading={reorderAnswersLoading}
          >
            {t('adminManageGuide.guide.question.edit.answers.saveReorder')}
          </Button>
        </div>
      )}

      <CreateAnswer
        questionId={questionId}
        locale={locale}
        disabled={editingOrder || disableCreator}
      />
    </div>
  )
}

ManageAnswersList.propTypes = {
  questionId: string,
  guideAnswers: array,
  questionName: string,
  locale: string.isRequired,
  readOnly: bool
}

ManageAnswersList.defaultProps = {}

export default ManageAnswersList
