import React from 'react'
import classnames from 'classnames'

const Label = ({
  className,
  children,
  title,
  subTitle,
  helperText,
  marginless,
  labelClassname
}) => {
  return (
    <div
      className={classnames(className, {
        'mb-4 mt-4': !marginless
      })}
    >
      <label className={`${labelClassname} block text-sm font-medium text-gray-700`}>
        {title}
      </label>
      {subTitle && <span className="text-sm text-gray-500">{subTitle}</span>}
      <div className="mt-1">{children}</div>
      {helperText && <span className="text-sm text-gray-500">{helperText}</span>}
    </div>
  )
}

Label.propTypes = {}

Label.defaultProps = {}

export default Label
