import React from 'react'
import cx from 'classnames'
import styles from './loader.module.css'

const Loader = ({ className }) => (
  <div className={cx(styles.ldsEllipsis, className)}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default Loader
