import React, { useState } from 'react'
import { func, object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useAutoSaveSingle from '../../../hooks/useAutoSaveSingle'
import useApproveReview from './hooks/useApproveReview'
import useEditReview from './hooks/useEditReviewData'
import useSendVerificationEmail from './hooks/useSendVerificationEmail'
import useDeleteReview from './hooks/useDeleteReview'

import ReviewItemForm from '../../reviews/your-reviews/item-form'
import Button from '../../shared/button'
import SitesSelect from '../../shared/sites-select'
import Input from '../../shared/input'
import ModalNotification from '../../modalNotification'
import Dialog from '../../shared/dialog'

const OurComment = ({ reviewDataId, cachedComment, onEditComment, onChange }) => {
  const { setAutoSaveValue: handleChangeComment, autoSaveValue: ourComment } =
    useAutoSaveSingle(cachedComment, (newComment) => {
      onEditComment(reviewDataId, newComment)
    })

  const handleChagne = (value) => {
    onChange()
    handleChangeComment(value)
  }

  return <Input value={ourComment || ''} onChange={handleChagne} />
}

const ReviewItem = ({ review, getCategoriesStringFn }) => {
  const { t } = useTranslation(['adminPages'])

  const [commentChangesLoading, setCommentChangesLoading] = useState(false)
  const [visibleVerificationModal, setVisibleVerificationModal] = useState(false)
  const [deleteReviewDataId, setDeleteReviewDataId] = useState(false)

  const { deleteReview, deleteReviewLoading } = useDeleteReview()
  const { approveReview, approveReviewLoading } = useApproveReview()
  const { editReviewData, editReviewDataLoading } = useEditReview()
  const {
    sendVerificationEmail,
    sendVerificationEmailLoading,
    sendVerificationEmailCalled
  } = useSendVerificationEmail()

  const handleSendVerificationEmail = (reviewId) => {
    sendVerificationEmail(reviewId, () => {
      setVisibleVerificationModal(true)
    })
  }

  const handleChangeApprove = (reviewDataId, approve) => {
    approveReview(reviewDataId, approve)
  }

  const handleEditLocale = (reviewDataId, locale) => {
    editReviewData(reviewDataId, {
      locale
    })
  }

  const handleDeleteReview = (reviewDataId) => {
    setDeleteReviewDataId(reviewDataId)
  }

  const doDeleteReviewData = () => {
    deleteReview(deleteReviewDataId)
  }

  const handleEditComment = (reviewDataId, updatedComment) => {
    editReviewData(reviewDataId, {
      ourComment: updatedComment
    }).finally(() => setCommentChangesLoading(false))
  }

  return (
    <div key={review.id}>
      <div className="flex gap-6 flex-col">
        <div className="text-sm flex gap-8 flex-col md:flex-row">
          <ul className="flex-1 font-medium text-gray-900">
            <li>
              {review.email}
              {review.reviewData.emailApproved ? (
                <p className="text-green-600">VERIFIED</p>
              ) : (
                <p className="text-red-600">UNVERIFIED</p>
              )}
            </li>
            <li>
              {!review.reviewData.emailApproved && (
                <Button
                  type="button"
                  size="small"
                  onClick={() => handleSendVerificationEmail(review.id)}
                  disabled={sendVerificationEmailCalled}
                  loading={sendVerificationEmailLoading}
                >
                  {t('adminApproveReviews.button.sendVerificationEmail')}
                </Button>
              )}
            </li>
            <li className="text-gray-500">{review.reviewData.reviewRole}</li>
            <li className="text-gray-500">{review.reviewData.reviewCompanySize}</li>
          </ul>
          <ul className="flex-1">
            <li className="text-gray-500">
              {getCategoriesStringFn(review.reviewData.systemV2.categories)}
            </li>
            <li>
              <SitesSelect
                onChange={(locale) => handleEditLocale(review.reviewData.id, locale)}
                value={review.reviewData.locale}
                disableAutoInit
                loading={editReviewDataLoading}
                marginless
              />
            </li>
          </ul>
        </div>

        <ReviewItemForm review={review.reviewData} />
      </div>

      <div className="mt-6">
        <div className="flex-1">
          {review.reviewData.systemV2.systemSiteData
            .filter(
              (systemSiteData) => systemSiteData.locale === review.reviewData.locale
            )
            .map((systemSiteData) => {
              return (
                <span key={systemSiteData.locale}>
                  {t('content:customerType')}: {systemSiteData.customerType}
                </span>
              )
            })}
        </div>
      </div>
      <div className="mt-4 flex gap-3 flex-col sm:flex-row sm:items-end">
        <div className="flex-1">
          <label htmlFor="ourComment" className="block text-sm font-medium text-gray-700">
            {t('sharedReviewFields.ourComment')}
          </label>
          <div className="mt-1">
            <OurComment
              reviewDataId={review.reviewData.id}
              cachedComment={review.reviewData.ourComment}
              onEditComment={handleEditComment}
              onChange={() => setCommentChangesLoading(true)}
            />
          </div>
        </div>
        <div className="flex sm:flex gap-3 justify-end">
          <Button
            mode="error"
            onClick={() => handleDeleteReview(review.reviewData.id)}
            disabled={
              approveReviewLoading ||
              commentChangesLoading ||
              review.reviewData.approved === false
            }
          >
            {t('content:delete')}
          </Button>

          <Button
            mode="outlined"
            disabled={
              approveReviewLoading ||
              commentChangesLoading ||
              review.reviewData.approved === false
            }
            onClick={() => handleChangeApprove(review.reviewData.id, false)}
          >
            {t('adminApproveReviews.button.reject')}
          </Button>

          <Button
            disabled={review.reviewData.approved === true}
            loading={approveReviewLoading || commentChangesLoading}
            onClick={() => handleChangeApprove(review.reviewData.id, true)}
          >
            {t('adminApproveReviews.button.approve')}
          </Button>
        </div>
      </div>

      <ModalNotification
        title={t('adminApproveReviews.sendVerificationEmailSent.title')}
        text={<span>{t('adminApproveReviews.sendVerificationEmailSent.text')} </span>}
        visible={visibleVerificationModal}
        handleVisible={() => {
          setVisibleVerificationModal(false)
        }}
      />

      <Dialog
        title={t('content:delete')}
        confirmText={t('content:delete')}
        isOpen={!!deleteReviewDataId}
        onConfirm={doDeleteReviewData}
        onClose={setDeleteReviewDataId}
        loading={deleteReviewLoading}
      />
    </div>
  )
}

ReviewItem.propTypes = {
  review: object.isRequired,
  getCategoriesStringFn: func.isRequired
}

ReviewItem.defaultProps = {}

export default ReviewItem
