import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import { useUserContext, useAuthContext } from '../contexts'
import { isAdmin } from '../utils'

import Loader from '../components/loader'

import { useNavigate } from 'react-router-dom'

import * as ga from '../lib/ga'

import { LockClosedIcon, XCircleIcon } from '@heroicons/react/solid'

const LOG_IN = gql`
  mutation LogIn($email: String!, $password: String!) {
    appLogin(email: $email, password: $password) {
      token
      isFirstLogin
      error
      user {
        id
        name
        email
        role
        appRole
        appAccepted
        appAcceptedDate
        vendor {
          id
          company
          accessRights
        }
      }
    }
  }
`

function LoginPage() {
  const { handleLogin } = useUserContext()
  const { auth } = useAuthContext()
  const [errorName, setErrorName] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation('signin')
  const tempStorage = window.sessionStorage

  let email
  let password

  const [logIn, { data: logInData, loading: logInLoading }] = useMutation(LOG_IN, {
    onError(err) {
      const error = `${err}`.split(':').reverse()[0]
      setErrorName(error)
    },
    onCompleted(data) {
      if (data.appLogin.error?.length) {
        setErrorName(data.appLogin.error)
      } else {
        handleLogin(data.appLogin)
      }
    }
  })

  useEffect(() => {
    if (!logInData || !auth?.isLoggedIn) {
      return
    }

    const isBusinessWithAdmin = isAdmin(logInData.appLogin.user.role)

    if (logInData.appLogin.isFirstLogin) {
      navigate('/update-password')
    } else if (logInData.appLogin.user.role === 'MARKET_RESEARCHER') {
      navigate('/market-researcher')
    } else if (!logInData.appLogin.user.vendor && !isBusinessWithAdmin) {
      navigate('/no-vendor')
    } else if (logInData.appLogin.user.appAccepted) {
      navigate('/')
    } else {
      navigate('/accept')
    }
  }, [logInData, logInLoading, navigate, auth])

  return (
    <>
      {/* Hero section */}
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                alt="People working on laptops"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-500 mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white font-bold">{t('header.title_1')}</span>
                <span className="block text-blue-200 font-extrabold">
                  {t('header.title_2')}
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {t('form.title')}
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            onSubmit={(e) => {
              e.preventDefault()
              logIn({
                variables: { email: email.value?.toLowerCase(), password: password.value }
              })

              // TODO: Only if first login
              tempStorage.setItem('pass', password.value)
              // email.value = "";
              // password.value = "";
              setErrorName(null)

              ga.event({
                action: 'login'
                // params : {
                //   search_term: query
                // }
              })
            }}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  {t('content:email')}
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder={t('content:email')}
                  ref={(node) => {
                    email = node
                  }}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  {t('content:password')}
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder={t('content:password')}
                  ref={(node) => {
                    password = node
                  }}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="/request-password-reset"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  {t('signin:form.forgot_password')}
                </a>
              </div>
            </div>

            {logInLoading && !errorName && (
              <div className="text-center">
                <Loader />
              </div>
            )}

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                    aria-hidden="true"
                  />
                </span>
                {t('content:signin')}
              </button>
            </div>

            {errorName && (
              <div className="rounded-md bg-red-50 border border-red-300 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {t('loginError')}
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul role="list" className="list-disc pl-5 space-y-1">
                        <li>{errorName}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-white">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">{t('wantToLearnMore')}</span>
            <span className="block bg-gradient-to-r from-blue-600 to-blue-600 bg-clip-text text-transparent">
              {t('contactUsOrReadMore')}
            </span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <a
              href="https://businesswith.se/for-leverantorer/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center bg-gradient-to-r from-blue-600 to-blue-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-blue-700 hover:to-blue-700"
            >
              {t('content:readMore')}
            </a>
          </div>
        </div>
      </div>

      {/* <form
        className={stylesForm.form}
        onSubmit={(e) => {
          e.preventDefault();
          logIn({
            variables: { email: email.value, password: password.value },
          });

          // TODO: Only if first login
          tempStorage.setItem("pass", password.value);
          // email.value = "";
          // password.value = "";
          setErrorName(null);

          ga.event({
            action: "login",
            // params : {
            //   search_term: query
            // }
          });
        }}
      >
        <div className={stylesForm.inputrow}>
          <label htmlFor="email" className={stylesForm.label}>
            Email
          </label>
          <input
            id="email"
            type="email"
            className={stylesForm.input}
            // placeholder="din@epost.se"
            ref={(node) => {
              email = node;
            }}
          />
        </div>

        <div className={stylesForm.inputrow}>
          <label htmlFor="password" className={stylesForm.label}>
            Password
          </label>
          <input
            id="password"
            type="password"
            className={stylesForm.input}
            // placeholder="d1tt-un1ka-losenord!"
            ref={(node) => {
              password = node;
            }}
          />
        </div>

        <div className={stylesForm.submitBtnWrap}>
          <button
            type="submit"
            className={`btn btn-primary ${stylesForm.submitBtn}`}
            disabled={logInLoading ? true : false}
          >
            Logga in
          </button>
        </div>

        {errorName && (
          <div style={{ textAlign: "center", color: "red", padding: "10px" }}>
            {errorName}
          </div>
        )}
      </form> */}

      {/* <p className={stylesLogin.description}>
        <a href="mailto:christian.gidlund@businesswith.se">Kontakta oss</a> för
        tillgång till portalen
      </p>
      <p className="" style={{ textAlign: "center" }}>
        <Link href="/request-password-reset">
          <a>Glömt lösenordet?</a>
        </Link>
        <br />
        <br />
        <Link href="/create-account">
          <a>Skapa konto</a>
        </Link>
      </p> */}
    </>
  )
}

// Page.getInitialProps = async (ctx) => {
//   //   console.log({ ctx });
//   const res = await fetch("http://localhost:4000/api-key/");
//   //   console.log(res);
//   const json = await res.json();
//   //   console.log(json);
//   return { uuid: json.apiKey };
// };

export default LoginPage
