import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import useOverlayNotification from '../useOverlayNotification'

const GQL_DELETE_SYSTEM_PROPS = gql`
  mutation DeleteSystemPropsMR($id: ID!, $propType: PropTypes!) {
    mrDeleteSystemProps(id: $id, propType: $propType)
  }
`

export default function useDeleteSystemProps(propType) {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [mrDeleteSystemPropsMutation, { loading }] = useMutation(
    GQL_DELETE_SYSTEM_PROPS,
    {
      onError: () => showErrorNotification()
    }
  )

  const mrDeleteSystemProps = useCallback(
    (id, onCompleted) => {
      return mrDeleteSystemPropsMutation({
        variables: {
          id,
          propType
        },
        onCompleted: (data) => {
          showSuccessNotification()
          onCompleted && onCompleted(data)
        }
      })
    },
    [mrDeleteSystemPropsMutation, propType, showSuccessNotification]
  )

  return {
    mrDeleteSystemProps,
    mrDeleteSystemPropsLoading: loading
  }
}
