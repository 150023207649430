import React, { memo } from 'react'
import { Menu } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import i18n, { AVAILABLE_LOCALE_TRANSLATIONS } from '../../i18n'

import MenuTransition from '../shared/dropdown/menu-transition'
import MenuItem from '../shared/dropdown/menu-item'
import Flag from '../shared/flag'

const LanguageSwitcher = () => {
  const { t } = useTranslation()
  const currentLanguage = i18n.language

  const handleChangeLanguage = (langCode) => {
    i18n.changeLanguage(langCode)
    localStorage.setItem('siteLanguage', langCode)
  }

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="overflow-hidden max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        <Flag className="p-1" locale={currentLanguage} height="34px" />
      </Menu.Button>

      <MenuTransition>
        <Menu.Items className="absolute right-0 z-10 mt-1 py-1 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {AVAILABLE_LOCALE_TRANSLATIONS.map((locale) => {
            if (locale === currentLanguage) {
              return null
            }

            return (
              <MenuItem key={locale} onClick={() => handleChangeLanguage(locale)}>
                <a className="flex gap-2">
                  <Flag locale={locale} height="20px" />
                  {t(`content:localeToLanguage.${locale}`, { lng: locale })}
                </a>
              </MenuItem>
            )
          })}
        </Menu.Items>
      </MenuTransition>
    </Menu>
  )
}

LanguageSwitcher.propTypes = {}

LanguageSwitcher.defaultProps = {}

export default memo(LanguageSwitcher)
