import { useMemo } from 'react'
import useAdminQuery from './useAdminQuery'
import { useParams } from 'react-router-dom'
import { useUserContext } from '../contexts'
import { useQuery, gql } from '@apollo/client'

import { isAdmin, isResearcher } from '../utils'

const GET_VENDOR_OUTBOUNDS_GQL = gql`
  query VendorOutbounds($vendorId: String!) {
    appVendorOutbounds(vendorId: $vendorId) {
      systemId
      systemV2 {
        systemSiteData {
          locale
          systemName
          companyName
        }
      }
      outbounds {
        period
        periods {
          whereOnSite
          count
          startDate
        }
      }
    }
  }
`

export default function useFetchVendorOutbounds(fetchPolicy = 'cache-and-network') {
  const { vendorId } = useParams()

  const { data, loading, refetch } = useAdminQuery(
    GET_VENDOR_OUTBOUNDS_GQL,
    {
      variables: {
        vendorId: vendorId
      }
    },
    fetchPolicy
  )

  return {
    vendorOutbounds: data?.appVendorOutbounds,
    systemLoading: loading,
    refetchSystem: refetch
  }
}
