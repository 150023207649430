import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'

const GQL_FETCH_SYSTEMS_OTHER_SITES_MR = gql`
  query ListSystemsOtherSitesMR(
    $currentLocale: String!
    $currentCategory: String!
    $compareLocale: String
    $compareCategory: String
  ) {
    mrListSystemsOtherSites(
      currentLocale: $currentLocale
      currentCategory: $currentCategory
      compareLocale: $compareLocale
      compareCategory: $compareCategory
    ) {
      id
      createdAt
      systemNamesToLocales
    }
  }
`

const EMPTY_ARR = []

export default function useFetchSystemsOtherSites(compareLocale, compareCategory) {
  const { locale, category } = useParams()

  const { data, loading } = useQuery(GQL_FETCH_SYSTEMS_OTHER_SITES_MR, {
    fetchPolicy: 'cache-and-network',
    variables: {
      currentLocale: locale,
      currentCategory: category,
      compareLocale,
      compareCategory
    },
    skip: !locale || !category
  })

  return {
    mrSystemsOtherSites: data?.mrListSystemsOtherSites || EMPTY_ARR,
    mrSystemsOtherSitesLoading: loading
  }
}
