import { useCallback, useState } from 'react'

export default function useFormFields(initialState, category) {
  const [fields, setValues] = useState(initialState)
  const [changedFields, setChangedFields] = useState({})
  const handleChange = useCallback(
    (e, locale) => {
      const { type, value, name, id } = e.target
      let idToSave
      let valueToSave

      if (type === 'radio') {
        idToSave = name.includes('--') ? name.split('--')[1] : name
        valueToSave = value

        if (
          value.startsWith('systemGuideProps') ||
          value.startsWith('systemProps') ||
          value.startsWith('systemAreas')
        ) {
          const values = value.split('--')
          idToSave = [values[0]]

          const oldData = fields[idToSave] || {}

          if (values[2] === 'true') {
            values[2] = true
          } else if (values[2] === 'false') {
            values[2] = false
          }

          valueToSave = {
            ...oldData,
            [category]: {
              ...oldData[category],
              [values[1]]: values[2]
            }
          }
        }
      } else if (type === 'checkbox') {
        idToSave = name.includes('--') ? name.split('--')[1] : name

        let oldValues = []

        if (fields[idToSave]) {
          oldValues = [...fields[idToSave]]
        }

        const fixId = id.includes('--') ? id.split('--')[1] : id

        if (oldValues?.includes(fixId)) {
          // Remove
          const pos = oldValues.indexOf(fixId)
          oldValues.splice(pos, 1)
          valueToSave = [...oldValues]
        } else {
          // Add
          valueToSave = [...oldValues, fixId]
        }
      } else if (type === 'number') {
        idToSave = id
        valueToSave = value || undefined
      } else {
        idToSave = id
        valueToSave = value
      }

      // Nested object (systemSiteData)
      if (typeof locale === 'string' && locale) {
        let changedSiteDataFields = {
          systemSiteData: changedFields.systemSiteData || []
        }

        // Create new if doesnt exist
        if (!fields.systemSiteData) {
          fields.systemSiteData = []
        }

        const systemSiteDataIndex = fields.systemSiteData.findIndex(
          (siteData) => siteData.locale === locale
        )

        const changedSiteDataIndex = changedSiteDataFields.systemSiteData.findIndex(
          (changedSiteData) => changedSiteData.locale === locale
        )

        if (systemSiteDataIndex === -1) {
          fields.systemSiteData.push({
            [idToSave]: valueToSave,
            locale
          })
        } else {
          fields.systemSiteData[systemSiteDataIndex][idToSave] = valueToSave
        }

        if (changedSiteDataIndex === -1) {
          changedSiteDataFields.systemSiteData.push({
            [idToSave]: valueToSave,
            locale
          })
        } else {
          changedSiteDataFields.systemSiteData[changedSiteDataIndex][idToSave] =
            valueToSave
        }
        setValues({ ...fields })

        setChangedFields((prevChangedFields) => ({
          ...prevChangedFields,
          ...changedSiteDataFields
        }))
        return
      }

      setValues({
        ...fields,
        [idToSave]: valueToSave // type === 'checkbox' ? checked : value,
      })

      setChangedFields((prevChangedFields) => {
        if (valueToSave === undefined) {
          valueToSave = null
        }

        return { ...prevChangedFields, [idToSave]: valueToSave }
      })
    },
    [changedFields, category, fields]
  )

  const setInitForm = useCallback((initialState) => {
    setValues(initialState)
    setChangedFields({})
  }, [])

  return [fields, handleChange, setInitForm, setValues, changedFields, setChangedFields]
}
