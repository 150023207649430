import React from 'react'

import { gql, useQuery } from '@apollo/client'

import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'

import { useNavigate } from 'react-router-dom'

const QUERY = gql`
  query User {
    user {
      id
      role
      vendor {
        id
      }
      appAccepted
    }
  }
`

function NoVendorPage() {
  const navigate = useNavigate()
  const { user, updateUser } = useUserContext()

  useQuery(QUERY, {
    onCompleted(data) {
      updateUser(data.user)

      if (data.user?.vendor) {
        navigate('/')
      }

      if (isAdmin(data.user.role)) {
        navigate('/')
      }
    }
  })

  return (
    <>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 mb-10">
        Hej, {user?.name}
      </h2>
      <div className="pb-10 bg-white">
        <p className="text-center">Tyvärr är det inte något konto kopplat än.</p>
        <p className="text-center">
          För att komma vidare vänligen kontakta BusinessWith:{' '}
          <a href="mailto:christian.gidlund@businesswith.se" className="underline">
            maila oss
          </a>
        </p>
      </div>
    </>
  )
}

export default NoVendorPage
