import React, { useEffect, useMemo, useState } from 'react'
import { array, func } from 'prop-types'
import isEqual from 'react-fast-compare'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Label from '../../shared/label'
import Input from '../../shared/input'
import Button from '../../shared/button'

import { findSystemSiteDataForLocale } from '../../../helpers/systems.helper'

const DEFAULT_FILTERS = {
  freeInput: ''
}

const SystemsFilter = ({ systems, systemsOtherSites, onFilter }) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const { locale } = useParams()

  useEffect(() => {
    if (!systems) {
      return
    }

    const filterNormalized = filters.freeInput.toLowerCase()

    const filteredSystems = systems.filter((system) => {
      const siteData = findSystemSiteDataForLocale(system, locale)
      const normalizedName = siteData?.systemName?.toLowerCase()

      return normalizedName?.includes(filterNormalized)
    })

    const filteredSystemsOtherSites = systemsOtherSites.filter((system) => {
      return Object.keys(system.systemNamesToLocales).some((systemName) => {
        return systemName.toLowerCase()?.includes(filterNormalized)
      })
    })

    onFilter(filteredSystems, filteredSystemsOtherSites)
  }, [filters.freeInput, systems, systemsOtherSites, onFilter, locale])

  const hasAppliedFilter = useMemo(() => {
    return !isEqual(filters, DEFAULT_FILTERS)
  }, [filters])

  const resetFilter = () => {
    setFilters(DEFAULT_FILTERS)
  }

  const handleChangeFilter = (filterName, value) => {
    setFilters((prevFilters) => {
      return { ...prevFilters, [filterName]: value }
    })
  }

  return (
    <div className="flex flex-row flex-1 items-end gap-2">
      <div className="flex-1">
        <Label
          title={t('marketResearcher:categories.filter.label.textSearch')}
          marginless
        >
          <Input
            id="freeInput"
            placeholder={t('marketResearcher:categories.filter.label.textSearch')}
            onChange={handleChangeFilter}
            value={filters.freeInput}
          />
        </Label>
      </div>

      <div>
        <Button
          className="w-full"
          mode="default"
          onClick={resetFilter}
          disabled={!hasAppliedFilter}
        >
          {t('content:clear')}
        </Button>
      </div>
    </div>
  )
}

SystemsFilter.propTypes = {
  systems: array,
  onFilter: func.isRequired
}

SystemsFilter.defaultProps = {}

export default SystemsFilter
