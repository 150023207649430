export const getWebhookEventTriggerStatuses = (webhooks) => {
  const result = {
    hasInquiryWebhook: false,
    hasGuideWebhook: false
  }
  webhooks.forEach((webhook) => {
    if (webhook.events.includes('EVENT_NEW_INQUIRY_LEAD')) {
      result.hasInquiryWebhook = true
    }
    if (webhook.events.includes('EVENT_NEW_GUIDE_LEAD')) {
      result.hasGuideWebhook = true
    }
  })
  return result
}
