import React, { useEffect } from 'react'

import useFetchGuide from '../hooks/manage-guides/useFetchGuide'

import Header from '../components/admin/manage-guides/header'
import { PageBody } from '../components/page-body'

import ManageGuide from '../components/admin/manage-guides/guide/manage-guide'

const AdminManageGuidePage = () => {
  const { guide, guideLoading, refetchGuide } = useFetchGuide()

  useEffect(() => {
    refetchGuide()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />

      <PageBody loading={guideLoading}>
        <ManageGuide guide={guide} />
      </PageBody>
    </>
  )
}

AdminManageGuidePage.propTypes = {}

AdminManageGuidePage.defaultProps = {}

export default AdminManageGuidePage
