import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Loader from '../components/loader'
import { PageBody } from '../components/page-body'
import { useTranslation, Trans } from 'react-i18next'
import * as ga from '../lib/ga'
import { XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import stylesForm from '../components/form.module.css'
import { Link } from 'react-router-dom'
import Checkbox from '../components/shared/checkbox'
import { validatePassword } from '../validators/password.validator'
import PasswordStrengthChecker from '../components/shared/password-strength-checker'
import PasswordRulesInformation from '../components/form/password-rules-information'
import { CheckCircleIcon } from '@heroicons/react/solid'

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $newPassword: String!
    $repeatPassword: String!
    $userId: String!
    $token: String!
  ) {
    appResetPassword(
      newPassword: $newPassword
      repeatPassword: $repeatPassword
      userId: $userId
      token: $token
    )
  }
`

function ResetPasswordPage() {
  let formNewPassword
  let formRepeatPassword

  const { t } = useTranslation('passwordReset')

  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [errorName, setErrorName] = useState(null)
  const [passwordWarning, setPasswordWarning] = useState('')
  const [validatorResults, setValidatorResults] = useState({})

  const [appResetPassword, { data: resetData, loading: resetLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onError(err) {
        const error = `${err}`.split(':').reverse()[0]
        setErrorName(error)
      }
    }
  )

  const checkPassword = () => {
    let hasDisabledSubmit = true
    const password = formNewPassword.value
    const repeatPassword = formRepeatPassword.value
    setPassword(password)

    const passwordValidator = validatePassword(password, repeatPassword, t)

    if (!passwordValidator.isValid) {
      if (!passwordValidator.results.identicalMatch) {
        setPasswordWarning(passwordValidator.errors[0])
      } else {
        setPasswordWarning(null)
      }
      hasDisabledSubmit = true
    } else {
      setPasswordWarning(null)
      hasDisabledSubmit = false
    }
    if (password.length !== repeatPassword.length) {
      hasDisabledSubmit = true
    }
    setDisabledSubmit(hasDisabledSubmit)
    setValidatorResults(passwordValidator.results)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const url = window.location.href
    const query = url.split('?')[1]
    const queries = query.split('&')

    const token = queries[0].split('=')[1]
    const userId = queries[1].split('=')[1]

    if (!formNewPassword.value?.length) {
      setErrorName(t('validation.passwordRequired'))
      return false
    }

    appResetPassword({
      variables: {
        newPassword: formNewPassword.value,
        repeatPassword: formRepeatPassword.value,
        userId,
        token
      }
    })
    formNewPassword.value = ''
    formRepeatPassword.value = ''
    setErrorName(null)
    ga.event({
      action: 'reset_password'
    })
  }

  if (resetLoading) {
    return <Loader />
  }

  // TODO: Add translations to password reset section
  if (resetData) {
    return (
      <>
        <div className="flex flex-col items-center justify-center  bg-gray-50 p-4 py-10">
          <div className="rounded-lg  p-6 max-w-md text-center">
            <CheckCircleIcon className="h-16 w-16 text-green-500 mx-auto mb-4" />
            <h1 className="text-2xl font-bold mb-2 text-gray-800">{t('success')}!</h1>
            <p className="text-lg text-gray-700">{t('successMessage')}</p>
            <p className="text-gray-600 mt-4">
              <Trans
                i18nKey="passwordReset:goToSignin"
                components={[<Link to="/login" />]}
              />
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <PageBody>
      <div className={'mx-auto max-w-[500px] max-md:w-full max-md:px-2'}>
        <h1 className={'text-4xl font-medium'}>
          <span>{t('title')}</span>
        </h1>
        <p className={'my-8'}>{t('description')}</p>
      </div>

      <form
        className={
          'mx-auto max-w-[500px] max-md:w-full my-10 py-5 px-8 bg-white shadow rounded-md'
        }
        onSubmit={handleSubmit}
      >
        <div className={stylesForm.inputrow}>
          <label htmlFor="newPassword" className={stylesForm.label}>
            {t('form.newPassword')}
          </label>
          <input
            id="formNewPassword"
            onChange={checkPassword}
            type={showPassword ? 'text' : 'password'}
            className={
              'appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm'
            }
            ref={(node) => {
              formNewPassword = node
            }}
          />
        </div>
        <div className={stylesForm.inputrow}>
          <label htmlFor="repeatPassword" className={stylesForm.label}>
            {t('form.repeatPassword')}
          </label>
          <input
            id="formRepeatPassword"
            onChange={checkPassword}
            type={'password'}
            className={
              'appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm'
            }
            ref={(node) => {
              formRepeatPassword = node
            }}
          />
        </div>
        {passwordWarning && (
          <div className="row-span-1 sm:row-span-1 text-sm text-red-500 flex gap-1 items-center my-2 font-semibold">
            <ExclamationCircleIcon className={'w-[18px]'} /> {passwordWarning}
          </div>
        )}
        <Checkbox
          label={t('form.showPassword')}
          onChange={() => {
            setShowPassword(!showPassword)
          }}
        />
        <PasswordStrengthChecker password={password} />
        <PasswordRulesInformation validatorResults={validatorResults} />
        <div className={stylesForm.submitBtnWrap}>
          <button
            type="submit"
            disabled={disabledSubmit}
            className={`my-5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
              disabledSubmit && 'bg-gray-400 cursor-not-allowed'
            }`}
          >
            {t('form.btnSaveNewPassword')}
          </button>
        </div>

        {errorName && (
          <div className="rounded-md bg-red-50 border border-red-300 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  {t('content:error.somethingWentWrong')}
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul role="list" className="list-disc pl-5 space-y-1">
                    <li>{errorName}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </PageBody>
  )
}

export default ResetPasswordPage
