import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const GQL_ADD_MARKET_RESEARCHER = gql`
  mutation AddMarketResearcherMutation($data: CreateUserMRInput!) {
    mrCreateUser(data: $data) {
      id
      name
      email
      latestSignedIn
      marketResearcherLocales
      tempPassword
    }
  }
`

export default function useCreateMarketResearcher() {
  const { showErrorNotification } = useOverlayNotification()

  const [addMarketResearcher, { loading: createMarketResearcherLoading }] = useMutation(
    GQL_ADD_MARKET_RESEARCHER
  )

  const createMarketResearcherCallback = useCallback(
    (data, onCompleted) => {
      return addMarketResearcher({
        variables: {
          data
        },
        onCompleted,
        onError: showErrorNotification
      })
    },
    [addMarketResearcher, showErrorNotification]
  )

  return {
    createMarketResearcher: createMarketResearcherCallback,
    createMarketResearcherLoading
  }
}
