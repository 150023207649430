import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useManageSystemContext } from '../../context'
import useUpdateSystemCategorySiteData from '../../../../hooks/useUpdateSystemCategorySiteData'
import useGetCategoryName from '../../../../hooks/useGetCategoryName'

import { TagIcon } from '@heroicons/react/outline'
import Label from '../../../shared/label'
import { MultiSelect } from '../../../shared/select'
import Tabs from '../../../shared/tabs'
import Button from '../../../shared/button'

import { ARRAY_BRANCHES } from '../../../../constants/profileFields'

const branchOptions = ARRAY_BRANCHES.map((branch) => {
  return {
    labelTid: branch.titleTid,
    value: branch.id
  }
})

const AdminGuideOverrides = ({ systemSiteData }) => {
  const { system } = useManageSystemContext()
  const [formData, setFormData] = useState(null)
  const [selectedCategoryTab, setSelectedCategoryTab] = useState(null)
  const { t } = useTranslation()
  const { getCategoryName } = useGetCategoryName()
  const { updateSystemCategorySiteData, updateSystemCategorySiteDataLoading } =
    useUpdateSystemCategorySiteData()

  const branchSelectValuesMemo = useMemo(() => {
    if (formData) {
      return formData
    }

    return systemSiteData?.systemCategorySiteData
      ? systemSiteData?.systemCategorySiteData?.reduce((acc, siteData) => {
          acc[siteData.category] = siteData.branch || []
          return acc
        }, {})
      : {}
  }, [formData, systemSiteData.systemCategorySiteData])

  const btnSaveDisabled = !formData || !Object.keys(formData).length

  const categoryTabsMemo = useMemo(() => {
    if (!system?.categories) {
      return []
    }

    return system.categories.map((category) => {
      return {
        icon: TagIcon,
        label: getCategoryName(category),
        value: category
      }
    })
  }, [system?.categories, getCategoryName])

  useEffect(() => {
    if (selectedCategoryTab || !categoryTabsMemo.length) {
      return
    }

    setSelectedCategoryTab(categoryTabsMemo[0])
  }, [categoryTabsMemo, selectedCategoryTab])

  const handleChange = (fieldName, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [fieldName]: value
      }
    })
  }

  const handleSave = () => {
    const changes = Object.entries(formData).reduce((acc, [category, branch]) => {
      acc.push({ category, branch })
      return acc
    }, [])

    updateSystemCategorySiteData(systemSiteData.id, changes, () => {
      setFormData(null)
    })
  }

  return (
    <>
      <p className="text-base font-medium text-gray-900">
        {t('forms:system.site.admin.categoryOverrides.title', {
          locale: systemSiteData.locale
        })}
      </p>

      <div>
        <div className="my-2">
          <Tabs
            tabs={categoryTabsMemo}
            activeTab={selectedCategoryTab?.value}
            onChange={setSelectedCategoryTab}
          />
        </div>

        <Label
          title={t('forms:system.site.admin.categoryOverrides.branch.label')}
          helperText={t('forms:system.site.admin.categoryOverrides.branch.helperText', {
            locale: systemSiteData.locale,
            category: selectedCategoryTab?.label
          })}
        >
          <MultiSelect
            id={selectedCategoryTab?.value}
            options={branchOptions}
            onChange={handleChange}
            value={branchSelectValuesMemo[selectedCategoryTab?.value]}
          />
        </Label>
      </div>

      <div className="flex justify-end">
        <Button
          onClick={handleSave}
          disabled={btnSaveDisabled}
          loading={updateSystemCategorySiteDataLoading}
        >
          {t('content:save')}
        </Button>
      </div>
    </>
  )
}

AdminGuideOverrides.propTypes = {}

AdminGuideOverrides.defaultProps = {}

export default AdminGuideOverrides
