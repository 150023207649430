import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

const GQL_AVAILABLE_SITES = gql`
  query FetchAvailableSites {
    appGetAvailableSites {
      defaultLocale
    }
  }
`

const EMPTY_ARR = []

export default function useFetchAvailableSites(skip) {
  const { data, loading } = useQuery(GQL_AVAILABLE_SITES, {
    skip
  })

  const sites = data?.appGetAvailableSites

  const sitesOptions = useMemo(() => {
    if (!sites) {
      return []
    }

    return sites.map((siteData) => {
      const { defaultLocale } = siteData

      return {
        labelTid: `shared:localeToCountry.${defaultLocale}`,
        value: defaultLocale
      }
    })
  }, [sites])

  return {
    sites: sites || EMPTY_ARR,
    sitesLoading: loading,
    sitesOptions
  }
}
