import React from 'react'

import { useManageSystemContext } from '../../context'

import Dialog from '../../../shared/dialog'

const ImagePreviewModal = () => {
  const { previewImageUrl, setPreviewImageUrl } = useManageSystemContext()

  return (
    <Dialog
      className="max-w-5xl w-fit"
      isOpen={!!previewImageUrl}
      onClose={setPreviewImageUrl}
    >
      {previewImageUrl && <img src={previewImageUrl} />}
    </Dialog>
  )
}

ImagePreviewModal.propTypes = {}

ImagePreviewModal.defaultProps = {}

export default ImagePreviewModal
