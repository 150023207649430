import React, { useState } from 'react'
import { func } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Dialog from '../../shared/dialog'
import LoadingSpinner from '../../shared/loading-spinner'

import DownloadCsvButton from '../../shared/download-csv-btn'

const AdminGuidesExport = ({ onFetchByLazyQuery }) => {
  const { t } = useTranslation()
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)

  const handleFetchData = (dataDoneCallback) => {
    setWarningDialogOpen(true)

    onFetchByLazyQuery({
      fetchPolicy: 'no-cache',
      variables: {
        fetchCsvData: true
      },
      onCompleted: ({ appAdminGetGuideResults }) => {
        dataDoneCallback(appAdminGetGuideResults.csvData)
        setWarningDialogOpen(false)
      },
      onError: () => setWarningDialogOpen(false)
    })
  }

  return (
    <div>
      <DownloadCsvButton
        btnText={t('content:export')}
        filename="admin-all-guides.csv"
        onFetchData={handleFetchData}
      />

      <Dialog isOpen={warningDialogOpen} title="Information">
        <div className="flex flex-col gap-3">
          <h1 className="font-semibold">
            OBS: Detta kan ta lite tid (~10sek per 500 guider)
          </h1>

          <p>
            Begränsning #1: Endast guidekörningar från senaste 600 dagar kommer med
            OAVSETT start eller slutdatum.
          </p>

          <div className="flex justify-center">
            <LoadingSpinner className="w-8 h-8" />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

AdminGuidesExport.propTypes = {
  onFetchByLazyQuery: func.isRequired
}

AdminGuidesExport.defaultProps = {}

export default AdminGuidesExport
