import { useCallback, useState } from 'react'
import { useManageSystemContext } from '../context'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../../contexts'
import { isAdmin } from '../../../utils'

export default function useSystemValidator() {
  const [generalValidationErrors, setGeneralValidationErrors] = useState([])
  const [siteDataValidationErrors, setSiteDataValidationErrors] = useState({})
  const { fieldsData, mediaAssetsData } = useManageSystemContext()
  const { user } = useUserContext()
  const { t } = useTranslation('adminPages')
  const hasAdmin = isAdmin(user?.role)

  const validateForm = useCallback(() => {
    const newGeneralErrors = []
    const newSiteDataValidationErrors = {}

    const addSiteDataError = (locale, errorMsg) => {
      if (!newSiteDataValidationErrors[locale]) {
        newSiteDataValidationErrors[locale] = []
      }

      newSiteDataValidationErrors[locale].push(errorMsg)
    }

    if (!fieldsData.categories?.length) {
      newGeneralErrors.push(t('adminAddSystem.dialog.validation.noCategories'))
    }

    if (!fieldsData.defaultCategory) {
      newGeneralErrors.push(t('adminAddSystem.dialog.validation.noDefaultCategory'))
    }

    // Validate logo only if user is not ADMIN
    // Logo does not need to exist when creating company
    if (!hasAdmin) {
      const foundLogo = mediaAssetsData.LOGO?.find((asset) => asset.action !== 'DELETE')

      if (!foundLogo) {
        newGeneralErrors.push(t('adminAddSystem.dialog.validation.noLogo'))
      }
    }

    // Sitedata validation
    const systemSiteData = fieldsData.systemSiteData
    if (systemSiteData && systemSiteData.length > 0) {
      systemSiteData.forEach((siteData) => {
        const { locale } = siteData

        const hasSystemName = siteData?.systemName?.trim().length > 0
        if (!hasSystemName) {
          addSiteDataError(locale, t('adminAddSystem.dialog.validation.noSystemName'))
        }

        // Make sure externalUrl starts with https:// if exists
        const systemExternalUrl = siteData?.systemExternalUrl?.trim()
        if (systemExternalUrl && !systemExternalUrl.startsWith('https://')) {
          addSiteDataError(
            locale,
            t('adminAddSystem.dialog.validation.incorrectExternalUrl', {
              fieldName: t('forms:system.systemExternalUrlName')
            })
          )
        }

        // Make sure extraBtnLink starts with https:// if exists
        const extraBtnLink = siteData?.extraBtnLink?.trim()
        if (extraBtnLink && !extraBtnLink.startsWith('https://')) {
          addSiteDataError(
            locale,
            t('adminAddSystem.dialog.validation.incorrectExternalUrl', {
              fieldName: t('forms:system.extraBtnLinkName')
            })
          )
        }
      })
    } else {
      newGeneralErrors.push(t('adminAddSystem.dialog.validation.noSites'))
    }

    setGeneralValidationErrors(newGeneralErrors)
    setSiteDataValidationErrors(newSiteDataValidationErrors)

    const hasGeneralErrors = newGeneralErrors.length > 0
    const hasSiteDataErrors = Object.keys(newSiteDataValidationErrors).length > 0

    return !(hasGeneralErrors || hasSiteDataErrors)
  }, [
    fieldsData.defaultCategory,
    fieldsData.categories,
    fieldsData.systemSiteData,
    mediaAssetsData.LOGO,
    t,
    hasAdmin
  ])

  return {
    generalValidationErrors,
    siteDataValidationErrors,
    validateForm
  }
}
