import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useUserContext } from '../contexts'

import useOverlayNotification from './useOverlayNotification'

import { isAdmin } from '../utils'

const UPDATE_WATCHED_BUYING_INTENT_GQL = gql`
  mutation UpdateWatchedBuyingIntent($ids: [ID!]!) {
    appUpdateWatchedBuyingIntent(ids: $ids) {
      id
      isNew
    }
  }
`
export default function useUpdateWatchedBuyingIntent() {
  const { showErrorNotification } = useOverlayNotification()
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const [updateWatchedBuyingIntentMutation] = useMutation(
    UPDATE_WATCHED_BUYING_INTENT_GQL,
    {
      onError(err) {
        showErrorNotification()
        const error = `${err}`.split(':').reverse()[0]
        console.log({ error })
      }
    }
  )

  const updateWatchedBuyingIntent = useCallback(
    (idOrIds, onCompleted) => {
      // Do not proceed if user is admin
      if (hasAdmin) {
        return
      }

      // No params...
      if (!idOrIds && !idOrIds?.length) {
        console.log('No params sent to updateWatchedBuyingIntent')
        return
      }

      // Convert to array
      if (!Array.isArray(idOrIds)) {
        idOrIds = [idOrIds]
      }

      // Request
      return updateWatchedBuyingIntentMutation({
        variables: {
          ids: idOrIds
        },
        onCompleted: (data) => {
          onCompleted?.(data.appUpdateWatchedBuyingIntent)
        }
      })
    },
    [hasAdmin, updateWatchedBuyingIntentMutation]
  )

  return { updateWatchedBuyingIntent }
}
