import React, { useCallback, useMemo, useState } from 'react'
import { gql } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import useAdminQuery from '../hooks/useAdminQuery'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import SystemsReviewsFilter from '../components/reviews/your-reviews/filter'
import ReviewList from '../components/reviews/your-reviews/list'
import ReviewerList from '../components/reviews/your-reviews/reviewer/list'
import Tabs from '../components/shared/tabs'

import { FRAGMENT_REVIEW_TEXT } from '../constants/gql/systems'

const GET_SYSTEMS_NAV = gql`
  ${FRAGMENT_REVIEW_TEXT}

  query SystemsData($companyId: ID, $userId: ID, $systemId: ID) {
    appSystemsOnVendor(companyId: $companyId, userId: $userId, systemId: $systemId) {
      id
      systemSiteData {
        locale
        systemName
      }
      reviewData {
        ...ReviewTextsFragment
        parentReviewDataFor {
          ...ReviewTextsFragment
        }
      }
    }
  }
`

function SystemsReviewsPage() {
  const { t } = useTranslation(['content'])
  const [filteredSystemsReviews, setFilteredSystemsReviews] = useState(null)

  const [activeTab, setActiveTab] = useState('REVIEWS')

  const {
    data: availableSystems,
    loading: availableSystemsLoading,
    refetch: refetchAvailableSystems
  } = useAdminQuery(GET_SYSTEMS_NAV)

  const handleTabChange = ({ value }) => {
    setActiveTab(value)
  }

  const allSystemsReviews = useMemo(() => {
    if (!availableSystems?.appSystemsOnVendor) {
      return []
    }
    return (
      availableSystems.appSystemsOnVendor.map((system) => {
        const sortedReviewData = [...system.reviewData].sort((a, b) => {
          return -a.created.localeCompare(b.created)
        })

        return {
          ...system,
          reviewData: sortedReviewData
        }
      }) || []
    )
  }, [availableSystems])

  const reviewerData = useMemo(() => {
    let emails = []

    allSystemsReviews.forEach((system) => {
      system.reviewData.forEach((reviewData) => {
        if (
          !emails.includes(reviewData.reviewEmailAnonymized) &&
          reviewData.reviewEmailAnonymized
        ) {
          emails.push(reviewData.reviewEmailAnonymized)
        }
      })
    })

    // Randomize the emails
    for (var i = emails.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1))
      var temp = emails[i]
      emails[i] = emails[j]
      emails[j] = temp
    }

    return emails.map((email) => {
      return {
        email
      }
    })
  }, [allSystemsReviews])

  const tabs = useMemo(() => {
    return reviewerData.length > 0
      ? [
          { label: t('reviews:tabs:reviews'), value: 'REVIEWS' },
          { label: t('reviews:tabs.reviewer'), value: 'REVIEWER' }
        ]
      : [{ label: t('reviews:tabs:reviews'), value: 'REVIEWS' }]
  }, [reviewerData, t])

  const handleReviewChange = useCallback(() => {
    refetchAvailableSystems()
  }, [refetchAvailableSystems])

  return (
    <>
      <PageHeader pageName={t('reviews:header.title')} />

      <PageBody loading={availableSystemsLoading}>
        {tabs?.length > 1 && (
          <Tabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
        )}

        {activeTab === 'REVIEWS' && (
          <>
            {!!allSystemsReviews?.length && (
              <SystemsReviewsFilter
                allSystemsWithReviews={allSystemsReviews}
                onReviewsFiltered={setFilteredSystemsReviews}
              />
            )}

            <ReviewList
              systemsWithReviews={filteredSystemsReviews}
              loading={availableSystemsLoading}
              onReviewsChanged={handleReviewChange}
            />
          </>
        )}
        {activeTab == 'REVIEWER' && (
          <ReviewerList reviewers={reviewerData} loading={availableSystemsLoading} />
        )}
      </PageBody>
    </>
  )
}

export default SystemsReviewsPage
