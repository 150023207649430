import { useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'

import useGetSystemName from './useGetSystemName'

/**
 * Reason for different endpoint and typename for SystemV2Mini is caching.
 * There's also another solution to this problem but it involves much more code
 * that might easily break. I believe that this is a fair solution for now.
 * Backend query still uses same function as for fetching SystemsV2, it's just re-used.
 */

const ALL_SYSTEMS_MINI_GQL = gql`
  query AdminAllSystemsMini {
    appSystemsMini {
      id
      active
      systemSiteData {
        locale
        systemName
      }
    }
  }
`

const EMPTY_ARR = []

export default function useSystemsOptions() {
  const { getSystemName } = useGetSystemName()

  const { data, loading: systemsOptionsLoading } = useQuery(ALL_SYSTEMS_MINI_GQL, {
    fetchPolicy: 'cache-first'
  })

  const systemsOptionsMemo = useMemo(() => {
    if (!data?.appSystemsMini) {
      return EMPTY_ARR
    }

    return data.appSystemsMini
      .map((system) => {
        const label = system.active
          ? getSystemName(system)
          : `${getSystemName(system)} (INACTIVE)`

        return {
          value: system.id,
          label
        }
      })
      .sort((a, b) => {
        const aLabel = a.label?.trim()?.toLowerCase() || ''
        const bLabel = b.label?.trim()?.toLowerCase() || ''
        return aLabel.localeCompare(bLabel)
      })
  }, [data, getSystemName])

  return {
    systemsOptions: systemsOptionsMemo,
    systemsOptionsLoading
  }
}
