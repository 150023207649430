import React from 'react'
import { shape, array, string, func } from 'prop-types'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import { CSVLink } from 'react-csv'
import { DocumentDownloadIcon } from '@heroicons/react/outline'
import Button from './button'

import * as ga from '../../lib/ga'

const StyledCSVLink = styled(CSVLink)`
  all: unset;
  pointer-events: ${({ $loading }) => (!$loading ? 'auto' : 'none')};
`

const GenerateCsvButton = ({
  filename,
  onClick,
  gaActionName,
  csvData,
  icon = DocumentDownloadIcon
}) => {
  const { t } = useTranslation()
  const Icon = icon
  const isLoading = !csvData?.headers?.length

  const handleClick = () => {
    onClick && onClick(csvData)

    if (gaActionName) {
      ga.event({
        action: gaActionName
      })
    }
  }

  return (
    <StyledCSVLink
      filename={filename}
      headers={csvData.headers || []}
      separator=";"
      data={csvData.data || []}
      $loading={isLoading}
    >
      <Button size="small" mode="default" onClick={handleClick} loading={isLoading}>
        <Icon className="h-5 w-5 text-base text-gray-100 cursor-pointer mr-1" />
        {t('content:export')}
      </Button>
    </StyledCSVLink>
  )
}

GenerateCsvButton.propTypes = {
  filename: string.isRequired,
  onClick: func,
  gaActionName: string,
  csvData: shape({
    headers: array.isRequired,
    data: array.isRequired
  }).isRequired
}

GenerateCsvButton.defaultProps = {
  filename: 'data.csv'
}

export default GenerateCsvButton
