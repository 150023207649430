import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import usePrepareSystemData from './usePrepareSystemData'
import useOverlayNotification from './useOverlayNotification'

import { SYSTEM_FRAGMENT_ADMIN } from '../constants/gql/systems'

const CREATE_SYSTEM_GQL = gql`
  mutation CreateSystemData($data: UpdateSystemV2AdminInput) {
    appCreateSystem(data: $data) {
      ${SYSTEM_FRAGMENT_ADMIN}
    }
  }
`
export default function useCreateSystem() {
  const { prepareSystemData } = usePrepareSystemData()
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [createSystemMutation, { loading }] = useMutation(CREATE_SYSTEM_GQL)

  const createSystem = useCallback(
    (formData, mediaAssetsData, onCompleted) => {
      const preparedSystemData = prepareSystemData(formData, mediaAssetsData)

      return createSystemMutation({
        variables: {
          data: preparedSystemData
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [
      createSystemMutation,
      prepareSystemData,
      showSuccessNotification,
      showErrorNotification
    ]
  )

  return { createSystem, loading }
}
