import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import useOverlayNotification from '../useOverlayNotification'
import { prepareFormDataForSubmit } from '../../helpers/systemProps.helper'

const GQL_EDIT_SYSTEM_PROPS_BY_TYPE = gql`
  mutation EditSystemPropsByTypeMR(
    $id: String!
    $dbName: String!
    $locale: String!
    $category: String!
    $propType: PropTypes!
    $data: EditSystemPropByTypeInput!
  ) {
    mrEditSystemPropsByType(
      id: $id
      dbName: $dbName
      locale: $locale
      category: $category
      propType: $propType
      data: $data
    )
  }
`

export default function useEditSystemPropsByType(propType) {
  const { locale, category } = useParams()
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [mrEditSystemPropsByTypeMutation, { loading }] = useMutation(
    GQL_EDIT_SYSTEM_PROPS_BY_TYPE,
    {
      onError: () => showErrorNotification()
    }
  )

  const mrEditSystemPropsByType = useCallback(
    (formData, onCompleted) => {
      const data = prepareFormDataForSubmit(formData, locale)

      return mrEditSystemPropsByTypeMutation({
        variables: {
          id: formData.id,
          data,
          dbName: formData.dbName,
          locale,
          category,
          propType
        },
        onCompleted: (data) => {
          showSuccessNotification()
          onCompleted && onCompleted(data)
        }
      })
    },
    [mrEditSystemPropsByTypeMutation, locale, category, propType, showSuccessNotification]
  )

  return {
    mrEditSystemPropsByType,
    mrEditSystemPropsByTypeLoading: loading
  }
}
