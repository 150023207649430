import { useMemo } from 'react'
import useAdminQuery from './useAdminQuery'
import { useParams } from 'react-router-dom'
import { useUserContext } from '../contexts'

import { isAdmin, isResearcher } from '../utils'

import {
  GET_SYSTEM_USER_GQL,
  GET_SYSTEM_ADMIN_GQL,
  GET_SYSTEM_RESEARCHER_GQL
} from '../constants/gql/systems'

export default function useFetchSystem(fetchPolicy = 'cache-and-network') {
  const { user } = useUserContext()
  const { locale, systemId } = useParams()
  const hasAdmin = isAdmin(user?.role)
  const hasResearcher = isResearcher(user?.role)

  const gqlQueryMemo = useMemo(() => {
    if (hasAdmin) {
      return GET_SYSTEM_ADMIN_GQL
    }

    if (hasResearcher) {
      return GET_SYSTEM_RESEARCHER_GQL
    }

    return GET_SYSTEM_USER_GQL
  }, [hasAdmin, hasResearcher])

  const gqlVariablesMemo = useMemo(() => {
    if (hasResearcher) {
      return {
        id: systemId,
        locale
      }
    }

    return {
      id: systemId
    }
  }, [hasResearcher, locale, systemId])

  const { data, loading, refetch } = useAdminQuery(
    gqlQueryMemo,
    {
      variables: gqlVariablesMemo,
      skip: !systemId || (hasResearcher && !locale)
    },
    fetchPolicy
  )

  return {
    system: data?.appSystem || data?.mrGetSystem,
    systemLoading: loading,
    refetchSystem: refetch
  }
}
