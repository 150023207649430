import { isProd } from '../../utils'

// log the pageview with their URL
export const pageview = (location) => {
  if (isProd) {
    if (window?.gtag) {
      gtag('event', 'page_view', {
        page_title: location.pathname,
        page_location: location.href,
        page_path: `${location.pathname}${location.search}`,
      });
    }
  }
}

// log specific events happening
export const event = ({ action, params }) => {
  if (isProd) {
    window.gtag('event', action, params)
  }
}
