import React from 'react'
import PropTypes from 'prop-types'
import { validationRules } from '../../validators/password.validator'
import { useTranslation } from 'react-i18next'
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/solid'

const PasswordRulesInformation = ({ validatorResults }) => {
  const { t } = useTranslation('userSettings')

  return (
    <>
      <h1>{t('password.passwordRules')}</h1>
      <ul>
        {Object.entries(validationRules).map(([key, value]) => {
          const noResults = Object.keys(validatorResults).length === 0
          let evaluationClass = 'text-gray-600 font-medium' // Default gray

          if (!noResults) {
            evaluationClass = validatorResults[key]
              ? 'text-green-600 font-medium'
              : 'text-red-600 font-medium'
          }

          return (
            <li key={key} className="flex items-center gap-2 my-2 text-sm">
              {noResults ? (
                '•\n'
              ) : validatorResults[key] ? (
                <CheckIcon className="w-4 h-4 text-green-600" />
              ) : (
                <ExclamationCircleIcon className="w-3 h-3 text-red-600" />
              )}
              <span className={evaluationClass}>
                {value == 1 ? t(`password.have${key}`) : t(`password.${key}`, { value })}
              </span>
            </li>
          )
        })}
      </ul>
    </>
  )
}

PasswordRulesInformation.propTypes = {
  validatorResults: PropTypes.shape({
    identicalMatch: PropTypes.bool,
    minLength: PropTypes.bool,
    capitalLetters: PropTypes.bool,
    lowerCaseLetters: PropTypes.bool,
    numbers: PropTypes.bool,
    specialCharacters: PropTypes.bool
  })
}

PasswordRulesInformation.defaultProps = {
  validatorResults: {}
}

export default PasswordRulesInformation
