import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const CREATE_GUIDE_QUESTION_GQL = gql`
  mutation AdminCreateGuideQuestion($guideId: ID!, $questionOrder: Float!) {
    createGuideQuestion(guideId: $guideId, questionOrder: $questionOrder) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useCreateGuideQuestion() {
  const { showErrorNotification } = useOverlayNotification()

  const [createGuideQuestionMutation, { loading }] = useMutation(
    CREATE_GUIDE_QUESTION_GQL
  )

  const createGuideQuestion = useCallback(
    (guideId, questionOrder, onCompleted) => {
      return createGuideQuestionMutation({
        variables: {
          guideId,
          questionOrder
        },
        onCompleted: ({ createGuideQuestion: updatedGuide }) => {
          onCompleted?.(updatedGuide)
        },
        onError: showErrorNotification
      })
    },
    [createGuideQuestionMutation, showErrorNotification]
  )

  return {
    createGuideQuestion,
    createGuideQuestionLoading: loading
  }
}
