import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { useUserContext } from '../../contexts'
import useOverlayNotification from '../../hooks/useOverlayNotification'

import useGetSystemName from '../../hooks/useGetSystemName'

import Button from '../shared/button'
import Checkbox from '../shared/checkbox'
import Radio from '../shared/radio'
import {
  hasValidEmail,
  hasMinLengthOf3,
  hasRequiredString
} from '../../validators/general'
import { isAdmin } from '../../utils'

const UPDATE_USER_MUTATION = gql`
  mutation UsersUpdateUser($companyId: ID, $userId: ID, $data: Json) {
    appUsersUpdateUser(companyId: $companyId, userId: $userId, data: $data) {
      id
      name
      email
      appRole
      systemsV2 {
        id
        systemSiteData {
          id
          systemName
          companyName
          locale
        }
      }
      appLocales
    }
  }
`

const CREATE_USER_MUTATION = gql`
  mutation UsersAddUser($companyId: ID, $userId: ID, $data: Json) {
    appUsersAddUser(companyId: $companyId, userId: $userId, data: $data) {
      id
      name
      email
      appRole
      systemsV2 {
        id
        systemSiteData {
          id
          systemName
          companyName
          locale
        }
      }
      appLocales
    }
  }
`

function createValidUser(user, userFormOptions) {
  const systemsV2 = user.systemsV2.filter((system) => {
    return userFormOptions?.systemsV2?.find((s) => s.id === system.id) !== undefined
  })

  const appLocales = user.appLocales.filter((locale) => {
    return userFormOptions?.appLocales?.find((l) => l === locale) !== undefined
  })

  return {
    ...user,
    systemsV2: systemsV2,
    appLocales: appLocales
  }
}

const UserForm = ({
  userFormOptions,
  user = null,
  isCreate = false,
  onCompleteSuccess,
  setCreateUser = null
}) => {
  const { t } = useTranslation('vendorSettings')
  const { user: userContext } = useUserContext()
  const [errors, setErrors] = useState({
    email: '',
    name: '',
    appRole: '',
    appLocales: '',
    systemsV2: '',
    disabled: ''
  })

  const initUser =
    user == null
      ? {
          email: '',
          name: '',
          appRole: 'USER',
          appLocales: [],
          systemsV2: [],
          disabled: false
        }
      : user

  const [editUser, setEditUser] = useState(createValidUser(initUser, userFormOptions))
  const [isUpdated, setIsUpdated] = useState(false)
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()
  const { getSystemName } = useGetSystemName()

  const admin = isAdmin(userContext?.role)

  const handleRoleChange = (role) => {
    setEditUser({ ...editUser, appRole: role })
    checkIsUpdated({ ...editUser, appRole: role })
  }

  const handleLoginChange = (id, login) => {
    setEditUser({ ...editUser, disabled: !login })
    checkIsUpdated({ ...editUser, disabled: !login })
  }

  const handleSystemsV2Change = (systemId) => {
    setEditUser({
      ...editUser,
      systemsV2: editUser.systemsV2.find((s) => s.id === systemId)
        ? editUser.systemsV2.filter((s) => s.id !== systemId)
        : [...editUser.systemsV2, { id: systemId }]
    })

    checkIsUpdated({
      ...editUser,
      systemsV2: editUser.systemsV2.find((s) => s.id === systemId)
        ? editUser.systemsV2.filter((s) => s.id !== systemId)
        : [...editUser.systemsV2, { id: systemId }]
    })
  }

  const handleAppLocaleChange = (locale) => {
    setEditUser({
      ...editUser,
      appLocales: editUser.appLocales.includes(locale)
        ? editUser.appLocales.filter((l) => l !== locale)
        : [...editUser.appLocales, locale]
    })

    checkIsUpdated({
      ...editUser,
      appLocales: editUser.appLocales.includes(locale)
        ? editUser.appLocales.filter((l) => l !== locale)
        : [...editUser.appLocales, locale]
    })
  }

  const handleEmailChange = (email) => {
    setEditUser({ ...editUser, email: email.trim().toLowerCase() })
    checkIsUpdated({ ...editUser, email: email.trim().toLowerCase() })
  }

  const handleNameChange = (name) => {
    setEditUser({ ...editUser, name: name.trim() })
    checkIsUpdated({ ...editUser, name: name.trim() })
  }

  const checkIsUpdated = (editedUser) => {
    // filter out systems that are not in initUser
    const editedUserSystemsInInitUser = editedUser.systemsV2.filter((system) => {
      return initUser.systemsV2.find((s) => s.id === system.id) === undefined
    })

    if (editedUserSystemsInInitUser.length > 0) {
      setIsUpdated(true)
      return true
    }

    // filter out systems that are not in editedUser
    const initUserSystemsInEditUser = initUser.systemsV2.filter((system) => {
      return editedUser.systemsV2.find((s) => s.id === system.id) === undefined
    })

    if (initUserSystemsInEditUser.length > 0) {
      setIsUpdated(true)
      return true
    }

    if (editedUser.appRole !== initUser.appRole) {
      setIsUpdated(true)
      return true
    }

    let localeFound = false
    editedUser.appLocales?.forEach((locale) => {
      if (initUser.appLocales.indexOf(locale) === -1) {
        localeFound = true
      }
    })

    if (!localeFound) {
      initUser.appLocales?.forEach((locale) => {
        if (editedUser.appLocales.indexOf(locale) === -1) {
          localeFound = true
        }
      })
    }

    if (localeFound) {
      setIsUpdated(true)
      return true
    }

    if (editedUser.disabled !== initUser.disabled) {
      setIsUpdated(true)
      return true
    }

    if (editedUser.email !== initUser.email) {
      setIsUpdated(true)
      return true
    }

    if (editedUser.name !== initUser.name) {
      setIsUpdated(true)
      return true
    }

    setIsUpdated(false)
    return false
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    let hasErrors = false

    let newErrors = {
      email: '',
      name: '',
      appRole: '',
      appLocales: '',
      systemsV2: '',
      disabled: ''
    }

    // Common validation

    // role
    if (editUser.appRole === '' || editUser.appRole.length === 0) {
      newErrors.appRole = t('users.formError.appRole.required')
    } else if (userFormOptions.appRoles.indexOf(editUser.appRole) === -1) {
      newErrors.appRole = t('users.formError.appRole.doesNotExist')
    }

    // systems
    let validSystems = true
    editUser.systemsV2?.forEach((system) => {
      if (userFormOptions.systemsV2.find((s) => s.id === system.id) === undefined) {
        validSystems = false
      }
    })

    if (!validSystems) {
      newErrors.systemsV2 = t('users.formError.systems.doesNotExist')
    }

    // Update validation
    if (!isCreate) {
      if (!user.id) {
        newErrors.name = t('users.formError.user.invalid')
      }
    }

    // Create validation
    if (isCreate) {
      if (!hasRequiredString(editUser.email)) {
        newErrors.email = t('users.formError.email.required')
      } else if (!hasValidEmail(editUser.email)) {
        newErrors.email = t('users.formError.email.invalid')
      }

      if (!hasRequiredString(editUser.name)) {
        newErrors.name = t('users.formError.name.required')
      } else if (!hasMinLengthOf3(editUser.name)) {
        newErrors.name = t('users.formError.name.minLength')
      }
    }

    Object.keys(newErrors)?.forEach((key) => {
      if (newErrors[key] !== '') {
        hasErrors = true
      }
    })

    setErrors(newErrors)

    if (hasErrors) {
      return
    }

    if (!isCreate) {
      usersUpdateUser({
        variables: {
          companyId: admin ? userContext.companyId : null,
          userId: admin ? userContext.userId : null,
          data: editUser
        }
      })
    } else {
      setCreateUser(editUser)
      usersCreateUser({
        variables: {
          companyId: admin ? userContext.companyId : null,
          userId: admin ? userContext.userId : null,
          data: {
            ...editUser,
            locale: localStorage.getItem('siteLanguage')
              ? localStorage.getItem('siteLanguage')
              : 'en_GB'
          }
        }
      })
    }
  }

  const [usersUpdateUser, { loading: mutationLoading }] = useMutation(
    UPDATE_USER_MUTATION,
    {
      onCompleted: () => {
        onCompleteSuccess()
        showSuccessNotification()
      },
      onError: ({ message }) => {
        showErrorNotification({ text: message })
      }
    }
  )

  const [usersCreateUser, { loading: createMutationLoading }] = useMutation(
    CREATE_USER_MUTATION,
    {
      onCompleted: () => {
        showSuccessNotification()
        onCompleteSuccess()
      },
      onError: ({ message }) => {
        showErrorNotification({ text: message })
      }
    }
  )
  return (
    <>
      <div
        className="bg-white shadow overflow-hidden sm:rounded-lg mb-10"
        key={`UserFormDiv-${editUser.id}`}
      >
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('users.details.title')}
          </h3>
        </div>

        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleFormSubmit}>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t('users.form.name')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {isCreate && (
                    <input
                      type="text"
                      name="name"
                      id="userCreateName"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder={t('users.form.example.name')}
                      onKeyUp={(e) => handleNameChange(e.target.value)}
                    />
                  )}
                  {!isCreate && (
                    <input
                      type="text"
                      id="userUpdateName"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md read-only:bg-gray-100"
                      value={user.name}
                      disabled={true}
                    />
                  )}
                  <span className="text-red-500 text-xs">{errors.name}</span>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t('users.form.email')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {isCreate && (
                    <input
                      type="text"
                      name="email"
                      id="userCreateEmail"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder={t('users.form.example.email')}
                      onKeyUp={(e) => handleEmailChange(e.target.value)}
                    />
                  )}

                  {!isCreate && (
                    <input
                      type="text"
                      id="userUpdateEmail"
                      className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md read-only:bg-gray-100"
                      value={user.email}
                      disabled={true}
                    />
                  )}
                  <span className="text-red-500 text-xs">{errors.email}</span>
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t('users.form.appRole')}
                </dt>
                <dd
                  className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
                  key={`roleddd-${editUser.id ? editUser.id : 'id'}`}
                >
                  <div
                    className="grid grid-cols-4 gap-4"
                    key={`rolediv-` + (editUser.id ? editUser.id : 'id')}
                  >
                    {userFormOptions.appRoles.map((role) => {
                      return (
                        <div key={`role-div-` + role}>
                          <Radio
                            key={`role-` + role}
                            id={role}
                            label={role}
                            onChange={handleRoleChange}
                            checked={editUser.appRole === role}
                            disabled={null}
                          />
                        </div>
                      )
                    })}
                  </div>
                  <span className="text-red-500 text-xs">{errors.appRole}</span>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t('users.form.systems')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="grid grid-cols-4 gap-4">
                    {userFormOptions.systemsV2.map((system) => {
                      return (
                        <div key={`systemsV2-div-${system.id}`}>
                          <Checkbox
                            id={system.id}
                            key={system.id}
                            label={getSystemName(system)}
                            value={system.id}
                            checked={
                              !(
                                editUser.systemsV2.find((s) => s.id === system.id) ===
                                undefined
                              )
                            }
                            onChange={() => handleSystemsV2Change(system.id)}
                          />
                        </div>
                      )
                    })}
                  </div>
                  <span className="text-red-500 text-xs">{errors.systemsV2}</span>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t('users.form.markets')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="grid grid-cols-4 gap-4">
                    {userFormOptions.appLocales.map((locale) => {
                      return (
                        <div key={`locale-div-${locale}`}>
                          <Checkbox
                            id={locale}
                            key={locale}
                            label={t('shared:localeToCountry.' + locale)}
                            value={locale}
                            checked={editUser.appLocales.includes(locale)}
                            onChange={handleAppLocaleChange}
                          />
                        </div>
                      )
                    })}
                  </div>
                  <span className="text-red-500 text-xs">{errors.appLocales}</span>
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t('users.form.canlogin')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <Checkbox
                    key={`checkbox-disabled`}
                    id="userCreateDisabled"
                    label={t('users.form.yes')}
                    rawChangeEvent={false}
                    checked={editUser.disabled === false}
                    onChange={handleLoginChange}
                  />
                  <span className="text-red-500 text-xs">{errors.disabled}</span>
                </dd>
              </div>
            </dl>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            {isCreate && (
              <Button
                className="inline-flex justify-center py-2 px-4 border 
                border-transparent shadow-sm text-sm font-medium rounded-md text-white 
                bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                size="small"
                mode="primary"
                type="submit"
                disabled={createMutationLoading || !isUpdated}
              >
                {t('users.button.createUserAndSendPassword')}
              </Button>
            )}
            {!isCreate && (
              <Button
                className="inline-flex justify-center py-2 px-4 border 
                border-transparent shadow-sm text-sm font-medium rounded-md text-white 
                bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                size="small"
                mode="primary"
                type="submit"
                disabled={mutationLoading || !isUpdated}
              >
                {t('users.button.updateUser')}
              </Button>
            )}
          </div>
        </form>
      </div>
    </>
  )
}

export default UserForm
