import React from 'react'
import { DateTime } from 'luxon'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { useQuery, gql } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import useAdminBypassSelect from '../hooks/useAdminBypassSelect'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import Loader from '../components/loader'
import Flag from '../components/shared/flag'

const QUERY = gql`
  query LatestSystems {
    appAdminLatestSystems {
      id
      systemName
      customerType
      locale
      createdAt
      systemV2Id
    }
  }
`

function SiteData({ siteData, navigateToSystem, index }) {
  return (
    <tr key={siteData.id}>
      <td className="flex flex-1 gap-2 whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        {index + 1}.
        <Flag locale={siteData.locale} height="20px" />
        <span className="cursor-pointer hover:underline" onClick={navigateToSystem}>
          {siteData.systemName}
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {DateTime.fromISO(siteData.createdAt).toISODate()}
      </td>
      <td
        className={cx('whitespace-nowrap px-3 py-4 text-sm text-gray-500', {
          'text-orange-600 font-medium	': ['CUSTOMER', 'TRIAL'].includes(
            siteData.customerType
          )
        })}
      >
        {siteData.customerType || 'BASIC'}
      </td>
    </tr>
  )
}

function LatestSiteDatas({ siteDatas }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { adminSelectSystem } = useAdminBypassSelect()

  const navigateToSystem = (siteData) => {
    const { systemV2Id: systemId, systemName } = siteData

    adminSelectSystem(systemId, systemName)
    navigate(`/system/${systemId}`)
  }

  if (!siteDatas?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  return (
    <div className="px-6 md:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {t('forms:system.systemNameName')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t('content:created')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t('content:customerType')}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {siteDatas.map((siteData, index) => {
                    return (
                      <SiteData
                        key={siteData.id}
                        siteData={siteData}
                        navigateToSystem={navigateToSystem.bind(null, siteData)}
                        index={index}
                      />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function AdminLatestSystemsPage() {
  const { t } = useTranslation()
  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'network-only'
  })

  return (
    <>
      <PageHeader pageName={t('adminPages:adminLatestSystems.header.title')}>
        {t('adminPages:adminLatestSystems.header.subtitle')}
      </PageHeader>

      <PageBody>
        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <LatestSiteDatas siteDatas={data?.appAdminLatestSystems} />
        )}
      </PageBody>
    </>
  )
}

export default AdminLatestSystemsPage
