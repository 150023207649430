export const prepareFormDataForSubmit = (formData, locale) => {
  return {
    nameByLocale: {
      en_GB: formData.name,
      [locale]: formData.nameForeign
    },
    descriptionByLocale: {
      en_GB: formData.description,
      [locale]: formData.descriptionForeign
    },
    filter: formData.filter || false,
    section: formData.section || undefined,
    impact: formData.impact || null,
    enabled: true
  }
}
