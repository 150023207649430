import React from 'react'
import Loader from './loader'

export function PageBody({ children, loading }) {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      {loading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : (
        children
      )}
    </div>
  )
}
