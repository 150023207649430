import React from 'react'

const ListToolbar = ({ children }) => {
  return <div className="flex justify-end my-3 mx-1">{children}</div>
}

ListToolbar.propTypes = {}

ListToolbar.defaultProps = {}

export default ListToolbar
