import React, { useState } from 'react'
import { bool, func, object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useGenericFormManager from '../../../../hooks/useGenericFormManager'
import useCreateEditReviewComment from '../hooks/useCreateEditReviewComment'
import { useUserContext } from '../../../../contexts'

import Dialog from '../../../shared/dialog'
import Input from '../../../shared/input'

import { hasRequiredString } from '../../../webhooks/validators/general'
import { isAdmin } from '../../../../utils'
import Label from '../../../shared/label'
import TextArea from '../../../shared/textarea'

const FIELD_COMMENT = 'commentText'
const FIELD_AUTHOR = 'commentAuthor'

const VALIDATORS_CONFIG = [
  {
    name: FIELD_COMMENT,
    validators: [
      {
        validator: hasRequiredString,
        error: 'forms:validation.general.required'
      }
    ]
  },
  {
    name: FIELD_AUTHOR,
    validators: [
      {
        validator: hasRequiredString,
        error: 'forms:validation.general.required'
      }
    ]
  }
]

const EditCreateCommentDialog = ({ isOpen, reviewData, onClose, onReviewsChanged }) => {
  const { t } = useTranslation()
  const { user } = useUserContext()
  const { formData, setFormData, onChange, validate, validationErrors } =
    useGenericFormManager(VALIDATORS_CONFIG, null)
  const { createEditReviewComment, createEditReviewCommentLoading } =
    useCreateEditReviewComment()

  const hasAdmin = isAdmin(user?.role)
  const userName = user?.name

  useState(() => {
    if (!Object.keys(formData).length && userName) {
      setFormData({
        [FIELD_COMMENT]: reviewData[FIELD_COMMENT] || '',
        [FIELD_AUTHOR]: reviewData[FIELD_AUTHOR] || userName
      })
    }
  }, [formData, reviewData, userName])

  const handleSubmit = () => {
    if (!validate()) {
      return
    }

    createEditReviewComment(reviewData.id, formData, () => {
      // If review comment was changed by Admin, we need to refetch
      // data to see the changes as we do not utilize Apollo cache for Admins
      if (hasAdmin) {
        onReviewsChanged()
      }

      onClose(false)
    })
  }

  const getCommonFormProps = (fieldName) => {
    return {
      id: fieldName,
      value: formData[fieldName],
      errorText: t(validationErrors[fieldName])
    }
  }

  return (
    <Dialog
      className="!max-w-xl"
      isOpen={isOpen}
      title={t('content:edit')}
      confirmText={t('content:save')}
      onConfirm={handleSubmit}
      onClose={onClose}
      loading={createEditReviewCommentLoading}
      noBackgroundClose
    >
      <div className="flex gap-4 flex-col">
        <Label title={t('comment')} marginless>
          <TextArea {...getCommonFormProps(FIELD_COMMENT)} onChange={onChange} rows={4} />
        </Label>

        <Label title={t('author')} marginless>
          <Input {...getCommonFormProps(FIELD_AUTHOR)} onChange={onChange} />
        </Label>
      </div>
    </Dialog>
  )
}

EditCreateCommentDialog.propTypes = {
  isOpen: bool,
  reviewData: object,
  onClose: func.isRequired,
  onReviewsChanged: func.isRequired
}

EditCreateCommentDialog.defaultProps = {}

export default EditCreateCommentDialog
