import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import { useUserContext, useAdminFieldsContext } from '../../../contexts'

import { isAdmin } from '../../../utils'

const AdminField = ({ children, className }) => {
  const { user } = useUserContext()
  const { adminFieldsVisible } = useAdminFieldsContext()
  const userIsAdmin = isAdmin(user?.role)

  if (!adminFieldsVisible || !user || !userIsAdmin) {
    return null
  }

  return (
    <div
      className={cx(
        'shadow-md shadow-inner my-2 py-4 -mx-4 px-4 bg-red-200 rounded-lg',
        className
      )}
    >
      <div className="text-gray-600 italic mb-3">Admin Area</div>
      {children}
    </div>
  )
}

AdminField.propTypes = {
  className: string
}

AdminField.defaultProps = {}

export default AdminField
