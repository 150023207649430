import React from 'react'
import { Link } from 'react-router-dom'
import { PageBody } from '../components/page-body'

function LegalPage() {
  return (
    <PageBody>
      <div style={{ marginTop: '2rem' }}>
        <Link to="/legal/privacy-policy">
          <span>Privacy Policy &rarr; </span>
        </Link>
        <br />
        <br />
        <Link to="/legal/terms-of-use">
          <span>Terms of use &rarr; </span>
        </Link>
      </div>
    </PageBody>
  )
}

export default LegalPage
