import React, { useState, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import useListMarketResearchers from '../hooks/market-researcher/useListMarketResearchers'
import useDeleteMarketResearcher from '../hooks/market-researcher/useDeleteMarketResearcher'

import PageHeader from '../components/shared/page-header'
import { PageBody } from '../components/page-body'
import Button from '../components/shared/button'
import Dialog from '../components/shared/dialog'

import AdminMarketResearcherUserForm from '../components/admin/admin-mr-user-form'

const AdminManageMarketResearchersPage = () => {
  const { t } = useTranslation('adminPages')

  const [mrIdToBeDeleted, setMrIdToBeDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editingUserData, setEditingUserData] = useState(null)

  const { mrMarketResearchers, mrMarketResearchersLoading, mrRefetchMarketResearchers } =
    useListMarketResearchers()
  const { deleteMarketResearcher } = useDeleteMarketResearcher()

  const mrCreateFormRef = useRef(null)
  const mrEditFormRef = useRef(null)

  const doDeleteUser = () => {
    deleteMarketResearcher(mrIdToBeDeleted).finally(() => {
      mrRefetchMarketResearchers()
    })

    setMrIdToBeDelete(false)
  }

  const handleLoadingChanged = (loading) => {
    setLoading(loading)
  }

  const handleEditUser = (mrUser) => {
    setEditingUserData(mrUser)
  }

  return (
    <>
      <PageHeader title="topNav:adminMenu.manageMarketResearchers" />

      <PageBody>
        <AdminMarketResearcherUserForm
          ref={mrCreateFormRef}
          onLoading={handleLoadingChanged}
          onDone={mrRefetchMarketResearchers}
        />

        <div className="pt-4">
          <div className="flex justify-end">
            <Button
              onClick={() => mrCreateFormRef.current?.submitForm()}
              loading={loading}
            >
              {t('content:create')}
            </Button>
          </div>
        </div>
      </PageBody>

      {mrMarketResearchers.length > 0 && (
        <div className="mt-5">
          <PageHeader title="adminPages:adminManageMR.active.header" />

          <PageBody loading={mrMarketResearchersLoading}>
            <div className="flex gap-2 flex-col">
              {mrMarketResearchers.map((mrUser, index) => {
                return (
                  <div
                    className="flex justify-between border border-gray-200 items-center rounded p-2 gap-2"
                    key={mrUser.id}
                  >
                    <div className="flex gap-2">
                      <div className="h-full flex items-center">{index + 1}.</div>
                      <div className="flex flex-col break-all">
                        <div>
                          {mrUser.name} - {mrUser.email}
                        </div>

                        <div>
                          {mrUser.marketResearcherLocales
                            .map((locale) => {
                              return t(`shared:localeToCountry.${locale}`)
                            })
                            .join(', ')}
                        </div>

                        {mrUser.latestSignedIn && (
                          <div>{new Date(mrUser.latestSignedIn).toLocaleString()}</div>
                        )}
                      </div>
                    </div>

                    <div className="flex shrink-0 gap-2">
                      <Button mode="default" onClick={() => handleEditUser(mrUser)}>
                        {t('content:edit')}
                      </Button>

                      <Button
                        mode="default"
                        onClick={() => {
                          setMrIdToBeDelete(mrUser.id)
                        }}
                      >
                        {t('content:delete')}
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
          </PageBody>

          <Dialog
            isOpen={!!mrIdToBeDeleted}
            title={t('adminPages:adminManageMR.deleteDialog.title')}
            onConfirm={doDeleteUser}
            confirmText={t('content:delete')}
            onClose={setMrIdToBeDelete}
          >
            {t('adminPages:adminManageMR.deleteDialog.info')}- {mrIdToBeDeleted}
          </Dialog>

          <Dialog
            isOpen={!!editingUserData}
            title={t('adminPages:adminManageMR.editDialog.title')}
            onConfirm={() => mrEditFormRef.current?.submitForm()}
            onClose={setEditingUserData}
          >
            <AdminMarketResearcherUserForm
              mrUser={editingUserData}
              ref={mrEditFormRef}
              onLoading={handleLoadingChanged}
              onDone={() => setEditingUserData(null)}
            />
          </Dialog>
        </div>
      )}
    </>
  )
}

AdminManageMarketResearchersPage.propTypes = {}

AdminManageMarketResearchersPage.defaultProps = {}

export default AdminManageMarketResearchersPage
