import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const UPDATE_ANSWER_SPECIAL_TEXT_GQL = gql`
  mutation AdminUpdateAnswerSpecialName($answerId: ID!, $specialName: String) {
    updateAnswerSpecialName(answerId: $answerId, specialName: $specialName) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useUpdateAnswerSpecialName() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateAnswerSpecialName, { loading }] = useMutation(
    UPDATE_ANSWER_SPECIAL_TEXT_GQL
  )

  const updateAnswerSpecialNameCallback = useCallback(
    (answerId, specialName, onCompleted) => {
      return updateAnswerSpecialName({
        variables: {
          answerId,
          specialName
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      })
    },
    [updateAnswerSpecialName, showSuccessNotification, showErrorNotification]
  )

  return {
    updateAnswerSpecialName: updateAnswerSpecialNameCallback,
    updateAnswerSpecialNameLoading: loading
  }
}
