import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const CREATE_ANSWER_GQL = gql`
  mutation AdminCreateAnswer($questionId: ID!, $data: Json!) {
    createAnswer(questionId: $questionId, data: $data) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useCreateAnswer() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [createAnswer, { loading }] = useMutation(CREATE_ANSWER_GQL)

  const createAnswerCallback = useCallback(
    (questionId, data, onCompleted) => {
      return createAnswer({
        variables: {
          questionId,
          data
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      })
    },
    [createAnswer, showSuccessNotification, showErrorNotification]
  )

  return {
    createAnswer: createAnswerCallback,
    createAnswerLoading: loading
  }
}
