import { useQuery, gql } from '@apollo/client'

const GQL_LIST_MARKET_RESEARCHERS = gql`
  query ListMarketResearchers {
    mrListResearchers {
      id
      name
      email
      latestSignedIn
      marketResearcherLocales
    }
  }
`

export default function useListMarketResearchers() {
  const { data, loading, refetch } = useQuery(GQL_LIST_MARKET_RESEARCHERS)

  return {
    mrMarketResearchers: data?.mrListResearchers || [],
    mrRefetchMarketResearchers: refetch,
    mrMarketResearchersLoading: loading
  }
}
