import React, { useEffect } from 'react'

import useListCommonQuestions from '../hooks/manage-guides/useListCommonQuestions'

import Header from '../components/admin/manage-guides/header'
import { PageBody } from '../components/page-body'

import CommonQuestionsOverview from '../components/admin/manage-guides/questions/common-questions-overview'

const AdminManageCommonQuestions = () => {
  const { commonQuestions, commonQuestionsLoading, refetchCommonQuestions } =
    useListCommonQuestions()

  useEffect(() => {
    refetchCommonQuestions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />

      <PageBody loading={commonQuestionsLoading}>
        <CommonQuestionsOverview commonQuestions={commonQuestions} />
      </PageBody>
    </>
  )
}

AdminManageCommonQuestions.propTypes = {}

AdminManageCommonQuestions.defaultProps = {}

export default AdminManageCommonQuestions
