import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { bool, func } from 'prop-types'

import { useSelectContext } from '../context'
import useKeyPress from '../../../../../hooks/useKeyPress'
import List from './list'

const VirtualizedList = ({ listLoading, renderItemContent }) => {
  const [dropdownHeight, setDropdownHeight] = useState(1)
  const { dropdownOpen, visibleOptions, setFocusedItemId } = useSelectContext()
  const ref = useRef(null)

  const ArrowDownPressed = useKeyPress('ArrowDown', dropdownOpen)
  const ArrowUpPressed = useKeyPress('ArrowUp', dropdownOpen)

  useEffect(() => {
    if (!dropdownOpen) {
      return
    }

    const optionsLength = visibleOptions.length

    if (ArrowDownPressed) {
      setFocusedItemId((prevItemId) => {
        const selectedItemIndex = visibleOptions.findIndex(
          (item) => item.value === prevItemId
        )

        if (optionsLength > selectedItemIndex + 1) {
          ref.current.scrollIntoView({
            index: selectedItemIndex + 1,
            behavior: 'auto',
            done: () => {
              setFocusedItemId(visibleOptions[selectedItemIndex + 1].value)
            }
          })

          return visibleOptions[selectedItemIndex + 1].value
        }

        return prevItemId
      })
    }

    if (ArrowUpPressed) {
      setFocusedItemId((prevItemId) => {
        const selectedItemIndex = visibleOptions.findIndex(
          (item) => item.value === prevItemId
        )

        if (selectedItemIndex === 0) {
          return prevItemId
        }

        ref.current.scrollIntoView({
          index: selectedItemIndex - 1,
          behavior: 'auto',
          done: () => {
            setFocusedItemId(visibleOptions[selectedItemIndex - 1].value)
          }
        })

        return visibleOptions[selectedItemIndex - 1].value
      })
    }
  }, [setFocusedItemId, dropdownOpen, visibleOptions, ArrowDownPressed, ArrowUpPressed])

  const onChangeListTotalHeight = useCallback(
    (listHeight) => {
      if (listHeight < 400 && listHeight !== dropdownHeight) {
        setDropdownHeight(listHeight)
      } else if (listHeight > 400 && dropdownHeight !== 400) {
        setDropdownHeight(400)
      }
    },
    [dropdownHeight]
  )

  const totalCount = visibleOptions?.length || 0

  return (
    <List loading={listLoading}>
      <Virtuoso
        ref={ref}
        style={{
          height: `${!totalCount ? 0 : dropdownHeight}px`
        }}
        totalCount={totalCount}
        itemContent={renderItemContent}
        totalListHeightChanged={onChangeListTotalHeight}
      />
    </List>
  )
}

VirtualizedList.propTypes = {
  listLoading: bool,
  renderItemContent: func.isRequired
}

VirtualizedList.defaultProps = {}

export default VirtualizedList
