import React from 'react'
import { Menu } from '@headlessui/react'

import classnames from 'classnames'

const MenuItem = ({ className, children, onClick, ...rest }) => {
  return (
    <Menu.Item onClick={onClick}>
      {({ active }) => {
        const customClassName = classnames(
          `block px-4 py-2 text-sm text-gray-700 cursor-pointer ${
            className ? className : ''
          }`,
          {
            'bg-gray-100': active
          }
        )

        return React.cloneElement(children, {
          className: classnames(children.props.className, customClassName),
          ...rest
        })
      }}
    </Menu.Item>
  )
}

MenuItem.propTypes = {}

MenuItem.defaultProps = {}

export default MenuItem
