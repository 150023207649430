import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const DELETE_GUIDE_QUESTION_GQL = gql`
  mutation AdminDeleteGuideQuestion($questionId: ID!) {
    deleteGuideQuestion(questionId: $questionId) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useDeleteGuideQuestion() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [deleteGuideQuestionMutation, { loading }] = useMutation(
    DELETE_GUIDE_QUESTION_GQL
  )

  const deleteGuideQuestion = useCallback(
    (questionId, onCompleted) => {
      return deleteGuideQuestionMutation({
        variables: {
          questionId
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      })
    },
    [deleteGuideQuestionMutation, showSuccessNotification, showErrorNotification]
  )

  return {
    deleteGuideQuestion,
    deleteGuideQuestionLoading: loading
  }
}
