import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

const GQL_FETCH_SITES_MR = gql`
  query ListSitesMR {
    mrListSites {
      defaultLocale
    }
  }
`

export default function useFetchSites(onCompleted) {
  const { data, loading } = useQuery(GQL_FETCH_SITES_MR, {
    onCompleted
  })

  const mrSitesMemo = useMemo(() => {
    if (!data?.mrListSites) {
      return []
    }

    return data.mrListSites.map((site) => {
      return site.defaultLocale
    })
  }, [data])

  return {
    mrSites: mrSitesMemo,
    mrSitesLoading: loading
  }
}
