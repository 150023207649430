import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { FRAGMENT_REVIEW_TEXT } from '../../../../constants/gql/systems'

const CREATE_REVIEW_TRANSLATION_GQL = gql`
  ${FRAGMENT_REVIEW_TEXT}

  mutation AdminCreateReviewTranslation(
    $parentReviewDataId: ID!
    $data: ReviewDataTranslationInput!
  ) {
    appAdminCreateReviewTranslation(
      parentReviewDataId: $parentReviewDataId
      data: $data
    ) {
      ...ReviewTextsFragment
    }
  }
`

export default function useCreateReviewTranslation() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appAdminCreateReviewTranslation, { loading }] = useMutation(
    CREATE_REVIEW_TRANSLATION_GQL
  )

  const createReviewTranslation = useCallback(
    (parentReviewDataId, formData, onCompleted) => {
      return appAdminCreateReviewTranslation({
        variables: {
          parentReviewDataId,
          data: formData
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appAdminCreateReviewTranslation, showSuccessNotification, showErrorNotification]
  )

  return {
    createReviewTranslation,
    createReviewTranslationLoading: loading
  }
}
