import React, { useState } from 'react'
import { func } from 'prop-types'

import { useTranslation } from 'react-i18next'

import { PlusCircleIcon } from '@heroicons/react/outline'
import Tile from './tile'
import Dialog from '../../../shared/dialog'
import Input from '../../../shared/input'
import Label from '../../../shared/label'

const AddVideo = ({ onChange, type, subType }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [inputUrl, setInputUrl] = useState('')
  const { t } = useTranslation()

  const getYoutubeVideoId = () => {
    if (!inputUrl.includes('yout')) {
      return false
    }

    let url

    try {
      url = new URL(inputUrl)
    } catch (error) {
      return false
    }

    let videoId = url.searchParams.get('v')

    // Not "normal" youtube link, keep trying
    if (!videoId) {
      if (url.pathname.includes('shorts')) {
        videoId = url.pathname.slice(1).split('/')[1]
      } else {
        videoId = url.pathname.slice(1).split('/')[0]
      }
    }

    return videoId
  }

  const getVimeoVideoId = () => {
    if (!inputUrl.includes('vimeo')) {
      return false
    }

    let url

    try {
      url = new URL(inputUrl)
    } catch (error) {
      return false
    }

    let videoId = url.pathname.replace(/\//, '')

    // const regExValidVimeoUrl = /^[0-9]+$/g
    const regExValidVimeoUrl = /^[a-z0-9\/]+$/g

    if (!videoId || !regExValidVimeoUrl.test(videoId)) {
      return false
    }

    return videoId
  }

  const handleChange = (newInput) => {
    setInputUrl(newInput)
    setErrorText('')
  }

  const handleSave = () => {
    let url
    if (subType === 'YOUTUBE') {
      url = getYoutubeVideoId()

      if (!url) {
        setErrorText(t('forms:system.assets.validation.incorrectYoutubeUrl'))
        return
      }
    } else if (subType === 'VIMEO') {
      url = getVimeoVideoId()

      if (!url) {
        setErrorText(t('forms:system.assets.validation.incorrectVimeoUrl'))
        return
      }
    }

    onChange('UPLOAD', type, { url, subType })
    handleDialogClose()
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setErrorText('')
    setInputUrl('')
  }

  let buttonText = ''
  let inputLabelText = ''
  if (subType === 'YOUTUBE') {
    buttonText = t('forms:system.assets.section.videos.tile.titleyoutube')
    inputLabelText = t('forms:system.assets.section.videos.dialog.addvideo.labelyoutube')
  } else if (subType === 'VIMEO') {
    buttonText = t('forms:system.assets.section.videos.tile.titlevimeo')
    inputLabelText = t('forms:system.assets.section.videos.dialog.addvideo.labelvimeo')
  }

  return (
    <>
      <Tile
        className="flex justify-center items-center flex-col hover:bg-gray-200 active:bg-gray-300 transition-colors cursor-pointer"
        onClick={() => {
          setDialogOpen(true)
        }}
      >
        <div className="flex flex-col items-center">
          <PlusCircleIcon className="w-8 h-8 text-gray-500 mb-1" />

          <span className="text-gray-700 text-sm italic text-center">{buttonText}</span>
        </div>
      </Tile>

      <Dialog
        isOpen={dialogOpen}
        confirmText={t('content:save')}
        title={t('forms:system.assets.section.videos.dialog.addvideo.title')}
        onConfirm={handleSave}
        onClose={handleDialogClose}
      >
        <Label title={inputLabelText}>
          <Input onChange={handleChange} value={inputUrl} errorText={errorText} />
        </Label>
      </Dialog>
    </>
  )
}

AddVideo.propTypes = {
  onChange: func.isRequired
}

AddVideo.defaultProps = {}

export default AddVideo
