import React from 'react'
import { array, bool, func } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Label from '../../../../shared/label'
import { MultiSelect } from '../../../../shared/select'

const ANSWER_ALTERNATIVES_OPTIONS = [
  { label: 'YES', value: 'YES' },
  { label: 'NO', value: 'NO' },
  { label: 'DONT_KNOW', value: 'DONT_KNOW' },
  { label: 'NOT_MATTER', value: 'NOT_MATTER' },
  { label: 'TRUE', value: 'TRUE' },
  { label: 'REQUIREMENT', value: 'REQUIREMENT' }
]

const ANSEWRS_HEADINGS_OPTIONS = [
  { label: 'IMPACT', value: 'IMPACT' },
  { label: 'SHOW_PERCENTAGE', value: 'SHOW_PERCENTAGE' }
]

const MultipleTypeSettings = ({
  onChange,
  loading,
  answerAlternatives,
  answersHeadings,
  disabled
}) => {
  const { t } = useTranslation('adminPages')

  return (
    <div className="flex gap-3 mt-2">
      <Label
        className="flex-1"
        title={t('adminManageGuide.guide.question.edit.answerAlternatives')}
        marginless
      >
        <MultiSelect
          id="answerAlternatives"
          options={ANSWER_ALTERNATIVES_OPTIONS}
          onChange={onChange}
          value={answerAlternatives}
          rawChangeEvent={false}
          loading={loading}
          disabled={loading || disabled}
          closeDropdownOnSelect={true}
          isSearchable={false}
        />
      </Label>

      <Label
        className="flex-1"
        title={t('adminManageGuide.guide.question.edit.answersHeadings')}
        marginless
      >
        <MultiSelect
          id="answersHeadings"
          options={ANSEWRS_HEADINGS_OPTIONS}
          onChange={onChange}
          value={answersHeadings}
          rawChangeEvent={false}
          loading={loading}
          disabled={loading || disabled}
          closeDropdownOnSelect={true}
          isSearchable={false}
        />
      </Label>
    </div>
  )
}

MultipleTypeSettings.propTypes = {
  onChange: func.isRequired,
  loading: bool,
  answerAlternatives: array,
  answersHeadings: array,
  disabled: bool
}

MultipleTypeSettings.defaultProps = {
  answerAlternatives: [],
  answersHeadings: []
}

export default MultipleTypeSettings
