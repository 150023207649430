import React, { Fragment } from 'react'
import classnames from 'classnames'
import { Dialog as ReactDialog, Transition } from '@headlessui/react'
import { bool, func, oneOf, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Button from '../button'

const Dialog = ({
  className,
  isOpen,
  onClose,
  onConfirm,
  onDelete,
  cancelText,
  cancelDisabled,
  confirmDisabled,
  confirmText,
  title,
  children,
  hideFooter,
  renderFooter,
  align,
  loading,
  noBackgroundClose
}) => {
  const { t } = useTranslation()

  const handleClose = () => {
    onClose ? onClose(false) : () => {}
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <ReactDialog
        as="div"
        className="relative z-10"
        onClose={!noBackgroundClose ? handleClose : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div
            className={classnames(
              'flex justify-center min-h-screen px-4 py-20 text-center',
              {
                'items-center ': align === 'center',
                'items-start': align === 'top',
                'items-end': align === 'bottom'
              }
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ReactDialog.Panel
                className={classnames(
                  `relative inline-block align-middle bg-white rounded-lg px-4 py-4 text-left overflow-visible shadow-xl transform transition-all max-w-sm w-full p-6`,
                  className
                )}
              >
                {title && (
                  <div className="text-left mb-5">
                    <ReactDialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {title}
                    </ReactDialog.Title>
                  </div>
                )}

                {children}

                {!hideFooter && !renderFooter && (
                  <div className="flex mt-5 sm:mt-6">
                    <div className="flex-1">
                      {onDelete && (
                        <Button mode="error" onClick={onDelete} disabled={loading}>
                          {t('content:delete')}
                        </Button>
                      )}
                    </div>

                    <div className="flex gap-3">
                      {onClose && (
                        <Button
                          mode="default"
                          onClick={handleClose}
                          disabled={cancelDisabled || loading}
                        >
                          {cancelText || t('content:close')}
                        </Button>
                      )}

                      {onConfirm && (
                        <Button
                          onClick={onConfirm}
                          disabled={confirmDisabled}
                          loading={loading}
                        >
                          {confirmText || t('content:done')}
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {renderFooter && renderFooter()}
              </ReactDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </ReactDialog>
    </Transition.Root>
  )
}

Dialog.propTypes = {
  isOpen: bool,
  onClose: func,
  onDone: func,
  cancelText: string,
  cancelDisabled: bool,
  onConfirm: func,
  confirmDisabled: bool,
  renderFooter: func,
  title: string,
  hideFooter: bool,
  align: oneOf(['top', 'center', 'bottom']),
  confirmText: string
}

Dialog.defaultProps = {
  align: 'center'
}

export default Dialog
