import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { FRAGMENT_REVIEW_TEXT } from '../../../../constants/gql/systems'

const EDIT_REVIEW_TRANSLATION_GQL = gql`
  ${FRAGMENT_REVIEW_TEXT}

  mutation AdminEditReviewTranslation(
    $reviewDataId: ID!
    $data: ReviewDataTranslationInput!
  ) {
    appAdminEditReviewTranslation(reviewDataId: $reviewDataId, data: $data) {
      ...ReviewTextsFragment
    }
  }
`

export default function useEditReviewTranslation() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appAdminEditReviewTranslation, { loading }] = useMutation(
    EDIT_REVIEW_TRANSLATION_GQL
  )

  const editReviewTranslation = useCallback(
    (reviewDataId, formData, onCompleted) => {
      return appAdminEditReviewTranslation({
        variables: {
          reviewDataId,
          data: formData
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appAdminEditReviewTranslation, showSuccessNotification, showErrorNotification]
  )

  return {
    editReviewTranslation,
    editReviewTranslationLoading: loading
  }
}
