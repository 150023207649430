import React, { useState } from 'react'
import { bool, func } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Label from '../../../../shared/label'
import { Select } from '../../../../shared/select'

const OPTIONS = [
  {
    labelTid: 'content:yes',
    value: true
  },
  {
    labelTid: 'content:no',
    value: false
  }
]

const RequiredQuestionSelect = ({ onChange, required }) => {
  const { t } = useTranslation('adminPages')
  const [loading, setLoading] = useState(false)

  const handleChangeRequiredQuestion = (updatedValue) => {
    setLoading(true)

    onChange('required', updatedValue).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Label title={t('adminManageGuide.guide.question.required')} marginless>
      <Select
        options={OPTIONS}
        onChange={handleChangeRequiredQuestion}
        rawChangeEvent={false}
        value={required}
        loading={loading}
        isSearchable={false}
      />
    </Label>
  )
}

RequiredQuestionSelect.propTypes = {
  onChange: func.isRequired,
  required: bool
}

RequiredQuestionSelect.defaultProps = {}

export default RequiredQuestionSelect
