import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { isAdmin, isAppAdmin } from '../../utils'
import { useTranslation } from 'react-i18next'
import useAdminQuery from '../../hooks/useAdminQuery'
import { useUserContext } from '../../contexts'
import Loader from '../loader'
import { Radiobuttons } from '../form/inputs'
import { UserIcon, OfficeBuildingIcon } from '@heroicons/react/solid'
import {
  FIELD_GUIDE_NOTIFICATIONS_USER,
  FIELD_GUIDE_NOTIFICATIONS_VENDOR
} from '../../constants/guide-notification-fields'
const GET_GUIDE_SETTINGS = gql`
  query Settings($companyId: ID, $userId: ID) {
    appGuideSettings(companyId: $companyId, userId: $userId) {
      users {
        id
        name
        email
        guideNotifications
      }
      vendor {
        id
        guideNotifications
      }
    }
  }
`

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: GuideNotificationsInput!) {
    appUpdateGuideNotificationsUser(data: $data) {
      id
      result
    }
  }
`

const UPDATE_VENDOR_MUTATION = gql`
  mutation UpdateVendor($data: GuideNotificationsInput!) {
    appUpdateGuideNotificationsVendor(data: $data) {
      id
      result
    }
  }
`

const CommunicationSettings = () => {
  const { t } = useTranslation()
  const [vendor, setVendor] = useState(null)
  const [users, setUsers] = useState([])

  const { user: userContext } = useUserContext()

  const hasAdmin = isAdmin(userContext?.role)
  const hasAppAdmin = isAppAdmin(userContext?.appRole)

  const [updateUser, {}] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      refetch()
    },
    onError: ({}) => {}
  })

  const [updateVendor, {}] = useMutation(UPDATE_VENDOR_MUTATION, {
    onCompleted: () => {
      refetch()
    },
    onError: ({}) => {}
  })

  const { loading, refetch, data } = useAdminQuery(GET_GUIDE_SETTINGS, {})

  const handleUserChange = (userId, value) => {
    updateUser({
      variables: {
        data: {
          companyId: hasAdmin ? userContext.companyId : null,
          userId: userId,
          guideNotifications: value
        }
      }
    })
  }

  const handleVendorChange = (value) => {
    updateVendor({
      variables: {
        data: {
          companyId: hasAdmin ? userContext.companyId : null,
          guideNotifications: value
        }
      }
    })
  }

  useEffect(() => {
    if (data?.appGuideSettings) {
      setVendor(data.appGuideSettings?.vendor)
      setUsers(data.appGuideSettings?.users)
    }
  }, [data])

  if (!hasAdmin && !hasAppAdmin) {
    return
  }

  if (loading) {
    return (
      <div className="text-center text-2xl font-bold text-gray-800">
        <Loader />
      </div>
    )
  }
  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {!loading && (
            <>
              <li key={`vendor-${vendor?.id}`}>
                <div className="relative block hover:bg-gray-50">
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex-shrink-0">
                        <div className="h-10 w-10 rounded-full bg-cover bg-center">
                          <OfficeBuildingIcon className="h-10 w-10 text-gray-400/75" />
                        </div>
                      </div>

                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                        <div className="flex md:mt-5 justify-center md:col-span-1">
                          <div className="grid">
                            <div>{t('content:vendorDefaultAndIntegration')}</div>
                          </div>
                        </div>
                        <div className="flex justify-end md:col-span-1 md:-mt-10 md:col-start-3">
                          <Radiobuttons
                            field={FIELD_GUIDE_NOTIFICATIONS_VENDOR}
                            data={
                              !vendor?.guideNotifications
                                ? 'ALL'
                                : vendor?.guideNotifications
                            }
                            handleFieldChange={(e) => {
                              handleVendorChange(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {users.map((user) => {
                return (
                  <li key={`user-${user?.id}`}>
                    <div className="relative block hover:bg-gray-50">
                      <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-center">
                          <div className="flex-shrink-0">
                            <div className="h-10 w-10 rounded-full bg-cover bg-center">
                              <UserIcon className="h-10 w-10 text-gray-400/75" />
                            </div>
                          </div>

                          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                            <div className="flex  justify-center md:col-span-1">
                              <div className="grid">
                                <div>{user?.name}</div>
                                <div>{user?.email}</div>
                              </div>
                            </div>
                            <div className="flex justify-end md:col-span-1 md:-mt-10 md:col-start-3">
                              <Radiobuttons
                                field={{
                                  ...FIELD_GUIDE_NOTIFICATIONS_USER,
                                  id: user.id
                                }}
                                data={
                                  user?.guideNotifications == null
                                    ? !vendor?.guideNotifications
                                      ? 'ALL'
                                      : vendor?.guideNotifications
                                    : user?.guideNotifications
                                }
                                handleFieldChange={(e) => {
                                  handleUserChange(user.id, e.target.value)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </>
          )}
        </ul>
      </div>
    </>
  )
}

export default CommunicationSettings
