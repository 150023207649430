import React from 'react'
import styled from 'styled-components'
import LoadingSpinner from '../../../loading-spinner'

import ListItemBase from './list-item-base'

const StyledWrapper = styled.div`
  top: 100%;
`

const List = ({ children, loading }) => {
  return (
    <StyledWrapper className="absolute shadow bg-white z-40 w-full rounded overflow-y-auto">
      <div className="flex flex-col w-full">
        {loading ? (
          <ListItemBase>
            <div className="flex w-full justify-center h-10 w-10 items-center">
              <LoadingSpinner className="h-6 w-6 text-indigo-600" />
            </div>
          </ListItemBase>
        ) : (
          children
        )}
      </div>
    </StyledWrapper>
  )
}

List.propTypes = {}

List.defaultProps = {}

export default List
