import React from 'react'
import { bool, func, string } from 'prop-types'
import cx from 'classnames'

const ToggleSwitch = ({ checked, disabled, onChange, text }) => {
  const handleChange = (e) => {
    onChange?.(e.target.checked)
  }

  return (
    <div className="items-center">
      <label
        className={cx('relative inline-flex items-center', {
          'cursor-pointer': !disabled
        })}
      >
        <input
          type="checkbox"
          value=""
          className="sr-only peer"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <div
          className={cx(
            "w-11 h-6 bg-gray-100 rounded-full peer peer-focus:ring-offset-2 peer-focus:ring-2 peer-focus:ring-indigo-600 dark:peer-focus:ring-indigo-600 dark:bg-gray-400 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-indigo-600",
            {
              'dark:bg-gray-300 peer-checked:bg-indigo-300': disabled
            }
          )}
        ></div>

        {text && (
          <span
            className={cx(
              'ml-3 text-sm font-medium text-gray-700 dark:text-gray-700 whitespace-nowrap',
              {
                'text-gray-400 dark:text-gray-400': disabled
              }
            )}
          >
            {text}
          </span>
        )}
      </label>
    </div>
  )
}

ToggleSwitch.propTypes = {
  checked: bool,
  disabled: bool,
  onChange: func,
  text: string
}

ToggleSwitch.defaultProps = {}

export default ToggleSwitch
