import React from 'react'
import { useTranslation } from 'react-i18next'

const PageHeader = ({ title }) => {
  const { t } = useTranslation()

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-8">
      <h1 className="text-2xl font-semibold text-gray-700">{t(title)}</h1>
    </div>
  )
}

PageHeader.propTypes = {}

PageHeader.defaultProps = {}

export default PageHeader
