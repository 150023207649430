import React, { useState } from 'react'
import { array, bool, func } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useGetSystemName from '../../../hooks/useGetSystemName'
import { useAdminFieldsContext } from '../../../contexts'

import DeleteReviewTranslationDialog from './dialogs/delete-review-translation.dialog'
import Alert from '../../shared/alert'
import ReviewItem from './item'

const ReviewList = ({ systemsWithReviews, loading, onReviewsChanged }) => {
  const { t } = useTranslation()
  const { adminFieldsVisible } = useAdminFieldsContext()
  const { getSystemName } = useGetSystemName()
  const [deleteReviewId, setDeleteReviewId] = useState(false)

  if (!systemsWithReviews?.length && !loading) {
    return <Alert>{t('content:warning.noData')}</Alert>
  }

  return systemsWithReviews?.map((system) => {
    return (
      <div className="mb-10" key={system.id}>
        <h2 className="text-lg font-medium text-gray-700 mb-3">
          {getSystemName(system)}
        </h2>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {system.reviewData.map((review) => (
              <React.Fragment key={review.id}>
                <ReviewItem review={review} onReviewsChanged={onReviewsChanged} />

                {adminFieldsVisible &&
                  review.parentReviewDataFor?.map((subReviewData) => {
                    return (
                      <ReviewItem
                        key={subReviewData.id}
                        review={subReviewData}
                        isSubItem={true}
                        onReviewsChanged={onReviewsChanged}
                        onDelete={setDeleteReviewId}
                      />
                    )
                  })}
              </React.Fragment>
            ))}

            <DeleteReviewTranslationDialog
              reviewId={deleteReviewId}
              onCompleted={onReviewsChanged}
              onClose={setDeleteReviewId}
            />
          </ul>
        </div>
      </div>
    )
  })
}

ReviewList.propTypes = {
  systemsWithReviews: array,
  loading: bool,
  onReviewsChanged: func.isRequired
}

ReviewList.defaultProps = {}

export default ReviewList
