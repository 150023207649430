import React, { useEffect, useRef, useMemo, useState } from 'react'
import { func, string } from 'prop-types'
import { DocumentDownloadIcon } from '@heroicons/react/outline'
import { CSVLink } from 'react-csv'

import { useTranslation } from 'react-i18next'

import Button from './button'

const DownloadCsvButton = ({ onFetchData, btnText, btnTextTid, filename }) => {
  const { t } = useTranslation()
  const [csvData, setCsvData] = useState({})
  const [loading, setLoading] = useState(false)
  const csvLinkRef = useRef()

  const csvLengthMemo = useMemo(() => {
    return Object.keys(csvData).length
  }, [csvData])

  const fetchCsvData = () => {
    setLoading(true)

    onFetchData((csvData = []) => {
      setCsvData(csvData)
      setLoading(false)
    })
  }

  const handleReset = () => {
    setCsvData({})
  }

  useEffect(() => {
    if (csvLengthMemo) {
      csvLinkRef.current.link.click()
    }
  }, [csvLengthMemo])

  return (
    <div>
      <Button size="small" mode="default" onClick={fetchCsvData} loading={loading}>
        <DocumentDownloadIcon className="h-5 w-5 text-base text-gray-100 cursor-pointer mr-1" />
        {btnTextTid ? t(btnTextTid) : btnText}
      </Button>

      <CSVLink
        headers={csvData.headers || []}
        data={csvData.data || []}
        filename={filename}
        separator=";"
        className="invisible"
        ref={csvLinkRef}
        onClick={handleReset}
      />
    </div>
  )
}

DownloadCsvButton.propTypes = {
  onFetchData: func.isRequired,
  btnText: string,
  btnTextTid: string,
  filename: string
}

DownloadCsvButton.defaultProps = {
  filename: 'data.csv'
}

export default DownloadCsvButton
