import React from 'react'
import { useLazyQuery, gql } from '@apollo/client'

import DownloadCsvButton from '../../shared/download-csv-btn'

const EXPORT_PRODUCT_FUNCTIONS_GQL = gql`
  query appAdminExportProductFunctions {
    appAdminExportProductFunctions
  }
`

const ProductFunctionsExport = () => {
  const [getProductFunctions] = useLazyQuery(EXPORT_PRODUCT_FUNCTIONS_GQL, {
    fetchPolicy: 'network-only'
  })

  const handleFetchData = (doneCallback) => {
    getProductFunctions({
      onCompleted: ({ appAdminExportProductFunctions }) => {
        doneCallback(appAdminExportProductFunctions)
      },
      onError: () => {
        doneCallback()
      }
    })
  }

  return (
    <DownloadCsvButton
      btnText="Export All PFs"
      filename="product-functions.csv"
      onFetchData={handleFetchData}
    />
  )
}

ProductFunctionsExport.propTypes = {}

ProductFunctionsExport.defaultProps = {}

export default ProductFunctionsExport
