import React, { useState, useEffect } from 'react'
import { bool, func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import { PencilAltIcon } from '@heroicons/react/outline'

import Button from '../../../shared/button'
import Input from '../../../shared/input'

const EditableInput = ({ id, value, onSave, disabled, loading }) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const [updatedValue, setUpdatedValue] = useState('')
  const disableFields = disabled || loading

  const specialName = editing ? updatedValue : value

  useEffect(() => {
    if (!editing) {
      setUpdatedValue('')
    }
  }, [editing])

  const handleStartEdit = () => {
    setUpdatedValue(specialName)
    setEditing(true)
  }

  const handleCancelEdit = () => {
    setEditing(false)
  }

  const handleChange = (updatedValue) => {
    setUpdatedValue(updatedValue)
  }

  const handleSave = () => {
    onSave(id, updatedValue.trim()).finally(() => {
      setEditing(false)
    })
  }

  return (
    <div className="flex flex-rows items-center gap-2 w-full">
      {editing ? (
        <div className="flex items-end gap-2 w-full flow-column">
          <Input value={specialName} onChange={handleChange} disabled={disableFields} />

          <div className="flex gap-2">
            <Button
              mode="default"
              size="small"
              onClick={handleCancelEdit}
              disabled={disableFields}
            >
              {t('content:cancel')}
            </Button>

            <Button size="small" onClick={handleSave} loading={loading}>
              {t('content:save')}
            </Button>
          </div>
        </div>
      ) : (
        <span className="text-sm italic">{value || t('content:noTextAdded')}</span>
      )}

      {!editing && (
        <Button
          size="small"
          onClick={handleStartEdit}
          mode="outlined"
          disabled={disableFields}
        >
          <PencilAltIcon className="w-4 text-gray-600" />
        </Button>
      )}
    </div>
  )
}

EditableInput.propTypes = {
  id: string,
  value: string,
  onChange: func,
  disabled: bool,
  loading: bool
}

EditableInput.defaultProps = {}

export default EditableInput
