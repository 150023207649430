import React from 'react'
import { useTranslation } from 'react-i18next'

import Loader from '../components/loader'
import Outbounds from '../components/outbounds'

import useFetchVendorOutbounds from '../hooks/useFetchVendorOutbounds'

function AdminVendorOutboundsPage() {
  const { t } = useTranslation('yourSystemProfile')

  const { vendorOutbounds, systemLoading } = useFetchVendorOutbounds()

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {t('content:system')}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {t('content:period')}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        {t('content:data')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {systemLoading ? (
                      <tr>
                        <td colSpan={4}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      vendorOutbounds.map((systemOutbound) => {
                        return <Outbounds systemOutbound={systemOutbound} />
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminVendorOutboundsPage
