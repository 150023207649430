import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'

const GQL_FETCH_SYSTEMS_MR = gql`
  query ListSystemsMR($locale: String!, $categoryDBName: String!) {
    mrListSystems(locale: $locale, categoryDBName: $categoryDBName) {
      id
      createdAt
      systemSiteData {
        systemName
        locale
      }
    }
  }
`

const EMPTY_ARR = []

export default function useFetchSystems() {
  const { locale, category } = useParams()

  const { data, loading } = useQuery(GQL_FETCH_SYSTEMS_MR, {
    fetchPolicy: 'cache-and-network',
    variables: {
      locale,
      categoryDBName: category
    },
    skip: !locale || !category
  })

  return {
    mrSystems: data?.mrListSystems || EMPTY_ARR,
    mrSystemsLoading: loading
  }
}
