import React from 'react'
import { bool } from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  transform-origin: top;
  transition: transform 0.2s ease;
  transform: ${({ open }) => (open ? 'scaleY(1)' : 'scaleY(0)')};
  position: ${({ open }) => (open ? 'relative' : 'absolute')};
`

const Expandable = ({ className, open, children }) => {
  return (
    <Wrapper className={className} open={open}>
      {children}
    </Wrapper>
  )
}

Expandable.propTypes = {
  open: bool
}

Expandable.defaultProps = {}

export default Expandable
