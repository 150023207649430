import React, { useState, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import EditCreateCommentDialog from './dialogs/edit-create-comment.dialog'
import DeleteReviewCommentDialog from './dialogs/delete-review-comment.dialog'

import Flag from '../../shared/flag'
import Button from '../../shared/button'
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'

const Comments = ({ review, onReviewsChanged }) => {
  const { t } = useTranslation()
  const [reviewDataToModify, setReviewDataToModify] = useState(null)
  const [reviewDataIdToDelete, setReviewDataIdToDelete] = useState(null)

  const flatReviews = useMemo(() => {
    return [review, review.parentReviewDataFor].filter((r) => r).flat()
  }, [review])

  return (
    <div>
      <h3 className="text-md font-medium text-gray-500 mb-2">
        {t('reviews.comments.ourComments')}
      </h3>

      <ul role="list" className="divide-y divide-gray-200 last:border-b">
        {flatReviews.map((reviewData) => {
          const hasComment = !!reviewData.commentAuthor && !!reviewData.commentText

          return (
            <li className="py-2 flex items-center" key={reviewData.id}>
              <div className="flex flex-1 items-center">
                <Flag locale={reviewData.locale} height="20px" />

                <div className="px-2 text-sm text-gray-500">
                  {hasComment ? (
                    <>
                      <span>{reviewData.commentText}</span>
                      <span className="italic"> - {reviewData.commentAuthor}</span>
                    </>
                  ) : (
                    <span className="italic">{t('reviews.comments.noComment')}</span>
                  )}
                </div>
              </div>

              <div className="flex gap-2">
                <Button
                  Icon={PencilAltIcon}
                  mode="outlined"
                  onClick={() => setReviewDataToModify(reviewData)}
                />

                {hasComment && (
                  <Button
                    Icon={TrashIcon}
                    mode="error"
                    onClick={() => setReviewDataIdToDelete(reviewData.id)}
                  />
                )}
              </div>
            </li>
          )
        })}
      </ul>

      {!!reviewDataToModify && (
        <EditCreateCommentDialog
          isOpen={true}
          reviewData={reviewDataToModify}
          onClose={setReviewDataToModify}
          onReviewsChanged={onReviewsChanged}
        />
      )}

      <DeleteReviewCommentDialog
        reviewDataId={reviewDataIdToDelete}
        onClose={setReviewDataIdToDelete}
        onReviewsChanged={onReviewsChanged}
      />
    </div>
  )
}

Comments.propTypes = {}

Comments.defaultProps = {}

export default Comments
