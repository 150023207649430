import React, { useRef } from 'react'
import { bool, oneOf } from 'prop-types'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useFetchSystemPropsByType from '../../../hooks/market-researcher/useFetchSystemPropsByType'

import Loader from '../../loader'
import CreateEditSytemPropDialog from './create-edit-system-prop.dialog'

const SystemPropsList = ({
  propType,
  showFilterField,
  showSectionField,
  showImpactField
}) => {
  const { t } = useTranslation()
  const { locale } = useParams()

  const { mrSystemProps, mrSystemPropsLoading, mrSystemPropsRefetch } =
    useFetchSystemPropsByType(propType)

  const productFunctionDialogRef = useRef(null)

  const handleEdit = (productFunction) => {
    productFunctionDialogRef.current.selectProductFunction(productFunction)
  }

  const handleEditOrCreate = () => {
    mrSystemPropsRefetch()
  }

  if (mrSystemPropsLoading) {
    return (
      <div className="flex justify-center">
        <Loader />
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="flex flex-row gap-2 items-end justify-end">
        <CreateEditSytemPropDialog
          ref={productFunctionDialogRef}
          propType={propType}
          showFilterField={showFilterField}
          showSectionField={showSectionField}
          showImpactField={showImpactField}
          onEditOrCreate={handleEditOrCreate}
        />
      </div>

      {!mrSystemProps.length && (
        <p className="text-center">{t('content:warning.noDataYet')}</p>
      )}

      {mrSystemProps
        .slice()
        .sort((productFunctionA, productFunctionB) => {
          if (
            productFunctionA.nameByLocale?.[locale] &&
            !productFunctionB.nameByLocale?.[locale]
          ) {
            return 1
          }
          if (
            !productFunctionA.nameByLocale?.[locale] &&
            productFunctionB.nameByLocale?.[locale]
          ) {
            return -1
          }
          if (
            productFunctionA.nameByLocale?.[locale] &&
            productFunctionB.nameByLocale?.[locale]
          ) {
            return productFunctionA.nameByLocale[locale].localeCompare(
              productFunctionB.nameByLocale[locale]
            )
          }

          return 0
        })
        .map((productFunction) => {
          const localeName = productFunction.nameByLocale?.[locale]
          const localeDescription = productFunction.descriptionByLocale?.[locale]

          return (
            <div
              key={productFunction.id}
              className="p-3 border border-gray-300 shadow-sm bg-white rounded cursor-pointer"
              onClick={handleEdit.bind(null, productFunction)}
            >
              <div className="flex flex-col">
                <span>
                  <span className={`font-semibold ${localeName ? '' : 'text-red-500'}`}>
                    {t('marketResearcher:productfunctions.item.name')}:{' '}
                  </span>
                  {localeName ||
                    `(${productFunction.category}.${productFunction.dbName})`}
                </span>

                <span>
                  <span className="font-semibold">
                    {t('marketResearcher:productfunctions.item.description')}:{' '}
                  </span>
                  {localeDescription || '-'}
                </span>

                {showFilterField && (
                  <span>
                    <span className="font-semibold">
                      {t('marketResearcher:productfunctions.item.filter')}:{' '}
                    </span>
                    {productFunction.filter ? t('content:yes') : t('content:no')}
                  </span>
                )}

                {showSectionField && (
                  <span>
                    <span className="font-semibold">{t('content:section')}: </span>
                    {productFunction.section || '-'}
                  </span>
                )}

                {showImpactField && (
                  <span>
                    <span className="font-semibold">TODO Impact: </span>
                    {productFunction.impact || '-'}
                  </span>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

SystemPropsList.propTypes = {
  propType: oneOf(['PRODUCT_FUNCTIONALITIES', 'SYSTEM_CHARACTERISTICS', 'AREAS_OF_USE'])
    .isRequired,
  showFilterField: bool,
  showSectionField: bool,
  showImpactField: bool
}

SystemPropsList.defaultProps = {}

export default SystemPropsList
