import { useState, useEffect, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export default function useAutoSaveSingle(cachedValue, onSave) {
  const [value, setValue] = useState(undefined)

  useEffect(() => {
    if (cachedValue === value) {
      setValue(undefined)
    }
  }, [value, cachedValue])

  const debounced = useDebouncedCallback(async (value) => {
    onSave(value)
  }, 800)

  const setAutoSaveValue = useCallback((newValue) => {
    setValue(newValue)
  }, [])

  useEffect(() => {
    if (value !== undefined) {
      debounced(value)
    }
  }, [debounced, value])

  return {
    setAutoSaveValue,
    autoSaveValue: value === undefined ? cachedValue : value
  }
}
