import React, { useEffect, useState, useMemo } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import classNames from 'classnames'

import { useTranslation } from 'react-i18next'
import { useFormFields } from '../hooks'
import useFetchOperationalCountries from '../hooks/useFetchOperationalCountries'
import useGetCategoryName from '../hooks/useGetCategoryName'
import useGetSystemName from '../hooks/useGetSystemName'

import Loader from '../components/loader'
import ModalNotification from '../components/modalNotification'

import Fields from '../components/form/fields'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import { Select } from '../components/form/inputs'

import useOverlayNotification from '../hooks/useOverlayNotification'

import { ADD_USER_FORM } from '../constants/user-form-fields'

const GET_VENDORS = gql`
  query Vendors {
    appAdminCompanies {
      id
      company
      accessRights
      userName
      systemsV2 {
        id
        categories
        systemSiteData {
          systemName
        }
      }
      user {
        id
        name
        email
        disabled
        appLocales
        appRole
      }
      buyingIntentBoost
      buyingIntentBoostCategories
      pipedriveId
    }
  }
`

const SAVE_USER = gql`
  mutation AppAddUser($data: Json) {
    appAddUser(data: $data) {
      id
      email
      tempPassword
    }
  }
`

const defaultFields = {
  name: '',
  email: '',
  appLocales: [],
  appRole: null,
  systems: []
}

function AdminAddUserPage() {
  const [selectedVendorId, setSelectedVendorId] = useState(null)
  const [tempPassword, setTempPassword] = useState(null)
  const [allVendors, setAllVendors] = useState([])
  const [formInputs, setFormInputs] = useState(null)
  const [visibleModal, setVisibleModal] = useState(false)
  const { t } = useTranslation('adminPages')
  const [fieldsData, handleFieldChange, saveInitialState] = useFormFields(defaultFields)
  const { usersMarketRegionsValues } = useFetchOperationalCountries()
  const { allCategoriesMap, getCategoriesString } = useGetCategoryName()
  const { getSystemName } = useGetSystemName()
  const { showErrorNotification } = useOverlayNotification()

  const { error: allVendorsError, loading: allVendorsLoading } = useQuery(GET_VENDORS, {
    onCompleted: (data) => {
      const updatedAllVendors = data.appAdminCompanies?.map((vendorObj) => {
        return {
          ...vendorObj,
          title: vendorObj.company
        }
      })
      console.log({ updatedAllVendors })
      setAllVendors(updatedAllVendors)
    }
  })

  const [addUser, { loading: addUserLoading, called: addUserCalled }] = useMutation(
    SAVE_USER,
    {
      onError(err) {
        showErrorNotification({ text: err.message })
      },
      onCompleted(data) {
        setTempPassword(data.appAddUser?.tempPassword)
        setVisibleModal(true)

        // Clear form
        saveInitialState(defaultFields)
      }
    }
  )

  const selectedVendorObj = useMemo(() => {
    if (!selectedVendorId || !allVendors) {
      return null
    }

    return allVendors.find((vendorObj) => vendorObj.id === selectedVendorId)
  }, [selectedVendorId, allVendors])

  useEffect(() => {
    if (!selectedVendorObj || !allCategoriesMap) {
      setFormInputs(ADD_USER_FORM)
      return
    }

    const updatedInputs = ADD_USER_FORM.map((inputObj) => {
      if (inputObj.id === 'appLocales') {
        inputObj.values = usersMarketRegionsValues
        return inputObj
      }

      if (inputObj.id !== 'systems') {
        return inputObj
      }

      const updatedInputObj = { ...inputObj }
      const systemsOnVendor = selectedVendorObj.systemsV2.map((systemObj) => {
        const { id, categories } = systemObj

        return {
          id,
          title: getSystemName(systemObj),
          text: `${t('content:category')}: ${getCategoriesString(categories)}`
        }
      })
      updatedInputObj.values = systemsOnVendor

      return updatedInputObj
    })

    setFormInputs(updatedInputs)
  }, [
    selectedVendorObj,
    allCategoriesMap,
    t,
    getSystemName,
    getCategoriesString,
    usersMarketRegionsValues
  ])

  const handleSelectCompanyId = (e) => {
    const { value } = e.target

    if (value === '-- Välj --') {
      setSelectedVendorId(null)
    } else {
      setSelectedVendorId(value)
    }

    saveInitialState(defaultFields)
  }

  // @TODO: Fix mutation
  const handleNewUserFormSubmit = (e) => {
    e.preventDefault()

    const { name, email, appLocales, appRole, systems } = fieldsData

    const cleanedEmail = email?.trim()?.toLowerCase()

    if (!name || !email || !cleanedEmail) {
      console.error('ERROR, missing data')
      return false
    }

    addUser({
      variables: {
        data: {
          name,
          email: cleanedEmail,
          vendor: selectedVendorId,
          appLocales,
          appRole,
          systems
        }
      }
    })
  }

  return (
    <>
      <PageHeader pageName={t('adminAddUser.header.title')}>
        <p>{t('adminAddUser.header.subtitle')}</p>
      </PageHeader>

      <PageBody>
        {allVendorsError &&
          allVendorsError.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}

        {allVendorsLoading && (
          <div className="text-center">
            <Loader />
          </div>
        )}

        <div>
          {!allVendorsLoading && (
            <div>
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={handleNewUserFormSubmit}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="pt-8">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {t('adminAddUser.addNewUserTitle')}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        {t('adminAddUser.addNewUserSubtitle')}
                      </p>

                      <Select
                        field={{
                          id: 'vendor',
                          nameTid: 'content:company',
                          textTid: 'adminPages:adminAddUser.companySubtitle',
                          type: 'select',
                          values: allVendors
                        }}
                        data={selectedVendorId}
                        handleFieldChange={handleSelectCompanyId}
                      />

                      <div className="mt-4">
                        <h4 className="block text-sm font-medium text-gray-700">
                          {t('adminAddUser.userOnCompany')}
                        </h4>
                        <ul>
                          {selectedVendorObj?.user?.map((user) => {
                            const { id, name, email, disabled, appRole, appLocales } =
                              user
                            return (
                              <li key={id} className="text-sm text-gray-500">
                                {name} - {email} - {disabled ? 'disabled ' : 'enabled '}-{' '}
                                {appRole} - {appLocales?.join(' ')}
                              </li>
                            )
                          })}
                        </ul>
                      </div>

                      {selectedVendorObj && !!Object.keys(fieldsData).length && (
                        <Fields
                          inputFields={formInputs}
                          fieldsData={fieldsData}
                          handleFieldChange={handleFieldChange}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      disabled={addUserCalled && addUserLoading}
                      className={classNames(
                        'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        {
                          'disabled:opacity-50': addUserCalled && addUserLoading
                        }
                      )}
                    >
                      {t('adminAddUser.button.save')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          <ModalNotification
            title={t('adminAddUser.userSavedModal.title')}
            text={
              <div>
                {`${t('adminAddUser.userSavedModal.temporaryPassword')}: `}
                <div>
                  <strong>{tempPassword}</strong>
                </div>
              </div>
            }
            visible={visibleModal}
            handleVisible={() => {
              setVisibleModal(false)
            }}
          />
        </div>
      </PageBody>
    </>
  )
}

export default AdminAddUserPage
