import { useQuery, gql } from '@apollo/client'

const GQL_FETCH_GUIDES = gql`
  query AdminListGuides {
    guides {
      id
      active
      categories
      locales
    }
  }
`

const EMPTY_ARR = []

export default function useFetchGuides(fetchPolicy) {
  const { data, loading } = useQuery(GQL_FETCH_GUIDES, {
    fetchPolicy
  })

  return {
    guides: data?.guides || EMPTY_ARR,
    guidesLoading: loading
  }
}
