const FIELD_GUIDE_NOTIFICATIONS_USER = {
  id: 'guideNotifications',
  textTid: 'content:notifications.notifyAboutSystemGuide.title',
  type: 'select',
  values: [
    {
      id: 'ALL',
      titleTid: 'content:yes'
    },
    {
      id: 'NONE',
      titleTid: 'content:no'
    },
    {
      id: 'ONLY_VERIFIED',
      titleTid: 'content:notifications.notifyAboutSystemGuide.onlyVerified'
    }
  ]
}

const FIELD_GUIDE_NOTIFICATIONS_VENDOR = {
  ...FIELD_GUIDE_NOTIFICATIONS_USER,
  textTid: 'content:notifications.notifyAboutSystemGuide.title'
}

const FIELD_GUIDE_NOTIFICATIONS_USER_SETTINGS = {
  ...FIELD_GUIDE_NOTIFICATIONS_USER,
  textTid: 'userSettings:notifications.notifyAboutSystemGuide'
}

export {
  FIELD_GUIDE_NOTIFICATIONS_VENDOR,
  FIELD_GUIDE_NOTIFICATIONS_USER,
  FIELD_GUIDE_NOTIFICATIONS_USER_SETTINGS
}
