import React from 'react'

import PageHeader from '../components/shared/page-header'
import { PageBody } from '../components/page-body'

import ManageSystem from '../components/manageSystem'

const AdminAddSystemV2Page = () => {
  return (
    <>
      <PageHeader title="adminPages:adminAddSystem.header.title" />
      <PageBody>
        <ManageSystem mode="CREATE" />
      </PageBody>
    </>
  )
}

export default AdminAddSystemV2Page
