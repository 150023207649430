import React from 'react'
import { bool, func, oneOfType, string } from 'prop-types'

import useDeleteReviewTranslation from '../hooks/useDeleteReviewTranslation'

import Dialog from '../../../shared/dialog'

const DeleteReviewTranslationDialog = ({ reviewId, onCompleted, onClose }) => {
  const { deleteReviewTranslation, deleteReviewTranslationLoading } =
    useDeleteReviewTranslation()

  const handleDeleteTranslation = () => {
    deleteReviewTranslation(reviewId, onCompleted)
    onClose(false)
  }

  return (
    <Dialog
      isOpen={!!reviewId}
      title="Ta Bort"
      confirmText="Bekräfta"
      onConfirm={handleDeleteTranslation}
      onClose={onClose}
      loading={deleteReviewTranslationLoading}
    />
  )
}

DeleteReviewTranslationDialog.propTypes = {
  reviewId: oneOfType([string, bool]),
  onCompleted: func.isRequired,
  onClose: func.isRequired
}

DeleteReviewTranslationDialog.defaultProps = {}

export default DeleteReviewTranslationDialog
