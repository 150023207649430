import React, { useState } from 'react'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'

import { InformationCircleIcon } from '@heroicons/react/outline'
import Dialog from '../shared/dialog'
import useMediaQuery from '../../hooks/useMediaQuery'

const STATE_AWARENESS = {
  cardTitle: {
    sv_SE: 'Awareness'
  },
  modalTitle: {
    sv_SE: 'Awareness Phase'
  },
  extraClassName: '',
  texts: {
    sv_SE: [
      {
        header: 'Vart köparen befinner sig i sin köpresa?',
        text: 'Köparen inser att de har ett problem eller en möjlighet men förstår det kanske inte helt ännu. De söker information för att bättre förstå sin situation. Det är inte alls säkert att köparen är redo för att ta ett möte.'
      },
      {
        header: 'Innehåll som köparen typiskt konsumerar i denna fas:',
        text: 'Blogginlägg, white papers, infographics, uppdateringar på sociala medier.'
      },
      {
        header: 'Hur du bör behandla lead i denna fas:',
        text: 'Jobba på din synlighet, se till att köparen uppfattar dig som en thought leader i din kategori, re-targeting med utbildande innehåll.'
      },
      {
        header: 'Mått',
        text: 'Profilbesök på BusinessWith, webbplatstrafik, engagemang på sociala medier.'
      }
    ],
    en_GB: [
      {
        header: 'Awareness - Buyer Journey',
        text: 'The buyer realizes they have a problem or an opportunity but may not fully understand it yet. They are seeking information to better grasp their situation. It is not at all certain that the buyer is ready to take a meeting.'
      },
      {
        header: 'Content that buyers typically consumes in this phase',
        text: 'Blog posts, white papers, infographics, social media updates.'
      },
      {
        header: 'How you should treat leads in this phase',
        text: 'Work on your visibility, ensure that the buyer perceives you as a thought leader in your category. The buyer will most likely visit your website, make sure you have implemented re-targeting with educational content. Goal is to build relationships with the buyer and stay top of mind.'
      },
      {
        header: 'Metrics',
        text: 'Profile visits on BusinessWith, Website traffic, Social media engagement.'
      }
    ]
  }
}

const STATE_CONSIDERATION = {
  cardTitle: {
    sv_SE: 'Consideration'
  },
  modalTitle: {
    sv_SE: 'Consideration Phase'
  },
  extraClassName: '',
  texts: {
    sv_SE: [
      {
        header: 'Vart köparen befinner sig i sin köpresa?',
        text: 'Köparen förstår mer om sitt problem/möjlighet och undersöker olika lösningar. De jämför alternativ och söker mer detaljerad information. Här är det större sannolikhet att köparen är mer mottaglig för att ta ett möte med dig men det är inget du ska förvänta dig. De är ännu en bit ifrån köpbeslut.'
      },
      {
        header: 'Innehåll som köparen typiskt konsumerar i denna fas:',
        text: 'Omdömen, kundcase, webbinarer, jämförelser, e-böker'
      },
      {
        header: 'Hur du bör behandla lead i denna fas:',
        text: 'Demonstrera värde, visa expertis, ge mer djupgående information om hur ni kan hjälpa köparen. Titta på köpsignaler och jobba även  här med re-targeting men med fokus på kundcase och jämförelser, utmärkelser osv.'
      },
      {
        header: 'Mått',
        text: 'Engagemang med innehåll, webbinariedeltagande, nedladdningar av guider, tid spenderad på webbplatsen.'
      }
    ],
    en_GB: [
      {
        header: 'Consideration - Buyer Journey',
        text: 'The buyer understands more about their problem/opportunity and is exploring various solutions. They are comparing alternatives and seeking more detailed information. At this stage, it is more likely that the buyer is receptive to taking a meeting with you, but this is not something you should expect. They are still not ready to make a purchase decision. But they are more likely to have meetings with vendors.'
      },
      {
        header: 'Content that buyers typically consumes in this phase',
        text: 'Reviews, customer cases, webinars, comparisons, e-books.'
      },
      {
        header: 'How you should treat leads in this phase',
        text: 'Demonstrate value, show expertise, show how you compare to the alternatives, provide more in-depth information on how you can help the buyer. Look for buying signals and also work with re-targeting but focus on customer cases, comparisons, awards, etc.'
      },
      {
        header: 'Metrics',
        text: 'Engagement with content, webinar attendance, guide downloads, time spent on the website.'
      }
    ]
  }
}

const STATE_DECISION = {
  cardTitle: {
    sv_SE: 'Decision'
  },
  modalTitle: {
    sv_SE: 'Decision Phase'
  },
  extraClassName: '',
  texts: {
    sv_SE: [
      {
        header: 'Vart köparen befinner sig i sin köpresa?',
        text: 'Köparen är mer redo att ta ett köpbeslut. De begränsar sina alternativ och behöver försäkran om att de väljer rätt lösning.'
      },
      {
        header: 'Innehåll som köparen typiskt konsumerar i denna fas:',
        text: 'Free trials, bokar demo, pricing sidor, ROI-beräkningar, kundomdömen.'
      },
      {
        header: 'Hur du bör behandla lead i denna fas:',
        text: 'Här bör du be om möte. Fördel om du är snabb med att höra av dig. Övertyga leadet om värdet du ger, ge tydlig pris- och ROI-information, hantera sista invändningarna.'
      },
      {
        header: 'Mått',
        text: 'Konverteringsgrad, antal demo/trials, offertförfrågningar, avslutade affärer.'
      }
    ],
    en_GB: [
      {
        header: 'Decision - Buyer Journey',
        text: 'The buyer is more ready to make a purchase decision. They are narrowing down their options and need reassurance that they are choosing the right solution.'
      },
      {
        header: 'Content the buyers typically consumes in this phase',
        text: 'Free trials, demo bookings, pricing pages, ROI calculations, customer testimonials.'
      },
      {
        header: 'How you should treat leads in this phase',
        text: "At this point, you should ask for a meeting. It's advantageous to be quick in reaching out. Convince the lead of the value you provide, offer clear pricing and ROI information, and address any final objections."
      },
      {
        header: 'Metrics',
        text: 'Conversion rate, number of demos/trials, proposal requests, closed deals.'
      }
    ]
  }
}

const STATE_NONE = {
  cardTitle: {
    sv_SE: 'N/A'
  }
}

const STATES_MAP = {
  NEW_SYSTEM: STATE_AWARENESS,
  CHANGE: STATE_DECISION,
  MARKET: STATE_AWARENESS,
  MATCHING: STATE_CONSIDERATION,
  BRANCH: STATE_CONSIDERATION,
  AVAILABLE_MARKET: STATE_AWARENESS,
  MATCHES_NEEDS: STATE_CONSIDERATION,
  FAR_IN_JOURNEY: STATE_DECISION,
  FIRM: STATE_CONSIDERATION,
  MORE_INFO: STATE_AWARENESS,
  NONE: STATE_NONE
}

const POSSIBLE_STATES_ARR = [STATE_AWARENESS, STATE_CONSIDERATION, STATE_DECISION]

const CustomerStagesExplainer = ({ specialName }) => {
  const [openDialogState, setOpenDialogState] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { i18n } = useTranslation()
  const { language } = i18n
  const currStateInfo = STATES_MAP[specialName]
  const isDesktop = useMediaQuery('(min-width: 1280px)')

  if (!currStateInfo) {
    console.log('No currStateInfo for: ' + specialName)
    return null
  }

  const textsToUse = openDialogState?.texts?.[language] || openDialogState?.texts?.sv_SE
  const tModalTitle =
    openDialogState?.modalTitle?.[language] || openDialogState?.modalTitle?.sv_SE

  const handleOpenDialog = (state) => {
    setOpenDialogState(state)
    setDialogOpen(true)
  }

  return (
    <div
      className={cx('flex gap-1', {
        'flex-1': isDesktop
      })}
    >
      {POSSIBLE_STATES_ARR.map((state) => {
        const { extraClassName } = currStateInfo
        const isCurrentState = currStateInfo.cardTitle.sv_SE === state.cardTitle.sv_SE
        const thisCardTitle = state.cardTitle[language]
          ? state.cardTitle[language]
          : state.cardTitle.sv_SE

        if (!isDesktop && !isCurrentState) {
          return null
        }

        return (
          <div
            key={state.cardTitle.sv_SE}
            className={cx(
              'flex flex-1 justify-center rounded px-1 py-[1px] text-gray-600 gap-1 z-[5] cursor-help',
              extraClassName,
              {
                'bg-green-400': isCurrentState,
                'bg-gray-300': !isCurrentState
              }
            )}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleOpenDialog(state)
            }}
          >
            <span className="text-xs uppercase">{thisCardTitle}</span>
            <InformationCircleIcon className="w-4 h-4" />
          </div>
        )
      })}

      <Dialog
        className="!max-w-md"
        title={tModalTitle}
        isOpen={dialogOpen}
        onClose={setDialogOpen}
      >
        {!!openDialogState && (
          <div className="flex flex-col gap-6">
            {textsToUse.map((section, index) => {
              return (
                <div className="flex flex-col" key={index}>
                  <p className="text-sm font-semibold text-gray-700">{section.header}</p>
                  {section.text && (
                    <p className="text-sm text-gray-600">{section.text}</p>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </Dialog>
    </div>
  )
}

CustomerStagesExplainer.propTypes = {}

CustomerStagesExplainer.defaultProps = {}

export default CustomerStagesExplainer
