import React, { useState, useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'

import { useUserContext } from '../contexts' // useAuthContext,
import { XCircleIcon } from '@heroicons/react/solid'
import { PageBody } from '../components/page-body'

import * as ga from '../lib/ga'
import { useNavigate } from 'react-router-dom'

import Loader from '../components/loader'
import PrivacyPolicy from '../data/privacyPolicy'
import TermsOfUse from '../data/termsOfUse'

const ACCEPT_AGREEMENT = gql`
  mutation AcceptAgreement($consent: Boolean!) {
    appAcceptAgreement(consent: $consent)
  }
`

function AcceptPage() {
  const { updateAcceptUser, user } = useUserContext()
  const navigate = useNavigate()
  const [errorName, setErrorName] = useState(null)

  let consent
  const [acceptAgreement, { loading: acceptLoading }] = useMutation(ACCEPT_AGREEMENT, {
    onError(err) {
      // console.log({ err });
      // err is for example: "Error: notFound"
      const error = `${err}`.split(':').reverse()[0]
      // console.log({ error });
      setErrorName(error)
    },
    onCompleted(data) {
      // console.log("done mutation", data);
      updateAcceptUser(data)

      navigate('/')
    }
  })

  useEffect(() => {
    if (user.isFirstLogin) {
      navigate('/update-password')
    } else if (user.accepted) {
      navigate('/')
    }
  }, [])

  // TODO: Show Accept overlay
  // When clicked accept, mutation to db
  // Redirect on ok aswer from mutation
  return (
    <PageBody>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 mb-10">
        Avtal
      </h2>

      <div className="flex flex-col items-center bg-white">
        <div
          className="bg-white border border-gray-300 rounded p-5 overflow-scroll max-w-screen-sm"
          style={{
            height: '300px'
          }}
        >
          <PrivacyPolicy />
        </div>

        <div
          className="bg-white border border-gray-300 rounded p-5 mt-10 overflow-scroll max-w-screen-sm"
          style={{
            height: '300px'
          }}
        >
          <TermsOfUse />
        </div>
      </div>

      <form
        className="py-6 bg-white"
        onSubmit={(e) => {
          e.preventDefault()

          acceptAgreement({
            variables: { consent: consent.checked }
          })
          consent.checked = false

          ga.event({
            action: 'accept_agreement'
          })
        }}
      >
        <div className="relative flex items-center justify-center">
          <div className="flex items-center h-5">
            <input
              id="consent"
              aria-describedby="consent"
              name="consent"
              type="checkbox"
              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              ref={(node) => {
                consent = node
              }}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="consent" className="font-medium text-gray-700">
              Jag godkänner avtalen
            </label>
          </div>
        </div>

        {/* <div className={stylesLogin.loginBtnWrap}> */}
        {acceptLoading && !errorName && <Loader />}

        <div className="flex my-5">
          {/* {!acceptLoading && ( */}
          <button
            type="submit"
            className="group relative flex justify-center m-auto py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Godkänn
          </button>
          {/* )} */}
        </div>

        {errorName && (
          <div className="rounded-md bg-red-50 border border-red-300 p-4 max-w-screen-sm m-auto">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Det var ett fel i formuläret
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul role="list" className="list-disc pl-5 space-y-1">
                    <li>{errorName}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </PageBody>
  )
}

export default AcceptPage
