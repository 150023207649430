import React, { useEffect, useState } from 'react'
import { bool, func, object, string } from 'prop-types'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { PencilAltIcon } from '@heroicons/react/outline'

import Button from './button'
import TextArea from './textarea'

const EditableI18nTextarea = ({
  id,
  texts,
  onSave,
  onCancel,
  locale,
  loading,
  creatorMode,
  disabled,
  allowSaveEmpty
}) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const [updatedTexts, setUpdatedTexts] = useState({})

  const isEditing = editing || creatorMode

  const textSource = isEditing ? updatedTexts.sourceText : texts?.en_GB || ''
  const textLocale = isEditing ? updatedTexts.localeText : texts?.[locale] || ''

  useEffect(() => {
    if (!isEditing) {
      setUpdatedTexts({})
    }
  }, [isEditing])

  const handleChange = (updatedLocale, value) => {
    const key = updatedLocale === 'en_GB' ? 'sourceText' : 'localeText'
    setUpdatedTexts((prevData) => ({ ...prevData, [key]: value || '' }))
  }

  const handleStartEdit = () => {
    setUpdatedTexts({
      localeText: texts?.[locale] || '',
      sourceText: texts?.en_GB || ''
    })
    setEditing(true)
  }

  const handleCancelEdit = () => {
    setEditing(false)
    onCancel?.()
  }

  const doneCallback = () => {
    setEditing(false)
  }

  const handleSave = () => {
    onSave(id, { ...updatedTexts, locale }, doneCallback)
  }

  const renderTextComponent = () => {
    if (isEditing) {
      return (
        <div className="flex gap-2 w-full">
          <TextArea
            className="flex-1"
            id="en_GB"
            value={textSource}
            onChange={handleChange}
            disabled={loading}
            rows={3}
            helperText={t(`content:localeToLanguage.en_GB`)}
          />

          <TextArea
            className="flex-1"
            id={locale}
            value={textLocale}
            onChange={handleChange}
            disabled={loading}
            rows={3}
            helperText={t(`content:localeToLanguage.${locale}`)}
          />
        </div>
      )
    }

    if (!textLocale) {
      return (
        <span className="flex items-center italic text-sm">
          {t('content:noTextAdded')}
        </span>
      )
    }

    return <span className="flex items-center">{textLocale}</span>
  }

  return (
    <div
      className={cx('flex', {
        'flex-col gap-2': isEditing
      })}
    >
      <div
        className={cx('flex mr-1', {
          'w-full': isEditing
        })}
      >
        {renderTextComponent()}
      </div>

      <div className="flex gap-2 justify-end">
        {isEditing ? (
          <>
            <Button
              size="small"
              mode="default"
              onClick={handleCancelEdit}
              disabled={loading}
            >
              {t('content:cancel')}
            </Button>

            <Button
              size="small"
              onClick={handleSave}
              loading={loading}
              disabled={
                !allowSaveEmpty &&
                (!textSource?.trim().length || !textLocale?.trim().length)
              }
            >
              {creatorMode ? t('content:create') : t('content:save')}
            </Button>
          </>
        ) : (
          <Button
            size="small"
            onClick={handleStartEdit}
            mode="outlined"
            disabled={disabled}
          >
            <PencilAltIcon className="w-4 text-gray-600" />
          </Button>
        )}
      </div>
    </div>
  )
}

EditableI18nTextarea.propTypes = {
  id: string,
  texts: object,
  locale: string,
  onSave: func,
  onCancel: func,
  loading: bool,
  creatorMode: bool,
  disabled: bool
}

EditableI18nTextarea.defaultProps = {}

export default EditableI18nTextarea
