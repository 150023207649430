import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import useFetchCategories from './useFetchCategories'

export default function useGetCategoryName() {
  const { allCategoriesMap, allCategoriesLoading } = useFetchCategories()
  const { i18n } = useTranslation()

  const getCategoryName = useCallback(
    (category, displayShort, prefferedLocale) => {
      if (!allCategoriesMap || !category || !allCategoriesMap[category]) {
        return ''
      }

      const categoryLocales = allCategoriesMap[category]?.locales

      if (!categoryLocales) {
        return ''
      }

      const languagePriority = [
        ...new Set([prefferedLocale, i18n.language, 'en_GB', 'sv_SE'].filter((l) => l))
      ]

      let localeObjToUse

      for (const language of languagePriority) {
        if (categoryLocales[language]) {
          localeObjToUse = categoryLocales[language]
          break
        }
      }

      if (!localeObjToUse) {
        localeObjToUse = Object.values(categoryLocales)[0]
      }

      const categoryNameToReturn = displayShort
        ? localeObjToUse?.nameShort
        : localeObjToUse?.name

      return categoryNameToReturn || ''
    },
    [allCategoriesMap, i18n.language]
  )

  const getCategoriesString = useCallback(
    (categoriesArray) => {
      if (!allCategoriesMap || !categoriesArray || !categoriesArray.length) {
        return ''
      }

      return categoriesArray.map((category) => getCategoryName(category)).join(', ')
    },
    [allCategoriesMap, getCategoryName]
  )

  return {
    allCategoriesMap,
    allCategoriesLoading,
    getCategoryName,
    getCategoriesString
  }
}
