import React, { useCallback, useEffect, useMemo } from 'react'
import { array, bool, func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useFetchAvailableSites from '../../hooks/useFetchAvailableSites'

import { Select } from './select'
import Label from './label'

const SitesSelect = ({
  alternativeLocales,
  label,
  value,
  onChange,
  fieldName,
  disableAutoInit,
  disabled,
  loading,
  marginless,
  skipLocales
}) => {
  const { t } = useTranslation()
  const { sites } = useFetchAvailableSites(!!alternativeLocales)

  const locales = useMemo(() => {
    if (alternativeLocales) {
      return alternativeLocales
    }

    const allLocales = sites.map((site) => site.defaultLocale)

    if (Array.isArray(skipLocales)) {
      return allLocales.filter((locale) => !skipLocales.includes(locale))
    }

    return allLocales
  }, [alternativeLocales, skipLocales, sites])

  const handleChange = useCallback(
    (locale) => {
      if (fieldName) {
        onChange(fieldName, locale)
      } else {
        onChange(locale)
      }
    },
    [onChange, fieldName]
  )

  useEffect(() => {
    if (!locales || value || disableAutoInit) {
      return
    }

    if (locales.includes('sv_SE')) {
      handleChange('sv_SE')
    } else {
      handleChange(locales[0])
    }
  }, [locales, value, handleChange, disableAutoInit])

  const localesOptions = useMemo(() => {
    if (!locales) {
      return []
    }

    return locales.map((locale) => ({
      label: t(`content:localeToLanguage.${locale}`),
      value: locale
    }))
  }, [locales, t])

  return (
    <Label title={label} marginless={marginless}>
      <Select
        options={localesOptions}
        onChange={handleChange}
        value={value}
        rawChangeEvent={false}
        isSearchable={false}
        disabled={disabled}
        loading={loading}
      />
    </Label>
  )
}

SitesSelect.propTypes = {
  alternativeLocales: array,
  onChange: func.isRequired,
  label: string,
  value: string,
  disableAutoInit: bool,
  disabled: bool,
  loading: bool,
  marginless: bool
}

SitesSelect.defaultProps = {}

export default SitesSelect
