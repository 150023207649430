import React, { forwardRef, useState } from 'react'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'
import styled from 'styled-components'
import cx from 'classnames'

import LoadingSpinner from '../../../loading-spinner'
import { ChevronDownIcon, XIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'

const StyledInnerWrapper = styled.div`
  min-height: 34px;
`

const Input = forwardRef(
  (
    {
      children,
      onChange,
      onFocus,
      value,
      onRequestOpen,
      placeholder,
      showClearIcon,
      readOnly,
      errorText,
      disabled,
      loading
    },
    ref
  ) => {
    const [hasFocus, setHasFocus] = useState(false)
    const { t } = useTranslation()

    const handleChange = (e) => {
      onChange(e.target.value)
    }

    const handleClear = () => {
      if (disabled) {
        return
      }

      onChange(undefined)
    }

    const handleFocus = (e) => {
      setHasFocus(true)
      onFocus && onFocus(e)
    }

    const handleBlur = () => {
      setHasFocus(false)
    }

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault()
      }
    }

    return (
      <div className="w-full">
        <div
          className={cx(
            'p-[3px] flex border border-gray-300 bg-white rounded sm:p-[1px] focus-within:ring-1 focus-within:ring-indigo-500 focus-within:border-indigo-500',
            {
              'border-rose-500 focus:border-rose-500 focus:ring-rose-500': !!errorText,
              'outline outline-1 outline-rose-500': !!errorText && hasFocus,
              'opacity-50': disabled
            }
          )}
        >
          <div className="flex flex-auto flex-wrap">
            {children}
            <StyledInnerWrapper className="flex-1">
              <input
                className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800 text-sm placeholder-gray-400 border-none focus:ring-0 focus:ring-offset-0"
                placeholder={placeholder ? t(placeholder) : ''}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={value}
                onKeyDown={handleKeyDown}
                ref={ref}
                readOnly={readOnly}
                disabled={disabled || loading}
              />
            </StyledInnerWrapper>
          </div>

          {showClearIcon && (
            <div
              className={cx('flex align-center px-2', {
                'cursor-pointer': !disabled
              })}
              onClick={handleClear}
            >
              <XIcon className="w-5 text-gray-500 px-0.5" />
            </div>
          )}

          <div
            className={cx('flex justify-center items-center w-10', {
              'cursor-pointer': !disabled && !loading
            })}
            onClick={!disabled && !loading ? onRequestOpen : undefined}
          >
            {loading ? (
              <LoadingSpinner className="w-5 mr-0 !text-gray-600" />
            ) : (
              <ChevronDownIcon className="w-5 text-gray-500" />
            )}
          </div>
        </div>

        {errorText && (
          <span className="relative mt-1 text-sm font-medium text-rose-500">
            {errorText}
          </span>
        )}
      </div>
    )
  }
)

Input.displayName = 'MultiselectInput'
Input.propTypes = {
  children: oneOfType([object, array]),
  onChange: func,
  onFocus: func,
  value: oneOfType([string, number]),
  onRequestOpen: func,
  placeholder: string,
  readOnly: bool,
  errorText: string
}

Input.defaultProps = {}

export default Input
