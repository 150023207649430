import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { SYSTEM_PROP_FRAGMENT } from '../../constants/gql/systemProp'

const GQL_FETCH_SYSTEM_PROPS_BY_TYPE = gql`
  query ListSystemPropsByType(
    $locale: String!
    $categoryDBName: String!
    $propType: PropTypes!
  ) {
    mrListSystemPropsByType(
      locale: $locale
      categoryDBName: $categoryDBName
      propType: $propType
    ) {
      ${SYSTEM_PROP_FRAGMENT}
    }
  }
`

export default function useFetchSystemPropsByType(propType) {
  const { locale, category: categoryDBName } = useParams()

  const { data, loading, refetch } = useQuery(GQL_FETCH_SYSTEM_PROPS_BY_TYPE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      propType,
      locale,
      categoryDBName
    },
    skip: !locale || !categoryDBName
  })

  return {
    mrSystemProps: data?.mrListSystemPropsByType || [],
    mrSystemPropsLoading: loading,
    mrSystemPropsRefetch: refetch
  }
}
