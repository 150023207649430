import { useCallback, useMemo, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import useFetchCategories from './useFetchCategories'

const QUERY_SEARCH_SYSTEM = gql`
  query SearchSystemsResearcher($locale: String!, $queryText: String!) {
    mrSearchSystems(locale: $locale, queryText: $queryText) {
      id
      categories
      locales
      systemName
    }
  }
`

export default function useSearchSystem() {
  const [resultsLoading, setResultsLoading] = useState(false)
  const { locale } = useParams()
  const { mrCategories } = useFetchCategories()

  const [searchSystemsQuery, { loading, data }] = useLazyQuery(QUERY_SEARCH_SYSTEM, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setResultsLoading(false)
    },
    onError: () => {
      setResultsLoading(false)
    }
  })

  const mrSearchSystemsDebouce = useDebouncedCallback((inputValue) => {
    searchSystemsQuery({
      variables: {
        queryText: inputValue,
        locale
      },
      skip: !locale
    })
  }, 800)

  const mrSearchSystems = useCallback(
    (inputValue) => {
      inputValue = inputValue ? inputValue.trim() : ''

      if (!inputValue) {
        return
      }

      setResultsLoading(true)
      mrSearchSystemsDebouce(inputValue)
    },
    [mrSearchSystemsDebouce]
  )

  const mrSystemsOptions = useMemo(() => {
    if (!data?.mrSearchSystems || !mrCategories?.length || resultsLoading) {
      return []
    }

    return data.mrSearchSystems.map((system) => {
      const categories = system.categories.map((category) => {
        const catObject = mrCategories.find((catObj) => catObj.dbName === category)
        return catObject ? catObject.name : category
      })

      return {
        ...system,
        categories,
        label: system.systemName,
        value: system.id
      }
    })
  }, [data, mrCategories, resultsLoading])

  return {
    mrSearchSystems,
    mrSearchSystemsLoading: loading || resultsLoading,
    mrSystemsOptions
  }
}
