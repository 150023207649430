import React, { useMemo } from 'react'

import useGetCategoryName from '../../../hooks/useGetCategoryName'
import useFetchAvailableSites from '../../../hooks/useFetchAvailableSites'
import useSystemsOptions from '../../../hooks/useSystemsOptions'

import Filters from '../../shared/filters'

const AdminAllGuidesFilter = ({ onChangeFilters }) => {
  const { sitesOptions } = useFetchAvailableSites()
  const { allCategoriesMap, getCategoryName } = useGetCategoryName()
  const { systemsOptions } = useSystemsOptions()

  const guideCategoriesMemo = useMemo(() => {
    const categoriesArr = Object.values(allCategoriesMap)

    return categoriesArr.filter((categoryData) => {
      return categoryData.guideLocales?.length > 0
    })
  }, [allCategoriesMap])

  const guideCategoriesOptionsMemo = useMemo(() => {
    return guideCategoriesMemo
      .map((categoryData) => {
        return {
          label: getCategoryName(categoryData.DB, false, 'sv_SE'),
          value: categoryData.DB
        }
      })
      .sort((a, b) => {
        const aLabel = a.label?.trim()?.toLowerCase() || ''
        const bLabel = b.label?.trim()?.toLowerCase() || ''

        return aLabel.localeCompare(bLabel)
      })
  }, [guideCategoriesMemo, getCategoryName])

  const filtersMemo = useMemo(() => {
    return [
      {
        id: 'fromDate',
        nameTid: 'content:from',
        type: 'datepicker'
      },
      {
        id: 'toDate',
        nameTid: 'content:to',
        type: 'datepicker'
      },
      {
        id: 'formDone',
        nameTid: 'content:done',
        singleValue: true,
        options: [
          { labelTid: 'content:yes', value: true },
          { labelTid: 'content:no', value: false }
        ]
      },
      {
        id: 'site',
        nameTid: 'content:sites',
        options: sitesOptions
      },
      {
        id: 'system',
        nameTid: 'content:system',
        options: systemsOptions,
        searchable: true
      },
      {
        id: 'category',
        nameTid: 'content:category',
        options: guideCategoriesOptionsMemo,
        searchable: true
      }
    ]
  }, [sitesOptions, systemsOptions, guideCategoriesOptionsMemo])

  return <Filters filters={filtersMemo} onChangeFilters={onChangeFilters} />
}

AdminAllGuidesFilter.propTypes = {}

AdminAllGuidesFilter.defaultProps = {}

export default AdminAllGuidesFilter
