export const hasRequiredString = (value = '') => {
  return value ? value.trim().length !== 0 : false
}

export const hasMinLengthOf3 = (value) => {
  return typeof value === 'string' ? value.trim().length >= 3 : false
}

export const hasValidEmail = (email = '') => {
  const trimmedEmail = email.trim()
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
    trimmedEmail
  )
}

export const hasItemsInArray = (array) => {
  return Array.isArray(array) && array.length > 0
}
