import { useLazyQuery, gql } from '@apollo/client'
import { useUserContext } from '../contexts'

import { isAdmin } from '../utils'

const GET_VENDORS = gql`
  query GetAllVendorsAdmin {
    appAdminCompanies {
      id
      company
    }
  }
`

export default function useFetchVendorsLazy() {
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const [fetchVendors, { data, loading }] = useLazyQuery(GET_VENDORS, {
    fetchPolicy: 'cache-first',
    skip: !hasAdmin
  })

  return {
    fetchVendors,
    vendors: data?.appAdminCompanies,
    vendorsLoading: loading
  }
}
