import React, { useState } from 'react'
import { string } from 'prop-types'
import { TrashIcon } from '@heroicons/react/outline'

import { useTranslation } from 'react-i18next'
import useDeleteAnswer from '../../../../../hooks/manage-guides/useDeleteAnswer'

import Button from '../../../../shared/button'
import Dialog from '../../../../shared/dialog'

const DeleteAnswerButton = ({ questionId, answerId }) => {
  const { t } = useTranslation('adminPages')
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const { deleteAnswer, deleteAnswerLoading } = useDeleteAnswer()

  const handleDelete = () => {
    setConfirmDeleteOpen(true)
  }

  const handleConfirmDelete = () => {
    setConfirmDeleteOpen(false)
    deleteAnswer(questionId, answerId)
  }

  const handleCancel = () => {
    setConfirmDeleteOpen(false)
  }

  return (
    <>
      <Button
        size="small"
        onClick={handleDelete}
        mode="outlined"
        loading={deleteAnswerLoading}
      >
        <TrashIcon className="w-4 text-gray-600" />
      </Button>

      <Dialog
        title={t('adminManageGuide.guide.question.edit.answers.delete.title')}
        isOpen={confirmDeleteOpen}
        onClose={handleCancel}
        onConfirm={handleConfirmDelete}
        cancelText={t('content:cancel')}
        confirmText={t('content:delete')}
      >
        {t('adminManageGuide.guide.question.edit.answers.delete.text')}
      </Dialog>
    </>
  )
}

DeleteAnswerButton.propTypes = {
  questionId: string,
  answerId: string
}

DeleteAnswerButton.defaultProps = {}

export default DeleteAnswerButton
