import React from 'react'
import { array, func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Checkbox from '../../../shared/checkbox'

const CountriesCheckboxes = ({
  assetId,
  assetType,
  locales,
  systemSiteData,
  onChange
}) => {
  const { t } = useTranslation()

  const handleChange = (locale, boolValue) => {
    onChange('SITE_SELECT', assetType, { assetId, locale, boolValue })
  }

  return (
    <div className="flex flex-col">
      {systemSiteData.map((siteData) => {
        return (
          <Checkbox
            key={siteData.locale}
            id={siteData.locale}
            label={t(`shared:localeToCountry.${siteData.locale}`)}
            onChange={handleChange}
            rawChangeEvent={false}
            checked={locales.includes(siteData.locale)}
          />
        )
      })}
    </div>
  )
}

CountriesCheckboxes.propTypes = {
  assetId: string.isRequired,
  assetType: string.isRequired,
  systemSiteData: array.isRequired,
  locales: array.isRequired,
  onChange: func.isRequired
}

CountriesCheckboxes.defaultProps = {
  systemSiteData: []
}

export default CountriesCheckboxes
