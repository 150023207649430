import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../hooks/useOverlayNotification'

const DELETE_SITE_DATA = gql`
  mutation DeleteSiteData($siteDataId: ID!) {
    appDeleteSiteData(siteDataId: $siteDataId) {
      id
      systemSiteData {
        id
      }
    }
  }
`

export default function useDeleteSiteData() {
  const { showErrorNotification, showSuccessNotification } = useOverlayNotification()

  const [deleteSiteData, { loading: deleteSiteDataLoading }] = useMutation(
    DELETE_SITE_DATA,
    {
      onError: showErrorNotification
    }
  )

  const deleteSiteDataCallback = useCallback(
    (siteDataId, onCompleted) => {
      return deleteSiteData({
        variables: {
          siteDataId
        },
        skip: !siteDataId,
        onCompleted: (data) => {
          showSuccessNotification()
          onCompleted && onCompleted(data)
        }
      })
    },
    [deleteSiteData, showSuccessNotification]
  )

  return {
    deleteSiteData: deleteSiteDataCallback,
    deleteSiteDataLoading
  }
}
