import UserTableItem from './usertableitem'
import React from 'react'

const UserTable = ({ users, isAdmin, userFormOptions, onCompleteSuccess }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {users.map((user) => {
          return (
            <UserTableItem
              user={user}
              isAdmin={isAdmin}
              key={`UserTableItem-${user.id}`}
              userFormOptions={userFormOptions}
              onCompleteSuccess={onCompleteSuccess}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default UserTable
