import React, { useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import { useTranslation } from 'react-i18next'

import Loader from '../components/loader'
import Label from '../components/shared/label'
import Subsection from '../components/shared/subsection'
import { Select } from '../components/shared/select'
import Button from '../components/shared/button'

import { useFormFields } from '../hooks'
import useAdminQuery from '../hooks/useAdminQuery'
import useOverlayNotification from '../hooks/useOverlayNotification'
import Webhooks from '../components/webhooks/webhooks'

const GET_VENDOR_QUERY = gql`
  query Vendor($companyId: ID, $userId: ID) {
    appVendor(companyId: $companyId, userId: $userId) {
      id
      company
      leadRemindersAmount
      leadRemindersInterval
    }
  }
`

const UPDATE_VENDOR_MUTATION = gql`
  mutation UpdateVendorSettings($companyId: ID!, $data: IntegrationSettingsInput!) {
    appUpdateIntegrationSettings(companyId: $companyId, data: $data) {
      id
      leadRemindersAmount
      leadRemindersInterval
    }
  }
`

const FIELD_AMOUNT = 'leadRemindersAmount'
const FIELD_INTERVAL = 'leadRemindersInterval'

function IntegrationSettingsPage() {
  const { t } = useTranslation(['vendorSettings'])
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()
  const [fieldsData, handleChange, setInitForm, setFieldsDataRaw, changedFields] =
    useFormFields({})

  const { data, loading: vendorLoading } = useAdminQuery(
    GET_VENDOR_QUERY,
    {
      onCompleted: ({ appVendor }) => {
        setInitForm(appVendor)
      }
    },
    'cache-and-network'
  )

  const [updateAppSettings, { loading: mutationLoading }] = useMutation(
    UPDATE_VENDOR_MUTATION,
    {
      onCompleted: showSuccessNotification,
      onError: showErrorNotification
    }
  )

  const amountOptionsMemo = useMemo(() => {
    const options = [
      {
        labelTid: 'vendorSettings:leadReminders.option.inactive',
        value: 0
      }
    ]

    for (let i = 1; i <= 10; i++) {
      options.push({ label: i, value: i })
    }

    return options
  }, [])

  const intervalOptionsMemo = useMemo(() => {
    const options = []

    for (let i = 1; i <= 31; i++) {
      options.push({ label: i, value: i })
    }

    return options
  }, [])

  const handleSubmit = () => {
    updateAppSettings({
      variables: {
        companyId: data?.appVendor?.id,
        data: changedFields
      }
    })
  }

  const renderPageBody = () => {
    if (vendorLoading) {
      return <Loader />
    }

    if (!data?.appVendor) {
      return t('content:warning.noData')
    }

    return (
      <>
        <div className="grid grid-cols-1 md:gap-2 md:grid-cols-1">
          <Subsection title={t('leadReminders.sectionTitle')}>
            <Label title={t('leadReminders.amount.title')}>
              <Select
                id={FIELD_AMOUNT}
                onChange={handleChange}
                options={amountOptionsMemo}
                value={fieldsData[FIELD_AMOUNT]}
                isSearchable={false}
              />
            </Label>
            <Label title={t('leadReminders.interval.title')}>
              <Select
                id={FIELD_INTERVAL}
                onChange={handleChange}
                options={intervalOptionsMemo}
                value={fieldsData[FIELD_INTERVAL]}
                isSearchable={false}
                isClearable={true}
              />
            </Label>
          </Subsection>
          <div className="flex justify-end">
            <Button onClick={handleSubmit} disabled={mutationLoading}>
              {t('content:save')}{' '}
            </Button>
          </div>

          <Webhooks vendorId={data?.appVendor?.id}></Webhooks>
        </div>
      </>
    )
  }
  return (
    <>
      <PageHeader pageName={t('integrationSettings.header.title')} />

      <PageBody>{renderPageBody()}</PageBody>
    </>
  )
}

export default IntegrationSettingsPage
