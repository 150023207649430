import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import useGetSystemName from '../hooks/useGetSystemName'
const Outbounds = ({ systemOutbound }) => {
  const { getSystemName } = useGetSystemName()
  const { t } = useTranslation('yourSystemProfile')

  return (
    <>
      {systemOutbound.outbounds.map((outbounds) => {
        return (
          <>
            <tr>
              <td className="flex flex-1 gap-2 whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                {getSystemName(systemOutbound.systemV2)}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {outbounds.period}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-black-500">
                {outbounds.periods.map((period) => {
                  return (
                    <>
                      {outbounds.period == 'month'
                        ? new Date(parseInt(period.startDate))
                            .toLocaleString('sv')
                            .substr(0, 7) +
                          ' (' +
                          t('content:week') +
                          ' ' +
                          new Date(parseInt(period.startDate)).getWeek() +
                          ')'
                        : new Date(parseInt(period.startDate))
                            .toLocaleString('sv')
                            .substr(0, 10)}{' '}
                      - {period.whereOnSite}: {period.count}
                      <br />
                    </>
                  )
                })}
              </td>
            </tr>
          </>
        )
      })}
    </>
  )
}

Date.prototype.getWeek = function () {
  var date = new Date(this.getTime())
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
    )
  )
}

export default Outbounds
