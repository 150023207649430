import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const DELETE_ANSWER_GQL = gql`
  mutation AdminDeleteAnswer($questionId: ID!, $answerId: ID!) {
    deleteAnswer(questionId: $questionId, answerId: $answerId) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useDeleteAnswer() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [deleteAnswer, { loading }] = useMutation(DELETE_ANSWER_GQL)

  const deleteAnswerCallback = useCallback(
    (questionId, answerId, onCompleted) => {
      return deleteAnswer({
        variables: {
          questionId,
          answerId
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      })
    },
    [deleteAnswer, showSuccessNotification, showErrorNotification]
  )

  return {
    deleteAnswer: deleteAnswerCallback,
    deleteAnswerLoading: loading
  }
}
