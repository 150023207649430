import React, { useMemo } from 'react'
import { gql } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import useRemoteGqlFilter from '../hooks/useRemoteGqlFilter'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
//import AdminGuidesExport from '../components/admin/admin-all-guides/guides-export'
import AdminAllReviewsFilter from '../components/admin/admin-all-reviews/filter'
import ReviewsList from '../components/admin/admin-all-reviews/list'
import LoadingSpinner from '../components/shared/loading-spinner'
import Button from '../components/shared/button'

const QUERY = gql`
  query AppAdminAllReviews(
    $system: [String]
    $category: [String]
    $site: [String]
    $locale: String
    $status: String
    $fromDate: String
    $toDate: String
    $offset: Int
    $fetchCsvData: Boolean
  ) {
    appAdminAllReviews(
      system: $system
      category: $category
      site: $site
      locale: $locale
      status: $status
      fromDate: $fromDate
      toDate: $toDate
      offset: $offset
      fetchCsvData: $fetchCsvData
    ) {
      totalCount
      hasMore
      csvData
      data {
        id
        createdAt
        email
        reviewData {
          id
          category
          approved
          reviewRole
          reviewCompanySize
          reviewTitle
          reviewLikeMost
          reviewLikeLeast
          reviewBusinessCritical
          reviewMetExpectations
          reviewUsablility
          reviewSupport
          reviewPriceRelation
          reviewFunctions
          reviewCollaborationSupplier
          reviewIntegrations
          reviewClientCustomizing
          lang
          emailApproved
          ourComment
          locale
          systemV2 {
            id
            categories
            systemSiteData {
              systemName
              customerType
              locale
            }
          }
        }
      }
    }
  }
`

function AdminAllReviewsPage() {
  const { t, i18n } = useTranslation('adminPages')

  const gqlVariablesMemo = useMemo(() => {
    return {
      locale: i18n.language
    }
  }, [i18n.language])

  const {
    data: reviewsData,
    error: reviewsError,
    fetchMore: reviewsFetchMore,
    fetchByLazyQuery: reviewsFetchByLazyQuery,
    fetchMoreLoading,
    handleChangeFilters,
    filterChangeFetchLoading,
    initialFetchLoading
  } = useRemoteGqlFilter(QUERY, gqlVariablesMemo, { status: 'NEW' })

  const reviews = reviewsData?.appAdminAllReviews?.data || []
  const totalReviewsCount = reviewsData?.appAdminAllReviews?.totalCount || 0
  const hasMoreReviews = reviewsData?.appAdminAllReviews?.hasMore || false

  return (
    <>
      <PageHeader pageName={t('adminAllReviews.header.title')} />

      <PageBody>
        <div className="flex flex-1 justify-between mb-5 gap-2">
          <div className="text-base text-gray-600">
            Resultat nuvarande filter:{' '}
            {filterChangeFetchLoading ? (
              <LoadingSpinner className="text-gray-900" />
            ) : (
              <span className="font-semibold">{totalReviewsCount}</span>
            )}
          </div>

          {/*<AdminGuidesExport onFetchByLazyQuery={reviewsFetchByLazyQuery} />*/}
        </div>

        <AdminAllReviewsFilter onChangeFilters={handleChangeFilters} />
      </PageBody>

      <PageBody loading={initialFetchLoading || filterChangeFetchLoading}>
        {reviewsError?.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}

        <ReviewsList reviews={reviews} />

        <div className="mt-8">
          <Button
            loading={fetchMoreLoading}
            disabled={!hasMoreReviews}
            onClick={() => {
              reviewsFetchMore({
                offset: reviews.length
              })
            }}
          >
            {t('shared.button.fetchMore', {
              value: 50
            })}
          </Button>
        </div>
      </PageBody>
    </>
  )
}

export default AdminAllReviewsPage
