import React, { memo, useMemo } from 'react'
import { ChevronDownIcon } from '@heroicons/react/outline'

import { useParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import useFetchCategories from '../../../hooks/market-researcher/useFetchCategories'
import useGetCategoryName from '../../../hooks/market-researcher/useGetCategoryName'
import useFetchAvailableSites from '../../../hooks/useFetchAvailableSites'

import { Menu } from '@headlessui/react'
import Flag from '../../shared/flag'
import MenuTransition from '../../shared/dropdown/menu-transition'
import MenuItem from '../../shared/dropdown/menu-item'
import Loader from '../../loader'
import { func } from 'prop-types'

const CategoryDropdown = ({
  mrCategories,
  selectedCategory,
  compareLocale,
  onChange
}) => {
  const { locale } = useParams()
  const { getCategoryName } = useGetCategoryName()

  return (
    <Menu as="div" className="relative mx-2 z-10">
      <div className="flex flex-col items-center sm:flex-row">
        <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 px-2">
          <span className="sr-only">Open menu</span>
          <span className="text-sm text-gray-700 px-1 sm:text-base">
            {getCategoryName(selectedCategory, compareLocale || locale)}
          </span>
          <ChevronDownIcon
            className="h-4 w-4 rounded-full text-gray-400 hover:text-gray-500"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <MenuTransition>
        <Menu.Items className="absolute right-0 mt-2 w-96 h-96 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll">
          {mrCategories.map((mrCategory) => {
            if (mrCategory.dbName === selectedCategory) {
              return null
            }

            return (
              <MenuItem
                key={mrCategory.dbName}
                className="flex"
                onClick={onChange.bind(null, mrCategory.dbName)}
              >
                <a className="flex flex-col">
                  <span className="text-gray-700 truncate ...">
                    {getCategoryName(mrCategory.dbName, locale)}
                  </span>
                  <span className="text-gray-500 truncate ...">
                    {getCategoryName(mrCategory.dbName, 'en_GB')}
                  </span>
                </a>
              </MenuItem>
            )
          })}
        </Menu.Items>
      </MenuTransition>
    </Menu>
  )
}

const SiteSelect = ({ sites, compareLocale, onChange }) => {
  const { t } = useTranslation()

  return (
    <Menu as="div" className="relative mx-2 z-10">
      <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 px-2">
        {compareLocale === 'ALL' ? (
          <span className="text-sm text-gray-700 px-1 sm:text-base">
            {t('marketResearcher:systems.allCountries')}
          </span>
        ) : (
          <>
            <Flag locale={compareLocale} height="16px" />
            <span className="sr-only">Open menu</span>
            <span className="text-sm text-gray-700 px-1 sm:text-base">
              {t(`shared:localeToCountry.${compareLocale}`)}
            </span>
          </>
        )}

        <ChevronDownIcon
          className="h-4 w-4 rounded-full text-gray-400 hover:text-gray-500"
          aria-hidden="true"
        />
      </Menu.Button>

      <MenuTransition>
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {sites.map((site) => {
            const { defaultLocale: locale } = site

            if (locale === compareLocale) {
              return null
            }

            if (locale === 'ALL') {
            }

            return (
              <MenuItem
                key={locale}
                className="flex gap-2"
                onClick={onChange.bind(null, locale)}
              >
                {locale === 'ALL' ? (
                  <a>
                    <span className="text-gray-700">
                      {t(`marketResearcher:systems.allCountries`)}
                    </span>
                  </a>
                ) : (
                  <a>
                    <Flag locale={locale} height="16px" />
                    <span className="text-gray-700">
                      {t(`shared:localeToCountry.${locale}`)}
                    </span>
                  </a>
                )}
              </MenuItem>
            )
          })}
        </Menu.Items>
      </MenuTransition>
    </Menu>
  )
}

const SystemsOtherSitesVariableSelector = ({ onChange, variables }) => {
  const { category } = useParams()
  const { mrCategories, mrCategoriesLoading } = useFetchCategories()
  const { sites, sitesLoading } = useFetchAvailableSites()

  const handleChangeCategory = (compareCategory) => {
    onChange({ ...variables, compareCategory })
  }

  const handleChangeLocale = (compareLocale) => {
    if (compareLocale === 'ALL') {
      compareLocale = null
    }

    onChange({ ...variables, compareLocale })
  }

  const allSitesMemo = useMemo(() => {
    return [{ defaultLocale: 'ALL' }, ...sites]
  }, [sites])

  if (mrCategoriesLoading || sitesLoading) {
    return (
      <div className="flex justify-center">
        <Loader />
      </div>
    )
  }

  return (
    <div className="flex items-center mb-2 justify-center">
      <Trans
        i18nKey="marketResearcher:systems.systemsOnOtherSites"
        components={[
          <CategoryDropdown
            key={1}
            mrCategories={mrCategories}
            selectedCategory={variables?.compareCategory || category}
            onChange={handleChangeCategory}
            compareLocale={variables?.compareLocale}
          />,
          <SiteSelect
            key={2}
            sites={allSitesMemo}
            compareLocale={variables?.compareLocale || 'ALL'}
            onChange={handleChangeLocale}
          />
        ]}
      />
    </div>
  )
}

SystemsOtherSitesVariableSelector.propTypes = {
  onChange: func.isRequired
}

SystemsOtherSitesVariableSelector.defaultProps = {}

export default memo(SystemsOtherSitesVariableSelector)
