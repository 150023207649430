import React from 'react'
import { bool, func, object, oneOfType } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Flag from '../../shared/flag'
import SitesSelect from '../../shared/sites-select'
import TextArea from '../../shared/textarea'
import ReviewStars from '../../shared/review-stars'

const ReviewItemForm = ({ review, onChange, isEditing, formData }) => {
  const { t } = useTranslation('reviews')

  return (
    <div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1">
          {review.reviewTitle && !isEditing && (
            <h4 className="mb-3 text-sm font-medium text-gray-500 italic">
              &quot;{review.reviewTitle}&quot;{' '}
              {review.reviewRole && <>- {review.reviewRole}</>}
            </h4>
          )}

          {isEditing && (
            <div className="mt-1 space-y-6 text-sm text-gray-500">
              <div className="flex flex-row items-center">
                <Flag className="h-12 w-12 mr-3" locale={formData?.locale || 'AQ'} />
                <SitesSelect
                  label="Site"
                  fieldName="locale"
                  value={formData?.locale}
                  // skipLocales={[review.locale]}
                  onChange={onChange}
                  disableAutoInit
                />
              </div>
            </div>
          )}

          {isEditing && (
            <div className="space-y-3">
              <TextArea
                helperText="Titel"
                fieldName="reviewTitle"
                onChange={onChange}
                value={formData?.reviewTitle}
                rows={2}
              />

              <TextArea
                helperText={t('content:role')}
                fieldName="reviewRole"
                onChange={onChange}
                value={formData?.reviewRole}
                rows={1}
              />
            </div>
          )}

          <h4 className="mt-3 text-sm font-medium text-gray-500">
            {t('question.reviewLikeMost')}
          </h4>
          <div className="mt-1 space-y-6 text-sm text-gray-500">
            {isEditing ? (
              <TextArea
                fieldName="reviewLikeMost"
                onChange={onChange}
                value={formData?.reviewLikeMost}
                rows={5}
              />
            ) : (
              review.reviewLikeMost || '-'
            )}
          </div>

          <h4 className="mt-3 text-sm font-medium text-gray-500">
            {t('question.reviewLikeLeast')}
          </h4>
          <div className="mt-1 space-y-6 text-sm text-gray-500">
            {isEditing ? (
              <TextArea
                fieldName="reviewLikeLeast"
                onChange={onChange}
                value={formData?.reviewLikeLeast}
                rows={5}
              />
            ) : (
              review.reviewLikeLeast || '-'
            )}
          </div>

          <h4 className="mt-3 text-sm font-medium text-gray-500">
            {t('question.reviewBusinessCritical')}
          </h4>
          <div className="mt-1 space-y-6 text-sm text-gray-500">
            {isEditing ? (
              <TextArea
                fieldName="reviewBusinessCritical"
                onChange={onChange}
                value={formData?.reviewBusinessCritical}
                rows={5}
              />
            ) : (
              review.reviewBusinessCritical || '-'
            )}
          </div>
        </div>

        <div className="center flex border-t mt-4 pt-4 justify-center items-center lg:ml-4 lg:pl-4 lg:pt-0 lg:w-max lg:mt-0 lg:border-l lg:border-t-0">
          <ReviewStars review={review} />
        </div>
      </div>
    </div>
  )
}

ReviewItemForm.propTypes = {
  review: object.isRequired,
  onChange: func,
  isEditing: bool,
  formData: oneOfType([object])
}

ReviewItemForm.defaultProps = {}

export default ReviewItemForm
