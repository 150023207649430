import React, { useMemo, useState } from 'react'
import { bool } from 'prop-types'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import useSearchSystem from '../../../hooks/market-researcher/useSearchSystem'
import useCreateSystem from '../../../hooks/market-researcher/useCreateSystem'

import Button from '../../shared/button'
import Dialog from '../../shared/dialog'

import AddSystemInput from './add-system-input'

const CreateSystemDialog = ({ loading }) => {
  const { t } = useTranslation()
  const { mrSearchSystems, mrSearchSystemsLoading, mrSystemsOptions } = useSearchSystem()
  const { mrCreateSystem, loading: mrCreateSystemLoading } = useCreateSystem()

  const [open, setOpen] = useState(false)
  const [selectedSystemOption, setSelectedSystemOption] = useState(null)
  const [systemName, newSystemName] = useState('')

  const handleChangeSystem = (system) => {
    newSystemName(null)
    setSelectedSystemOption(system)
  }

  const handleChangeInput = (input) => {
    newSystemName(input)
    mrSearchSystems(input)

    if (selectedSystemOption) {
      setSelectedSystemOption(null)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedSystemOption(null)
    newSystemName('')
  }

  const handleSubmit = () => {
    const systemId = selectedSystemOption?.value || null
    const locale = selectedSystemOption?.locales?.[0] || null

    mrCreateSystem(systemName, systemId, locale)
  }

  const systemNameExistsInList = useMemo(() => {
    if (!mrSystemsOptions || !systemName || mrSearchSystemsLoading) {
      return false
    }

    return mrSystemsOptions.some((systemOption) => {
      return systemOption.systemName.toLowerCase() === systemName.trim().toLowerCase()
    })
  }, [mrSystemsOptions, systemName, mrSearchSystemsLoading])

  const confirmBtnDisabled = useMemo(() => {
    const noSystemName = !systemName?.trim()

    if (!selectedSystemOption && noSystemName) {
      return true
    }

    if (systemNameExistsInList) {
      return true
    }

    return false
  }, [selectedSystemOption, systemName, systemNameExistsInList])

  const renderCustomFooter = () => {
    const disableControls = mrSearchSystemsLoading || mrCreateSystemLoading

    return (
      <div className="flex justify-end mt-5 gap-3 sm:mt-6">
        <Button mode="default" onClick={handleClose} disabled={disableControls}>
          {t('content:cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          loading={disableControls}
          disabled={confirmBtnDisabled}
        >
          {selectedSystemOption
            ? t('marketResearcher:systems.createDialog.btnLinkSystem')
            : t('marketResearcher:systems.createDialog.btnCreateSystem')}
        </Button>
      </div>
    )
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} loading={loading}>
        {t('marketResearcher:systems.btnCreateSystem')}
      </Button>

      <Dialog
        isOpen={open}
        title={t('marketResearcher:systems.createDialog.title')}
        renderFooter={renderCustomFooter}
      >
        <div>
          <AddSystemInput
            mrSystemsOptions={mrSystemsOptions}
            onChange={handleChangeSystem}
            onInputChange={handleChangeInput}
            value={selectedSystemOption?.value || null}
            listLoading={mrSearchSystemsLoading}
          />
        </div>

        <div className="flex flex-col gap-2">
          <span>{t('marketResearcher:systems.createDialog.helperText')}</span>

          <span
            className={cx(`font-medium text-rose-500 text-center`, {
              visible: systemNameExistsInList,
              invisible: !systemNameExistsInList
            })}
          >
            {t('marketResearcher:systems.createDialog.systemExistsError')}
          </span>
        </div>
      </Dialog>
    </>
  )
}

CreateSystemDialog.propTypes = {
  loading: bool
}

CreateSystemDialog.defaultProps = {}

export default CreateSystemDialog
