import React from 'react'
import classnames from 'classnames'

const ListItemBase = ({ className, active, onClick, children, value }) => {
  const handleSelect = () => {
    onClick(value)
  }

  return (
    <div
      className={classnames('w-full border-gray-100 rounded-t border-b', className, {
        'bg-blue-100': active,
        'cursor-pointer hover:bg-blue-100': !!onClick
      })}
      onClick={handleSelect}
    >
      <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative">
        <div className="w-full mx-2 leading-6 text-sm">{children}</div>
      </div>
    </div>
  )
}

ListItemBase.propTypes = {}

ListItemBase.defaultProps = {}

export default ListItemBase
