import React, { useMemo, useState } from 'react'
import { useQuery, useLazyQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import Label from '../components/shared/label'
import { Select, MultiSelect } from '../components/shared/select'
import SitesSelect from '../components/shared/sites-select'
import LoadingSpinner from '../components/shared/loading-spinner'
import AnswersOptions from '../components/admin/guide-simulator/answers-options'
import Button from '../components/shared/button'
import ResultList from '../components/admin/guide-simulator/result-list'
import ToggleSwitch from '../components/shared/toggle-switch'

import useFetchGuides from '../hooks/manage-guides/useFetchGuides'
import useGetCategoryName from '../hooks/useGetCategoryName'
import useOverlayNotification from '../hooks/useOverlayNotification'

import { GUIDE_FRAGMENT } from '../constants/gql/guide'

const GET_GUIDE_SIMULATOR_SYSTEMS = gql`
  query AdminGuideSimulation(
    $category: String
    $locale: String
    $companySizes: [String]
    $answers: [GuideInstanceDoneAnswers]
    $isSimulation: Boolean
  ) {
    guideSimulation(
      category: $category
      locale: $locale
      companySizes: $companySizes
      answers: $answers
      isSimulation: $isSimulation
    )
  }
`

export const GQL_FETCH_GUIDE = gql`
  query FetchGuide($category: String) {
    guide(category: $category) {
      ${GUIDE_FRAGMENT}
    }
  }
`

const COMPANY_SIZES_OPTIONS = [
  { value: 'MICRO', label: '1-10 (XS)' },
  { value: 'SMALL', label: '11-100 (S)' },
  { value: 'MEDIUM', label: '101-500 (M)' },
  { value: 'LARGE', label: '500+ (L)' }
]

const COMPANY_SIZES_INIT = COMPANY_SIZES_OPTIONS.map((option) => option.value)

function AdminSimulatorGuidePage() {
  const { t } = useTranslation(['adminPages', 'yourSystemProfile'])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedLocale, setSelectedLocale] = useState(null)
  const [selectedCompanySizes, setSelectedCompanySizes] = useState(COMPANY_SIZES_INIT)
  const [isSimulation, setIsSimulation] = useState(true)
  const [guideAnswers, setGuideAnswers] = useState([])
  const [guideSimulationResult, setGuideSimulationResult] = useState(null)

  const { getCategoryName, allCategoriesLoading } = useGetCategoryName()
  const { guides } = useFetchGuides('cache-and-network')
  const { showErrorNotification } = useOverlayNotification()

  const { data: guideData, loading: guideDataLoading } = useQuery(GQL_FETCH_GUIDE, {
    variables: {
      category: selectedCategory
    },
    fetchPolicy: 'no-cache',
    skip: !selectedCategory
  })

  const [simulateGuideQuery, { loading: systemsLoading }] = useLazyQuery(
    GET_GUIDE_SIMULATOR_SYSTEMS,
    {
      fetchPolicy: 'no-cache',
      skip: !selectedLocale || !selectedCategory,
      onCompleted: ({ guideSimulation }) => {
        setGuideSimulationResult(guideSimulation)
      },
      onError: () => {
        setGuideSimulationResult(null)
        showErrorNotification()
      }
    }
  )

  const isLoading = guideDataLoading || systemsLoading

  const handleSimulateGuide = () => {
    simulateGuideQuery({
      variables: {
        category: selectedCategory,
        locale: selectedLocale,
        answers: guideAnswers,
        companySizes: selectedCompanySizes,
        isSimulation
      }
    })
  }

  const handleSelectCategory = (category) => {
    setGuideAnswers([])
    setSelectedCategory(category)
    setSelectedLocale(null)
    setGuideSimulationResult(null)
  }

  const handleChangeCompanySizes = (values) => {
    if (Array.isArray(values)) {
      setSelectedCompanySizes(values)
    } else {
      setSelectedCompanySizes([values])
    }
  }

  const handleToggleGuideSimulator = (updatedIsSimulation) => {
    setIsSimulation(updatedIsSimulation)

    // Is simulator, select all company sizes
    if (updatedIsSimulation) {
      setSelectedCompanySizes(COMPANY_SIZES_INIT)
    } else {
      setSelectedCompanySizes(['MICRO'])
    }
  }

  const categoryOptionsMemo = useMemo(() => {
    const allCategories = guides
      .map((guide) => {
        return guide.categories
      })
      .flat()

    return [...new Set(allCategories)].map((category) => {
      return {
        label: getCategoryName(category),
        value: category
      }
    })
  }, [guides, getCategoryName])

  const sitesOptionsMemo = useMemo(() => {
    if (!guideData || guideDataLoading) {
      return []
    }

    return guideData.guide?.locales || []
  }, [guideData, guideDataLoading])

  const handleChangeFilter = (activeFilters) => {
    const updatedGuideAnswers = activeFilters.map((activeFilter) => {
      return {
        questionId: activeFilter.filter,
        answerId: activeFilter.rawOption.value,
        specialName: activeFilter.rawOption.specialName || null
      }
    })

    setGuideAnswers(updatedGuideAnswers)
  }
  return (
    <>
      <PageHeader pageName={t('adminSimulatorGuide.header.title')} />
      <PageBody>
        <Label helperText={t('adminSimulatorGuide.controls.toggleSimulateText')}>
          <ToggleSwitch
            text={t('adminSimulatorGuide.controls.toggleSimulateLabel')}
            onChange={handleToggleGuideSimulator}
            checked={isSimulation}
          />
        </Label>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-end mb-8">
          <Label title={t('content:category')} marginless>
            <Select
              options={categoryOptionsMemo}
              onChange={handleSelectCategory}
              value={selectedCategory}
              rawChangeEvent={false}
              loading={!categoryOptionsMemo.length || allCategoriesLoading}
            />
          </Label>

          <Label title={t('content:companySize')} marginless>
            {isSimulation ? (
              <MultiSelect
                options={COMPANY_SIZES_OPTIONS}
                onChange={handleChangeCompanySizes}
                value={selectedCompanySizes}
                rawChangeEvent={false}
              />
            ) : (
              <Select
                options={COMPANY_SIZES_OPTIONS}
                onChange={handleChangeCompanySizes}
                value={selectedCompanySizes?.[0]}
                rawChangeEvent={false}
              />
            )}
          </Label>

          <SitesSelect
            label={t('content:sites')}
            alternativeLocales={sitesOptionsMemo}
            onChange={setSelectedLocale}
            value={selectedLocale}
            loading={guideDataLoading}
            disabled={!guideData}
            marginless
          />

          <Button
            className="mt-1"
            onClick={handleSimulateGuide}
            disabled={!selectedCategory || !selectedLocale}
            loading={isLoading}
          >
            {t('adminSimulatorGuide.controls.simulate')}
          </Button>
        </div>

        {!guideDataLoading && selectedCategory && (
          <AnswersOptions
            guide={guideData?.guide}
            locale={selectedLocale}
            onChange={handleChangeFilter}
            isSimulation={isSimulation}
          />
        )}

        {isLoading ? (
          <div
            className={cx('w-full flex justify-center', {
              'mt-8': guideDataLoading
            })}
          >
            <LoadingSpinner className="h-8 w-8 text-black" />
          </div>
        ) : (
          <>
            {guideSimulationResult && (
              <ResultList guideSimulationResult={guideSimulationResult} />
            )}
          </>
        )}
      </PageBody>
    </>
  )
}

export default AdminSimulatorGuidePage
