import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../contexts'

import Header from '../../components/market-researcher/header'
import { PageBody } from '../../components/page-body'
import { DesktopComputerIcon, TagIcon } from '@heroicons/react/outline'

import Tabs from '../../components/shared/tabs'
import SystemsList from '../../components/market-researcher/systems/systems-list'
import PropsList from '../../components/market-researcher/product-functions/props-list'

import { isAdmin } from '../../utils'

/**
 * Possible options:
 * - showFilterField
 * - showSectionField
 * - showImpactField
 * - requireAdmin
 */
const SYSTEM_PROPS_INSTR = {
  PRODUCT_FUNCTIONALITIES: {
    showFilterField: true
  },
  SYSTEM_CHARACTERISTICS: {
    requireAdmin: true,
    showSectionField: true,
    showImpactField: true
  },
  AREAS_OF_USE: {
    requireAdmin: true
  }
}

const MarketResearcherSystemsPage = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('SYSTEMS')
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const allTabsMemo = useMemo(() => {
    const allTabs = [
      {
        label: t('marketResearcher:systems.tabs.systems'),
        value: 'SYSTEMS',
        icon: DesktopComputerIcon
      }
    ]

    Object.entries(SYSTEM_PROPS_INSTR).map(([propType, instr]) => {
      if (instr.requireAdmin && !hasAdmin) {
        return
      }

      allTabs.push({
        label: t(`content:entityPropType.${propType}`),
        value: propType,
        icon: TagIcon
      })
    })

    return allTabs
  }, [t, hasAdmin])

  const handleTabChange = ({ value }) => {
    setActiveTab(value)
  }

  return (
    <>
      <Header />

      <PageBody>
        <div className="grid gap-3 ">
          <Tabs tabs={allTabsMemo} activeTab={activeTab} onChange={handleTabChange} />

          {activeTab === 'SYSTEMS' && <SystemsList />}

          {Object.entries(SYSTEM_PROPS_INSTR).map(([propType, data]) => {
            if (propType === activeTab) {
              return <PropsList key={propType} {...data} propType={propType} />
            }
          })}
        </div>
      </PageBody>
    </>
  )
}

MarketResearcherSystemsPage.propTypes = {}

MarketResearcherSystemsPage.defaultProps = {}

export default MarketResearcherSystemsPage
