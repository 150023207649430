import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import { array, bool, number, object, oneOf, oneOfType, string } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useOnClickOutside } from '../../../../hooks/useOnClickOutside'

const SelectContext = React.createContext({})

export const SelectContextProvider = ({
  children,
  options,
  value,
  type,
  providedInputRef,
  onInputChange,
  onDropdownOpen,
  freeInput,
  closeDropdownOnSelect
}) => {
  const [searchQuery, setSearchQuery] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [focusedItemId, setFocusedItemId] = useState(null)

  const wrapperRef = useRef()
  const inputRef = useRef()
  const inputRefToUse = providedInputRef || inputRef

  const { t } = useTranslation()

  const isSingleSelect = type === 'SINGLE'
  const isMultiSelect = type === 'MULTI'

  const handleSetSearchQuery = useCallback(
    (newState) => {
      if (typeof onInputChange === 'function') {
        onInputChange(newState)
      }

      setSearchQuery(newState)
    },
    [onInputChange]
  )

  useOnClickOutside(wrapperRef, () => {
    if (!freeInput) {
      handleSetSearchQuery(null)
    }

    setDropdownOpen(false)
    setFocusedItemId(null)
  })

  const visibleOptionsMemo = useMemo(() => {
    let newOptions = options

    if (!Array.isArray(value)) {
      newOptions = options.filter((option) => option.value !== value)
    } else if (Array.isArray(value)) {
      newOptions = options.filter((option) => !value.includes(option.value))
    }

    if (!searchQuery) {
      return newOptions
    }

    const normalizedSearchQuery = searchQuery.toLowerCase().replace(/ /g, '')

    return newOptions.filter((option) => {
      let labelToUse = option.labelTid ? t(option.labelTid) : option.label

      if (typeof labelToUse !== 'string') {
        labelToUse = labelToUse.toString()
      }

      const normalizedOption = labelToUse.toLowerCase().replace(/ /g, '')

      return normalizedOption.includes(normalizedSearchQuery)
    })
  }, [searchQuery, options, value, t])

  const inputValueMemo = useMemo(() => {
    if (typeof searchQuery === 'string') {
      return searchQuery
    }

    const foundValue = options.find((option) => option.value === value)

    if (!foundValue) {
      return ''
    }

    if (foundValue.labelTid) {
      return t(foundValue.labelTid)
    }

    return foundValue.label
  }, [searchQuery, options, value, t])

  const onOpen = useCallback(() => {
    setDropdownOpen(true)

    if (typeof onDropdownOpen === 'function') {
      onDropdownOpen(true)
    }

    if (visibleOptionsMemo?.length > 0) {
      setFocusedItemId(visibleOptionsMemo[0].value)
    }

    inputRefToUse.current.focus()
  }, [visibleOptionsMemo, inputRefToUse, onDropdownOpen])

  const closeDropdown = useCallback(() => {
    setDropdownOpen(false)
    setFocusedItemId(null)
    inputRefToUse.current.blur()
  }, [inputRefToUse])

  const onSelect = useCallback(() => {
    setSearchQuery(null)

    if (isSingleSelect) {
      closeDropdown()
    }

    if (isMultiSelect && closeDropdownOnSelect) {
      closeDropdown()
    }
  }, [isSingleSelect, closeDropdown, isMultiSelect, closeDropdownOnSelect])

  // Select first item when dropdown opens.
  // Deselect focused item if there are no visible options
  useEffect(() => {
    if (!dropdownOpen) {
      return
    }

    if (visibleOptionsMemo?.length > 0) {
      setFocusedItemId(visibleOptionsMemo[0].value)
    } else {
      setFocusedItemId(null)
    }
  }, [dropdownOpen, visibleOptionsMemo])

  const contextValues = useMemo(() => {
    return {
      dropdownOpen,
      wrapperRef,
      inputRef: inputRefToUse,
      visibleOptions: visibleOptionsMemo,
      inputValue: inputValueMemo,
      searchQuery,
      setSearchQuery: handleSetSearchQuery,
      onOpen,
      onSelect,
      onFocus: onOpen,
      focusedItemId,
      setFocusedItemId
    }
  }, [
    dropdownOpen,
    visibleOptionsMemo,
    inputValueMemo,
    searchQuery,
    handleSetSearchQuery,
    onOpen,
    onSelect,
    focusedItemId,
    inputRefToUse
  ])

  return <SelectContext.Provider value={contextValues}>{children}</SelectContext.Provider>
}

SelectContextProvider.propTypes = {
  children: oneOfType([object, array]).isRequired,
  type: oneOf(['SINGLE', 'MULTI']).isRequired,
  options: array.isRequired,
  value: oneOfType([string, array, number, bool]),
  providedInputRef: object
}

SelectContextProvider.defaultProps = {
  options: []
}

export const useSelectContext = () => React.useContext(SelectContext)
