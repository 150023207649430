import React from 'react'
import classNames from 'classnames'
import { bool, func, number, string } from 'prop-types'

const TextArea = ({
  className,
  id,
  fieldName,
  helperText,
  errorText,
  value,
  disabled,
  onChange,
  placeholder,
  rawChangeEvent,
  resizable,
  rows
}) => {
  const fieldNameToUse = id || fieldName

  const handleChange = (event) => {
    if (rawChangeEvent) {
      if (fieldNameToUse) {
        onChange(fieldNameToUse, event)
      } else {
        onChange(event)
      }
    } else {
      if (fieldNameToUse) {
        onChange(fieldNameToUse, event.target.value)
      } else {
        onChange(event.target.value)
      }
    }
  }

  return (
    <div className={className}>
      {helperText && <p className="text-sm text-gray-500">{helperText}</p>}

      <textarea
        id={id}
        name={id}
        type="text"
        disabled={disabled}
        value={value || ''}
        onChange={handleChange}
        placeholder={placeholder}
        rows={rows}
        className={classNames(
          'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
          {
            'disabled:opacity-50': disabled,
            'border-rose-500 focus:border-rose-500 focus:ring-rose-500': !!errorText,
            'resize-none': !resizable
          }
        )}
      />

      {errorText && (
        <span className="text-sm font-medium text-rose-500">{errorText}</span>
      )}
    </div>
  )
}

TextArea.propTypes = {
  id: string,
  fieldName: string,
  helperText: string,
  value: string,
  disabled: bool,
  onChange: func,
  rawChangeEvent: bool,
  errorText: string,
  placeholder: string,
  rows: number
}

TextArea.defaultProps = {}

export default TextArea
