import React, { useRef } from 'react'
import { useMutation, gql } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import useOverlayNotification from '../hooks/useOverlayNotification'

import { PageBody } from '../components/page-body'
import { PageHeader } from '../components/page-header'
import VendorForm from '../components/admin/vendor-form'

const SAVE_VENDOR = gql`
  mutation AppAddVendor($data: Json) {
    appAddVendor(data: $data) {
      id
    }
  }
`

function AdminAddVendorPage() {
  const { t } = useTranslation('adminPages')
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()
  const formRef = useRef()

  const [addVendor, { loading: addVendorLoading }] = useMutation(SAVE_VENDOR, {
    onError(err) {
      showErrorNotification({ text: err.message })
    },
    onCompleted(data) {
      console.log('done mutation', data)
      showSuccessNotification()
      formRef?.current?.resetForm()
    }
  })

  const handleSubmit = (data) => {
    const { company } = data

    if (!company) {
      showErrorNotification({ text: 'Missing Company Name' })
      return
    }

    addVendor({
      variables: {
        data
      }
    })
  }

  return (
    <>
      <PageHeader pageName={t('adminAddVendor.header.title')}>
        <p>{t('adminAddVendor.header.subtitle')}</p>
      </PageHeader>

      <PageBody>
        <VendorForm onSubmit={handleSubmit} loading={addVendorLoading} ref={formRef} />
      </PageBody>
    </>
  )
}

export default AdminAddVendorPage
