import { useCallback, useEffect, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'

export default function useRemoteGqlFilter(QUERY, extraGqlVariables) {
  const [filterChangeFetchLoading, setFilterChangeFetchLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [gqlVariables, setGqlVariables] = useState(extraGqlVariables)

  const {
    data,
    loading: initialFetchLoading,
    error,
    fetchMore,
    refetch
  } = useQuery(QUERY, {
    variables: gqlVariables
  })

  const [fetchLazy] = useLazyQuery(QUERY)

  if (error) {
    console.error(error)
  }

  useEffect(() => {
    setGqlVariables((prev) => ({ ...prev, ...extraGqlVariables }))
  }, [extraGqlVariables])

  useEffect(() => {
    setFilterChangeFetchLoading(true)
  }, [gqlVariables])

  useEffect(() => {
    refetch(gqlVariables).finally(() => {
      setFilterChangeFetchLoading(false)
    })
  }, [gqlVariables, refetch])

  const handleChangeFilters = useCallback(
    (newFilters, newMappedFilters) => {
      if (!Object.keys(newMappedFilters).length) {
        setGqlVariables({})
      } else {
        setGqlVariables({ ...newMappedFilters, ...extraGqlVariables })
      }
    },
    [extraGqlVariables]
  )

  const fetchMoreCallback = useCallback(
    (otherVariables) => {
      setFetchMoreLoading(true)

      fetchMore({
        variables: {
          ...gqlVariables,
          ...otherVariables
        }
      }).finally(() => {
        setFetchMoreLoading(false)
      })
    },
    [fetchMore, gqlVariables]
  )

  const fetchByLazyQuery = useCallback(
    (otherGqlData = {}) => {
      const { variables: otherVariables, onCompleted, fetchPolicy } = otherGqlData

      return fetchLazy({
        fetchPolicy,
        variables: {
          ...gqlVariables,
          ...otherVariables
        },
        onCompleted
      })
    },
    [fetchLazy, gqlVariables]
  )

  return {
    data,
    error,
    fetchMore: fetchMoreCallback,
    fetchMoreLoading,
    fetchByLazyQuery,
    initialFetchLoading,
    handleChangeFilters,
    filterChangeFetchLoading
  }
}
