import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'

import { useNavigate } from 'react-router-dom'
import { useAdminFieldsContext } from '../../contexts'
import useAdminSelect from '../../hooks/useAdminBypassSelect'
import { useUserContext } from '../../contexts'
import useGetSystemName from '../../hooks/useGetSystemName'
import useGetCategoryName from '../../hooks/useGetCategoryName'

import Dialog from '../shared/dialog'
import { Select, ListItemBase } from '../shared/select'

import { isAdmin } from '../../utils'

const QUERY_ADMIN_RESOUCE_SEARCH = gql`
  query AdminResourceSearch($search: String!) {
    appAdminResourceSearch(search: $search) {
      systems {
        id
        defaultCategory
        systemSiteData {
          locale
          systemName
          companyName
        }
      }
      users {
        id
        email
      }
      vendors {
        id
        company
      }
    }
  }
`
const AdminSearchSelectDialog = () => {
  const [loading, setLoading] = useState(false)
  const { adminSearchVisible, setAdminSearchVisible } = useAdminFieldsContext()
  const { user } = useUserContext()
  const { getSystemName } = useGetSystemName()
  const { allCategoriesMap, getCategoryName } = useGetCategoryName()
  const { adminSelectVendor, adminSelectSystem, adminSelectUser, adminSelectClear } =
    useAdminSelect()

  const hasAdmin = isAdmin(user?.role)
  const navigate = useNavigate()
  const inputRef = useRef()
  const selectValue = user?.userId || user?.systemId || user?.companyId

  const [adminResourceSearch, { data }] = useLazyQuery(QUERY_ADMIN_RESOUCE_SEARCH)

  useEffect(() => {
    if (adminSearchVisible) {
      setTimeout(() => {
        inputRef?.current?.focus()
      }, 1)
    }
  }, [adminSearchVisible])

  const adminSearchDebounce = useDebouncedCallback((search) => {
    adminResourceSearch({
      fetchPolicy: 'network-only',
      variables: {
        search
      },
      onCompleted: () => {
        setLoading(false)
      },
      onError: () => {
        setLoading(false)
      }
    })
  }, 800)

  const optionsMemo = useMemo(() => {
    if (!data || !allCategoriesMap || loading) {
      return []
    }

    const allUsers = data?.appAdminResourceSearch?.users || []
    const allSystems = data?.appAdminResourceSearch?.systems || []
    const allVendors = data?.appAdminResourceSearch?.vendors || []

    const allUserOptions = allUsers.map((user) => {
      return {
        type: 'USER',
        label: user.email,
        value: user.id
      }
    })

    const allSystemsOptions = allSystems.map((system) => {
      return {
        type: 'SYSTEM',
        label: getSystemName(system),
        value: system.id,
        subtitle: getCategoryName(system.defaultCategory)
      }
    })

    const allVendorsOptions = allVendors.map((vendor) => {
      return {
        type: 'VENDOR',
        label: vendor.company,
        value: vendor.id
      }
    })

    return [...allVendorsOptions, ...allSystemsOptions, ...allUserOptions]
  }, [data, loading, getSystemName, allCategoriesMap, getCategoryName])

  const handleChange = useCallback(
    (value) => {
      // Clear field
      if (!value) {
        adminSelectClear()
        navigate('/')
        return
      }

      const valueObject = optionsMemo.find((option) => option.value === value)

      if (!valueObject) {
        return
      } else {
        setAdminSearchVisible(false)
      }

      if (valueObject.type === 'USER') {
        adminSelectUser(valueObject.value)
        navigate('/')
      }

      if (valueObject.type === 'SYSTEM') {
        const {
          value: systemId,
          label: systemName,
          subtitle: systemCategory
        } = valueObject

        adminSelectSystem(systemId, systemName, systemCategory)
        navigate(`/system/${valueObject.value}`)
      }

      if (valueObject.type === 'VENDOR') {
        adminSelectVendor(valueObject.value)
        navigate('/')
      }
    },
    [
      optionsMemo,
      setAdminSearchVisible,
      navigate,
      adminSelectClear,
      adminSelectUser,
      adminSelectSystem,
      adminSelectVendor
    ]
  )

  const handleInputChange = (inputValue) => {
    if (!hasAdmin || !inputValue || inputValue.length < 2) {
      return
    }

    setLoading(true)
    adminSearchDebounce(inputValue)
  }

  const renderListItem = ({ option, handleSelect, focusedItemId }) => {
    return (
      <ListItemBase
        onClick={handleSelect}
        active={option.value === focusedItemId}
        value={option.value}
        key={option.value}
      >
        <div className="flex flex-col text-gray-800">
          <span className="text-xs">{option.type}</span>
          <span className="font-medium">{option.label}</span>
          {option.subtitle && <span className="text-xs">{option.subtitle}</span>}
        </div>
      </ListItemBase>
    )
  }

  return (
    <Dialog
      isOpen={adminSearchVisible}
      onClose={setAdminSearchVisible}
      align="top"
      hideFooter
    >
      <div className="flex">
        <Select
          options={optionsMemo}
          onChange={handleChange}
          renderListItem={renderListItem}
          value={selectValue}
          providedInputRef={inputRef}
          freeInput={true}
          rawChangeEvent={false}
          isClearable={true}
          onInputChange={handleInputChange}
        />
      </div>

      {loading && <span>Söker...</span>}
      {!loading && !optionsMemo.length && <span>Inga resultat</span>}
    </Dialog>
  )
}

AdminSearchSelectDialog.propTypes = {}

AdminSearchSelectDialog.defaultProps = {}

export default memo(AdminSearchSelectDialog)
