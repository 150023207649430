import React from 'react'
import { useTranslation } from 'react-i18next'

import ListItemBase from './list-item-base'

const ListItem = ({ label, labelTid, value, onClick, active }) => {
  const { t } = useTranslation()
  const labelText = labelTid ? t(labelTid) : label

  function handleSelect() {
    onClick(value)
  }

  return (
    <ListItemBase active={active} onClick={handleSelect}>
      {labelText}
    </ListItemBase>
  )
}

ListItem.propTypes = {}

ListItem.defaultProps = {}

export default ListItem
