import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { client } from './apollo-client'
import {
  AuthContextProvider,
  UserContextProvider,
  PageContextProvider,
  OverlayNotificationProvider,
  AdminFieldsContextProvider
} from './contexts'
import './i18n'

import App from './app'
import './i18n'

import * as ga from './lib/ga'

import './styles/globals.css'

//const WrappedApp = withAuthenticationRequired(App);

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <UserContextProvider>
            <PageContextProvider>
              <OverlayNotificationProvider>
                <AdminFieldsContextProvider>
                  <App />
                </AdminFieldsContextProvider>
              </OverlayNotificationProvider>
            </PageContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
)
