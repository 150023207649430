import React, { useState } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { useManageSystemContext } from '../context'
import useSubmitSystem from '../hooks/useSubmitSystem'
import useSystemValidator from '../hooks/useSystemValidator'

import ValidationDialog from './validation-dialog'
import FullscreenLoader from '../../shared/fullscreen-loader'

// Could be replaced with TAILWINDCSS JIT plugin
const StyledWrapper = styled.div`
  width: calc(100% - 16rem);

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
`

const FixedFooter = () => {
  const [validationDialogOpen, setValidationDialogOpen] = useState(false)
  const { operationMode } = useManageSystemContext()
  const { submitSystem, loading } = useSubmitSystem()
  const { validateForm, generalValidationErrors, siteDataValidationErrors } =
    useSystemValidator()
  const { t } = useTranslation()

  const handleSubmit = () => {
    const isValid = validateForm()

    if (isValid) {
      submitSystem()
    } else {
      setValidationDialogOpen(true)
    }
  }

  return (
    <StyledWrapper className="justify-end fixed md:w-full border-gray-200 bg-white right-0 bottom-0 py-3 bg-opacity-90 z-10">
      <div className="flex justify-end gap-4 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <button
          onClick={handleSubmit}
          className={
            'px-4 py-1 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          }
        >
          {operationMode === 'CREATE' ? t('content:create') : t('content:save')}
        </button>
      </div>

      <ValidationDialog
        generalValidationErrors={generalValidationErrors}
        siteDataValidationErrors={siteDataValidationErrors}
        onClose={setValidationDialogOpen}
        isOpen={validationDialogOpen}
      />

      <FullscreenLoader show={loading} />
    </StyledWrapper>
  )
}

FixedFooter.propTypes = {}

FixedFooter.defaultProps = {}

export default FixedFooter
