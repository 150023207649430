import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import useGetCachedGuideQuestion from '../../../../hooks/manage-guides/useGetCachedGuideQuestion'
import useCreateGuideCommonQuestion from '../../../../hooks/manage-guides/useCreateGuideCommonQuestion'

import Button from '../../../shared/button'
import SitesSelect from '../../../shared/sites-select'
import QuestionItem from '../guide/question/item'

import EditQuestionDialog from '../guide/question/edit-question.dialog'

const CommonQuestionsOverview = ({ commonQuestions }) => {
  const { t } = useTranslation()
  const [selectedLocale, setSelectedLocale] = useState('sv_SE')
  const [editQuestionId, setEditQuestionId] = useState(false)

  const { createGuideCommonQuestion, createGuideCommonQuestionLoading } =
    useCreateGuideCommonQuestion()

  const cachedQuestion = useGetCachedGuideQuestion(editQuestionId)

  const handleCreateQuestion = useCallback(() => {
    createGuideCommonQuestion((fakeGuideObject) => {
      const lastQuestion = fakeGuideObject.guideQuestions.pop()
      setEditQuestionId(lastQuestion.id)
    })
  }, [createGuideCommonQuestion])

  return (
    <div className="mt-10">
      <div className="flex justify-between mb-4 items-end">
        <SitesSelect
          label={t('content:sites')}
          onChange={setSelectedLocale}
          value={selectedLocale}
          marginless
        />

        <Button onClick={handleCreateQuestion} loading={createGuideCommonQuestionLoading}>
          TODO Skapa fråga
        </Button>
      </div>

      <div className="grid gap-3">
        {commonQuestions.map((question, index) => {
          return (
            <QuestionItem
              key={index}
              question={question}
              editable={true}
              locale={selectedLocale}
              onEditQuestion={setEditQuestionId}
            />
          )
        })}
      </div>

      <EditQuestionDialog
        guideQuestion={cachedQuestion}
        locale={selectedLocale}
        onClose={setEditQuestionId}
        disableCommonSelect={true}
        forceShowSpecialName={true}
      />
    </div>
  )
}

CommonQuestionsOverview.propTypes = {}

CommonQuestionsOverview.defaultProps = {}

export default CommonQuestionsOverview
