import React from 'react'
import { func, object, string, oneOfType, array } from 'prop-types'
import classnames from 'classnames'

const Tile = React.forwardRef(({ className, children, ...rest }, ref) => {
  return (
    <div
      className={`flex-col flex p-2 bg-white border border-gray-200 rounded-lg grow min-w-[200px] max-w-[200px] basis-0 sm:p-3 ${className}`}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  )
})

Tile.displayName = 'Tile'
Tile.propTypes = {
  className: string,
  children: oneOfType([array, object]),
  onDelete: func
}

export default Tile

export const TileHeader = ({ image, text, onClick }) => {
  return (
    <div
      className={classnames('flex w-full h-20 items-center justify-center', {
        'cursor-pointer': !!onClick
      })}
      onClick={onClick}
    >
      {image && image !== 'MISSING' && <img className="rounded-lg h-20" src={image} />}
      {image && image === 'MISSING' && (
        <div className="rounded-lg flex items-center justify-center w-full bg-gray-100 h-20">
          <span className="font-bold text-gray-700">VIDEO</span>
        </div>
      )}
      {text && <span className="text-gray-700">{text}</span>}
    </div>
  )
}

export const TileHeaderDocument = ({ text, onClick }) => {
  return (
    <div
      className={classnames(
        'rounded-lg flex flex-col w-full h-20 items-center justify-center text-center bg-gray-100',
        {
          'cursor-pointer': !!onClick
        }
      )}
      onClick={onClick}
    >
      <span className="font-bold text-gray-700">PDF</span>
      <span className="text-sm text-gray-700 break-words w-full">{text}</span>
    </div>
  )
}

export const TileBody = ({ className, children }) => {
  return (
    <div className={`mt-3 pt-2 border-t border-gray-200 ${className}`}>{children}</div>
  )
}

export const TileFooter = ({ className, children }) => {
  return (
    <div className={`flex mt-3 pt-2 border-t border-gray-200 ${className}`}>
      {children}
    </div>
  )
}
