import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

const DELETE_REVIEW = gql`
  mutation AppDeleteReview($id: ID!) {
    appDeleteReview(id: $id) {
      id
      ourComment
      approved
    }
  }
`

export default function useDeleteReview() {
  const { showErrorNotification } = useOverlayNotification()

  const [deleteReviewMutation, { loading: deleteReviewLoading }] = useMutation(
    DELETE_REVIEW,
    {
      onError: (err) => {
        const error = `${err}`.split(':').reverse()[0]
        showErrorNotification({ text: error })
      }
    }
  )

  const deleteReviewCallback = useCallback(
    (reviewDataId) => {
      return deleteReviewMutation({
        variables: {
          id: reviewDataId
        }
      })
    },
    [deleteReviewMutation]
  )

  return {
    deleteReview: deleteReviewCallback,
    deleteReviewLoading
  }
}
