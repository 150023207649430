import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const UPDATE_QUESTION_TEXT_GQL = gql`
  mutation AdminUpdateQuestionText($questionId: ID!, $field: String!, $data: Json!) {
    updateQuestionText(questionId: $questionId, field: $field, data: $data) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useUpdateQuestionText() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateQuestionText, { loading }] = useMutation(UPDATE_QUESTION_TEXT_GQL)

  const updateQuestionTextCallback = useCallback(
    (questionId, field, data, onCompleted) => {
      return updateQuestionText({
        variables: {
          questionId,
          field,
          data
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: ({ message }) => {
          showErrorNotification({ text: message })
        }
      })
    },
    [updateQuestionText, showSuccessNotification, showErrorNotification]
  )

  return {
    updateQuestionText: updateQuestionTextCallback,
    updateQuestionTextLoading: loading
  }
}
