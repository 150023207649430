import useAdminQuery from './useAdminQuery'

import {
  GET_SYSTEM_PROPS_GQL,
  GET_SYSTEM_PROPS_ADMIN_GQL
} from '../constants/gql/systems'

import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'

export default function useFetchSystemProps() {
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const { data, loading } = useAdminQuery(
    hasAdmin ? GET_SYSTEM_PROPS_ADMIN_GQL : GET_SYSTEM_PROPS_GQL,
    null,
    'cache-first'
  )

  return {
    systemProps: data?.systemProps,
    systemPropsArea: data?.systemPropsArea,
    systemPropsGuide: data?.systemPropsGuide,
    loading
  }
}
