import { useMemo } from 'react'
import { useQuery, gql } from '@apollo/client'

import useFetchAvailableSites from './useFetchAvailableSites'
const GQL_FETCH_OP_COUNTRIES = gql`
  query FetchOperationalCountries {
    appOperationalCountries {
      systemsMarketRegions {
        id
        tid
      }
    }
  }
`

export default function useFetchOperationalCountries() {
  const { data } = useQuery(GQL_FETCH_OP_COUNTRIES)
  const { sitesOptions } = useFetchAvailableSites()
  const systemsMarketRegions = data?.appOperationalCountries?.systemsMarketRegions

  const usersMarketRegionsValues = useMemo(() => {
    return sitesOptions.map((siteOption) => {
      return {
        id: siteOption.value,
        titleTid: siteOption.labelTid
      }
    })
  }, [sitesOptions])

  const systemsMarketRegionsValues = useMemo(() => {
    if (!systemsMarketRegions) {
      return []
    }

    return systemsMarketRegions.map((systemsMarketRegion) => {
      return {
        id: systemsMarketRegion.id,
        titleTid: systemsMarketRegion.tid
      }
    })
  }, [systemsMarketRegions])

  return {
    usersMarketRegionsValues,
    systemsMarketRegionsValues
  }
}
