import React from 'react'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

const activeClass = 'text-blue-600 border-blue-500 opacity-100'
const activeClassLink =
  'hover:border-gray-300 text-gray-500 hover:text-gray-700 border-transparent'

const Tabs = ({ activeTab, tabs, onChange, setTabDisabled }) => {
  const { t } = useTranslation()
  const alternativeStyling = typeof setTabDisabled === 'function'

  return (
    <nav className="flex flex-wrap gap-2" aria-label="Tabs">
      {tabs.map((tab, index) => {
        const isActive = activeTab === tab.value
        const Icon = tab.icon
        const disabled = setTabDisabled && setTabDisabled(tab)
        const tabName = tab.translate ? t(tab.label) : tab.label

        return (
          <a
            key={index}
            href="#"
            className={cx(
              'whitespace-nowrap group inline-flex items-center py-3 px-3 border-b-2 font-medium text-sm',
              tab.className,
              {
                [activeClass]: isActive && !disabled,
                [activeClassLink]: !isActive && !disabled,
                'border-orange-400': alternativeStyling && !disabled && !isActive,
                'cursor-default text-gray-300 opacity-100': disabled
              }
            )}
            onClick={() => {
              if (!disabled) {
                onChange(tab)
              }
            }}
          >
            {Icon && (
              <Icon
                className={cx('mr-2 h-5 w-5', {
                  'group-hover:text-gray-500': !isActive && !disabled
                })}
              />
            )}

            {tab.customIcon && (
              <span
                className={cx('mr-1.5', {
                  'opacity-50': disabled
                })}
              >
                {tab.customIcon}
              </span>
            )}
            <span>{tabName}</span>
          </a>
        )
      })}
    </nav>
  )
}

Tabs.propTypes = {
  setTabDisabled: func
}

Tabs.defaultProps = {
  tabs: []
}

export default Tabs
