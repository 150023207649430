import { useQuery, gql } from '@apollo/client'

const GQL_FETCH_CATEGORIES = gql`
  query FetchCategories {
    appCategories
  }
`
const EMPTY_OBJ = {}

export default function useFetchCategories() {
  const { data, loading } = useQuery(GQL_FETCH_CATEGORIES)

  return {
    allCategoriesMap: data?.appCategories || EMPTY_OBJ,
    allCategoriesLoading: !data && loading
  }
}
