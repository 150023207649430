import React, { useState, useMemo } from 'react'
import { useMutation, gql, useApolloClient } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import useOverlayNotification from '../hooks/useOverlayNotification'
import useGetCategoryName from '../hooks/useGetCategoryName'

import { ChevronRightIcon } from '@heroicons/react/solid'
import { ClipboardCopyIcon, CalendarIcon, PhoneIcon } from '@heroicons/react/outline'
import ListToolbar from './shared/list-toolbar'
import GenerateCsvButton from './shared/generate-csv-btn'

import * as ga from '../lib/ga'
import Flag from './shared/flag'
import EmailStatus from './shared/email/emailStatus'
import CustomerStagesExplainer from './leads-guide/customer-stages-explainer'

const SAVE_WATCHED = gql`
  mutation UpdateWatchedGuide($id: ID!) {
    appUpdateWatchedGuide(id: $id) {
      id
      isNew
    }
  }
`

const GuideLead = function ({ lead, isAdmin }) {
  const [isOpen, setIsOpen] = useState(false)
  const [hasOpen, setHasOpen] = useState(false)
  const client = useApolloClient()
  const { t } = useTranslation('leadsGuide')
  const { showSuccessNotification } = useOverlayNotification()

  const { isNew } = lead

  const emailStatus = lead?.emailStatus
    ? lead.emailStatus
    : {
        email: null,
        score: -1,
        status: ''
      }

  // Save to db first time a box has been toggle
  const [updateWatchedGuide] = useMutation(SAVE_WATCHED, {
    onError(err) {
      // err is for example: "Error: notFound"
      const error = `${err}`.split(':').reverse()[0]
      console.log({ error })
    },
    update() {
      const { appGuideLeadsNumbers } = client.readQuery({
        query: gql`
          query ReadGuideLeadNumber {
            appGuideLeadsNumbers {
              id
              result
            }
          }
        `
      })

      client.writeQuery({
        query: gql`
          query WriteNewGuideLeadNumber {
            appGuideLeadsNumbers {
              id
              result
            }
          }
        `,
        data: {
          appGuideLeadsNumbers: {
            __typename: 'ResultString',
            id: 'guideLeadsNumber',
            result: String(parseInt(appGuideLeadsNumbers.result, 10) - 1)
          }
        }
      })
    }
  })

  return (
    <li>
      <div className="relative block hover:bg-gray-50">
        <button
          type="button"
          className="absolute w-full top-0 right-0 bottom-0 left-0 border-0 bg-transparent hover:bg-transparent focus:bg-transparent cursor-pointer"
          onClick={() => {
            const isClosed = !isOpen

            setIsOpen(!isOpen)
            if (isNew && !hasOpen) {
              setHasOpen(true)

              // Trigger mutation
              if (!isAdmin) {
                updateWatchedGuide({
                  variables: { id: lead.id }
                })
              }
            }

            if (isClosed) {
              ga.event({ action: 'open_lead_guide' })
            }
          }}
        />
        {isNew && (
          <span className="absolute inline-flex items-center px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800">
            {t('content:new')}
          </span>
        )}
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={lead.locale} />
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div>
                <p className="text-sm font-medium text-blue-600 truncate">
                  <button
                    type="button"
                    className="relative flex border-0 z-1 hover:bg-transparent focus:bg-transparent"
                    onClick={() => {
                      const cb = navigator.clipboard
                      cb.writeText(lead.email).then(
                        () => {
                          showSuccessNotification({
                            titleTid: 'content:success.successfullyCopied'
                          })
                        },
                        (err) => {
                          console.error('error with copy: ', err)
                        }
                      )
                    }}
                  >
                    {lead.email}
                    <ClipboardCopyIcon
                      className="ml-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />{' '}
                    {emailStatus ? (
                      <EmailStatus emailStatus={emailStatus} styleing="ml-3 h-5 w-5" />
                    ) : null}
                    {lead.phone && (
                      <PhoneIcon
                        className="flex-shrink-0 ml-3 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <time dateTime={lead.ended}>
                    {lead.ended.toLocaleString('sv').substr(0, 16)}
                  </time>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="flex text-sm">
                    <p className="font-medium truncate">{lead.systemName}</p>
                    <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                      i {lead.category}
                    </p>
                  </div>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {lead.companySize} - {lead.role}
                  </p>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="flex justify-end">
                  <CustomerStagesExplainer
                    specialName={lead.guideAnswersData[0].specialName}
                  />
                </div>
                <p className="mt-[2px] text-right text-sm text-gray-500 truncate">
                  {lead.guideAnswersData[0].answerText[0]}
                </p>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-10 py-5 bg-gray-50">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('leadCard.contactInformation')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t('content:email')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {lead.email}
                  {emailStatus ? (
                    <EmailStatus
                      emailStatus={emailStatus}
                      styleing="inline-flex align-top ml-2 h-5 w-5"
                    />
                  ) : null}
                </dd>
              </div>
              {lead.role && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:role')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {lead.role}
                  </dd>
                </div>
              )}
              {lead.phone && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:phone')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {lead.phone}
                  </dd>
                </div>
              )}
              {lead.company && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:company')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {lead.company}
                  </dd>
                </div>
              )}
              {lead.name && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">
                    {t('content:name')}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {lead.name}
                  </dd>
                </div>
              )}
            </dl>
          </div>
          <div className="pt-5 border-t border-gray-200">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('leadCard.answers.title')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {lead.guideAnswersData.map((answerData) => (
                <div
                  key={answerData.questionId}
                  className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                >
                  <dt className="text-sm font-medium text-gray-500">
                    {answerData.questionText}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {answerData.answerText.map((answerText, index) => (
                      <li key={index}>{answerText}</li>
                    ))}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      )}
    </li>
  )
}

export default function LeadsGuide({ leads, systemsArray, isAdmin }) {
  const { t } = useTranslation('content')
  const { getCategoryName } = useGetCategoryName()
  const questionsHeader = useMemo(() => [], [])

  const updatedLeadsObj = useMemo(() => {
    if (!leads?.length) {
      return []
    }

    return leads.map((lead) => {
      let questionsAnswers = {}

      const guideAnswersArray = lead.guideAnswersData.map((resObject) => {
        const { questionText, answerText, questionId } = resObject
        const answerString = answerText.join(', ')

        questionsHeader.push({ label: questionText, key: 'questionId-' + questionId })
        questionsAnswers['questionId-' + questionId] = answerText.join(', ')
        return `${questionText}: ${answerString}`
      })

      const guideAnswersString = guideAnswersArray.join(', ')

      const systemName = systemsArray?.find(
        (systemObj) => systemObj.id === lead.systemId
      )?.name

      return {
        ...lead,
        category: getCategoryName(lead.category),
        systemName,
        ...questionsAnswers,
        guideAnswersString,
        ended: new Date(lead.ended),
        emailScore: lead?.emailStatus?.score == 100 ? 'OK' : 'Unkonwn'
      }
    })
  }, [leads, systemsArray, getCategoryName, questionsHeader])

  const csvDataObject = useMemo(() => {
    return {
      headers: [
        { label: 'Category', key: 'category' },
        { label: 'System Name', key: 'systemName' },
        { label: 'Name', key: 'name' },
        { label: 'Phone', key: 'phone' },
        { label: 'Email', key: 'email' },
        { label: 'Email status', key: 'emailScore' },
        { label: 'Date', key: 'ended' },
        { label: 'Role', key: 'role' },
        { label: 'Locale', key: 'locale' },
        ...questionsHeader,
        { label: 'All Guide answers', key: 'guideAnswersString' }
      ],
      data: updatedLeadsObj
    }
  }, [updatedLeadsObj, questionsHeader])

  if (!leads?.length) {
    return <div>{t('warning.noData')}</div>
  }

  return (
    <>
      <ListToolbar>
        <GenerateCsvButton
          filename="guide-leads.csv"
          csvData={csvDataObject}
          gaActionName="export_guide_leads_csv"
        />
      </ListToolbar>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {updatedLeadsObj.map((lead) => {
            return <GuideLead key={lead.ended} lead={lead} isAdmin={isAdmin} />
          })}
        </ul>
      </div>
    </>
  )
}
