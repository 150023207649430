import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'

const FIELDS_EDIT_WEBHOOK_ARRAY = ['name', 'url', 'events', 'transformer']

const GQL_EDIT_WEBHOOK = gql`
  mutation EditWebhook($vendorId: ID!, $webhookId: ID!, $params: WebhookInput!) {
    editWebhook(vendorId: $vendorId, webhookId: $webhookId, params: $params) {
      id
      name
      url
      events
      transformer
    }
  }
`

export default function useEditWebhook(vendorId) {
  const [editWebhook] = useMutation(GQL_EDIT_WEBHOOK)

  const editWebhookCallback = useCallback(
    (params) => {
      const data = {}

      Object.keys(params).forEach((key) => {
        if (FIELDS_EDIT_WEBHOOK_ARRAY.includes(key)) {
          data[key] = params[key]
        }
      })

      return editWebhook({
        variables: { vendorId, webhookId: params.id, params: data },
        optimisticResponse: {
          editWebhook: params
        }
      })
    },
    [vendorId, editWebhook]
  )

  return {
    editWebhook: editWebhookCallback
  }
}
