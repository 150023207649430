import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as ga from '../lib/ga'

import { usePageContext } from '../contexts'

const CookieConsent = () => {
  const [showCookie, setShowCookie] = useState(false)
  const { acceptedCookie, setAcceptedCookie } = usePageContext()

  useEffect(() => {
    setShowCookie(!acceptedCookie)
  }, [acceptedCookie])

  if (!showCookie) {
    return null
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white p-2 py-10 border-t z-10">
      <div className="flex flex-row max-w-screen-md m-auto">
        <p className="flex-1">
          Vi använder cookies för att leverera den bästa upplevelsen och analysera. Genom
          att använda vår site så godkänner du användandet av cookies enligt vår{' '}
          <a className="underline" href="https://businesswith.se/legal/cookie-policy/">
            cookie policy
          </a>{' '}
          och{' '}
          <Link to="/legal/privacy-policy">
            <span className="underline">privacy policy</span>
          </Link>
          .
        </p>
        <button
          type="button"
          onClick={(e) => {
            setAcceptedCookie(true)
            ga.event({
              action: 'accept_cookie'
              // params : {
              //   search_term: query
              // }
            })
          }}
          className="flex-shrink-0 items-center self-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Jag förstår
        </button>
      </div>
    </div>
  )
}

export default CookieConsent
