import React, { useState, memo, useMemo } from 'react'
import { bool, func, object } from 'prop-types'
import cx from 'classnames'

import { ChevronRightIcon, ChevronDownIcon, CalendarIcon } from '@heroicons/react/solid'
import { LinkIcon, TrashIcon, PlusIcon, PencilIcon } from '@heroicons/react/outline'

import { useTranslation } from 'react-i18next'

import ReviewItemForm from './item-form'
import Comments from './comments'

import EditCreateTranslationDialog from './dialogs/edit-create-translation.dialog'
import Flag from '../../shared/flag'
import AdminField from '../../shared/admin-field'
import Button from '../../shared/button'

const ReviewItem = ({ review, isSubItem, isCreator, onReviewsChanged, onDelete }) => {
  const [isOpen, setIsOpen] = useState(isCreator ? true : false)
  const [openEditDialogMode, setOpenEditDialogMode] = useState(false) // CREATE / EDIT
  const { t } = useTranslation()

  const otherReviewSitesMemo = useMemo(() => {
    return (
      review.parentReviewDataFor?.map((childReviewData) => childReviewData.locale) || []
    )
  }, [review?.parentReviewDataFor])

  const titleMissing = !review.reviewTitle?.length

  return (
    <li>
      <div
        className="block hover:bg-gray-50 cursor-pointer"
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          {isSubItem && <LinkIcon className="w-5 h-5 text-red-300 mr-3" />}

          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={review.locale} />
              </div>
            </div>
            <div className="flex-1 px-4 grid sm:grid-cols-2 gap-2 sm:gap-4">
              <div className="min-w-0">
                <p
                  className={cx('text-sm font-medium text-blue-600 truncate', {
                    'italic text-gray-500': titleMissing
                  })}
                >
                  {!titleMissing ? review.reviewTitle : t('reviews:missingTitle')}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <time dateTime={review.created}>
                    {review.created.toLocaleString('sv').substr(0, 10)}
                  </time>
                </p>
              </div>

              <div className="flex gap-1 items-center flex-wrap">
                {otherReviewSitesMemo.map((locale) => {
                  return (
                    <Flag
                      className="h-4 w-4 sm:w-5 sm:h-5"
                      locale={locale}
                      key={locale}
                    />
                  )
                })}
              </div>
            </div>
          </div>
          <div>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="px-10 py-6 bg-gray-50">
          <div className="space-y-4">
            <ReviewItemForm review={review} />
            <div className="border-t" />
            <Comments review={review} onReviewsChanged={onReviewsChanged} />
          </div>

          <AdminField className="mt-8">
            <div className="flex justify-between">
              <Button
                mode="error"
                Icon={TrashIcon}
                disabled={!isSubItem}
                onClick={() => onDelete(review.id)}
              >
                {t('content:delete')}
              </Button>

              {isSubItem && (
                <Button
                  mode="primary"
                  Icon={PencilIcon}
                  onClick={() => setOpenEditDialogMode('EDIT')}
                >
                  {t('content:edit')}
                </Button>
              )}

              {!isSubItem && (
                <Button
                  mode="primary"
                  Icon={PlusIcon}
                  onClick={() => setOpenEditDialogMode('CREATE')}
                >
                  {t('content:create')}
                </Button>
              )}
            </div>
          </AdminField>
        </div>
      )}

      {!!openEditDialogMode && (
        <EditCreateTranslationDialog
          isOpen={true}
          review={review}
          onClose={setOpenEditDialogMode}
          mode={openEditDialogMode}
          onReviewsChanged={onReviewsChanged}
        />
      )}
    </li>
  )
}

ReviewItem.propTypes = {
  review: object.isRequired,
  isSubItem: bool,
  isCreator: bool,
  onReviewsChanged: func.isRequired,
  onDelete: func
}

ReviewItem.defaultProps = {}

export default memo(ReviewItem)
