import React, { useMemo } from 'react'
import { func, object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useFetchGuides from '../../../hooks/manage-guides/useFetchGuides'
import useCategoriesOptions from '../../../hooks/useCategoriesOptions'
import useFetchAvailableSites from '../../../hooks/useFetchAvailableSites'

import Label from '../../shared/label'
import { Select, MultiSelect } from '../../shared/select'

import { hasItemsInArray } from '../../../validators/general'

const FIELD_CATEGORIES = 'categories'
const FIELD_LOCALES = 'locales'
const FIELD_ACTIVE = 'active'

const GUIDE_GENERAL_FORM_INSTR = [
  {
    name: FIELD_CATEGORIES,
    validators: [
      { validator: hasItemsInArray, error: 'forms:validation.general.arrayRequired' }
    ]
  },
  {
    name: FIELD_LOCALES,
    validators: [
      { validator: hasItemsInArray, error: 'forms:validation.general.arrayRequired' }
    ]
  }
]

export const GUIDE_CREATE_FORM_INSTR = [...GUIDE_GENERAL_FORM_INSTR]

export const GUIDE_EDIT_FORM_INSTR = [
  ...GUIDE_GENERAL_FORM_INSTR,
  {
    name: FIELD_ACTIVE
  }
]

const GuideGeneralForm = ({ formData, onChange, validationErrors }) => {
  const { t } = useTranslation()
  const { categoriesOptions } = useCategoriesOptions()
  const { guides } = useFetchGuides('cache-first')
  const { sitesOptions } = useFetchAvailableSites()
  const currentGuideId = formData?.id

  const filteredCategoriesOptionsMemo = useMemo(() => {
    if (!guides?.length) {
      return []
    }

    const usedCategories = []

    guides.forEach((guide) => {
      if (currentGuideId === guide.id) {
        return
      }

      guide.categories.forEach((category) => {
        usedCategories.push(category)
      })
    })

    return categoriesOptions.filter((categoryOption) => {
      return !usedCategories.includes(categoryOption.value)
    })
  }, [guides, categoriesOptions, currentGuideId])

  const getCommonProps = (fieldName) => {
    return {
      id: fieldName,
      value: formData[fieldName],
      errorText: t(validationErrors[fieldName]),
      onChange
    }
  }

  const handleChangeCategory = (fieldName, updatedCategory) => {
    onChange(fieldName, [updatedCategory])
  }

  return (
    <>
      <Label className="flex-1" title={t('content:category')} marginless>
        <Select
          {...getCommonProps(FIELD_CATEGORIES)}
          onChange={handleChangeCategory}
          value={formData[FIELD_CATEGORIES]?.[0]}
          options={filteredCategoriesOptionsMemo}
          rawChangeEvent={false}
        />
      </Label>

      <Label className="flex-1" title={t('content:sites')} marginless>
        <MultiSelect {...getCommonProps(FIELD_LOCALES)} options={sitesOptions} rows={5} />
      </Label>
    </>
  )
}

GuideGeneralForm.propTypes = {
  formData: object.isRequired,
  onChange: func.isRequired,
  validationErrors: object.isRequired
}

GuideGeneralForm.defaultProps = {}

export default GuideGeneralForm
