export const getSystemName = (systemObject) => {
  if (!systemObject || !Array.isArray(systemObject.systemSiteData)) {
    return ''
  }

  return (
    systemObject.systemSiteData[systemObject.systemSiteData.length - 1].systemName || ''
  )
}

export const getSystemPublished = (systemSiteData) => {
  if (!systemSiteData) {
    return false
  }

  return systemSiteData.some((siteData) => {
    return siteData.visibility !== 'HIDDEN'
  })
}

export const findSystemSiteDataForLocale = (system, locale) => {
  if (!locale || !system.systemSiteData) {
    return null
  }

  const siteDataForLocale = system.systemSiteData.find(
    (siteData) => siteData.locale === locale
  )

  return siteDataForLocale || null
}
