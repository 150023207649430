import { useCallback } from 'react'

import useGetCategoryName from '../useGetCategoryName'
import useGetSystemName from '../useGetSystemName'

export default function useFieldsSystemsValues() {
  const { getSystemName } = useGetSystemName()
  const { allCategoriesMap, getCategoryName } = useGetCategoryName()

  const getSortedSystemValues = useCallback(
    (allSystems) => {
      if (!allCategoriesMap || !allSystems) {
        return []
      }

      const allSystemsCopy = [...allSystems]

      return allSystemsCopy
        .sort((a, b) => {
          const nameA = getSystemName(a).toUpperCase()
          const nameB = getSystemName(b).toUpperCase()

          if (nameA < nameB) {
            return -1
          }

          if (nameA > nameB) {
            return 1
          }

          return 0
        })
        .map((systemObj) => {
          return {
            id: systemObj.id,
            title: getSystemName(systemObj),
            text: getCategoryName(systemObj.defaultCategory)
          }
        })
    },
    [allCategoriesMap, getCategoryName, getSystemName]
  )

  return {
    getSortedSystemValues
  }
}
