import React from 'react'
import { func, object, oneOf } from 'prop-types'
import { ManageSystemContextProvider } from './context'

import SystemTabs from './src/system-tabs'
import SystemTabsOutput from './src/system-tabs-output'
import FixedFooter from './src/footer'

const ManageSystem = ({
  mode,
  systemData,
  setLangFields,
  setTabDisabled,
  initialTab
}) => {
  return (
    <ManageSystemContextProvider
      operationMode={mode}
      systemData={systemData}
      setLangFields={setLangFields}
    >
      <SystemTabs setTabDisabled={setTabDisabled} initialTab={initialTab} />

      <div className="mt-6 mb-24">
        <SystemTabsOutput />
      </div>
      <FixedFooter />
    </ManageSystemContextProvider>
  )
}

ManageSystem.propTypes = {
  mode: oneOf(['CREATE', 'UPDATE']).isRequired,
  systemData: object,
  setTabDisabled: func,
  setLangFields: func
}

ManageSystem.defaultProps = {}

export default ManageSystem
