import { useQuery, gql } from '@apollo/client'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

export const LIST_COMMON_QUESTIONS_GQL = gql`
  query ListCommonQuestions {
    commonQuestions {
      ${GUIDE_FRAGMENT}
    }
  }
`

const EMPTY_ARR = []

export default function useListCommonQuestions() {
  const { data, loading, refetch } = useQuery(LIST_COMMON_QUESTIONS_GQL)

  return {
    commonQuestions: data?.commonQuestions?.guideQuestions || EMPTY_ARR,
    commonQuestionsLoading: !data && loading,
    refetchCommonQuestions: refetch
  }
}
