import React from 'react'
import { bool, func, string } from 'prop-types'
import classnames from 'classnames'

import { TrashIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'

const ManageAssetControls = ({
  assetId,
  assetType,
  hideOrderControls,
  moveLeftDisabled,
  moveRightDisabled,
  onDelete,
  onReorder
}) => {
  const handleDelete = () => {
    onDelete('DELETE', assetType, { id: assetId })
  }

  const handleReorderLeft = () => {
    if (!moveLeftDisabled) {
      onReorder('REORDER', assetType, { id: assetId, direction: 'L' })
    }
  }

  const handleReorderRight = () => {
    if (!moveRightDisabled) {
      onReorder('REORDER', assetType, { id: assetId, direction: 'R' })
    }
  }

  return (
    <div className="flex justify-between w-full text-sm text-gray-600">
      <div className="flex gap-2">
        {!hideOrderControls && (
          <>
            <ArrowLeftIcon
              onClick={handleReorderLeft}
              className={classnames('h-4 w-4 cursor-pointer', {
                'opacity-50 cursor-default': moveLeftDisabled
              })}
            />

            <ArrowRightIcon
              onClick={handleReorderRight}
              className={classnames('h-4 w-4 cursor-pointer', {
                'opacity-50 cursor-default': moveRightDisabled
              })}
            />
          </>
        )}
      </div>

      {onDelete && (
        <div className="flex gap-2">
          <TrashIcon className="h-4 w-4 cursor-pointer" onClick={handleDelete} />
        </div>
      )}
    </div>
  )
}

ManageAssetControls.propTypes = {
  assetId: string.isRequired,
  assetType: string.isRequired,
  hideOrderControls: bool,
  moveLeftDisabled: bool,
  moveRightDisabled: bool,
  onDelete: func.isRequired,
  onReorder: func.isRequired
}

ManageAssetControls.defaultProps = {}

export default ManageAssetControls
