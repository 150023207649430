import React, { useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import useGetSystemName from '../hooks/useGetSystemName'
import useGetCategoryName from '../hooks/useGetCategoryName'
import { CalendarIcon, PhoneIcon } from '@heroicons/react/outline'
import Flag from './shared/flag'

import EmailStatus from './shared/email/emailStatus'
import EmailStatusManual from './admin/admin-all-guides/emailStatusManual'
import CustomerStagesExplainer from './leads-guide/customer-stages-explainer'

const DATA_ROWS = [
  { dataKey: 'role', translationId: 'content:role' },
  { dataKey: 'phone', translationId: 'content:phone' },
  { dataKey: 'company', translationId: 'content:company' },
  { dataKey: 'name', translationId: 'content:name' }
]

const GuideLead = function ({ lead }) {
  const { t } = useTranslation('adminPages')
  const [isOpen, setIsOpen] = useState(false)
  const { getSystemName } = useGetSystemName()
  const { getCategoryName } = useGetCategoryName()

  const emailStatus = lead.emailStatus
  const emailNewStatus = lead.newEmailStatus
  const leadEndedDate = new Date(lead.ended)

  return (
    <li key={lead.email}>
      <div
        className={classNames('block cursor-pointer', {
          'hover:bg-gray-50': lead.formDone,
          'bg-red-100 hover:bg-red-200': !lead.formDone
        })}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={lead.locale} />
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
              <div>
                <p className="text-sm font-medium text-blue-600 truncate flex">
                  {lead.newEmail ? lead.newEmail : lead.email}
                  {lead.newEmail ? (
                    <EmailStatus
                      emailStatus={emailNewStatus}
                      styleing="inline-flex align-top ml-2 h-5 w-5"
                    />
                  ) : emailStatus ? (
                    <EmailStatus
                      emailStatus={emailStatus}
                      ignoreOnEmpty={false}
                      styleing="inline-flex align-top ml-2 h-5 w-5"
                    />
                  ) : null}
                  {lead.phone && (
                    <PhoneIcon
                      className="flex-shrink-0 ml-3 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <time dateTime={leadEndedDate}>
                    {leadEndedDate.toLocaleString('sv').substr(0, 16)}
                  </time>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="flex text-sm">
                    <p className="font-medium truncate">
                      {getCategoryName(lead.category)}{' '}
                      <span className="font-normal text-gray-400">
                        - System: {lead.resultSystemsWithPoints.length} (
                        {lead.resultSystemsWithPoints.reduce(
                          (acc, resultSystemWithPoints) => {
                            const siteCount =
                              resultSystemWithPoints?.systemV2?.systemSiteData?.reduce(
                                (acc, systemSiteData) => {
                                  return (
                                    acc +
                                    (systemSiteData.locale == lead.locale &&
                                    (systemSiteData.customerType === 'CUSTOMER' ||
                                      systemSiteData.customerType === 'TRIAL')
                                      ? 1
                                      : 0)
                                  )
                                },
                                0
                              )
                            return acc + siteCount
                          },
                          0
                        )}
                        )
                      </span>
                    </p>
                  </div>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {lead.companySize} {lead.role ? ` - ${lead.role}` : null}
                  </p>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="flex justify-end">
                  <CustomerStagesExplainer
                    specialName={lead.guideAnswersData[0].specialName}
                  />
                </div>

                <p className="mt-2 text-sm text-gray-500 text-right truncate">
                  {lead.guideAnswersData[0].answerText[0]}
                </p>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-10 py-5 bg-gray-50">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('adminAllGuides.recommendedSystems')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {lead.resultSystemsWithPoints.map((guideSystemObj) => {
                const systemSiteDataLocale = guideSystemObj.systemV2.systemSiteData.find(
                  (systemSiteData) => {
                    return systemSiteData.locale === lead.locale
                  }
                )

                const customerFormat =
                  systemSiteDataLocale.customerType == 'CUSTOMER' ||
                  systemSiteDataLocale.customerType == 'TRIAL'
                    ? 'text-gray-900 font-bold'
                    : 'text-gray-500 italic'

                return (
                  <div
                    key={guideSystemObj.systemV2.id}
                    className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                  >
                    <dt className={`text-sm font-medium ${customerFormat}`}>
                      {getSystemName(guideSystemObj.systemV2)}
                    </dt>
                    <dd className={`mt-1 text-sm  sm:mt-0 sm:col-span-2 text-gray-500`}>
                      Score: {guideSystemObj.points} - {guideSystemObj.guideRating}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </div>

          <div className="pt-5 border-t border-gray-200">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('content:contactInformation')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  {t('content:email')}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {lead.email}{' '}
                  {emailStatus ? (
                    <>
                      <EmailStatus
                        emailStatus={emailStatus}
                        styleing="inline-flex align-top ml-2 h-5 w-5"
                      />
                      <EmailStatusManual
                        emailStatus={emailStatus}
                        styleing="inline-flex align-top ml-2 h-5 w-5"
                      />
                    </>
                  ) : null}
                  {lead.newEmail ? (
                    <p className="font-bold">
                      {t('adminAllGuides.emailUpdatedTo')}:
                      <br />
                      {lead.newEmail}{' '}
                      {emailNewStatus ? (
                        <>
                          <EmailStatus
                            emailStatus={emailNewStatus}
                            styleing="inline-flex align-top ml-2 h-5 w-5"
                          />
                          <EmailStatusManual
                            emailStatus={emailNewStatus}
                            styleing="inline-flex align-top ml-2 h-5 w-5"
                          />
                        </>
                      ) : null}
                    </p>
                  ) : null}
                </dd>
              </div>

              {DATA_ROWS.map((rowData, index) => {
                if (!lead?.[rowData.dataKey]) {
                  return null
                }

                return (
                  <div
                    className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                    key={index}
                  >
                    <dt className="text-sm font-medium text-gray-500">
                      {t(rowData.translationId)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {lead[rowData.dataKey]}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </div>
          <div className="pt-5 border-t border-gray-200">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('content:answer')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {lead.guideAnswersData.map((answerData) => (
                <div
                  key={answerData.questionId}
                  className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                >
                  <dt className="text-sm font-medium text-gray-500">
                    {answerData.questionText}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul className="pl-2">
                      {answerData.answerText.map((answerText, index) => (
                        <li key={index}>{answerText}</li>
                      ))}
                    </ul>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      )}
    </li>
  )
}

export default function LeadsGuide({ leads }) {
  const { t } = useTranslation()

  if (!leads?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {leads.map((lead) => {
          return <GuideLead key={lead.id} lead={lead} />
        })}
      </ul>
    </div>
  )
}
