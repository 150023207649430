import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const ARCHIVE_GUIDE_GQL = gql`
  mutation AdminArchiveGuide($id: ID!) {
    archiveGuide(id: $id)
  }
`

export default function useArchiveGuide() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [archiveGuide, { loading }] = useMutation(ARCHIVE_GUIDE_GQL)

  const archiveGuideCallback = useCallback(
    (id, onCompleted) => {
      return archiveGuide({
        variables: {
          id
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: ({ message }) => {
          showErrorNotification({ text: message })
        }
      })
    },
    [archiveGuide, showSuccessNotification, showErrorNotification]
  )

  return {
    archiveGuide: archiveGuideCallback,
    archiveGuideLoading: loading
  }
}
