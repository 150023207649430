import React from 'react'
import { bool, object, string } from 'prop-types'

import useUpdateQuestionText from '../../../../../hooks/manage-guides/useUpdateQuestionText'

import EditableI18nTextarea from '../../../../shared/editable-textarea-i18n-input'

const EditQuestionText = ({ questionId, id, locale, texts, disabled }) => {
  const { updateQuestionText, updateQuestionTextLoading } = useUpdateQuestionText()

  const handleSave = (id, data, doneCallback) => {
    updateQuestionText(questionId, id, data, () => {
      doneCallback()
    })
  }

  return (
    <EditableI18nTextarea
      id={id}
      loading={updateQuestionTextLoading}
      texts={texts}
      locale={locale}
      onSave={handleSave}
      disabled={disabled}
    />
  )
}

EditQuestionText.propTypes = {
  questionId: string,
  id: string.isRequired,
  locale: string.isRequired,
  texts: object,
  disabled: bool
}

EditQuestionText.defaultProps = {}

export default EditQuestionText
