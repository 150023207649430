import { useCallback } from 'react'
import { useMutation, gql } from '@apollo/client'

const GQL_CREATE_WEBHOOK = gql`
  mutation CreateWebhook($vendorId: ID!, $params: WebhookInput!) {
    createWebhook(vendorId: $vendorId, params: $params) {
      id
      name
      url
      events
      transformer
    }
  }
`

export default function useCreateWebhook(vendorId) {
  const [createWebhook] = useMutation(GQL_CREATE_WEBHOOK)

  const createWebhookCallback = useCallback(
    (params) => {
      return createWebhook({
        variables: { vendorId, params },
        optimisticResponse: {
          createWebhook: {
            ...params,
            id: '-1',
            __typename: 'Webhook'
          }
        },
        update: (cache, res) => {
          cache.modify({
            fields: {
              webhooks: (existingWebhooks, { toReference }) => {
                return [toReference(res.data.createWebhook), ...existingWebhooks]
              }
            }
          })
        }
      })
    },
    [createWebhook, vendorId]
  )

  return {
    createWebhook: createWebhookCallback
  }
}
