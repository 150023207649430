import React, { useState } from 'react'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import VendorForm from '../components/admin/vendor-form'

import { useTranslation } from 'react-i18next'
import { useMutation, gql } from '@apollo/client'
import useOverlayNotification from '../hooks/useOverlayNotification'

const SAVE_VENDOR = gql`
  mutation AppUpdateVendor($id: ID!, $data: Json) {
    appUpdateVendor(id: $id, data: $data) {
      id
    }
  }
`

function AdminEditVendorPage() {
  const { t } = useTranslation('adminPages')
  const [selectedVendorId, setSelectedVendorId] = useState(null)
  const { showSuccessNotification } = useOverlayNotification()

  const [updateVendor, { loading }] = useMutation(SAVE_VENDOR, {
    onError(err) {
      // err is for example: "Error: notFound"
      const error = `${err}`.split(':').reverse()[0]
      console.log({ error })
    }
  })

  const handleFormSubmit = (data, refetchVendors) => {
    updateVendor({
      variables: {
        id: selectedVendorId,
        data
      },
      onCompleted(data) {
        showSuccessNotification()
        refetchVendors()
      }
    })
  }

  return (
    <>
      <PageHeader pageName={t('adminEditVendor.header.title')}>
        <p>{t('adminEditVendor.header.subtitle')}</p>
      </PageHeader>

      <PageBody>
        <VendorForm
          selectedVendorId={selectedVendorId}
          onChangeVendorId={setSelectedVendorId}
          onSubmit={handleFormSubmit}
          loading={loading}
        />
      </PageBody>
    </>
  )
}

export default AdminEditVendorPage
