import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Loader from '../components/loader'
import { PageBody } from '../components/page-body'

import useGetCategoryName from '../hooks/useGetCategoryName'
import useGetSystemName from '../hooks/useGetSystemName'

import {
  ExclamationIcon,
  UserIcon,
  UserAddIcon,
  PencilIcon
} from '@heroicons/react/solid'
import Input from '../components/shared/input'

const QUERY = gql`
  query Users($searchTerm: String) {
    appAdminUsers(searchTerm: $searchTerm) {
      users {
        id
        email
        appAccepted
        disabled
        systemsV2 {
          id
          defaultCategory
          systemSiteData {
            systemName
            locale
          }
        }
        appLocales
        appRole
        vendor {
          id
          company
          accessRights
          systemsV2 {
            id
            defaultCategory
            systemSiteData {
              locale
              systemName
            }
          }
        }
      }
      totalCount
    }
  }
`

function User({ user }) {
  const { t } = useTranslation()
  const { getCategoryName } = useGetCategoryName()
  const { getSystemName } = useGetSystemName()
  const isActive = user.appAccepted && (user.disabled === false || user.disabled === null)

  let icon
  switch (user?.appRole) {
    case 'USER':
      icon = (
        <UserIcon className="h-10 w-10 rounded-full text-gray-400" aria-hidden="true" />
      )
      break

    case 'EDITOR':
      icon = (
        <PencilIcon
          className="h-10 w-10 rounded-full text-emerald-400"
          aria-hidden="true"
        />
      )
      break

    case 'ADMIN':
      icon = (
        <UserAddIcon
          className="h-10 w-10 rounded-full text-blue-400"
          aria-hidden="true"
        />
      )
      break
    default:
      icon = (
        <ExclamationIcon
          className="h-10 w-10 rounded-full text-yellow-400"
          aria-hidden="true"
        />
      )
      break
  }

  return (
    <tr key={user.email}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="h-10 w-10 flex-shrink-0">{icon}</div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">{user.email}</div>
            <div className="text-gray-500">{user.vendor?.company}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-900">
          {user.vendor?.accessRights.map((right) => {
            return <div key={right}>{right}</div>
          })}
        </div>
        <div className="border-t-4 text-gray-500">{user?.appRole}</div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {user.appLocales.map((region) => {
          return <div key={region}>{region}</div>
        })}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {user.systemsV2?.map((system) => {
          return (
            <div key={system.id} style={{ display: 'flex', whiteSpace: 'pre' }}>
              {getSystemName(system)} -{' '}
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {getCategoryName(system.defaultCategory, true)}
              </span>
            </div>
          )
        })}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {isActive ? (
          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
            {t('content:active')}
          </span>
        ) : (
          <span className="group relative">
            <span className="absolute top-0 scale-0 transition-all rounded bg-gray-800 p-1 text-xs text-white group-hover:scale-100 z-auto">
              {user.disabled == true ? t('content:disabled') : ''}
              <br />
              {user.appAccepted ? t('content:accepted') : ''}
            </span>
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
              {t('content:inactive')}
            </span>
          </span>
        )}
      </td>
    </tr>
  )
}

function AppUsers({ appUsers }) {
  const { t } = useTranslation()
  return (
    <div className="">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      {t('content:name')}
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('content:privileges')}
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('content:markets')}
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('content:system')}
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {t('content:status')}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {appUsers.length > 0 ? (
                    appUsers
                      .slice()
                      .sort((a, b) => {
                        const nameA = a.vendor?.company.toUpperCase()
                        const nameB = b.vendor?.company.toUpperCase()
                        if (nameA < nameB) {
                          return -1
                        }
                        if (nameA > nameB) {
                          return 1
                        }
                        return 0
                      })
                      .map((user) => {
                        return <User key={user.id} user={user} />
                      })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center p-5 bg-white">
                        {t('searchToSeeResults')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function AdminAllUsersPage() {
  const { t } = useTranslation()
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [totalCount, setTotalCount] = useState(null)

  const { loading, error, refetch } = useQuery(QUERY, {
    variables: { searchTerm },
    onCompleted: (data) => {
      setTotalCount(data?.appAdminUsers?.totalCount)
    }
  })

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 3) {
        refetch({ searchTerm })
          .then(({ data }) => {
            setUsers(data?.appAdminUsers?.users || [])
          })
          .catch((err) => {
            console.error('Search error:', err)
          })
      } else {
        setUsers([])
      }
    }, 1000)
    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm, refetch])

  return (
    <PageBody>
      {error &&
        error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
      {loading && (
        <div className="text-center">
          <Loader />
        </div>
      )}
      <div className={'bg-white p-3 rounded-md'}>
        <div className={'flex justify-between items-center max-md:flex-wrap'}>
          <Input
            type="text"
            className={'w-56'}
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder={t('content:search')}
          />
          <p className={'text-sm font-semibold text-gray-500'}>
            {/*TODO: add translations here*/}
            {t('content:total')}
            <span className={'text-blue-600 text-lg'}> {totalCount}</span>
          </p>
        </div>
        {/* Users Table */}
        {!loading && <AppUsers appUsers={users} />}
      </div>
    </PageBody>
  )
}

export default AdminAllUsersPage
