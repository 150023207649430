import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext, useUserContext } from '../contexts'
import { isAdmin } from '../utils'

const ProtectedRouteMR = ({ children }) => {
  // Identify authenticated user
  const { auth } = useAuthContext()
  const { user } = useUserContext()
  const isAuthenticated = auth?.isLoggedIn
  const isFirstLogin = user?.isFirstLogin
  const hasAdmin = isAdmin(user?.role)
  const isAccepted = user?.accepted
  const isMR = user?.role === 'MARKET_RESEARCHER'

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  if (isFirstLogin) {
    return <Navigate to="/update-password" />
  }

  if (!isAccepted) {
    return <Navigate to="/accept" />
  }

  if (!isMR && !hasAdmin) {
    return <Navigate to="/" />
  }

  return children
}

export default ProtectedRouteMR
