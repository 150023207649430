import React from 'react'
import { bool, func } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Label from '../../../shared/label'
import ToggleSwitch from '../../../shared/toggle-switch'

const ToggleActiveGuide = ({ onChange, value }) => {
  const { t } = useTranslation('adminPages')

  const handleChange = (value) => {
    onChange('active', value)
  }

  return (
    <Label title={t('adminManageGuide.guide.publishLabel')}>
      <ToggleSwitch onChange={handleChange} checked={value} />
    </Label>
  )
}

ToggleActiveGuide.propTypes = {
  onChange: func,
  value: bool
}

ToggleActiveGuide.defaultProps = {}

export default ToggleActiveGuide
