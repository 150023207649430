import React from 'react'
import { useApolloClient } from '@apollo/client'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu } from '@headlessui/react'

import Flag from '../shared/flag'
import MenuTransition from '../shared/dropdown/menu-transition'
import MenuItem from '../shared/dropdown/menu-item'
import Loader from '../loader'

import {
  useAuthContext,
  useUserContext,
  useMarketResearcherContext
} from '../../contexts'
import { isAdmin } from '../../utils'

import { logout } from '../../utils/auth'
import { UserCircleIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { AVAILABLE_LOCALE_TRANSLATIONS } from '../../i18n'

function NavMarketResearcher() {
  const client = useApolloClient()
  const { auth } = useAuthContext()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('topNav')
  const { user } = useUserContext()
  const { locale: localeParam } = useParams()
  const currentLanguage = i18n.language
  const isAuthenticated = auth?.isLoggedIn
  const hasAdmin = isAuthenticated && isAdmin(user?.role)
  const { mrSites, mrSitesLoading } = useMarketResearcherContext()

  const handleChangeLanguage = (langCode) => {
    i18n.changeLanguage(langCode)
    localStorage.setItem('siteLanguage', langCode)
  }

  const handleChangeSite = (locale) => {
    navigate(`/market-researcher/${locale}`)
  }

  const handleLogout = () => {
    logout(() => {
      try {
        client.clearStore()
      } catch (error) {
        console.log(error.message)
      }
    })

    navigate('/login')
  }

  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex items-center">
          <img
            className="h-10 w-auto sm:h-12"
            src="/businesswith-logo.svg"
            alt="BusinessWith Logo"
          />
        </div>

        <div className="flex-1 flex items-center justify-center">
          {mrSitesLoading ? (
            <Loader />
          ) : (
            <Menu as="div" className="relative">
              <div className="flex flex-col items-center sm:flex-row">
                <span className="text-base mr-2 text-gray-700 sm:text-lg">
                  {t('topNav:researcher.nowResearchingCountry')}
                </span>
                <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 px-2">
                  <Flag locale={localeParam} height="16px" />
                  <span className="sr-only">Open menu</span>
                  <span className="text-sm text-gray-700 px-1 sm:text-base">
                    {t(`shared:localeToCountry.${localeParam}`)}
                  </span>
                  <ChevronDownIcon
                    className="h-4 w-4 rounded-full text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <MenuTransition>
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {mrSites.map((mrSiteLocale) => {
                    if (mrSiteLocale === localeParam) {
                      return null
                    }

                    return (
                      <MenuItem
                        key={mrSiteLocale}
                        className="flex gap-2"
                        onClick={() => handleChangeSite(mrSiteLocale)}
                      >
                        <a>
                          <Flag locale={mrSiteLocale} height="16px" />
                          <span className="text-gray-700">
                            {t(`shared:localeToCountry.${mrSiteLocale}`)}
                          </span>
                        </a>
                      </MenuItem>
                    )
                  })}
                </Menu.Items>
              </MenuTransition>
            </Menu>
          )}
        </div>

        <div className="ml-4 flex items-center md:ml-6 gap-2">
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="max-w-xs p-1 bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <span className="sr-only">Open user menu</span>
                <UserCircleIcon
                  className="h-6 w-6 rounded-full text-gray-400 hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <MenuTransition>
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {hasAdmin && (
                  <MenuItem>
                    <Link to="/">{t('topNav:researcher.goBackToSystem')}</Link>
                  </MenuItem>
                )}

                {AVAILABLE_LOCALE_TRANSLATIONS.map((locale) => {
                  if (locale === currentLanguage) {
                    return null
                  }

                  return (
                    <MenuItem key={locale} onClick={() => handleChangeLanguage(locale)}>
                      <a>{t('topNav:researcher.setLanguageToLocale', { locale })}</a>
                    </MenuItem>
                  )
                })}

                <MenuItem onClick={handleLogout}>
                  <a>{t('topNav:userMenu.logout')}</a>
                </MenuItem>
              </Menu.Items>
            </MenuTransition>
          </Menu>
        </div>
      </div>
    </div>
  )
}

export default NavMarketResearcher
