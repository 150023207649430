import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'

const GQL_FETCH_CATEGORIES_MR = gql`
  query ListCategoriesMR($locale: String!) {
    mrListCategories(locale: $locale) {
      dbName
      locales
    }
  }
`

const EMPTY_ARR = []

export default function useFetchCategories(fetchPolicy) {
  const { locale } = useParams()

  const { data, loading } = useQuery(GQL_FETCH_CATEGORIES_MR, {
    fetchPolicy: fetchPolicy || null,
    variables: {
      locale
    },
    skip: !locale
  })

  return {
    mrCategories: data?.mrListCategories || EMPTY_ARR,
    mrCategoriesLoading: loading
  }
}
