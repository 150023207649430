import React from 'react'
import { object } from 'prop-types'
import cx from 'classnames'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import Label from '../../shared/label'

const VerticalCustomerTypeText = styled.span`
  writing-mode: vertical-rl;
  line-height: 12px;
  transform: rotate(180deg);
  font-style: italic;
  font-size: 12px;
`

const companySizeOrder = {
  MICRO: 0,
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3
}

const clientCompanySizeSorted = (clientCompanySize) =>
  clientCompanySize
    .slice()
    .sort((a, b) => {
      if (companySizeOrder[a] < companySizeOrder[b]) {
        return -1
      }
      if (companySizeOrder[a] > companySizeOrder[b]) {
        return 1
      }
      return 0
    })
    .map((size) => {
      switch (size) {
        case 'MICRO':
          return 'XS'
        case 'SMALL':
          return 'S'
        case 'MEDIUM':
          return 'M'
        case 'LARGE':
          return 'L'

        default:
          return 'MISSING'
      }
    })

const ResultRow = ({ result }) => {
  const { t } = useTranslation('adminPages')
  const { system, productFunctionalities, systemCharacteristics, total } = result
  const { systemSiteData } = system

  const visibleInGuide = systemSiteData.visibility === 'SITE_AND_GUIDE'
  const isCustomer = systemSiteData.customerType === 'CUSTOMER'
  const isTrial = systemSiteData.customerType === 'TRIAL'
  const isFree = !isCustomer && !isTrial

  return (
    <tr
      key={system.id}
      className={cx('text-gray-600 text-sm', {
        'bg-slate-200': !visibleInGuide
      })}
    >
      <td
        className={cx('w-4 border-r', {
          'bg-slate-200': !visibleInGuide,
          'bg-green-200': visibleInGuide && isCustomer,
          'bg-yellow-200': visibleInGuide && isTrial,
          'bg-white': visibleInGuide && isFree
        })}
      >
        <VerticalCustomerTypeText>{systemSiteData.customerType}</VerticalCustomerTypeText>
      </td>

      <td className="py-4 pl-4" width="30%">
        <div className="flex flex-col gap-3 ">
          <Label title={t('adminSimulatorGuide.table.header.systemName')} marginless>
            {systemSiteData.systemName} - {result.companyName}
          </Label>

          <Label title={t('adminSimulatorGuide.table.header.companySize')} marginless>
            <span>{clientCompanySizeSorted(system.clientCompanySize).join('-')}</span>
            {system.clientCompanySizeGuide.length > 0 && (
              <span className="font-semibold ml-1">
                ({clientCompanySizeSorted(system.clientCompanySizeGuide).join('-')})
              </span>
            )}
          </Label>
        </div>
      </td>

      <td className="py-4">
        <div className="flex flex-col gap-3 ">
          <Label title={t('adminSimulatorGuide.table.header.total')} marginless>
            {total.answersPoints} ({total.systemPoints}) / {total.maxCategoryPoints}
          </Label>

          <Label title={t('adminSimulatorGuide.table.header.boost')} marginless>
            {systemSiteData.guideBoost || '-'}
          </Label>
        </div>
      </td>

      <td className="py-4">
        <div className="flex flex-col gap-3">
          <Label title={t('adminSimulatorGuide.table.header.properties')} marginless>
            {systemCharacteristics.answersPointsNoBoost} (
            {systemCharacteristics.systemPointsNoBoost}) /{' '}
            {systemCharacteristics.maxCategoryPointsNoBoost}
          </Label>

          <Label
            title={t('adminSimulatorGuide.table.header.productFunctions')}
            marginless
          >
            {productFunctionalities.answersPointsNoBoost} (
            {productFunctionalities.systemPointsNoBoost}) /{' '}
            {productFunctionalities.maxCategoryPointsNoBoost}
          </Label>
        </div>
      </td>
      <td className="py-4 mr-4">
        <div className="flex flex-col gap-3 ">
          <Label title={t('adminSimulatorGuide.table.licenses')} marginless>
            {systemSiteData.licenses?.min || systemSiteData.licenses?.max
              ? `${systemSiteData.licenses?.min || 'No min'} - ${
                  systemSiteData.licenses?.max || 'No max'
                }`
              : '-'}
          </Label>

          <Label title={t('adminSimulatorGuide.table.revenue')} marginless>
            {systemSiteData.revenue?.min || systemSiteData.revenue?.max
              ? `${systemSiteData.revenue?.min || 'No min'} - ${
                  systemSiteData.revenue?.max || 'No max'
                }`
              : '-'}
          </Label>
        </div>
      </td>
    </tr>
  )
}

const ResultList = ({ guideSimulationResult }) => {
  const { t } = useTranslation()
  const { results } = guideSimulationResult

  if (!results.length) {
    return <span>{t('content:noResultsToShow')}</span>
  }

  return (
    <div className="px-4 md:px-0">
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="w-full divide-y divide-gray-300 table-fixed">
                <tbody className="divide-y divide-gray-200 bg-white">
                  {results.map((result) => {
                    return <ResultRow result={result} key={result.system.id} />
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ResultList.propTypes = {
  guideSimulationResult: object.isRequired
}

ResultList.defaultProps = {}

export default ResultList
