import React, { useState, useCallback } from 'react'
import { getUser, updateUser, handleLogin, updateAcceptUser } from '../utils/auth'

const UserContext = React.createContext({})

export const UserContextProvider = ({ children }) => {
  const user = getUser()

  const setUpdateUser = useCallback((obj) => {
    const updatedUser = updateUser(obj)
    setState((prevState) => ({ ...prevState, user: updatedUser }))
  }, [])

  const setHandleLogin = useCallback((obj) => {
    const updatedUser = handleLogin(obj)
    setState((prevState) => ({ ...prevState, user: updatedUser }))
  }, [])

  const setUpdateAcceptUser = useCallback((obj) => {
    const updatedUser = updateAcceptUser(obj)
    setState((prevState) => ({ ...prevState, user: updatedUser }))
  }, [])

  const initState = {
    user: user,
    updateUser: setUpdateUser,
    handleLogin: setHandleLogin,
    updateAcceptUser: setUpdateAcceptUser
  }

  const [state, setState] = useState(initState)

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>
}

export const useUserContext = () => React.useContext(UserContext)
