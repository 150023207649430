import React, { Fragment, useState, useCallback } from 'react'

import { Transition } from '@headlessui/react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

import { useTranslation } from 'react-i18next'

const generateRandom = () => {
  return (Math.random() + 1).toString(36).substring(7)
}

function promiseTimeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const OverlayNotificationContext = React.createContext()

export const OverlayNotificationProvider = ({ children }) => {
  const [queue, setQueue] = useState([])
  const { t } = useTranslation()

  const closeAndRemoveNotification = useCallback(async (notificationId) => {
    setQueue((prevQueue) => {
      const indexToRemove = prevQueue.findIndex(
        (queueItem) => queueItem.id === notificationId
      )

      if (indexToRemove === -1) {
        return prevQueue
      }

      const prevQueueClone = [...prevQueue]
      prevQueueClone[indexToRemove].visible = false
      return prevQueueClone
    })

    await promiseTimeout(500)

    setQueue((prevQueue) =>
      prevQueue.filter((queueItem) => queueItem.id !== notificationId)
    )
  }, [])

  const queueNotification = useCallback(
    async (notificationData) => {
      const randomId = generateRandom()

      notificationData.id = randomId
      notificationData.visible = false

      setQueue((prevQueue) => [notificationData, ...prevQueue])

      await promiseTimeout(50)

      setQueue((prevQueue) => {
        const prevQueueClone = [...prevQueue]
        const indexToChange = prevQueueClone.findIndex(
          (queueItem) => queueItem.id === randomId
        )
        prevQueueClone[indexToChange].visible = true
        return prevQueueClone
      })

      if (notificationData.error) {
        await promiseTimeout(10000)
      } else {
        await promiseTimeout(3000)
      }

      closeAndRemoveNotification(randomId)
    },
    [closeAndRemoveNotification]
  )

  const contextValues = {
    queueNotification
  }

  return (
    <OverlayNotificationContext.Provider value={contextValues}>
      {children}

      {/* Notification Code */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        style={{ zIndex: 1000 }}
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {queue.map((notification) => {
            return (
              <Transition
                key={notification.id}
                show={notification.visible}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {notification.success && (
                          <CheckCircleIcon
                            className="h-6 w-6 text-green-400"
                            aria-hidden="true"
                          />
                        )}
                        {notification.error && (
                          <XCircleIcon
                            className="h-6 w-6 text-red-400"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {notification.success && (
                            <>
                              {notification.title
                                ? notification.title
                                : notification.titleTid
                                ? t(notification.titleTid)
                                : `${t('content:success.successfullySaved')}!`}
                            </>
                          )}

                          {notification.error &&
                            `${t('content:error.somethingWentWrong')}.`}
                        </p>

                        {(notification.text || notification.textTid) && (
                          <p className="mt-1 text-sm text-gray-500">
                            {notification.text ? notification.text : ''}
                            {notification.textTid ? t(notification.textTid) : ''}
                          </p>
                        )}
                      </div>
                      <div className="ml-4 flex-shrink-0 flex">
                        <button
                          className="bg-white rounded-md inline-flex text-gray-400 border-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => closeAndRemoveNotification(notification.id)}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            )
          })}
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        </div>
      </div>
    </OverlayNotificationContext.Provider>
  )
}

export const useOverlayNotificationContext = () =>
  React.useContext(OverlayNotificationContext)
