import React, { useState } from 'react'
import { func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Label from '../../../../shared/label'
import { Select } from '../../../../shared/select'

const OPTIONS = [
  {
    label: 'Radio',
    value: 'radio'
  },
  {
    label: 'Checkbox',
    value: 'checkbox'
  },
  {
    label: 'Multiple',
    value: 'multiple'
  }
]

const TypeQuestionSelect = ({ onChange, type }) => {
  const { t } = useTranslation('adminPages')
  const [loading, setLoading] = useState(false)

  const handleChangeTypeQuestion = (updatedValue) => {
    setLoading(true)

    onChange('type', updatedValue).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Label title={t('adminManageGuide.guide.question.type')} marginless>
      <Select
        options={OPTIONS}
        onChange={handleChangeTypeQuestion}
        rawChangeEvent={false}
        value={type}
        loading={loading}
        isSearchable={false}
      />
    </Label>
  )
}

TypeQuestionSelect.propTypes = {
  onChange: func.isRequired,
  type: string
}

TypeQuestionSelect.defaultProps = {}

export default TypeQuestionSelect
