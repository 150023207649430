import React, { useState, useEffect, useMemo } from 'react'
import useKeyPress from '../hooks/useKeyPress'

import { useUserContext } from './userContext'
import { isAdmin } from '../utils'

const AdminFieldsContext = React.createContext({})

export const AdminFieldsContextProvider = ({ children }) => {
  const [adminFieldsVisible, setAdminFieldsVisible] = useState(false)
  const [adminSearchVisible, setAdminSearchVisible] = useState(false)

  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const ctrlPressed = useKeyPress('Control')
  const bPressed = useKeyPress('b')
  const BPressed = useKeyPress('B')
  const spacebarPressed = useKeyPress(' ')

  useEffect(() => {
    if (!hasAdmin) {
      return
    }

    if (ctrlPressed && spacebarPressed) {
      setAdminSearchVisible((prevValue) => !prevValue)
    }
  }, [hasAdmin, ctrlPressed, spacebarPressed])

  // Controls for toggling admin fields
  useEffect(() => {
    if (!hasAdmin) {
      return
    }

    if (ctrlPressed && (bPressed || BPressed)) {
      setAdminFieldsVisible((prevValue) => !prevValue)
    }
  }, [hasAdmin, ctrlPressed, bPressed, BPressed])

  const contextValues = useMemo(() => {
    return {
      adminFieldsVisible,
      adminSearchVisible,
      setAdminSearchVisible
    }
  }, [adminFieldsVisible, adminSearchVisible])

  return (
    <AdminFieldsContext.Provider value={contextValues}>
      {children}
    </AdminFieldsContext.Provider>
  )
}

export const useAdminFieldsContext = () => React.useContext(AdminFieldsContext)
