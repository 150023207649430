import React, { useEffect, useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import useCreateAnswer from '../../../../../hooks/manage-guides/useCreateAnswer'

import EditableI18nTextarea from '../../../../shared/editable-textarea-i18n-input'

const CreateAnswer = ({ questionId, locale, disabled }) => {
  const { t } = useTranslation('adminPages')
  const { createAnswer, createAnswerLoading } = useCreateAnswer()
  const [creator, setCreator] = useState(false)

  useEffect(() => {
    if (disabled) {
      handleReset()
    }
  }, [disabled])

  const handleCreateAnswer = (id, text) => {
    createAnswer(id, text, () => {
      setCreator(false)
    })
  }

  const handleStartCreate = () => {
    if (!disabled) {
      setCreator(true)
    }
  }

  const handleReset = () => {
    setCreator(false)
  }

  return (
    <div className="mt-2">
      {creator ? (
        <EditableI18nTextarea
          id={questionId}
          locale={locale}
          creatorMode={true}
          onCancel={handleReset}
          onSave={handleCreateAnswer}
          loading={createAnswerLoading}
        />
      ) : (
        <div
          className={cx(
            'border w-full h-16 flex justify-center items-center bg-slate-100 rounded',
            {
              'cursor-not-allowed': disabled,
              'cursor-pointer': !disabled
            }
          )}
          onClick={handleStartCreate}
        >
          {`+ ${t('adminManageGuide.guide.question.edit.answers.createAnswer')}`}
        </div>
      )}
    </div>
  )
}

CreateAnswer.propTypes = {
  questionId: string.isRequired,
  locale: string.isRequired,
  disabled: bool
}

CreateAnswer.defaultProps = {}

export default CreateAnswer
