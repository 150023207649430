import { useCallback, useEffect, useState } from 'react'

export default function useKeyPress(targetKey, activeOverride, fn) {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = useCallback(
    ({ key }) => {
      if (key === targetKey && !keyPressed) {
        setKeyPressed(true)
        fn && fn()
      }
    },
    [targetKey, keyPressed, fn]
  )

  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    },
    [targetKey]
  )

  useEffect(() => {
    if (activeOverride === undefined || activeOverride) {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)
    } else {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
      setKeyPressed(false)
    }

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [downHandler, upHandler, activeOverride])

  return keyPressed
}
