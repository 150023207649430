import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useManageSystemContext } from '../context'
import { useTranslation } from 'react-i18next'

import Subsection from '../../shared/subsection'
import LogoSection from './media/logo-section'
import ScreenshotSection from './media/screenshot-section'
import VideoSection from './media/video-section'
import DocumentsSection from './media/document-section'

import ImagePreviewModal from './media/image-preview-modal'

const MediaForm = () => {
  const { mediaAssetsData, setMediaAssetsData, selectedCountries } = useManageSystemContext()
  const { t } = useTranslation()

  /**
   * @param {String} action UPLOAD / DELETE / EDIT
   * @param {String} type LOGO / SCREENSHOT / VIDEO / DOCUMENT / PITCH
   * @param {Object} data Data object to save with file, url etc
   */
  const handleChangeAsset = (action, type, data) => {
    // Handle upload for images
    if (action === 'UPLOAD' && ['LOGO', 'SCREENSHOT', 'DOCUMENT'].includes(type)) {
      // No files, something went wrong, abort
      if (!data.files.length) {
        return
      }

      setMediaAssetsData((prevState) => {
        const newAssetsArray = data.files.map((file) => {
          file.src = URL.createObjectURL(file)

          return {
            id: uuidv4(),
            action,
            file,
            locales: [... selectedCountries]
          }
        })

        if (!prevState[type]) {
          prevState[type] = []
        }

        const newTypeArray = [...prevState[type], ...newAssetsArray]
        return { ...prevState, [type]: newTypeArray }
      })
    }

    // Handle "upload" (create) for videos
    if (action === 'UPLOAD' && (type === 'VIDEO' || type === 'PITCH')) {
      setMediaAssetsData((prevState) => {
        if (!prevState[type]) {
          prevState[type] = []
        }

        const newTypeArray = [...prevState[type], { id: uuidv4(), locales: [... selectedCountries], action, ...data }]
        return { ...prevState, [type]: newTypeArray }
      })
    }

    // Handle delete
    if (action === 'DELETE') {
      const assetId = data.id
      const deletedAssetIndex = mediaAssetsData[type].findIndex(
        (asset) => asset.id === assetId
      )

      // Should not happen but...
      if (deletedAssetIndex === -1) {
        return
      }

      const newState = { ...mediaAssetsData }

      // Not yet saved to db, only remove it from the array
      if (mediaAssetsData[type][deletedAssetIndex].action === 'UPLOAD') {
        if (type !== 'VIDEO' || type !== 'PITCH') {
          URL.revokeObjectURL(mediaAssetsData[type][deletedAssetIndex].file.src)
        }

        newState[type].splice(deletedAssetIndex, 1)
        setMediaAssetsData(newState)
      } else {
        // Already in DB, mark for deletion
        newState[type][deletedAssetIndex].action = 'DELETE'
        setMediaAssetsData(newState)
      }
    }

    // Handle reorder
    if (action === 'REORDER') {
      const { direction, id: assetId } = data
      const assetIndex = mediaAssetsData[type].findIndex((asset) => asset.id === assetId)

      if (assetIndex === -1) {
        return
      }

      const newTypeArray = mediaAssetsData[type]
      const assetCopy = newTypeArray[assetIndex]

      newTypeArray.splice(assetIndex, 1)

      // Move left
      if (direction === 'L') {
        newTypeArray.splice(assetIndex - 1, 0, assetCopy)
      } else if (direction === 'R') {
        // Move right
        newTypeArray.splice(assetIndex + 1, 0, assetCopy)
      }

      setMediaAssetsData({ ...mediaAssetsData, [type]: newTypeArray })
    }

    if (action === 'SITE_SELECT') {
      const { assetId, locale, boolValue } = data
      const newState = { ...mediaAssetsData }
      const assetIndex = newState[type].findIndex((asset) => asset.id === assetId)
      
      if (!newState[type][assetIndex]?.locales) {
        newState[type][assetIndex].locales = []
      }

      if (boolValue) {
        // Add
        newState[type][assetIndex].locales.push(locale)
      } else {
        // Remove
        const localeIndex = newState[type][assetIndex].locales.findIndex(
          (currLocale) => currLocale === locale
        )

        newState[type][assetIndex].locales.splice(localeIndex, 1)
      }

      if (!newState[type][assetIndex].action) {
        newState[type][assetIndex].action = 'EDIT'
      }

      setMediaAssetsData(newState)
    }
  }

  const getAssets = (assetType) => {
    return mediaAssetsData[assetType]?.filter((asset) => asset.action !== 'DELETE') || []
  }

  return (
    <>
      <Subsection title={t('forms:system.assets.section.logo')}>
        <LogoSection onChange={handleChangeAsset} assets={getAssets('LOGO')} />
      </Subsection>

      <Subsection title={t('forms:system.assets.section.screenshots')}>
        <ScreenshotSection
          onChange={handleChangeAsset}
          assets={getAssets('SCREENSHOT')}
        />
      </Subsection>

      <Subsection title={t('forms:system.assets.section.videos.title')}>
        <VideoSection onChange={handleChangeAsset} assetType="VIDEO" assets={getAssets('VIDEO')} />
      </Subsection>

      <Subsection title={t('forms:system.assets.section.pitch.title')}>
        <VideoSection onChange={handleChangeAsset} assetType="PITCH" assets={getAssets('PITCH')} />
      </Subsection>

      <Subsection title={t('forms:system.assets.section.documentsTitle')}>
        <DocumentsSection onChange={handleChangeAsset} assets={getAssets('DOCUMENT')} />
      </Subsection>

      <ImagePreviewModal />
    </>
  )
}

MediaForm.propTypes = {}

MediaForm.defaultProps = {}

export default MediaForm
