import React from 'react'
import { bool, func, oneOfType, string } from 'prop-types'

import { useUserContext } from '../../../../contexts'
import { useTranslation } from 'react-i18next'
import useDeleteReviewComment from '../hooks/useDeleteReviewComment'

import Dialog from '../../../shared/dialog'
import { isAdmin } from '../../../../utils'

const DeleteReviewCommentDialog = ({ reviewDataId, onClose, onReviewsChanged }) => {
  const { t } = useTranslation()
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)
  const { deleteReviewComment, deleteReviewCommentLoading } = useDeleteReviewComment()

  const handleDeleteTranslation = () => {
    deleteReviewComment(reviewDataId, () => {
      // If review comment was changed by Admin, we need to refetch
      // data to see the changes as we do not utilize Apollo cache for Admins
      if (hasAdmin) {
        onReviewsChanged()
      }

      onClose(false)
    })
  }

  return (
    <Dialog
      isOpen={!!reviewDataId}
      title={t('content:delete')}
      confirmText={t('content:delete')}
      onConfirm={handleDeleteTranslation}
      onClose={onClose}
      loading={deleteReviewCommentLoading}
    />
  )
}

DeleteReviewCommentDialog.propTypes = {
  reviewDataId: oneOfType([string, bool]),
  onClose: func.isRequired,
  onReviewsChanged: func.isRequired
}

DeleteReviewCommentDialog.defaultProps = {}

export default DeleteReviewCommentDialog
