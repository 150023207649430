import React from 'react'
import classNames from 'classnames'
import { isAdmin } from '../../../utils'

import { MailIcon } from '@heroicons/react/solid'

const EmailStatus = ({ emailStatus, ignoreOnEmpty = false, styleing }) => {
  if (ignoreOnEmpty && emailStatus.email == null) {
    return <></>
  }

  let colorClass = 'text-green-600'

  if (emailStatus.score < 0 && ignoreOnEmpty) {
    return <></>
  } else if (emailStatus.score < 0) {
    colorClass = 'text-gray-600'
  } else if (emailStatus.score == 0) {
    colorClass = 'text-red-600'
  } else if (emailStatus.status === '') {
    return <></>
  } else if (emailStatus.score <= 25) {
    colorClass = 'text-orange-400' // Bad validation but good delivery
  } else if (emailStatus.score <= 50) {
    colorClass = 'text-orange-200' // Good validation but bad delivery
  }

  if (isAdmin && emailStatus.status) {
    return (
      <span className="group relative">
        <MailIcon
          className={classNames(`${styleing} ${colorClass}`)}
          aria-hidden="true"
        />
        <span className="absolute top-0 scale-0 transition-all rounded bg-gray-800 p-1 text-xs text-white group-hover:scale-100 z-auto">
          {emailStatus.status}
        </span>
      </span>
    )
  }

  return (
    <MailIcon className={classNames(`${styleing} ${colorClass}`)} aria-hidden="true" />
  )
}

export default EmailStatus
