import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'

const GQL_TEST_WEBHOOK = gql`
  mutation TestWebhook($vendorId: ID!, $webhookId: ID!, $event: String!) {
    testWebhook(vendorId: $vendorId, webhookId: $webhookId, event: $event) {
      id
    }
  }
`

export default function useTestWebhook(vendorId) {
  const [testWebhook] = useMutation(GQL_TEST_WEBHOOK)
  const testWebhookCallback = useCallback(
    (webhookId, event) => {
      return testWebhook({
        variables: { vendorId, webhookId, event }
      })
    },
    [vendorId, testWebhook]
  )

  return {
    testWebhook: testWebhookCallback
  }
}
