import React from 'react'
import { Link } from 'react-router-dom'
import PrivacyPolicy from '../../data/privacyPolicy'
import { PageBody } from '../../components/page-body'

// import styles from '../../styles/page.module.css';

function PrivacyPolicyPage() {
  return (
    <PageBody>
      <div style={{ maxWidth: '800px', margin: '0 auto', padding: '2rem' }}>
        <PrivacyPolicy />

        <Link to="/legal">
          <span>&larr; Legal</span>
        </Link>
      </div>
    </PageBody>
  )
}

export default PrivacyPolicyPage
