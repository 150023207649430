import React from 'react'
import { bool, func, object, string } from 'prop-types'
import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { CloudUploadIcon } from '@heroicons/react/outline'
import Tile from './tile'

const Dropzone = ({ assetType, onDrop, multiple, accept }) => {
  const { t } = useTranslation()

  const handleDrop = (files) => {
    onDrop('UPLOAD', assetType, { files })
  }

  const { isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: handleDrop,
    multiple
  })

  return (
    <Tile
      className={classnames(
        'flex justify-center items-center flex-col hover:bg-gray-200 active:bg-gray-300 transition-colors cursor-pointer',
        {
          'bg-red-200': isDragReject,
          'bg-green-200': isDragAccept
        }
      )}
      {...getRootProps()}
    >
      <div className="flex flex-col items-center">
        <input {...getInputProps()} />
        <CloudUploadIcon className="w-8 h-8 text-gray-500 mb-1" />

        {isDragAccept ? (
          <span className="text-gray-700 text-sm italic text-center">
            {t('forms:system.assets.dropzone.image.releaseToUpload')}
          </span>
        ) : isDragReject ? (
          <span className="text-gray-700 text-sm italic text-center">
            {t('forms:system.assets.dropzone.image.onlyImageFormats')}
          </span>
        ) : (
          <span className="text-gray-700 text-sm italic text-center">
            {assetType === 'DOCUMENT'
              ? t('forms:system.assets.dropzone.document.upload')
              : t('forms:system.assets.dropzone.image.upload')}
          </span>
        )}
      </div>
    </Tile>
  )
}

Dropzone.propTypes = {
  onDrop: func.isRequired,
  assetType: string.isRequired,
  accept: object.isRequired,
  multiple: bool
}

Dropzone.defaultProps = {
  multiple: true,
  accept: {
    'image/jpeg': [],
    'image/png': [],
    'image/webp': []
  }
}

export default Dropzone
