import { gql, useQuery } from '@apollo/client'

const GET_ALL_REDIRECTS = gql`
  query GetAllRedirects($page: Int, $limit: Int, $search: String) {
    appAdminGetAllRedirects(page: $page, limit: $limit, search: $search) {
      data {
        id
        createdAt
        updatedAt
        from
        to
        locale
        type
      }
      totalCount
    }
  }
`

const useFetchRedirects = (page = 1, limit = 10, search) => {
  const { data, loading, error } = useQuery(GET_ALL_REDIRECTS, {
    variables: { page, limit, search }
  })
  return {
    data: data?.appAdminGetAllRedirects?.data || [],
    totalCount: data?.appAdminGetAllRedirects?.totalCount || 0,
    loading,
    error
  }
}

export default useFetchRedirects
