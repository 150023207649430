import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { array } from 'prop-types'
import cx from 'classnames'

import { useNavigate } from 'react-router-dom'

import CreateGuideDialog from '../create-guide-dialog'
import Flag from '../../../shared/flag'
import Button from '../../../shared/button'

import { Checkboxes } from '../../../form/inputs'

const ManageGuides = ({ guides }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedLocales, setSelectedLocales] = useState([])

  const INPUT_LOCALE = 'selected_locales'
  let availableLocales = []

  if (!guides?.length) {
    return null
  }
  const currentLanguageCompare = localStorage.getItem('siteLanguage')
    ? localStorage.getItem('siteLanguage').split('_')[0]
    : 'en'

  guides.forEach((element) => {
    if (element.locales) {
      availableLocales = [...new Set([...availableLocales, ...element.locales])]
    }
  })

  const guidesSorted = Object.values(guides).sort((guideA, guideB) => {
    const { categoriesString: categoriesStringA } = guideA
    const { categoriesString: categoriesStringB } = guideB
    return categoriesStringA.localeCompare(categoriesStringB, currentLanguageCompare, {
      sensitivity: 'base'
    })
  })

  const filteredGuides = guidesSorted
    .filter((guide) => {
      const { categoriesString, categories } = guide
      const categoryDbName = categories[0] ? categories[0] : ''

      return (
        searchFilter.trim() == '' ||
        searchFilter.length < 2 ||
        categoriesString.toLowerCase().indexOf(searchFilter.toLocaleLowerCase()) > -1 ||
        categoryDbName.toLowerCase().indexOf(searchFilter.toLocaleLowerCase()) > -1
      )
    })
    .filter((guide) => {
      if (selectedLocales.length === 0) {
        return true
      }

      return selectedLocales.every((locale) => guide.locales.includes(locale))
    })

  const navigateToGuide = (guide) => {
    navigate(`/admin-manage-guides/${guide.id}`)
  }

  const navigateToCommonQuestions = () => {
    navigate('/admin-manage-guides/common-questions')
  }

  const handleGuideFilter = (filter) => {
    setSearchFilter(filter)
  }

  const handleLocaleFilter = (e) => {
    if (e.target.checked) {
      setSelectedLocales([
        ...selectedLocales,
        ...[e.target.id.replaceAll(INPUT_LOCALE + '--', '')]
      ])
    } else {
      setSelectedLocales(
        selectedLocales.filter(
          (locale) => locale !== e.target.id.replaceAll(INPUT_LOCALE + '--', '')
        )
      )
    }
  }

  return (
    <div className="grid gap-3">
      <div className="flex mb-4 justify-between">
        <Button mode="default" onClick={navigateToCommonQuestions}>
          TODO Common Questions
        </Button>
        <CreateGuideDialog onGuideCreated={navigateToGuide} />
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2 mt-6">
          <div className="relative mr-4 py-1 flex items-start">
            <legend className="text-base font-medium text-gray-900">
              {t('content:search')}
            </legend>
          </div>
          <div className="flex items-center h-5">
            <input
              type="text"
              name="name"
              id="userCreateName"
              className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              onKeyUp={(e) => handleGuideFilter(e.target.value)}
            />
          </div>
        </div>
        <div className="col-span-1">
          <Checkboxes
            field={{
              name: t('content:markets'),
              id: INPUT_LOCALE,
              values: availableLocales.map((locale) => ({
                id: locale,
                title: <Flag key={locale} locale={locale} height="16px" />
              }))
            }}
            data={selectedLocales}
            handleFieldChange={handleLocaleFilter}
          />
        </div>
      </div>

      {filteredGuides.map((guide) => {
        const { id, active, categoriesString, locales, categories } = guide

        return (
          <div
            key={id}
            className={cx(
              'p-3 border border-gray-300 shadow-sm bg-white cursor-pointer rounded flex flex-col border-l-8 gap-1',
              {
                'border-l-red-600': !active,
                'border-l-green-600': active
              }
            )}
            onClick={() => navigateToGuide(guide)}
          >
            <span className="font-medium">
              {categoriesString}{' '}
              {categories[0] &&
              categoriesString.toUpperCase().replaceAll(/[_-\s]+/gm, '') !=
                categories[0].replaceAll(/[_-]+/gm, '')
                ? `(` + categories[0] + `)`
                : ''}
            </span>
            <span className="flex gap-1">
              {locales?.length > 0 &&
                locales.map((locale) => (
                  <Flag key={locale} locale={locale} height="16px" />
                ))}
            </span>
          </div>
        )
      })}
    </div>
  )
}

ManageGuides.propTypes = {
  guides: array
}

ManageGuides.defaultProps = {}

export default ManageGuides
