import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const UPDATE_QUESTION_GENERIC_GQL = gql`
  mutation AdminUpdateQuestion($questionId: ID!, $data: GenericQuestionInput!) {
    updateQuestionGeneric(questionId: $questionId, data: $data) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useUpdateQuestionGeneric() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateQuestion, { loading }] = useMutation(UPDATE_QUESTION_GENERIC_GQL)

  const updateQuestionCallback = useCallback(
    (questionId, data) => {
      return updateQuestion({
        variables: {
          questionId,
          data
        },
        onCompleted: () => {
          showSuccessNotification()
        },
        onError: showErrorNotification
      })
    },
    [updateQuestion, showSuccessNotification, showErrorNotification]
  )

  return {
    updateQuestion: updateQuestionCallback,
    updateQuestionLoading: loading
  }
}
