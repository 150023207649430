import React from 'react'
import { oneOf, string } from 'prop-types'
import cx from 'classnames'

const Chip = ({ children, text, type }) => {
  return (
    <div
      className={cx(
        'flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full ',
        {
          'text-gray-600 bg-blue-200': type === 'primary',
          'text-gray-600 bg-orange-300': type === 'warning',
          'text-gray-600 bg-green-300': type === 'success',
          'text-gray-600 border': type === 'outlined'
        }
      )}
    >
      <div className="text-xs font-normal leading-none max-w-full flex-initial">
        {text}
      </div>

      {children}
    </div>
  )
}

Chip.propTypes = {
  text: string,
  type: oneOf(['primary', 'warning', 'success', 'outlined'])
}

Chip.defaultProps = {
  type: 'primary'
}

export default Chip
