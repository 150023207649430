import React, { memo, useCallback } from 'react'
import { array, func, string } from 'prop-types'

import { useSelectContext } from '../context'
import useKeyPress from '../../../../../hooks/useKeyPress'

import Input from '../shared/input'
import VirtualizedList from '../shared/virtualized-list'
import ListItem from '../shared/list-item'

import SelectedItem from './selected-item-chip'

const Multiselect = ({
  options,
  value,
  onChange,
  placeholder,
  errorText,
  id,
  renderListItem,
  loading,
  disabled,
  isSearchable
}) => {
  const {
    wrapperRef,
    inputRef,
    dropdownOpen,
    visibleOptions,
    searchQuery,
    setSearchQuery,
    onSelect,
    onFocus,
    onOpen,
    focusedItemId
  } = useSelectContext()

  const handleSelect = useCallback(
    (selectedValue) => {
      onSelect()

      if (id) {
        onChange(id, [...value, selectedValue], 'ADD', selectedValue)
      } else {
        onChange([...value, selectedValue], 'ADD', selectedValue)
      }
    },
    [onSelect, value, id, onChange]
  )

  // Select options when pressed enter
  useKeyPress('Enter', dropdownOpen, () => {
    if (focusedItemId) {
      handleSelect(focusedItemId)
    }
  })

  const handleDelete = (deletedValue) => {
    const index = value.findIndex((el) => el === deletedValue)

    if (index === -1) {
      return
    }

    const newValues = [...value]
    const deletedObject = value[index]

    newValues.splice(index, 1)

    if (id) {
      onChange(id, newValues, 'DELETE', deletedObject)
    } else {
      onChange(newValues, 'DELETE', deletedObject)
    }
  }

  const renderItemContent = (index) => {
    const option = visibleOptions[index]

    if (renderListItem) {
      return renderListItem({ option, focusedItemId, handleSelect })
    }

    return (
      <ListItem
        key={option.value}
        value={option.value}
        label={option.label}
        labelTid={option.labelTid}
        onClick={handleSelect}
        active={option.value === focusedItemId}
      />
    )
  }

  return (
    <div
      ref={wrapperRef}
      className="w-full flex flex-col items-center mx-auto shadow-sm"
      tabIndex="0"
    >
      <div className="w-full flex flex-col items-center relative">
        <Input
          onChange={setSearchQuery}
          value={searchQuery || ''}
          onFocus={onFocus}
          ref={inputRef}
          onRequestOpen={onOpen}
          placeholder={placeholder}
          errorText={errorText}
          loading={loading}
          disabled={disabled}
          readOnly={!isSearchable}
        >
          {value.map((value) => {
            const option = options.find((option) => option.value === value)

            if (!option) {
              return null
            }

            return (
              <SelectedItem
                key={value}
                label={option.label}
                labelTid={option.labelTid}
                value={option.value}
                type={option.type}
                onDelete={handleDelete}
                disabled={disabled}
              />
            )
          })}
        </Input>

        {dropdownOpen && <VirtualizedList renderItemContent={renderItemContent} />}
      </div>
    </div>
  )
}

Multiselect.propTypes = {
  options: array,
  value: array,
  onChange: func,
  placeholder: string,
  errorText: string,
  renderListItem: func
}

Multiselect.defaultProps = {
  options: [],
  value: [],
  placeholder: 'placeholders.select.select',
  isSearchable: true
}

export default memo(Multiselect)
