import React from 'react'
import { StarIcon } from '@heroicons/react/solid'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { bool } from 'prop-types'

const reviewStarFields = [
  'reviewMetExpectations',
  'reviewUsablility',
  'reviewSupport',
  'reviewPriceRelation',
  'reviewFunctions',
  'reviewCollaborationSupplier',
  'reviewIntegrations',
  'reviewClientCustomizing'
]

const ReviewStars = ({ review, showNumbers }) => {
  const { t } = useTranslation('reviews')

  return (
    <div className="flex items-center xl:col-span-1">
      <div>
        {reviewStarFields.map((field) => {
          return (
            <div className="flex items-center" key={field}>
              {[0, 1, 2, 3, 4].map((rating) => (
                <StarIcon
                  key={rating}
                  className={cx(
                    review[field] > rating ? 'text-yellow-400' : 'text-gray-200',
                    'h-5 w-5 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
              ))}

              <p className="ml-3 text-sm text-gray-700">
                {t(`field.${field}`)}
                {showNumbers ? `: ${review[field]}` : ''}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ReviewStars.propTypes = {
  showNumbers: bool
}

ReviewStars.defaultProps = {
  showNumbers: false
}

export default ReviewStars
