import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

const REVIEW_SEND_VERIFICATION_EMAIL = gql`
  mutation AppSendReviewVerificationEmail($id: ID!) {
    appSendReviewVerificationEmail(id: $id)
  }
`

export default function useSendVerificationEmail() {
  const { showErrorNotification } = useOverlayNotification()

  const [
    sendVerificationEmailMutation,
    { loading: sendVerificationEmailLoading, called: sendVerificationEmailCalled }
  ] = useMutation(REVIEW_SEND_VERIFICATION_EMAIL, {
    onError: (err) => {
      const error = `${err}`.split(':').reverse()[0]
      showErrorNotification({ text: error })
    }
  })

  const sendVerificationEmailCallback = useCallback(
    (reviewId, onCompleted) => {
      return sendVerificationEmailMutation({
        variables: {
          id: reviewId
        },
        onCompleted
      })
    },
    [sendVerificationEmailMutation]
  )

  return {
    sendVerificationEmail: sendVerificationEmailCallback,
    sendVerificationEmailLoading,
    sendVerificationEmailCalled
  }
}
