import React, { useState } from 'react'
import { bool, func } from 'prop-types'

import { useTranslation } from 'react-i18next'

import Label from '../../../../shared/label'
import { Select } from '../../../../shared/select'

const OPTIONS = [
  {
    labelTid: 'adminPages:adminManageGuide.guide.question.edit.filtering',
    value: true
  },
  {
    labelTid: 'adminPages:adminManageGuide.guide.question.edit.notFiltering',
    value: false
  }
]

const FilterQuestionSelect = ({ onChange, filter }) => {
  const { t } = useTranslation('adminPages')
  const [loading, setLoading] = useState(false)

  const handleChange = (newValue) => {
    setLoading(true)

    onChange('filter', newValue).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Label title={t('adminManageGuide.guide.question.filter')} marginless>
      <Select
        options={OPTIONS}
        onChange={handleChange}
        rawChangeEvent={false}
        value={filter || false}
        loading={loading}
        isSearchable={false}
      />
    </Label>
  )
}

FilterQuestionSelect.propTypes = {
  onChange: func.isRequired,
  filter: bool
}

FilterQuestionSelect.defaultProps = {}

export default FilterQuestionSelect
