const FIELD_DATA_NAME = {
  id: 'name',
  nameTid: 'content:name',
  textTid: 'adminPages:sharedUserForm.nameText',
  type: 'text'
}

const FIELD_DATA_EMAIL = {
  id: 'email',
  nameTid: 'content:email',
  textTid: 'adminPages:sharedUserForm.emailText',
  type: 'text'
}

const FIELD_DATA_MARKET_REGIONS = {
  id: 'appLocales',
  nameTid: 'content:marketsRegions',
  textTid: 'adminPages:sharedUserForm.usersMarketsRegionText',
  type: 'multiple',
  values: []
}

const FIELD_DATA_ROLE = {
  id: 'appRole',
  nameTid: 'adminPages:sharedUserForm.userAccess',
  textTid: 'adminPages:sharedUserForm.userAccessText',
  type: 'single',
  values: [
    {
      id: 'ADMIN',
      title: 'ADMIN',
      text: null
    },
    {
      id: 'EDITOR',
      title: 'EDITOR',
      text: null
    },
    {
      id: 'USER',
      title: 'USER',
      text: null
    }
  ]
}

const FIELD_DATA_SYSTEMS = {
  id: 'systems',
  nameTid: 'content:system',
  textTid: 'adminPages:sharedUserForm.systemText',
  type: 'multiple',
  values: [] // This should come from Vendors
}

const FIELD_DATA_SYSTEMS_EDIT = {
  ...FIELD_DATA_SYSTEMS,
  id: 'systems'
}

const FIELD_DATA_DISABLED = {
  id: 'disabled',
  nameTid: 'adminPages:adminEditUser.usedDisabled',
  text: null,
  type: 'single',
  values: [
    {
      id: 'true',
      titleTid: 'content:yes',
      text: null
    },
    {
      id: 'false',
      titleTid: 'content:no',
      text: null
    }
  ]
}

const ADD_USER_FORM = [
  FIELD_DATA_NAME,
  FIELD_DATA_EMAIL,
  FIELD_DATA_MARKET_REGIONS,
  FIELD_DATA_ROLE,
  FIELD_DATA_SYSTEMS
]

const EDIT_USER_FORM = [
  FIELD_DATA_NAME,
  FIELD_DATA_EMAIL,
  FIELD_DATA_DISABLED,
  FIELD_DATA_MARKET_REGIONS,
  FIELD_DATA_ROLE,
  FIELD_DATA_SYSTEMS_EDIT
]

export { ADD_USER_FORM, EDIT_USER_FORM }
