import { useCallback } from 'react'

import { useUserContext } from '../contexts'

import { isAdmin } from '../utils'

export default function useAdminBypassSelect() {
  const { user, updateUser } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const adminSelectVendor = useCallback(
    (vendorId) => {
      if (!hasAdmin || !vendorId) {
        return
      }

      updateUser({ companyId: vendorId })
    },
    [hasAdmin, updateUser]
  )

  const adminSelectSystem = useCallback(
    (systemId, systemName, systemCategory) => {
      if (!hasAdmin) {
        return
      }

      updateUser({
        systemId,
        systemName,
        systemCategory
      })
    },
    [hasAdmin, updateUser]
  )

  const adminSelectUser = useCallback(
    (userId) => {
      if (!hasAdmin || !userId) {
        return
      }

      updateUser({ userId })
    },
    [hasAdmin, updateUser]
  )

  const adminSelectClear = useCallback(() => {
    updateUser({
      userId: undefined,
      systemId: undefined,
      systemName: undefined,
      systemCategory: undefined,
      companyId: undefined
    })
  }, [updateUser])

  return {
    adminSelectVendor,
    adminSelectSystem,
    adminSelectUser,
    adminSelectClear
  }
}
