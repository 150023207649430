import useAdminQuery from './useAdminQuery'

import useFetchSystemProps from './useFetchSystemProps'

export default function useFetchSystems(GQL_FETCH_SYSTEMS_QUERY, fetchPolicy) {
  const {
    systemProps,
    systemPropsArea,
    systemPropsGuide,
    loading: propsLoading
  } = useFetchSystemProps()

  const { data, refetch, loading } = useAdminQuery(
    GQL_FETCH_SYSTEMS_QUERY,
    null,
    fetchPolicy
  )

  return {
    availableSystems: data?.appSystemsOnVendor,
    systemProps,
    systemPropsArea,
    systemPropsGuide,
    availableSystemsLoading: !data && (loading || propsLoading),
    refetchAvailableSystems: refetch
  }
}
