import React from 'react'
import { func } from 'prop-types'

import { useTranslation } from 'react-i18next'

import DownloadCsvButton from '../../shared/download-csv-btn'

const AdminFormsExport = ({ onFetchByLazyQuery }) => {
  const { t } = useTranslation()

  const handleFetchData = (dataDoneCallback) => {
    onFetchByLazyQuery({
      fetchPolicy: 'no-cache',
      variables: {
        fetchCsvData: true
      },
      onCompleted: ({ appAdminFormLeads }) => {
        dataDoneCallback(appAdminFormLeads.csvData)
      }
    })
  }

  return (
    <DownloadCsvButton
      btnText={t('content:export')}
      filename="admin-all-forms.csv"
      onFetchData={handleFetchData}
    />
  )
}

AdminFormsExport.propTypes = {
  onFetchByLazyQuery: func.isRequired
}

AdminFormsExport.defaultProps = {}

export default AdminFormsExport
