import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import EmailStatus from './emailStatus'

const EmailStatusExplainListItems = ({
  liStyleing = 'pl-4 mb-1',
  statusStyleing = 'inline-flex h-5 w-5 mr-2'
}) => {
  const { t } = useTranslation(['content'])
  return (
    <>
      <li className={classNames(`${liStyleing}`)}>
        <EmailStatus
          emailStatus={{
            email: 'info@businesswith.se',
            score: 100,
            status: 'Delivered'
          }}
          styleing={statusStyleing}
        />
        = {t('greenEnvelopeIcon', { ns: 'content' })}
      </li>
      <li className={classNames(`${liStyleing}`)}>
        <EmailStatus
          emailStatus={{
            email: 'info@businesswith.se',
            score: 50,
            status: 'Good domain. Failed to deliver'
          }}
          styleing={statusStyleing}
        />
        = {t('lightOrangeEnvelopeIcon', { ns: 'content' })}
      </li>
      <li className={classNames(`${liStyleing}`)}>
        <EmailStatus
          emailStatus={{
            email: 'info@businesswith.se',
            score: 25,
            status: 'Bad domain'
          }}
          styleing={statusStyleing}
        />
        = {t('orangeEnvelopeIcon', { ns: 'content' })}
      </li>
      <li className={classNames(`${liStyleing}`)}>
        <EmailStatus
          emailStatus={{
            email: 'info@businesswith.se',
            score: 0,
            status: 'Failed'
          }}
          styleing={statusStyleing}
        />
        = {t('redEnvelopeIcon', { ns: 'content' })}
      </li>
      <li className={classNames(`${liStyleing}`)}>
        <EmailStatus
          emailStatus={{
            email: 'info@businesswith.se',
            score: -1,
            status: 'Unverified'
          }}
          styleing={statusStyleing}
        />
        = {t('blackEnvelopeIcon', { ns: 'content' })}
      </li>
    </>
  )
}

export default EmailStatusExplainListItems
