import { useCallback } from 'react'

import { useOverlayNotificationContext } from '../contexts'

export default function useOverlayNotification() {
  const { queueNotification } = useOverlayNotificationContext()

  const showSuccessNotification = useCallback(
    ({ title, text, titleTid, textTid } = {}) => {
      queueNotification({
        success: true,
        title,
        titleTid,
        text,
        textTid
      })
    },
    [queueNotification]
  )

  const showErrorNotification = useCallback(
    ({ text, textTid } = {}) => {
      queueNotification({
        error: true,
        text,
        textTid
      })
    },
    [queueNotification]
  )

  return {
    showSuccessNotification,
    showErrorNotification
  }
}
