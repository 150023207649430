import React from 'react'
// import * as ga from "../lib/ga";

function Content() {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>BusinessWith's Privacy Policy</h1>
      <p>
        BusinessWith cares about privacy and protecting the Personal Data handled by us.
        All Personal Data is Processed in accordance with Applicable Law. In this Policy
        we describe how and the purposes for which we use your personal information as
        well as what lawful basis we use and what measures we take to protect Personal
        data. We also provide information on how you exercise the rights you have linked
        to our Processing of Personal data.
      </p>
      <h2>Why and who?</h2>
      <p>
        BusinessWith Sweden AB Reg. No. (559199-7373) (
        <strong>&quot;BusinessWith&quot;</strong>,<strong>&quot;we&quot;</strong>,{' '}
        <strong>&quot;us&quot;</strong>, <strong>&quot;our&quot;</strong>) is the
        Controller of all Personal Data listed in this Privacy Policy (the{' '}
        <strong>&quot;Policy&quot;</strong>). In order to be fully transparent regarding
        where your Personal Data is stored and Processed, we will also provide a list of
        all our data Processors.
      </p>
      <p>
        This Policy provides information on how we handle Personal Data when you
        communicate with us, use the Services or visit our website businesswith.se
        (together the <strong>&quot;Functions&quot;</strong>).
      </p>
      <h3>The intended recipient of the information provided in this Policy is:</h3>
      <ul>
        <li>Employees of existing customers</li>
      </ul>
      <h2>Definitions</h2>
      <p>
        <strong>&quot;Applicable Law&quot;</strong> refers to the legislation applicable
        to the processing of Personal Data, including the GDPR, supplementary national
        legislation, as well as practices, guidelines and recommendations issued by a
        national or EU supervisory authority.
      </p>
      <p>
        <strong>&quot;Controller&quot;</strong> is the company/organisation that decides
        for what purposes and in what way personal data is to be processed and is
        responsible for the Processing of Personal Data in accordance with Applicable Law.
      </p>
      <p>
        <strong>&quot;Data Subject&quot;</strong> is the living, natural person whose
        Personal Data is being processed.
      </p>
      <p>
        <strong>&quot;Personal Data&quot;</strong> is all information relating, directly
        or indirectly, to an identifiable natural person.
      </p>
      <p>
        <strong>&quot;Processing&quot;</strong> means any operation or set of operations
        which is performed on Personal data, e.g. storage, modification, reading, handover
        and similar.
      </p>
      <p>
        <strong>&quot;Processor&quot;</strong> is the company/organisation that processes
        personal data on behalf of the Controller and can therefore only process the
        Personal Data according to the instructions of the Controller and the Applicable
        Law.
      </p>
      <p>
        <strong>&quot;The Services&quot;</strong> is digital products on businesswith.se
        that helps users in choosing a system for their business, as well as other digital
        tools for users to get in contact with system providers.
      </p>
      <p>
        The definitions above shall apply in the Policy regardless if they are capitalised
        or not.
      </p>
      <h2>BusinessWith's role as a Controller</h2>
      <p>
        The information in this Policy covers Personal Data Processing for which
        BusinessWith is the Controller. As a Controller we are responsible for the
        Processing for which we decide the purpose of (&quot;the why&quot;) and the means
        for the Processing (what methods, what personal data and for how long it is
        stored). The Policy does not describe how we Process Personal Data in the role of
        a Processor - i.e. when we process Personal Data on behalf of our customers.
      </p>
      <p>
        We provide a digital platform which helps our Visitors to compare, to be
        recommended, and/or read reviews regarding Software Providers listed on our Site.
        In addition, the digital platform helps Software Providers to market their digital
        products and get contact information from Users that either accepted or requested
        to use our digital services (for example Systemguide, Demo requests, More
        information request etc).
      </p>
      <h2>BusinessWith's processing of personal data</h2>
      <p>
        We have a responsibility to describe and demonstrate how we fulfill the
        requirements that are imposed on us when we Process your Personal Data. This
        section aims to describe:
      </p>
      <ul>
        <li>That Processing of Personal Data is necessary is for the purpose</li>
        <li>That we have identified the lawful basis for the Processing</li>
      </ul>
      <h3>Lawful basis</h3>
      <p>
        <strong>Legitimate interest</strong> - BusinessWith may process Personal Data if
        we have assessed that a legitimate interest overrides the interest of fundamental
        rights and freedoms of the Data Subject, and if the processing is necessary for
        the purpose in question.
      </p>
      <h3>For how long do we store your personal data?</h3>
      <p>
        We will keep your personal data as long as it is necessary for the purpose for
        which it was collected. Depending on the lawful basis on which we support the
        Processing, this may a) be regulated in a contract, b) be dependent on valid
        consent, c) be stated in legislation or d) follow by an internal assessment based
        on a legitimate interest assessment (LIA). In the list below, we indicate, where
        possible, the period during which the Personal Data will be stored and the
        criteria used to determine the storage period.
      </p>
      <h3>Processings</h3>
      <ol>
        <li>
          <strong>Processing and purpose of Processing:</strong> Register user accounts to
          enable users to access their account at BusinessWith.
          <br />
          <strong>Personal Data:</strong> E-mail, name, company name and password.
          <br />
          <strong>Source:</strong> Directly from the registered or from the registered
          employer.
          <br />
          <strong>Lawful basis:</strong> The legitimate interest of fulfilling our
          contract with the registered´s employer and/or allowing users to update their
          own company information on BusinessWith.se.
          <br />
          <strong>Storage period:</strong> Twelve (12) months from when the registered
          last used his or her account.\
        </li>
        <li>
          <strong>Processing and purpose of processing:</strong> Analyze service usage to
          use as basis for improvement and development of the service.
          <br />
          <strong>Personal data:</strong> User behavior.
          <br />
          <strong>Source:</strong> From our database.
          <br />
          <strong>Lawful basis:</strong> The legitimate interest of improving and develop
          our services to our clients.
          <br />
          <strong>Storage period:</strong> As long as the account is active.\
        </li>
      </ol>
      <h2>Your rights</h2>
      <p>
        You are the one in control of your Personal data and we always strive to ensure
        that you can exercise your rights as efficiently and smoothly as possible.
      </p>
      <p>
        <strong>Access</strong> - You always have the right to receive information about
        the Processing of data that concerns you. We only provide information if we have
        been able to verify that it is you that are requesting the information.
      </p>
      <p>
        <strong>Rectification</strong> - If you find that the Personal Data we process
        about you is incorrect, let us know and we will fix it!
      </p>
      <p>
        <strong>Erasure</strong> - Do you want us to completely forget about you? You have
        the right to be forgotten and request deletion of your Personal Data when the
        Processing is no longer necessary for the purpose for which it was collected. If
        we are required to retain your information under applicable law or a contract that
        we have entered with you, we will ensure that it is processed only for the
        specific purpose set forth in such applicable law or contract. We will thereafter
        erase the information as soon as possible.
      </p>
      <p>
        <strong>Objections</strong> - Do you disagree with our assessment that a
        legitimate interest for Processing your Personal Data overrides your interest in
        protecting your privacy? Don’t worry - in such case, we will review our legitimate
        interest assessment. Of course, we add your objection to the balance and make a
        new assessment to see if we can still justify our Processing of your Personal
        Data. If you object to direct marketing, we will immediately delete your personal
        information without making an assessment.
      </p>
      <p>
        <strong>Restriction</strong> - You can also ask us to restrict our Processing of
        your Personal Data
      </p>
      <ul>
        <li>Whilst we are Processing a request from you for any of your other rights;</li>
        <li>
          If, instead of requesting erasure, you want us to limit the Processing of
          Personal Data for a specific purpose. For example, if you do not want us to send
          advertising to you in the future, we still need to save your name in order to
          know that we should not contact you; or
        </li>
        <li>
          In cases where we no longer need the information in relation to the purpose for
          which it was collected, provided that you do not have an interest in retaining
          it to make a legal claim.
        </li>
      </ul>
      <p>
        <strong>Data portability</strong> - We may provide you with the data that you have
        submitted to us or that we have received from you in connection with a contract
        that we have entered with you. You will receive your information in a commonly
        used and machine-readable format that you can transfer to another personal data
        manager.
      </p>
      <p>
        <strong>Withdraw consent</strong> - If you have given consent to one or several
        specific processing(s) of your Personal Data, you have the right to withdraw your
        consent at any time and thus ask us to terminate the Processing immediately.
        Please note that you can only withdraw your consent for future processing of
        Personal Data and not for Processing that has already taken place.
      </p>
      <h3>How you use your rights</h3>
      <p>
        Contact us at <a href="mailto:info@businesswith.se">info@businesswith.se</a> for
        assistance.
      </p>
      <h2>Transfer of personal data</h2>
      <p>
        In order to run our business, we may need help from others who will process
        Personal Data on our behalf, so-called Processors.
      </p>
      <p>
        In cases where our Processors transfer Personal Data outside the EU/EEA, we have
        ensured that the level of protection is adequate, and in compliance with
        Applicable Law, by controlling that either of the following requirements are
        fulfilled:
      </p>
      <ul>
        <li>
          the EU Commission has determined that the level of protection is adequate in the
          third country where the data is processed;
        </li>
        <li>
          the Processor has signed up to the EU Commission's standard contract clauses
          (SCCs) for data transfer to non-EU/EEA countries; or
        </li>
        <li>
          the Processor has taken other appropriate safeguards prior to the transfer and
          that such safeguards comply with Applicable law.
        </li>
      </ul>
      <p>
        We have entered into Data Processing Agreements (DPA) with all our Processors. The
        DPA sets out, among other things, how the Processor may process the Personal Data
        and what security measures are required for the Processing.
      </p>
      <p>
        We may also need to disclose your personal information to certain designated
        authorities in order to fulfill obligations under applicable law or legally
        binding judgements.
      </p>
      <h3>Our processors</h3>
      <ol>
        <li>
          <strong>Processor:</strong> Heroku
          <br />
          <strong>Personal data being processed:</strong> E-mail, name, company name,
          password.
          <br />
          <strong>Instructions:</strong> Store data
        </li>
        <li>
          <strong>Processor:</strong> Google Analytics.
          <br />
          <strong>Personal data being processed:</strong> User behaviour.
          <br />
          <strong>Instructions:</strong> Analyze the behaviour and create insights.
        </li>
        <li>
          <strong>Processor:</strong> Sentry.
          <br />
          <strong>Personal data being processed:</strong> User behavior.
          <br />
          <strong>Instructions:</strong> Track usage to avoid errors.
        </li>
        <li>
          <strong>Processor:</strong> Mailgun.
          <br />
          <strong>Personal data being processed:</strong> E-mail.
          <br />
          <strong>Instructions:</strong> Store.
        </li>
      </ol>
      <h2>Security measures</h2>
      <p>
        BusinessWith has taken technical and organisational measures to ensure that your
        Personal Data is processed securely and protected from loss, abuse and
        unauthorised access.
      </p>
      <h3>Our security measures</h3>
      <p>
        <strong>Organisational security measures</strong> are measures that are
        implemented in work methods and routines within the organisation.
      </p>
      <ul>
        <li>Login and password management</li>
      </ul>

      <p>
        <strong>Technical security measures</strong> are measures implemented through
        technical solutions.
      </p>
      <ul>
        <li>Encryption</li>
        <li>Access control level</li>
      </ul>
      <h2>Cookies</h2>
      <p>
        BusinessWith uses cookies and similar tracking techniques to analyse the use of
        the Functions so that we can give you the best user experience. For more
        information on how we use cookies, see our Cookie Policy
        (https://businesswith.se/legal/cookie-policy/).
      </p>
      <h2>If we don’t keep our promise</h2>
      <p>
        If you think that we are not Processing your Personal Data correctly, even after
        you have notified us of this, you are always entitled to submit your complaint to
        the Swedish Authority for Privacy Protection.
      </p>
      <p>
        More information about our obligations and your rights can be found at{' '}
        <a href="https://www.imy.se/">https://www.imy.se/</a> You can contact the
        authority via e-mail at: <a href="mailto:imy@imy.se">imy@imy.se</a>
      </p>
      <h2>Changes to this policy</h2>
      <p>
        We reserve the rights to make changes to this Policy. In the event that the change
        affects our obligations or your rights, we will inform you about the changes in
        advance so that you are given the opportunity to take a position on the updated
        policy.
      </p>
      <h2>Contact</h2>
      <p>
        Please contact us if you have questions about your rights or if you have any other
        questions about how we process your personal information:
      </p>
      <p>
        <a href="mailto:christian.gidlund@businesswith.se">
          christian.gidlund@businesswith.se
        </a>
      </p>
      <p>Last update: 2021-06-01</p>
    </div>
  )
}

export default Content
