import React, { memo } from 'react'
import { array } from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import cx from 'classnames'

/**
    Breadcrumbs array example: 
    [
        {
            text: t('marketResearcher:categories.header'),
            href: `/market-researcher/${locale}`
        },
        {
            text: t('marketResearcher:categories.header'),
            href: `/market-researcher/${locale}`
        }
    ]
 */
const Breadcrumbs = ({ breadcrumbs }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleNavigate = (href) => {
    navigate(href)
  }

  const renderBreadcrumb = (breadcrumb, index) => {
    const active = breadcrumb.href === pathname

    return (
      <React.Fragment key={breadcrumb.href}>
        {index !== 0 && <span className="text-gray-300">/</span>}
        <a
          className={cx('cursor-pointer', {
            'pointer-events-none underline': active
          })}
          onClick={handleNavigate.bind(null, breadcrumb.href)}
        >
          {breadcrumb.text}
        </a>
      </React.Fragment>
    )
  }

  return (
    <div className="flex justify-start items-center gap-2 text-xl font-semibold text-gray-900">
      {breadcrumbs.map(renderBreadcrumb)}
    </div>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: array.isRequired
}

Breadcrumbs.defaultProps = {}

export default memo(Breadcrumbs)
