import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const CREATE_GUIDE_COMMON_QUESTION_GQL = gql`
  mutation AdminCreateGuideCommonQuestion {
    createGuideCommonQuestion {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useCreateGuideCommonQuestion() {
  const { showErrorNotification } = useOverlayNotification()

  const [createGuideCommonQuestionMutation, { loading }] = useMutation(
    CREATE_GUIDE_COMMON_QUESTION_GQL
  )

  const createGuideCommonQuestion = useCallback(
    (onCompleted) => {
      return createGuideCommonQuestionMutation({
        onCompleted: ({ createGuideCommonQuestion }) => {
          onCompleted?.(createGuideCommonQuestion)
        },
        onError: showErrorNotification
      })
    },
    [createGuideCommonQuestionMutation, showErrorNotification]
  )

  return {
    createGuideCommonQuestion,
    createGuideCommonQuestionLoading: loading
  }
}
