export { isAdmin } from './admin'

export { isAppAdmin } from './appadmin'

export const isResearcher = (role) => role === 'MARKET_RESEARCHER'

export const isVendor = (role) => role === 'VENDOR'

export const isProd = process.env.NODE_ENV !== 'development'

export const isNotProd = process.env.NODE_ENV !== 'production'

export const getUserRegions = (marketRegions) => marketRegions

export const isBasicCustomer = (siteData) => {
  if (!siteData || typeof siteData !== 'object') {
    return true
  }

  return siteData.customerType === 'BASIC' || !siteData.customerType
}
