import React, { useMemo } from 'react'

import useCategoriesOptions from '../../../hooks/useCategoriesOptions'
import useFetchAvailableSites from '../../../hooks/useFetchAvailableSites'
import useSystemsOptions from '../../../hooks/useSystemsOptions'

import Filters from '../../shared/filters'

const AdminAllReviewsFilter = ({ onChangeFilters }) => {
  const { categoriesOptions } = useCategoriesOptions(true)
  const { sitesOptions } = useFetchAvailableSites()
  const { systemsOptions } = useSystemsOptions()

  const filtersMemo = useMemo(() => {
    return [
      {
        id: 'fromDate',
        nameTid: 'content:from',
        type: 'datepicker'
      },
      {
        id: 'toDate',
        nameTid: 'content:to',
        type: 'datepicker'
      },
      {
        id: 'status',
        nameTid: 'content:status',
        singleValue: true,
        options: [
          {
            labelTid: 'adminPages:adminAllReviews.filters.status.new',
            value: 'NEW'
          },
          {
            labelTid: 'adminPages:adminAllReviews.filters.status.approved',
            value: 'APPROVED'
          },
          {
            labelTid: 'adminPages:adminAllReviews.filters.status.rejected',
            value: 'REJECTED'
          }
        ]
      },
      {
        id: 'site',
        nameTid: 'content:sites',
        options: sitesOptions
      },
      {
        id: 'system',
        nameTid: 'content:system',
        options: systemsOptions,
        searchable: true
      },
      {
        id: 'category',
        nameTid: 'content:category',
        options: categoriesOptions,
        searchable: true
      }
    ]
  }, [sitesOptions, systemsOptions, categoriesOptions])

  return <Filters filters={filtersMemo} onChangeFilters={onChangeFilters} />
}

AdminAllReviewsFilter.propTypes = {}

AdminAllReviewsFilter.defaultProps = {}

export default AdminAllReviewsFilter
