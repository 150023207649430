import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export default function useGetSystemName() {
  const { i18n } = useTranslation()

  const getSystemName = useCallback(
    (system) => {
      const language = i18n.language
      const systemSiteData = system?.systemSiteData

      if (!systemSiteData || !systemSiteData.length) {
        return ''
      }

      const siteDataForLocale = systemSiteData.find(
        (siteData) => siteData.locale === language
      )

      // Return system name for selected language if exists
      if (siteDataForLocale && siteDataForLocale.systemName) {
        return siteDataForLocale.systemName
      }

      // Retrun system name for Swedish if exists
      const siteDataForSwedish = systemSiteData.find(
        (siteData) => siteData.locale === 'sv_SE'
      )

      if (siteDataForSwedish && siteDataForSwedish.systemName) {
        return siteDataForSwedish.systemName
      }

      // Return first system name in array
      const firstSystemName = systemSiteData[0]?.systemName

      if (firstSystemName) {
        return firstSystemName
      }

      // Return ANY found systemName that exists.
      const systemObject = systemSiteData.find((siteData) => siteData.systemName)

      return systemObject?.systemName || ''
    },
    [i18n.language]
  )

  return {
    getSystemName
  }
}
