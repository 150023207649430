import React from 'react'

const Subsection = ({ title, children }) => {
  return (
    <div className="mb-4 mt-4">
      <label className="text-md font-medium text-gray-700">{title}</label>
      {children}
    </div>
  )
}

Subsection.propTypes = {}

Subsection.defaultProps = {}

export default Subsection
