import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { bool, func, oneOf } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useGenericFormManager from '../../../hooks/useGenericFormManager'
import useCreateSystemPropsByType from '../../../hooks/market-researcher/useCreateSystemPropsByType'
import useEditSystemPropsByType from '../../../hooks/market-researcher/useEditSystemPropsByType'
import useDeleteSystemProps from '../../../hooks/market-researcher/useDeleteSystemProps'
import { useUserContext } from '../../../contexts'

import Button from '../../shared/button'
import Dialog from '../../shared/dialog'
import Textarea from '../../shared/textarea'
import Label from '../../shared/label'
import Flag from '../../shared/flag'
import Checkbox from '../../shared/checkbox'
import { Select } from '../../shared/select'

import { hasMinLengthOf3, hasRequiredString } from '../../../validators/general'
import { isAdmin } from '../../../utils'

const FIELD_NAME = 'name'
const FIELD_NAME_FOREIGN = 'nameForeign'
const FIELD_DESC = 'description'
const FIELD_DESC_FOREIGN = 'descriptionForeign'
const FIELD_FILTER = 'filter'
const FIELD_SECTION = 'section'
const FIELD_IMPACT = 'impact'

const SECTION_OPTIONS = [
  { label: 'COOPERATION', value: 'COOPERATION' },
  { label: 'SYSTEM_PROPERTIES', value: 'SYSTEM_PROPERTIES' }
]

const IMPACT_OPTIONS = [
  {
    label: 'low',
    value: 'low'
  },
  {
    label: 'medium',
    value: 'medium'
  },
  {
    label: 'high',
    value: 'high'
  }
]

const FORM_INSTR = [
  {
    name: FIELD_NAME,
    validators: [
      { validator: hasMinLengthOf3, error: 'forms:validation.general.minLengthOf3' }
    ]
  },
  {
    name: FIELD_NAME_FOREIGN,
    validators: [
      { validator: hasMinLengthOf3, error: 'forms:validation.general.minLengthOf3' }
    ]
  },
  {
    name: FIELD_SECTION,
    validators: [
      { validator: hasRequiredString, error: 'forms:validation.general.required' }
    ]
  }
]

const ignoreSectionFn = (formData, extraValidatorData) => {
  return extraValidatorData?.showSectionField === false
}

const IGNORE_FIELDS = {
  [FIELD_SECTION]: ignoreSectionFn
}

const CreateEditSystemPropDialog = forwardRef(
  (
    { propType, showFilterField, showSectionField, showImpactField, onEditOrCreate },
    ref
  ) => {
    const { t } = useTranslation()
    const { locale } = useParams()
    const { user } = useUserContext()
    const [open, setOpen] = useState(false)
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
    const hasAdmin = isAdmin(user?.role)

    const { formData, onChange, validate, validationErrors, resetForm, setFormData } =
      useGenericFormManager(FORM_INSTR, undefined, {
        ignoreFields: IGNORE_FIELDS,
        extraValidatorData: { showSectionField }
      })

    const { mrEditSystemPropsByType, mrEditSystemPropsByTypeLoading } =
      useEditSystemPropsByType(propType)

    const { mrCreateSystemPropsByType, mrCreateSystemPropsByTypeLoading } =
      useCreateSystemPropsByType(propType)

    const { mrDeleteSystemProps, mrDeleteSystemPropsLoading } =
      useDeleteSystemProps(propType)

    const isEditing = Boolean(formData.id)
    const isLoading =
      mrCreateSystemPropsByTypeLoading ||
      mrEditSystemPropsByTypeLoading ||
      mrDeleteSystemPropsLoading

    useImperativeHandle(ref, () => {
      return {
        selectProductFunction: (systemProp) => {
          setFormData({
            id: systemProp.id,
            [FIELD_NAME]: systemProp.nameByLocale.en_GB || '',
            [FIELD_DESC]: systemProp.descriptionByLocale.en_GB || '',
            [FIELD_NAME_FOREIGN]: systemProp.nameByLocale[locale] || '',
            [FIELD_DESC_FOREIGN]: systemProp.descriptionByLocale[locale] || '',
            [FIELD_FILTER]: systemProp.filter,
            [FIELD_SECTION]: systemProp.section,
            [FIELD_IMPACT]: systemProp[FIELD_IMPACT],
            dbName: systemProp.dbName
          })

          setOpen(true)
        }
      }
    })

    const handleDelete = () => {
      setConfirmDeleteOpen(false)
      mrDeleteSystemProps(formData.id, handleOnCompleted)
    }

    const handleOnCompleted = () => {
      setOpen(false)
      onEditOrCreate && onEditOrCreate()
    }

    const handleSubmit = () => {
      if (!validate()) {
        return
      }

      if (isEditing) {
        mrEditSystemPropsByType(formData, handleOnCompleted)
      } else {
        mrCreateSystemPropsByType(formData, handleOnCompleted)
      }
    }

    const handleCancel = () => {
      resetForm()
      setOpen(false)
    }

    const getCommonProps = (fieldName) => {
      return {
        id: fieldName,
        value: formData[fieldName],
        errorText: t(validationErrors[fieldName]),
        onChange,
        disabled: isLoading
      }
    }

    const transEntityName = t(`content:entityPropType.${propType}`).toLowerCase()

    return (
      <>
        <Button onClick={() => setOpen(true)}>
          {t('content:create')} {transEntityName}
        </Button>

        <Dialog
          isOpen={confirmDeleteOpen}
          title={`${t('content:delete')} ${transEntityName}`}
          confirmText={t('content:delete')}
          cancelText={t('content:cancel')}
          onClose={setConfirmDeleteOpen}
          onConfirm={handleDelete}
        />

        <Dialog
          className="!max-w-4xl"
          isOpen={open}
          onConfirm={handleSubmit}
          onClose={handleCancel}
          onDelete={hasAdmin ? () => setConfirmDeleteOpen(true) : undefined}
          loading={isLoading}
          noBackgroundClose={confirmDeleteOpen}
          title={
            isEditing
              ? `${t('content:edit')} ${transEntityName}`
              : `${t('content:create')} ${transEntityName}`
          }
          confirmText={t('content:save')}
        >
          <div className="grid grid-flow-col gap-4">
            <div>
              <Flag locale="en_GB" height="32px" />

              <Label title={t('content:name')}>
                <Textarea {...getCommonProps(FIELD_NAME)} rows={2} />
              </Label>

              <Label title={t('content:description')}>
                <Textarea {...getCommonProps(FIELD_DESC)} rows={5} />
              </Label>
            </div>

            <div>
              <Flag locale={locale} height="32px" />

              <Label title={t('content:name')}>
                <Textarea {...getCommonProps(FIELD_NAME_FOREIGN)} rows={2} />
              </Label>

              <Label title={t('content:description')}>
                <Textarea {...getCommonProps(FIELD_DESC_FOREIGN)} rows={5} />
              </Label>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3">
            {showFilterField && (
              <Checkbox
                label={t('marketResearcher:productfunctions.filterDialog.checkbox')}
                text={t('marketResearcher:productfunctions.filterDialog.info')}
                onChange={(value) => {
                  onChange(FIELD_FILTER, !value)
                }}
                disabled={isLoading}
                checked={!formData[FIELD_FILTER]}
                rawChangeEvent={false}
                helper
              />
            )}

            {showSectionField && (
              <Label title={t('content:section')} marginless>
                <Select
                  {...getCommonProps(FIELD_SECTION)}
                  options={SECTION_OPTIONS}
                  rawChangeEvent={false}
                  isSearchable={false}
                />
              </Label>
            )}

            {showImpactField && (
              <Label title="TODO " marginless>
                <Select
                  {...getCommonProps(FIELD_IMPACT)}
                  options={IMPACT_OPTIONS}
                  rawChangeEvent={false}
                  isSearchable={false}
                  isClearable={true}
                />
              </Label>
            )}
          </div>
        </Dialog>
      </>
    )
  }
)

CreateEditSystemPropDialog.displayName = CreateEditSystemPropDialog

CreateEditSystemPropDialog.propTypes = {
  propType: oneOf(['PRODUCT_FUNCTIONALITIES', 'SYSTEM_CHARACTERISTICS', 'AREAS_OF_USE'])
    .isRequired,
  onEditOrCreate: func.isRequired,
  showFilterField: bool,
  showSectionField: bool,
  showImpactField: bool
}

CreateEditSystemPropDialog.defaultProps = {
  showFilterField: false,
  showSectionField: false
}

export default CreateEditSystemPropDialog
