import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { FRAGMENT_REVIEW_TEXT } from '../../../../constants/gql/systems'

const DELETE_REVIEW_TRANSLATION_GQL = gql`
  ${FRAGMENT_REVIEW_TEXT}

  mutation AdminDeleteReviewTranslation($reviewDataId: ID!) {
    appAdminDeleteReviewTranslation(reviewDataId: $reviewDataId) {
      ...ReviewTextsFragment
    }
  }
`

export default function useDeleteReviewTranslation() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appAdminDeleteReviewTranslation, { loading }] = useMutation(
    DELETE_REVIEW_TRANSLATION_GQL
  )

  const deleteReviewTranslation = useCallback(
    (reviewDataId, onCompleted) => {
      return appAdminDeleteReviewTranslation({
        variables: {
          reviewDataId
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appAdminDeleteReviewTranslation, showSuccessNotification, showErrorNotification]
  )

  return {
    deleteReviewTranslation,
    deleteReviewTranslationLoading: loading
  }
}
