import React from 'react'
import { string, bool } from 'prop-types'

const ListItem = ({ children, id }) => {
  return (
    <div id={id}>
      <div className="w-full min-h-[75px] grid grid-flow-col grid-cols-1 items-center relative">
        {children}
      </div>
    </div>
  )
}

ListItem.propTypes = {
  className: string,
  isLoading: bool,
  id: string
}

ListItem.defaultProps = {
  hasHoverEffect: true
}

export default ListItem
