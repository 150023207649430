export const GUIDE_QUESTION_FRAGMENT = `
    id
    type
    archived
    isCommon
    required
    filter
    commonQuestionId
    hasCommonAnswers
    autogenerated
    answerAlternatives
    answersHeadings
    order
    texts
    textsShort
    textsHelp
    name
    guideAnswers {
        id
        isCommon
        texts
        textsHelp
        section
        type
        order
        specialName
    }
`

export const GUIDE_FRAGMENT = `
    id
    active
    archived
    categories
    locales
    guideQuestions {
        ${GUIDE_QUESTION_FRAGMENT}
    }
    createdAt
`
