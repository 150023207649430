import React, { useState } from 'react'
import useAdminQuery from '../../hooks/useAdminQuery'
import { gql } from '@apollo/client'
import { getWebhookEventTriggerStatuses } from '../../utils/webhook.util'
import { useTranslation } from 'react-i18next'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

const GET_VENDOR_QUERY = gql`
  query Vendor($companyId: ID, $userId: ID) {
    appVendor(companyId: $companyId, userId: $userId) {
      id
      company
      webhooks {
        name
        events
      }
    }
  }
`
const WebhookStatus = ({ triggerName, isActive, show = false }) => {
  if (!show) return
  return (
    <p className="text-sm text-gray-600 flex gap-3 items-center">
      {triggerName}
      {isActive ? (
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
      ) : (
        <XCircleIcon className="h-6 w-6 text-red-500" />
      )}
    </p>
  )
}

const AdminWebhookTriggerStatuses = ({ inquiryLead = false, guideLead = false }) => {
  const { t } = useTranslation('vendorSettings')

  const [hasInquiryWebhook, setHasInquiryWebhook] = useState(false)
  const [hasGuideWebhook, setHasGuideWebhook] = useState(false)

  const { loading } = useAdminQuery(
    GET_VENDOR_QUERY,
    {
      onCompleted: ({ appVendor }) => {
        const eventTriggerStatus = getWebhookEventTriggerStatuses(appVendor.webhooks)
        setHasInquiryWebhook(eventTriggerStatus.hasInquiryWebhook)
        setHasGuideWebhook(eventTriggerStatus.hasGuideWebhook)
      }
    },
    'cache-and-network'
  )
  return (
    <div className="bg-white p-2 rounded-md">
      <p className="text-grey-600 text-sm">{t('vendorSettings:webhooks.title')}</p>
      {!loading && (
        <>
          <WebhookStatus
            triggerName={t('webhooks.triggers.EVENT_NEW_INQUIRY_LEAD')}
            show={inquiryLead}
            isActive={hasInquiryWebhook}
          />
          <WebhookStatus
            triggerName={t('webhooks.triggers.EVENT_NEW_GUIDE_LEAD')}
            show={guideLead}
            isActive={hasGuideWebhook}
          />
        </>
      )}
    </div>
  )
}

export default AdminWebhookTriggerStatuses
