import React from 'react'
import { useMutation, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'

import { PageBody } from '../components/page-body'
import { PageHeader } from '../components/page-header'

import styles from '../styles/page.module.css'

const SAVE_SYSTEM_DATA = gql`
  mutation CreateSystemData($id: ID!, $data: Json) {
    appCreateSystem(id: $id, data: $data) {
      id
    }
  }
`

function AdminAddSytemsPage() {
  const { user } = useUserContext()
  const { t } = useTranslation('adminPages')
  // console.log({ user });

  const hasAdmin = isAdmin(user?.role)

  // Save to db first time a box has been toggle
  const [
    saveMultipleSystems,
    { data: savedData, loading: savedLoading, error: savedError }
  ] = useMutation(SAVE_SYSTEM_DATA, {
    onError(err) {
      // console.log({ err });
      // err is for example: "Error: notFound"
      const error = `${err}`.split(':').reverse()[0]
      console.log({ error })
      // setErrorName(error);
    },
    onCompleted(data) {
      console.log('done mutation', data)
    }
  })

  const handleSystemChange = (e) => {
    const { type, value, checked, id } = e.target

    setSelectedSystemId(value)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    // Validate form

    // If valid, Send form
    const dataToSend = fieldsData

    // Mutation
    // createSystem({
    //   variables: { id: fieldsData.id, data: dataToSend },
    // });
  }

  return (
    <>
      <PageHeader pageName={t('adminAddSystems.header.title')} />

      <PageBody>
        <div>
          <form
            className="space-y-8 divide-y divide-gray-200"
            onSubmit={handleFormSubmit}
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    @TODO Add textarea here, for copy in CSV here
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t('content:save')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </PageBody>
    </>
  )
}

export default AdminAddSytemsPage
