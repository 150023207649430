import React, { Fragment, memo } from 'react'
import { Transition, Dialog as ReactDialog } from '@headlessui/react'
import { bool } from 'prop-types'

const FullscreenLoader = ({ show }) => {
  return (
    <Transition.Root show={show} as={Fragment} className="fixed z-10">
      <ReactDialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed top-0 right-0 h-screen w-screen z-50 flex justify-center items-center">
            <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-b-4 border-white-600" />
          </div>
        </Transition.Child>
      </ReactDialog>
    </Transition.Root>
  )
}

FullscreenLoader.propTypes = {
  show: bool.isRequired
}

FullscreenLoader.defaultProps = {}

export default memo(FullscreenLoader)
