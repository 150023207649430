import React from 'react'
import { array, func } from 'prop-types'

import Dropzone from './dropzone'
import AssetsRenderer from './assets-renderer'

const ASSET_SCREENSHOT = 'SCREENSHOT'

const ScreenshotSection = ({ onChange, assets }) => {
  return (
    <div className="flex gap-3 flex-wrap mt-2">
      <AssetsRenderer assetType={ASSET_SCREENSHOT} assets={assets} onChange={onChange} />
      <Dropzone assetType={ASSET_SCREENSHOT} onDrop={onChange} />
    </div>
  )
}

ScreenshotSection.propTypes = {
  onChange: func.isRequired,
  assets: array.isRequired
}

ScreenshotSection.defaultProps = {}

export default ScreenshotSection
