import React, { useEffect, useState } from 'react'

import { isLoggedIn } from '../utils/auth'

const AuthContext = React.createContext({})

export const AuthContextProvider = ({ children }) => {
  const updatedValue = {
    auth: {
      isLoggedIn: isLoggedIn()
    }
  }

  const [state, setState] = useState(updatedValue)

  // Handle context change
  useEffect(() => {
    const changeState = () => {
      setState((prevState) => {
        prevState.auth.isLoggedIn = isLoggedIn()
        return prevState
      })
    }

    window.addEventListener('storage', changeState)

    return () => {
      window.removeEventListener('storage', changeState)
    }
  }, [])

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => React.useContext(AuthContext)
