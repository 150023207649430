import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { FRAGMENT_REVIEW_TEXT } from '../../../../constants/gql/systems'

const EDIT_REVIEW_COMMENT_GQL = gql`
  ${FRAGMENT_REVIEW_TEXT}

  mutation CreateEditReviewComment($reviewDataId: ID!, $data: ReviewCommentInput!) {
    appCreateEditReviewComment(reviewDataId: $reviewDataId, data: $data) {
      ...ReviewTextsFragment
    }
  }
`

export default function useCreateEditReviewComment() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appCreateEditReviewComment, { loading }] = useMutation(EDIT_REVIEW_COMMENT_GQL)

  const createEditReviewComment = useCallback(
    (reviewDataId, formData, onCompleted) => {
      return appCreateEditReviewComment({
        variables: {
          reviewDataId,
          data: formData
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appCreateEditReviewComment, showSuccessNotification, showErrorNotification]
  )

  return {
    createEditReviewComment,
    createEditReviewCommentLoading: loading
  }
}
