import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'

const GQL_DELETE_WEBHOOK = gql`
  mutation DeleteWebhook($vendorId: ID!, $webhookId: ID!) {
    deleteWebhook(vendorId: $vendorId, webhookId: $webhookId) {
      id
    }
  }
`

export default function useDeleteWebhook(vendorId) {
  const [deleteWebhook] = useMutation(GQL_DELETE_WEBHOOK)
  const deleteWebhookCallback = useCallback(
    (webhookId) => {
      return deleteWebhook({
        variables: { vendorId, webhookId },
        optimisticResponse: {
          deleteWebhook: {
            id: webhookId
          }
        },
        update: (cache, { data: { deleteWebhook } }) => {
          cache.modify({
            fields: {
              webhooks: (webhooks, { readField }) => {
                return webhooks.filter((webhookRef) => {
                  return readField('id', webhookRef) !== deleteWebhook.id
                })
              }
            }
          })

          const normalizedId = cache.identify({
            id: deleteWebhook.id,
            __typename: 'Webhook'
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      })
    },
    [vendorId, deleteWebhook]
  )

  return {
    deleteWebhook: deleteWebhookCallback
  }
}
