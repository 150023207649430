import React, { useState } from 'react'
import { func, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useDeleteGuideQuestion from '../../../../../hooks/manage-guides/useDeleteGuideQuestion'

import Button from '../../../../shared/button'
import Dialog from '../../../../shared/dialog'

const DeleteQuestionButton = ({ questionId, onDeleted }) => {
  const { t } = useTranslation('adminPages')
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const { deleteGuideQuestion, deleteGuideQuestionLoading } = useDeleteGuideQuestion()

  const handleDelete = () => {
    setConfirmDeleteOpen(true)
  }

  const doDelete = () => {
    setConfirmDeleteOpen(false)
    deleteGuideQuestion(questionId, onDeleted)
  }

  const handleCancel = () => {
    setConfirmDeleteOpen(false)
  }

  return (
    <>
      <Button onClick={handleDelete} loading={deleteGuideQuestionLoading} mode="error">
        {t('content:delete')}
      </Button>

      <Dialog
        title={t('adminManageGuide.guide.question.delete.title')}
        isOpen={confirmDeleteOpen}
        onClose={handleCancel}
        onConfirm={doDelete}
        cancelText={t('content:cancel')}
        confirmText={t('content:delete')}
      >
        {t('adminManageGuide.guide.question.delete.text')}
      </Dialog>
    </>
  )
}

DeleteQuestionButton.propTypes = {
  questionId: string,
  onDeleted: func.isRequired
}

DeleteQuestionButton.defaultProps = {}

export default DeleteQuestionButton
