export const hasRequiredChangedValue = (value) => {
  return value === undefined || value.length !== 0
}

export const hasRequiredString = (value = '') => {
  return value ? value.trim().length !== 0 : false
}

export const isValidEmail = (email = '') => {
  const trimmedEmail = email.trim()
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
    trimmedEmail
  )
}

export const isValidUrl = (url) => {
  try {
    new URL(url)
  } catch (e) {
    return false
  }

  return true
}

export const hasItemsInArray = (array) => {
  return Array.isArray(array) && array.length > 0
}

export const isEmpty = (value) => {
  return !value || value.trim().length === 0
}

export const isValidJsonStringOrEmpty = (jsonString) => {
  if (isEmpty(jsonString)) {
    return true
  }

  return isValidJsonString(jsonString)
}

export const isValidJsonString = (jsonString) => {
  try {
    JSON.parse(jsonString)
  } catch (e) {
    return false
  }

  return true
}
