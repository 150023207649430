import React, { useMemo } from 'react'
import { bool } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useParams, useLocation } from 'react-router-dom'
import useFetchGuide from '../../../hooks/manage-guides/useFetchGuide'
import useGetCategoryName from '../../../hooks/useGetCategoryName'

import Loader from '../../loader'
import Breadcrumbs from '../../shared/breadcrumbs'

const BASEPATH = '/admin-manage-guides'

const Header = ({ loading }) => {
  const { t } = useTranslation('adminPages')
  const { guideId } = useParams()
  const { pathname } = useLocation()

  const { guide } = useFetchGuide(false, 'cache-only')
  const { getCategoryName } = useGetCategoryName()

  const guideCategory = guide ? guide.categories?.[0] : false

  const breadcrumbsMemo = useMemo(() => {
    const items = [{ text: t('adminManageGuide.guides.title'), href: BASEPATH }]

    if (pathname.includes('/common-questions')) {
      items.push({
        text: 'Common Questions',
        href: `${BASEPATH}/common-questions`
      })
    }

    if (!guideCategory) {
      return items
    }

    items.push({
      text: getCategoryName(guideCategory),
      href: `${BASEPATH}/${guideId}`
    })

    return items
  }, [t, getCategoryName, guideCategory, pathname, guideId])

  return (
    <div className="flex flex-col max-w-7xl mx-auto px-4 mb-10 sm:px-6 md:px-8 ">
      <Breadcrumbs breadcrumbs={breadcrumbsMemo} />

      {loading && (
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  loading: bool
}

Header.defaultProps = {}

export default Header
