import React, { useMemo, useState } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import GenerateCsvButton from '../components/shared/generate-csv-btn'

const GET_GUIDE_LEADS = gql`
  query GuideLeads($name: String!, $time: String!) {
    appAdminLeadsGuide(name: $name, time: $time) {
      id
      ended
      category
      categoryDb
      companySize
      emailEnding
      locale
      company
      systemNames
      howMany
      revenue
      formDone
    }
  }
`

const GET_INQUERY_LEADS = gql`
  query GuideInquiry($name: String!, $time: String!) {
    appAdminLeadsInquiry(name: $name, time: $time) {
      id
      company
      companySize
      created
      emailEnding
      inquiryType
      isNew
      licenses
      systemName
      category
      locale
    }
  }
`

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

function AdminSpecialsPage() {
  const { t } = useTranslation(['adminPages', 'guideQuestions'])
  const [textArea, setTextArea] = useState('')
  const [formatedText, setFormatedText] = useState('')
  const [convertToType, setConvertToType] = useState('upperCase')
  const [leadsTime, setLeadsTime] = useState('year')

  const [searchInputValueGuide, setSearchInputValueGuide] = useState('')
  const [searchInputValueInquiry, setSearchInputValueInquiry] = useState('')
  const [getLeadsGuide, { loading: loadingGuide, data: dataGuide, called: calledGuide }] =
    useLazyQuery(GET_GUIDE_LEADS, {
      variables: { name: searchInputValueGuide, time: leadsTime }
    })
  const [
    getLeadsInquiry,
    { loading: loadingInquiry, data: dataInquiry, called: calledInquiry }
  ] = useLazyQuery(GET_INQUERY_LEADS, {
    variables: { name: searchInputValueInquiry, time: leadsTime }
  })

  const onChangeGuide = (e) => {
    setSearchInputValueGuide(e.target.value)
  }

  const onChangeInquiry = (e) => {
    setSearchInputValueInquiry(e.target.value)
  }

  const onLeadsTimeChange = (e) => {
    const { value } = e.target
    setLeadsTime(value)
  }

  const onSubmitGuide = (e) => {
    e.preventDefault()
    if (!calledGuide) {
      getLeadsGuide()
    }
  }

  const onSubmitInquiry = (e) => {
    e.preventDefault()
    if (!calledInquiry) {
      getLeadsInquiry()
    }
  }

  const handleText = () => {
    let textArray = textArea.split('\n')
    console.log('textArray', textArray)
    let markup = ''

    if (convertToType === 'upperCase') {
      const upperCaseArr = textArray.map((row) =>
        row
          .split(' ')
          .map((text) => text.toUpperCase())
          .join('_')
      )

      markup = upperCaseArr.join('<br />')
    }

    if (convertToType === 'camelCaseFromUpperCase') {
      const textArr = textArray.map((row) => row.toLowerCase().replaceAll('_', ' '))

      const textArrToCamelize = textArr.map((row) => camelize(row))

      markup = textArrToCamelize.join('<br />')
    }

    if (convertToType === 'camelCase') {
      console.log(textArray)
      console.log(textArray[0])
      console.log(textArray[1])
      const camelCaseArr = textArray.map((row) => camelize(row))
      markup = camelCaseArr.join('<br />')
    }

    setFormatedText(markup)
  }

  const csvDataObjectGuide = useMemo(() => {
    if (!dataGuide || loadingGuide) {
      return {
        headers: [],
        data: []
      }
    }
    return {
      headers: [
        { label: 'Ended', key: 'ended' },
        { label: 'Category', key: 'category' },
        { label: 'Company', key: 'company' },
        { label: 'Company Size', key: 'companySize' },
        { label: 'Email Ending', key: 'emailEnding' },
        { label: 'Locale', key: 'locale' },
        { label: 'Systemnames', key: 'systemNames' },
        { label: 'How Many', key: 'howMany' },
        { label: 'Revenue', key: 'revenue' },
        { label: 'FormDone', key: 'formDone' }
      ],
      data: dataGuide.appAdminLeadsGuide.map((guideLead) => {
        const newGuideLead = { ...guideLead }

        newGuideLead.locale = guideLead.locale
        newGuideLead.systemNames = guideLead.systemNames.join(', ')
        newGuideLead.howMany = guideLead.howMany
          ? t(`${guideLead.categoryDb}.HOW_MANY.ANSWERS.${guideLead.howMany}.ANSWER`, {
              ns: 'guideQuestions'
            })
          : 'No data'

        newGuideLead.revenue = guideLead.revenue
          ? t(
              [
                `${guideLead.categoryDb}.COMPANY_TURNOVER.ANSWERS.${guideLead.revenue}.ANSWER`,
                `${guideLead.categoryDb}.REVENUE.ANSWERS.${guideLead.revenue}.ANSWER`
              ],
              {
                ns: 'guideQuestions'
              }
            )
          : 'No data'

        return newGuideLead
      })
    }
  }, [dataGuide, loadingGuide, t])

  const csvDataObjectInquiry = useMemo(() => {
    if (!dataInquiry || loadingInquiry) {
      return {
        headers: [],
        data: []
      }
    }

    return {
      headers: [
        { label: 'Created', key: 'created' },
        { label: 'Type', key: 'inquiryType' },
        { label: 'Category', key: 'category' },
        { label: 'Company', key: 'company' },
        { label: 'Company Size', key: 'companySize' },
        { label: 'Email Ending', key: 'emailEnding' },
        { label: 'Country', key: 'country' },
        { label: 'Systemname', key: 'systemName' }
      ],
      data: dataInquiry.appAdminLeadsInquiry.map((inquiryLead) => {
        const newInquiryLead = { ...inquiryLead }

        newInquiryLead.country = inquiryLead.country || 'SV'

        return newInquiryLead
      })
    }
  }, [dataInquiry, loadingInquiry])

  const year = new Date().getFullYear()

  return (
    <>
      <PageHeader
        pageName={t('adminSpecials.header.title')}
        filename="admin-special-leads.csv"
      >
        <p>{t('adminSpecials.header.subtitle')}</p>
      </PageHeader>

      <PageBody>
        <h3>{t('adminSpecials.guideLeads.label')}</h3>
        <div style={{ maxWidth: '800px' }}>
          <form onSubmit={onSubmitGuide}>
            <select
              id="leadsTime1"
              name="leadsTime"
              value={leadsTime}
              onChange={onLeadsTimeChange}
            >
              <option value="year">{year}</option>
              <option value="one">1 year back</option>
              <option value="two">2 year back</option>
              <option value="three">3 year back</option>
            </select>
            <input
              type="search"
              placeholder={t('content:search')}
              value={searchInputValueGuide}
              onChange={onChangeGuide}
              autoComplete="off"
            />
            <button
              className="ml-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="submit"
            >
              {t('content:search')}
            </button>
          </form>
          <p>
            <small>{t('adminSpecials.guideLeads.helperText')}</small>
          </p>
        </div>

        <div style={{ maxWidth: '800px', margin: '1rem auto 1rem' }}>
          <div className="mb-2">
            {csvDataObjectGuide.data.length > 0 && (
              <GenerateCsvButton csvData={csvDataObjectGuide} />
            )}
          </div>

          <h3>{t('adminSpecials.guideLeads.result')}:</h3>
          {loadingGuide && <p>{t('adminSpecials.guideLeads.loading')}...</p>}

          {csvDataObjectGuide.data.length <= 0 && t('adminSpecials.guideLeads.noResult')}

          {csvDataObjectGuide.data.map((lead) => (
            <div
              key={lead.id}
              style={{
                borderTop: '1px solid #ccc',
                margin: '10px',
                padding: '10px 0 0'
              }}
            >
              {lead.ended},{lead.category},{lead.companySize},{lead.emailEnding},
              {lead.locale},{lead.systemNames},{lead.howMany}
              {' -- '}
              {lead.revenue}, {' -- '}, {lead.formDone.toString()}
            </div>
          ))}
        </div>

        <h3 style={{ marginTop: '2rem' }}>{t('adminSpecials.guideInquiry.label')}</h3>
        <div style={{ maxWidth: '800px' }}>
          <form onSubmit={onSubmitInquiry}>
            <select
              id="leadsTime2"
              name="leadsTime"
              value={leadsTime}
              onChange={onLeadsTimeChange}
            >
              <option value="year">{year}</option>
              <option value="one">1 year back</option>
              <option value="two">2 year back</option>
              <option value="three">3 year back</option>
            </select>
            <input
              type="search"
              placeholder={t('content:search')}
              value={searchInputValueInquiry}
              onChange={onChangeInquiry}
              autoComplete="off"
            />
            <button
              className="ml-4 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="submit"
            >
              {t('content:search')}
            </button>
          </form>
          <p>
            <small>{t('adminSpecials.inquiryLeads.helperText')}</small>
          </p>
        </div>

        <div style={{ maxWidth: '800px', margin: '1rem auto 1rem' }}>
          <div className="mb-2">
            {csvDataObjectInquiry.data.length > 0 && (
              <GenerateCsvButton csvData={csvDataObjectInquiry} />
            )}
          </div>

          <h3>{t('adminSpecials.guideLeads.result')}:</h3>
          {loadingInquiry && <p>{t('adminSpecials.guideLeads.loading')}...</p>}

          {csvDataObjectInquiry.data.length <= 0 &&
            t('adminSpecials.guideLeads.noResult')}

          {csvDataObjectInquiry.data.map((lead) => (
            <div
              key={lead.id}
              style={{
                borderTop: '1px solid #ccc',
                margin: '10px',
                padding: '10px 0 0'
              }}
            >
              {lead.created},{lead.inquiryType},{lead.category},{lead.company},
              {lead.companySize},{lead.emailEnding},{lead.country},{lead.systemName}
            </div>
          ))}
        </div>

        <br />
        <hr />
        <br />
        <hr />
        <br />
        <hr />
        <br />

        <h3>{t('adminSpecials.convertText.title')}</h3>
        <div style={{ padding: '1rem' }}>
          <select
            id="convertToType"
            name="convertToType"
            value={convertToType}
            onChange={(e) => {
              setConvertToType(e.target.value)
            }}
          >
            <option value="upperCase">UPPER_CASE</option>
            <option value="camelCase">camelCase</option>
            <option value="camelCaseFromUpperCase">camelCase from UPPER_CASE</option>
          </select>
        </div>
        <div style={{ padding: '1rem' }}>
          <textarea
            name="textArea"
            id="textArea"
            cols="100"
            rows="20"
            value={textArea}
            onChange={(e) => {
              setTextArea(e.target.value)
            }}
          />
        </div>
        <div style={{ padding: '1rem' }}>
          <button
            type="button"
            onClick={handleText}
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t('adminSpecials.convertText.button.convert')}
          </button>
        </div>
        <div
          style={{ padding: '3rem' }}
          dangerouslySetInnerHTML={{ __html: formatedText }}
        />
      </PageBody>
    </>
  )
}

export default AdminSpecialsPage
