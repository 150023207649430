import React, { useCallback } from 'react'

import { useParams } from 'react-router-dom'
import useFetchSystem from '../../hooks/useFetchSystem'
import { useUserContext } from '../../contexts'
import { useAdminFieldsContext } from '../../contexts'

import Header from '../../components/market-researcher/header'
import { PageBody } from '../../components/page-body'
import ManageSystem from '../../components/manageSystem'

import { profileFields } from '../../constants'
import { isAdmin } from '../../utils'

const MarketResearcherSystemPage = () => {
  const { locale, category } = useParams()
  const { system, systemLoading } = useFetchSystem()
  const { user } = useUserContext()
  const { adminFieldsVisible } = useAdminFieldsContext()
  const hasAdmin = isAdmin(user?.role)

  const handleSetLangFields = useCallback(
    (activeTab) => {
      if (hasAdmin && adminFieldsVisible) {
        return false
      }

      // Same locale as selected, keep fields unlocked
      if (activeTab.value === locale) {
        return profileFields.langFieldsResearcher.map((field) => {
          if (field.id === 'introDescription') {
            field.disabled = true
          }

          return field
        })
      }

      // Other locale, lock every field
      return profileFields.langFieldsResearcher.map((field) => {
        return {
          ...field,
          disabled: true
        }
      })
    },
    [hasAdmin, adminFieldsVisible, locale]
  )

  const handleSetTabDisabled = useCallback(
    (tab) => {
      if (tab.type === 'CATEGORY' && tab.value !== category) {
        return true
      }

      return false
    },
    [category]
  )

  return (
    <>
      <Header loading={systemLoading} />

      {!systemLoading && (
        <PageBody>
          <ManageSystem
            mode="UPDATE"
            systemData={system}
            setLangFields={handleSetLangFields}
            setTabDisabled={
              !hasAdmin || !adminFieldsVisible ? handleSetTabDisabled : undefined
            }
            initialTab={{ value: locale, type: 'COUNTRY' }}
          />
        </PageBody>
      )}
    </>
  )
}

MarketResearcherSystemPage.propTypes = {}

MarketResearcherSystemPage.defaultProps = {}

export default MarketResearcherSystemPage
