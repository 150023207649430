import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  ShieldCheckIcon
} from '@heroicons/react/solid'

const PasswordStrengthChecker = ({ password }) => {
  const { t } = useTranslation('content') // TODO: Add namespace and translations for this component in localazy
  const [strengthClass, setStrengthClass] = useState('')
  const [strengthIcon, setStrengthIcon] = useState(null)
  const [bgClass, setBgClass] = useState('')

  const calculatePasswordStrength = (password) => {
    if (!password) return

    let score = 0

    // Length check
    if (password.length >= 12) score += 2

    // Character type checks
    const hasUpper = /[A-Z]/.test(password)
    const hasLower = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)
    const hasSpecial = /[@$!%*?&#]/.test(password)

    if (hasUpper) score += 1
    if (hasLower) score += 1
    if (hasNumber) score += 1
    if (hasSpecial) score += 1

    // Final evaluation based on score
    if (score >= 6) return 'veryStrong'
    if (score >= 4) return 'strong'
    if (score >= 2) return 'moderate'
    return 'weak'
  }

  useEffect(() => {
    const calculatedStrength = calculatePasswordStrength(password)

    switch (calculatedStrength) {
      case 'veryStrong':
        setStrengthClass('text-green-800 text-sm')
        setBgClass('bg-green-100')
        setStrengthIcon(<ShieldCheckIcon className="h-5 w-5 text-green-800" />)
        break
      case 'strong':
        setStrengthClass('text-blue-800 text-sm')
        setBgClass('bg-blue-100')
        setStrengthIcon(<CheckCircleIcon className="h-5 w-5 text-blue-800" />)
        break
      case 'moderate':
        setStrengthClass('text-yellow-800 text-sm')
        setBgClass('bg-yellow-100')
        setStrengthIcon(<ExclamationCircleIcon className="h-5 w-5 text-yellow-800" />)
        break
      case 'weak':
        setStrengthClass('text-red-800 text-sm')
        setBgClass('bg-red-100')
        setStrengthIcon(<XCircleIcon className="h-5 w-5 text-red-800" />)
        break
      default:
        setStrengthClass('')
        setBgClass('')
        setStrengthIcon(null)
    }
  }, [password])

  return (
    <>
      {password && (
        <div className={`flex items-center space-x-2 p-2 rounded-md my-2 ${bgClass}`}>
          {strengthIcon}
          <p className={strengthClass}>
            {t('passwordStrength')}:{' '}
            <strong>{t(`${calculatePasswordStrength(password)}`)}</strong>
          </p>
        </div>
      )}
    </>
  )
}

export default PasswordStrengthChecker
