import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

const CREATE_GUIDE_GQL = gql`
  mutation CreateGuide($data: GuideInput!) {
    createGuide(data: $data) {
      id
    }
  }
`
export default function useCreateGuide() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [createGuideMutation, { loading }] = useMutation(CREATE_GUIDE_GQL)

  const createGuide = useCallback(
    (data, onCompleted) => {
      return createGuideMutation({
        variables: {
          data
        },
        onCompleted: ({ createGuide }) => {
          onCompleted && onCompleted(createGuide)
          showSuccessNotification()
        },
        onError: showErrorNotification
      })
    },
    [createGuideMutation, showSuccessNotification, showErrorNotification]
  )

  return {
    createGuide,
    createGuideLoading: loading
  }
}
