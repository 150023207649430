import { isProd } from '../utils'
import createValidator from '../helpers/validator.helper'

const validationRules = JSON.parse(
  process.env.REACT_APP_PASSWORD_VALIDATION_RULES || '{}'
)

/**
 * This will check if passwords is valid based on rules
 * @param password
 * @param repeatPassword
 * @param t translations
 * @returns {{isValid: boolean, errors: Array}}
 */
const validatePassword = (password, repeatPassword, t = null) => {
  const validator = createValidator()

  const { minLength, capitalLetters, lowerCaseLetters, numbers, specialCharacters } =
    validationRules

  if (isProd) {
    if (password.length > 3 && repeatPassword.length > 3) {
      validator
        .validate(password)
        .hasIdenticalMatch(
          repeatPassword,
          t ? t('password.doesNotMatch') : 'Passwords do not match.'
        )
        .hasMinLength(
          minLength,
          t
            ? t('password.minLength', { value: minLength })
            : `Password must be at least ${minLength} characters long`
        )
        .hasCapitalLetters(
          capitalLetters,
          t
            ? capitalLetters == 1
              ? t('password.havecapitalLetters')
              : t('password.capitalLetters', { value: capitalLetters })
            : `Password must contain at least ${capitalLetters} uppercase letters`
        )
        .hasLowerCaseLetters(
          lowerCaseLetters,
          t
            ? lowerCaseLetters == 1
              ? t('password.havelowerCaseLetters')
              : t('password.lowerCaseLetters', { value: lowerCaseLetters })
            : `Password must contain at least ${lowerCaseLetters} lowercase letters`
        )
        .hasNumbers(
          numbers,
          t
            ? numbers > 1
              ? t('password.havenumbers')
              : t('password.numbers', { value: numbers })
            : `Password must contain at least ${numbers} numbers`
        )
        .hasSpecialCharacters(
          specialCharacters,
          t
            ? specialCharacters == 1
              ? t('password.havespecialCharacters')
              : t('password.specialCharacters', { value: specialCharacters })
            : `Password must contain at least ${specialCharacters} special characters`
        )
    }
  } else {
    // No restrictions in development
    validator
      .validate(password)
      .hasIdenticalMatch(
        repeatPassword,
        t ? t('password.doesNotMatch') : 'Passwords do not match.'
      )
  }
  return {
    isValid: validator.isValid(),
    errors: validator.getErrors(),
    results: validator.getResults()
  }
}

export { validatePassword, validationRules }
