import React, { useMemo } from 'react'
import { bool } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useGetCategoryName from '../../hooks/market-researcher/useGetCategoryName'
import useFetchSystem from '../../hooks/useFetchSystem'

import Loader from '../loader'
import Breadcrumbs from '../shared/breadcrumbs'

const Header = ({ loading }) => {
  const { t } = useTranslation()
  const { locale, category, systemId } = useParams()
  const { system } = useFetchSystem('cache-only')
  const { getCategoryName } = useGetCategoryName()

  const currentSystemNameMemo = useMemo(() => {
    if (!systemId || !locale || !system) {
      return ''
    }

    return system.systemSiteData.find((siteData) => siteData.locale === locale)
      ?.systemName
  }, [systemId, system, locale])

  const breadcrumbsMemo = useMemo(() => {
    const items = []

    if (locale) {
      items.push({
        text: t('marketResearcher:categories.header'),
        href: `/market-researcher/${locale}`
      })
    }

    if (category) {
      items.push({
        text: getCategoryName(category, locale),
        href: `/market-researcher/${locale}/${category}`
      })
    }

    if (systemId && currentSystemNameMemo) {
      items.push({
        text: currentSystemNameMemo,
        href: `/market-researcher/${locale}/${category}/${systemId}`
      })
    }

    return items
  }, [t, locale, category, systemId, currentSystemNameMemo, getCategoryName])

  return (
    <div className="flex flex-col max-w-7xl mx-auto px-4 mb-10 sm:px-6 md:px-8 ">
      <Breadcrumbs breadcrumbs={breadcrumbsMemo} loading={loading} />

      {loading && (
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  loading: bool
}

Header.defaultProps = {}

export default Header
