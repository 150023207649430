import React, { useMemo, useState } from 'react'
import { useMutation, gql, useApolloClient } from '@apollo/client'
import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'

import ListToolbar from './shared/list-toolbar'
import GenerateCsvButton from './shared/generate-csv-btn'

import * as ga from '../lib/ga'
import Flag from './shared/flag'

const SAVE_WATCHED = gql`
  mutation UpdateWatchedForms($id: ID!) {
    appUpdateWatchedForms(id: $id) {
      id
      isNew
    }
  }
`

const DATA_ROWS = [
  { dataKey: 'company', translationId: 'content:company' },
  { dataKey: 'name', translationId: 'content:name' },
  { dataKey: 'email', translationId: 'content:email' },
  { dataKey: 'licenses', translationId: 'content:licenses' },
  { dataKey: 'phone', translationId: 'content:phone' },
  { dataKey: 'textField', translationId: 'content:text' }
]

const FormLead = function ({ lead, isAdmin }) {
  const [isOpen, setIsOpen] = useState(false)
  const [hasOpen, setHasOpen] = useState(false)
  const client = useApolloClient()
  const { t } = useTranslation()
  const { isNew } = lead

  // Save to db first time a box has been toggle
  const [updateWatchedForms] = useMutation(SAVE_WATCHED, {
    onError(err) {
      // err is for example: "Error: notFound"
      const error = `${err}`.split(':').reverse()[0]
      console.log({ error })
    },
    update() {
      const { appFormLeadsNumbers } = client.readQuery({
        query: gql`
          query ReadFormLeadNumber {
            appFormLeadsNumbers {
              id
              result
            }
          }
        `
      })

      client.writeQuery({
        // ($id: Int!) -- (id: $id)
        query: gql`
          query WriteNewFormLeadNumber {
            appFormLeadsNumbers {
              id
              result
            }
          }
        `,
        data: {
          appFormLeadsNumbers: {
            __typename: 'ResultString',
            id: 'formLeadsNumber',
            result: String(parseInt(appFormLeadsNumbers.result, 10) - 1)
          }
        }
      })
    }
  })

  return (
    <li>
      <div
        className="block hover:bg-gray-50 cursor-pointer"
        type="button"
        onClick={() => {
          const isClosed = !isOpen

          setIsOpen(!isOpen)
          if (isNew && !hasOpen) {
            setHasOpen(true)
            // Trigger mutation
            if (!isAdmin) {
              updateWatchedForms({
                variables: { id: lead.id }
              })
            }
          }

          if (isClosed) {
            ga.event({
              action: 'open_lead_inquiry'
            })
          }
        }}
      >
        {isNew && (
          <span className="absolute inline-flex items-center px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800">
            {t('content:new')}
          </span>
        )}
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={lead.locale} />
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-blue-600 truncate">
                  {lead.company}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <time dateTime={lead.created}>
                    {lead.created.toLocaleString('sv').substr(0, 10)}
                  </time>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="flex text-sm">
                    <p className="font-medium truncate">{lead.systemName}</p>
                    <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                      i {lead.category}
                    </p>
                  </div>

                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {lead.inquiryType}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-10 py-5 bg-gray-50">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('content:contactInformation')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {DATA_ROWS.map((rowData, index) => {
                if (!lead?.[rowData.dataKey]) {
                  return null
                }

                return (
                  <div
                    className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                    key={index}
                  >
                    <dt className="text-sm font-medium text-gray-500">
                      {t(rowData.translationId)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {lead[rowData.dataKey]}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </div>
        </div>
      )}
    </li>
  )
}

export default function FormLeads({ leads, systemsArray, isAdmin }) {
  const csvDataObject = useMemo(() => {
    const data = {
      headers: [
        { label: 'Category', key: 'category' },
        { label: 'System Name', key: 'systemName' },
        { label: 'Name', key: 'name' },
        { label: 'Phone', key: 'phone' },
        { label: 'Email', key: 'email' },
        { label: 'Date', key: 'ended' },
        { label: 'Role', key: 'role' },
        { label: 'Locale', key: 'locale' },
        { label: 'Text', key: 'textField' }
      ],
      data: leads || []
    }

    if (!leads?.length) {
      return data
    }

    return data
  }, [leads])

  if (!leads?.length) {
    return <div>Tyvärr inte någon data.</div>
  }

  const updatedLeads = leads.map((lead) => {
    const systemName = systemsArray?.find(
      (systemObj) => systemObj.id === lead.systemId
    )?.name
    return { ...lead, systemName, created: new Date(lead.created) }
  })

  return (
    <>
      <ListToolbar>
        <GenerateCsvButton
          filename="enquiries.csv"
          csvData={csvDataObject}
          gaActionName="export_leads_csv"
        />
      </ListToolbar>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {updatedLeads
            .sort((a, b) => b.created - a.created)
            .map((lead) => {
              return <FormLead key={lead.created} lead={lead} isAdmin={isAdmin} />
            })}
        </ul>
      </div>
    </>
  )
}
