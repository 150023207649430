import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

// import { getSystemName } from '../helpers/systems.helper'
import { CalendarIcon, PhoneIcon } from '@heroicons/react/outline'
import Flag from '../shared/flag'

const BiLead = function ({ lead }) {
  const { t } = useTranslation('adminPages')
  const [isOpen, setIsOpen] = useState(false)

  // const guideAnswersFix = guideAnswers(lead.guideAnswersData)

  return (
    <li key={lead.id}>
      <div
        className={classNames('block', {
          'hover:bg-gray-50': lead.pages?.length,
          'bg-red-100 hover:bg-red-200': !lead.pages?.length
        })}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={lead.locale} />
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-blue-600 truncate flex">
                  {lead.name}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <time dateTime={lead.date}>
                    {lead.date.toLocaleString('sv').substr(0, 16)}
                  </time>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="flex text-sm">
                    <p className="font-medium truncate">
                      Pages: {lead.pages?.length}
                      <span className="font-normal text-gray-400">
                        {' - '}Sessions: {lead.sessions?.length}
                      </span>
                    </p>
                  </div>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    Employees: {lead.employeeMin} - {lead.employeeMax}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-10 py-5 bg-gray-50">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Data</h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {lead.pages?.map((page, i) => {
                return (
                  <div
                    key={`${page}-${lead.website}-${i}`}
                    className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                  >
                    <dt className="text-sm font-medium text-gray-500">Page</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {page}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </div>

          <div className="pt-5 border-t border-gray-200">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('content:contactInformation')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Website</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {lead.website}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </li>
  )
}

export default BiLead
