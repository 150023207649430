import React, { useState, memo } from 'react'
import cx from 'classnames'
import { ChevronRightIcon, ChevronDownIcon, CalendarIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'

import Flag from '../../shared/flag'

const ReviewListItem = ({ review, children, getSystemNameFn, getCategoryNameFn }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen((prev) => !prev)

  return (
    <li>
      <div
        className={cx('block cursor-pointer hover:bg-gray-50 border-l-8', {
          'border-green-600': review.reviewData.approved === true,
          'border-red-600': review.reviewData.approved === false
        })}
        type="button"
        onClick={toggleOpen}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={review.reviewData.locale} />
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p
                  className={cx('text-sm font-medium text-blue-600 truncate', {
                    'text-red-600': !review.reviewData.emailApproved
                  })}
                >
                  {review.email}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />

                  {DateTime.fromISO(review.createdAt).toFormat('yyyy-MM-dd HH:mm:ss')}
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="flex text-sm">
                    <p className="font-medium truncate">
                      {getSystemNameFn(review.reviewData.systemV2)}
                    </p>
                  </div>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {getCategoryNameFn(review.reviewData.category)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {isOpen ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        </div>
      </div>
      {isOpen && <div className="mx-5 my-5 sm:mx-10">{children}</div>}
    </li>
  )
}

export default memo(ReviewListItem)
