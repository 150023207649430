import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { array, func } from 'prop-types'

import Filters from '../shared/filters'

const LeadsFormsFilter = ({ allFormLeads, systemsArray, onLeadsFiltered }) => {
  const [mappedActiveFilters, setMappedActiveFilters] = useState({})

  const handleFilterLeads = useCallback(() => {
    // No Filters
    if (!Object.keys(mappedActiveFilters).length) {
      onLeadsFiltered(allFormLeads)
      return
    }

    const filteredLeadsForms = allFormLeads.filter((formLead) => {
      const sitesFilter = mappedActiveFilters.locale || []
      const categoriesFilter = mappedActiveFilters.category || []
      const systemsFilter = mappedActiveFilters.system || []

      let matchSites = true
      if (sitesFilter.length) {
        matchSites = sitesFilter.includes(formLead.locale)
      }

      let matchCategories = true
      if (categoriesFilter.length) {
        matchCategories = categoriesFilter.includes(formLead.category)
      }

      let matchSystems = true
      if (systemsFilter.length) {
        matchSystems = systemsFilter.includes(formLead.systemId)
      }

      return matchSites && matchCategories && matchSystems
    })

    onLeadsFiltered(filteredLeadsForms)
  }, [allFormLeads, mappedActiveFilters, onLeadsFiltered])

  useEffect(() => {
    handleFilterLeads()
  }, [handleFilterLeads])

  const handleChangeFilter = (updatedActiveFilters, updatedMappedActiveFilters) => {
    setMappedActiveFilters(updatedMappedActiveFilters)
  }

  const systemOptionsMemo = useMemo(() => {
    if (!systemsArray?.length) {
      return []
    }

    return systemsArray.map((system) => {
      return {
        label: system.name,
        value: system.id
      }
    })
  }, [systemsArray])

  const sitesOptionsMemo = useMemo(() => {
    if (!allFormLeads?.length) {
      return []
    }

    const localeSet = new Set()

    allFormLeads.forEach((formLead) => {
      if (!formLead.locale) {
        return
      }

      localeSet.add(formLead.locale)
    })

    return [...localeSet].map((locale) => {
      return {
        labelTid: `shared:localeToCountry.${locale}`,
        value: locale
      }
    })
  }, [allFormLeads])

  const categoryOptionsMemo = useMemo(() => {
    if (!allFormLeads?.length) {
      return []
    }
    const categorySet = new Set()

    allFormLeads.forEach((formLead) => {
      if (!formLead.category) {
        return
      }

      categorySet.add(formLead.category)
    })

    return [...categorySet].map((category) => {
      return {
        label: category,
        value: category
      }
    })
  }, [allFormLeads])

  const filtersMemo = useMemo(() => {
    return [
      {
        id: 'system',
        nameTid: 'content:system',
        options: systemOptionsMemo
      },
      {
        id: 'locale',
        nameTid: 'content:sites',
        options: sitesOptionsMemo
      },
      {
        id: 'category',
        nameTid: 'content:category',
        options: categoryOptionsMemo
      }
    ]
  }, [systemOptionsMemo, sitesOptionsMemo, categoryOptionsMemo])

  return <Filters filters={filtersMemo} onChangeFilters={handleChangeFilter} />
}

LeadsFormsFilter.propTypes = {
  allFormLeads: array,
  systemsArray: array,
  onLeadsFiltered: func
}

LeadsFormsFilter.defaultProps = {}

export default LeadsFormsFilter
