import React, { useMemo } from 'react'
import { bool, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useListCommonQuestions from '../../../../../hooks/manage-guides/useListCommonQuestions'
import useSetCommonQuestion from '../../../../../hooks/manage-guides/useSetCommonQuestion'

import Label from '../../../../shared/label'
import { Select } from '../../../../shared/select'

const CommonQuestionSelect = ({ questionId, commonQuestionId, disabled }) => {
  const { t } = useTranslation('adminPages')
  const { commonQuestions } = useListCommonQuestions()
  const { setCommonQuestion, setCommonQuestionLoading } = useSetCommonQuestion()

  const commonQuestionsOptionsMemo = useMemo(() => {
    return commonQuestions.map((question) => {
      return {
        label: question.name,
        value: question.id
      }
    })
  }, [commonQuestions])

  const handleChangeCommonQuestion = (commonQuestionId) => {
    setCommonQuestion(questionId, commonQuestionId)
  }

  return (
    <Label title={t('adminManageGuide.guide.question.common')} marginless>
      <Select
        options={commonQuestionsOptionsMemo}
        onChange={handleChangeCommonQuestion}
        rawChangeEvent={false}
        value={commonQuestionId || undefined}
        loading={setCommonQuestionLoading}
        disabled={disabled}
        isSearchable={false}
        isClearable
      />
    </Label>
  )
}

CommonQuestionSelect.propTypes = {
  questionId: string.isRequired,
  commonQuestionId: string,
  disabled: bool
}

CommonQuestionSelect.defaultProps = {}

export default CommonQuestionSelect
