import React from 'react'

import NavMarketResearcher from './navMarketResearcher'
import CookieConsent from '../cookieConsent'
import { Outlet } from 'react-router-dom'

import '../../styles/globals.css'

function LayoutMarketResearcher() {
  return (
    <>
      <div>
        <div className="flex flex-col flex-1">
          <NavMarketResearcher />

          <main className="flex-1">
            <div className="py-6">
              <Outlet />
            </div>
          </main>
        </div>
      </div>

      <CookieConsent />
    </>
  )
}

LayoutMarketResearcher.propTypes = {}

export default LayoutMarketResearcher
