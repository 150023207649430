import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'

/**
 * Returns JSON object with following structure
 * {
 *  [locale]: {
 *    [category]: Count (integer)
 *  },
 *  site: {
 *   [category]: Count (integer)
 *  }
 * }
 */
const GQL_FETCH_SYSTEM_COUNT = gql`
  query SystemsCountInCategoriesMR($locale: String!) {
    mrGetSystemsCountInCategories(locale: $locale)
  }
`

const EMPTY_OBJ = {}

export default function useFetchSytemsCountInCategories() {
  const { locale } = useParams()

  const { data, loading } = useQuery(GQL_FETCH_SYSTEM_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      locale
    },
    skip: !locale
  })

  return {
    mrSystemsCount: data?.mrGetSystemsCountInCategories || EMPTY_OBJ,
    mrSystemsCountLoading: loading
  }
}
