export const profileFieldsCategories = Object.freeze({
  market: { sort: 10 },
  price: { sort: 20 },
  profile: { sort: 30 },
  functions: { sort: 40 },
  subfunctions: { sort: 45 },
  education: { sort: 50 },
  support: { sort: 60 },
  other: { sort: 100 }
})
