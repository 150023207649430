import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const uri = process.env.REACT_APP_APP_BE_URL

const httpLink = createUploadLink({
  uri
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  const user = localStorage.getItem('businessWithUser')
  const userJson = JSON.parse(user)
  const token = userJson && userJson.token

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        appAdminGetGuideResults: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          merge(existing, incoming) {
            if (!existing) {
              return incoming
            }

            return {
              ...incoming,
              data: [...existing.data, ...incoming.data]
            }
          }
        },
        appAdminFormLeads: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          merge(existing, incoming) {
            if (!existing) {
              return incoming
            }

            return {
              ...incoming,
              data: [...existing.data, ...incoming.data]
            }
          }
        },
        appAdminAllReviews: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          merge(existing, incoming) {
            if (!existing) {
              return incoming
            }

            return {
              ...incoming,
              data: [...existing.data, ...incoming.data]
            }
          }
        }
      }
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
})
