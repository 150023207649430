import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useManageSystemContext } from '../../context'
import { useUserContext } from '../../../../contexts'
import { isAdmin } from '../../../../utils'
import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import Dialog from '../../../shared/dialog'
import Input from '../../../shared/input'
import Button from '../../../shared/button'

import AdminField from '../../../shared/admin-field'

const DELETE_SYSTEM_GQL = gql`
  mutation DeleteSystem($id: ID!) {
    appDeleteSystem(id: $id) {
      id
    }
  }
`

const DeleteSystem = () => {
  const { t } = useTranslation()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const { fieldsData } = useManageSystemContext()
  const navigate = useNavigate()
  const { user } = useUserContext()
  const hasAdminRole = isAdmin(user?.role)
  const { showErrorNotification } = useOverlayNotification()
  const { id: systemId } = fieldsData

  const [deleteSystem, { loading: deleteSystemLoading }] = useMutation(
    DELETE_SYSTEM_GQL,
    {
      variables: {
        id: systemId
      },
      onCompleted: () => {
        navigate('/')
      },
      onError: showErrorNotification
    }
  )

  const handleDeleteSystem = () => {
    deleteSystem()
  }

  const handleDialogClose = () => {
    setConfirmOpen(false)
    setConfirmText('')
  }

  if (!hasAdminRole) {
    return null
  }

  return (
    <AdminField>
      <Button mode="default" onClick={() => setConfirmOpen(true)}>
        {t('forms:system.deleteDialog.title')}
      </Button>

      <Dialog
        title={t('forms:system.deleteDialog.title')}
        isOpen={confirmOpen}
        onClose={handleDialogClose}
        confirmDisabled={confirmText !== 'DELETE' || deleteSystemLoading}
        onConfirm={handleDeleteSystem}
        confirmText={t('content:delete')}
      >
        <Input
          label={t('forms:system.deleteDialog.inputLabel')}
          helperText={t('forms:system.deleteDialog.inputHelper')}
          onChange={setConfirmText}
          value={confirmText}
        />
      </Dialog>
    </AdminField>
  )
}

export default DeleteSystem
