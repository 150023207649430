import { useState, useEffect, useCallback } from 'react'
import isEqual from 'react-fast-compare'
/**
 * liveValidationWaitForFormData - wait for formData to have at least one key before validating
 */
export default function useGenericFormManager(fields, defaultFormData, options = {}) {
  const [formData, setFormData] = useState({})
  const [changedFields, setChangedFields] = useState({})
  const [validationErrors, setValidationErrors] = useState({})
  const {
    enableLiveValidation,
    ignoreFields,
    extraValidatorData,
    liveValidationWaitForFormData
  } = options
  const validatorRunnable =
    !liveValidationWaitForFormData || Object.keys(formData).length > 0

  useEffect(() => {
    if (defaultFormData) {
      setFormData(defaultFormData || {})
    }
  }, [defaultFormData])

  const validate = useCallback(() => {
    const errors = {}

    fields.forEach((field) => {
      if (!field.validators) {
        return
      }

      if (ignoreFields?.[field.name]?.(formData, extraValidatorData)) {
        return
      }

      field.validators.forEach((validator) => {
        // Field already has errors
        if (errors[field.name]) {
          return
        }

        if (!validator.validator(formData[field.name], extraValidatorData)) {
          errors[field.name] = validator.error
        }
      })
    })

    const hasErrors = Boolean(Object.keys(errors).length)

    if (!isEqual(validationErrors, errors)) {
      setValidationErrors(errors)
    }

    // True if there are no errors
    return !hasErrors
  }, [fields, formData, ignoreFields, validationErrors])

  useEffect(() => {
    if (enableLiveValidation && validatorRunnable) {
      validate()
    }
  }, [enableLiveValidation, validatorRunnable, validate])

  const deleteValidationErrorForField = useCallback(
    (fieldName) => {
      if (validationErrors[fieldName]) {
        delete validationErrors[fieldName]
      }
    },
    [validationErrors]
  )

  const onChange = useCallback(
    (fieldName, value) => {
      setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: value }))
      setChangedFields((prevFormData) => ({ ...prevFormData, [fieldName]: value }))

      if (!enableLiveValidation) {
        deleteValidationErrorForField(fieldName)
      }
    },
    [enableLiveValidation, deleteValidationErrorForField]
  )

  const onChangeMultiple = useCallback(
    (fields) => {
      setFormData((prevFormData) => ({ ...prevFormData, ...fields }))
      setChangedFields((prevFormData) => ({ ...prevFormData, ...fields }))

      if (!enableLiveValidation) {
        Object.keys(fields).forEach((fieldName) => {
          deleteValidationErrorForField(fieldName)
        })
      }
    },
    [enableLiveValidation, deleteValidationErrorForField]
  )

  const resetValidationError = useCallback((fieldName) => {
    setValidationErrors((prevErrors) => {
      delete prevErrors[fieldName]
      return prevErrors
    })
  }, [])

  const resetValidationErrors = useCallback(() => {
    setValidationErrors({})
  }, [])

  const resetChangedFields = useCallback(() => {
    setChangedFields({})
  }, [])

  const resetForm = useCallback(() => {
    setFormData({})
    setChangedFields({})
    resetValidationErrors()
  }, [resetValidationErrors])

  return {
    onChange,
    onChangeMultiple,
    formData,
    changedFields,
    validate,
    validationErrors,
    resetValidationError,
    resetValidationErrors,
    resetChangedFields,
    resetForm,
    setFormData
  }
}
