import React from 'react'

import { useTranslation } from 'react-i18next'
import useGetSystemName from '../../../hooks/useGetSystemName'
import useGetCategoryName from '../../../hooks/useGetCategoryName'

import ReviewListItem from './list-item'
import ReviewItem from './item'

export default function ReviewsList({ reviews }) {
  const { t } = useTranslation()
  const { getSystemName } = useGetSystemName()
  const { getCategoryName, getCategoriesString } = useGetCategoryName()

  if (!reviews?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {reviews.map((review) => {
          return (
            <ReviewListItem
              key={review.id}
              review={review}
              getSystemNameFn={getSystemName}
              getCategoryNameFn={getCategoryName}
            >
              <ReviewItem review={review} getCategoriesStringFn={getCategoriesString} />
            </ReviewListItem>
          )
        })}
      </ul>
    </div>
  )
}
