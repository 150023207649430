import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMarketResearcherContext } from '../../contexts'
import useFetchCategories from '../../hooks/market-researcher/useFetchCategories'
import useFetchSytemsCountInCategories from '../../hooks/market-researcher/useFetchSystemsCountInCategories'
import useGetCategoryName from '../../hooks/market-researcher/useGetCategoryName'

import { PageBody } from '../../components/page-body'
import Header from '../../components/market-researcher/header'
import CategoriesFilter from '../../components/market-researcher/categories/categories-filter'
import LoadingSpinner from '../../components/shared/loading-spinner'
import CategoriesExport from '../../components/market-researcher/categories/categories-export'
import AdminField from '../../components/shared/admin-field'
import ProductFunctionsExport from '../../components/market-researcher/product-functions/product-functions-export'

const MarketResearcherPage = () => {
  const { t } = useTranslation()
  const { locale } = useParams()
  const [filteredCategories, setFilteredCategories] = useState([])
  const { getCategoryName } = useGetCategoryName()
  const { mrSitesLoading, navigateToCategory } = useMarketResearcherContext()
  const { mrCategories, mrCategoriesLoading } = useFetchCategories('cache-and-network')
  const { mrSystemsCount, mrSystemsCountLoading } = useFetchSytemsCountInCategories()
  const isLoading = mrSitesLoading || mrCategoriesLoading

  /**
   * Merge categories object with systems count object
   */
  const categoriesWithSystemsCountMemo = useMemo(() => {
    return mrCategories
      .filter((category) => {
        return !!category.locales[locale]
      })
      .map((category) => {
        return {
          ...category,
          systemsCount: {
            locale: mrSystemsCount?.[locale]?.[category.dbName] || 0,
            site: mrSystemsCount?.site?.[category.dbName] || 0
          }
        }
      })
  }, [mrCategories, mrSystemsCount, locale])

  if (isLoading) {
    return <Header loading={isLoading} />
  }

  return (
    <>
      <Header />

      <PageBody>
        <AdminField className="mb-8">
          <ProductFunctionsExport />
        </AdminField>

        <div className="mb-4 flex gap-2">
          <CategoriesExport
            categoriesData={categoriesWithSystemsCountMemo}
            loading={mrCategoriesLoading || mrSystemsCountLoading}
          />
        </div>

        <CategoriesFilter
          categories={categoriesWithSystemsCountMemo}
          onFilter={setFilteredCategories}
        />

        <div className="grid grid-cols-1 gap-4">
          {!filteredCategories.length && (
            <p className="text-center">
              {t('marketResearcher:categories.filter.noResult')}
            </p>
          )}

          {filteredCategories.map((category) => {
            const systemsCountLocale = category.systemsCount?.locale
            const sytemsCountSite = category.systemsCount?.site
            const categoryName = getCategoryName(category.dbName, locale)
            const categoryNameBackup = getCategoryName(category.dbName, 'en_GB')

            return (
              <div
                key={category.dbName}
                className="p-3 border border-gray-300 shadow-sm bg-white cursor-pointer rounded flex flex-col"
                onClick={navigateToCategory.bind(null, category.dbName)}
              >
                <span>
                  <span className="font-medium mr-2">{categoryName}</span>
                  <span className="text-gray-500 italic">({categoryNameBackup})</span>
                </span>

                <span className="text-sm">
                  {`${t('marketResearcher:categories.items.label.systemsCountInLocale', {
                    locale
                  })}: `}
                  {mrSystemsCountLoading ? (
                    <LoadingSpinner className="text-gray-800" />
                  ) : (
                    systemsCountLocale
                  )}
                </span>
                <span className="text-sm">
                  {`${t('marketResearcher:categories.items.label.systemsCountOnSite')}: `}
                  {mrSystemsCountLoading ? (
                    <LoadingSpinner className="text-gray-800" />
                  ) : (
                    sytemsCountSite
                  )}
                </span>
              </div>
            )
          })}
        </div>
      </PageBody>
    </>
  )
}

MarketResearcherPage.propTypes = {}

MarketResearcherPage.defaultProps = {}

export default MarketResearcherPage
