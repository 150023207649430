import React from 'react'
import { array, func } from 'prop-types'

import AssetsRenderer from './assets-renderer'
import Dropzone from './dropzone'

const ASSET_DOCUMENT = 'DOCUMENT'

const DocumentSection = ({ onChange, assets }) => {
  return (
    <div className="flex gap-3 flex-wrap">
      <AssetsRenderer assetType={ASSET_DOCUMENT} assets={assets} onChange={onChange} />
      <Dropzone
        assetType={ASSET_DOCUMENT}
        onDrop={onChange}
        accept={{
          'application/pdf': ['.pdf']
        }}
      />
    </div>
  )
}

DocumentSection.propTypes = {
  onChange: func.isRequired,
  assets: array.isRequired
}

DocumentSection.defaultProps = {}

export default DocumentSection
