import React from 'react'
import { Link } from 'react-router-dom'
import TermsOfUse from '../../data/termsOfUse'
import { PageBody } from '../../components/page-body'

function TermsOfUsePage() {
  return (
    <PageBody>
      <div style={{ maxWidth: '800px', margin: '0 auto', padding: '2rem' }}>
        <TermsOfUse />

        <Link to="/legal">
          <span>&larr; Legal</span>
        </Link>
      </div>
    </PageBody>
  )
}

export default TermsOfUsePage
