import React, { useState } from 'react'
import UserForm from './userform'

import {
  ChevronUpIcon,
  ChevronDownIcon,
  CalendarIcon,
  UserIcon
} from '@heroicons/react/solid'
const UserTableItem = ({ user, userFormOptions, onCompleteSuccess }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <li className="even:bg-gray-50">
        <div
          className="block hover:bg-gray-50 cursor-pointer"
          type="button"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="min-w-0 flex-1 flex items-center">
              <div className="flex-shrink-0">
                <div className="h-15 w-10 rounded-full bg-cover bg-center">
                  {user.disabled ? (
                    <UserIcon className="h-10 w-10 text-gray-400/75" />
                  ) : user.latestSignedIn ? (
                    <UserIcon className="h-10 w-10 text-green-400/75" />
                  ) : (
                    <UserIcon className="h-10 w-10 text-red-400/75" />
                  )}
                </div>
              </div>
              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p className="text-sm font-medium text-blue-600 truncate">
                    {user.name}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {user.latestSignedIn ? (
                      <>
                        <CalendarIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <time dateTime={user.latestSignedIn}>
                          {user.latestSignedIn?.toLocaleString('sv').substr(0, 10)}
                        </time>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
                <div className="hidden md:block">
                  <div>
                    <div className="flex text-sm">
                      <p className="font-medium truncate">{user.appRole}</p>
                    </div>

                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      {user.email}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {!isOpen && (
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
              {isOpen && (
                <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <UserForm
            isCreate={false}
            userFormOptions={userFormOptions}
            user={user}
            onCompleteSuccess={onCompleteSuccess}
            key={`UserForm-${user.id}`}
          />
        )}
      </li>
    </>
  )
}
export default UserTableItem
