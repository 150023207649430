import React from 'react'
import { bool, func, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import LoadingSpinner from './loading-spinner'

const Button = ({
  className,
  onClick,
  mode,
  disabled,
  size,
  children,
  Icon,
  loading,
  type
}) => {
  const buttonBackgroundClass = classNames(
    'inline-flex justify-center border border-transparent shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 items-center',
    {
      'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:bg-indigo-700':
        mode === 'primary',
      'text-gray-100 bg-gray-500 hover:bg-gray-600 focus:ring-gray-700 focus:bg-gray-600':
        mode === 'default',
      'text-white bg-red-500 hover:bg-red-600 focus:ring-red-400 focus:bg-red-600':
        mode === 'error',
      'text-gray-500 bg-transparent !border-gray-400 border-inherit': mode === 'outlined',
      'py-2 px-4': size === 'md' && !!children,
      'p-2': !children,
      'h-8 py-1 px-2': size === 'small',
      'disabled:opacity-50': disabled || loading
    }
  )

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={classNames('relative', buttonBackgroundClass, className)}
      onClick={onClick}
    >
      {loading && (
        <div className={classNames('absolute h-full w-full', buttonBackgroundClass)}>
          <LoadingSpinner />
        </div>
      )}

      {Icon && (
        <Icon
          className={classNames('w-4 h-4', {
            'mr-1': !!children
          })}
        />
      )}
      {children}
    </button>
  )
}

Button.propTypes = {
  onClick: func,
  mode: oneOf(['primary', 'default', 'error', 'outlined']),
  size: oneOf(['small', 'md']),
  disabled: bool,
  loading: bool,
  type: string
}

Button.defaultProps = {
  mode: 'primary',
  type: 'button',
  size: 'md'
}

export default Button
