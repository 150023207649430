import React, { useMemo, forwardRef } from 'react'

import Filters from '../../shared/filters'
import { func, object, string } from 'prop-types'

const SPECIAL_FILTERS = {
  PRODUCT_FUNCTIONALITIES: {
    name: 'Product Functionalities (P)'
  },
  SYSTEM_CHARACTERISTICS: {
    name: 'System Characteristics (P)'
  }
}

const AnswersOptions = forwardRef(
  ({ guide, locale, onChange, isSimulation }, forwardedRef) => {
    const filtersMemo = useMemo(() => {
      if (!guide) {
        return []
      }

      return guide.guideQuestions
        .sort((a, b) => {
          const requestedOrder = Object.keys(SPECIAL_FILTERS)
          const indexOfA = requestedOrder.indexOf(a.name)
          const indexOfB = requestedOrder.indexOf(b.name)

          return indexOfB - indexOfA
        })
        .map((question) => {
          const specialFilterData = SPECIAL_FILTERS[question.name]

          if (!question.filter && !specialFilterData) {
            return
          }

          const specialName = specialFilterData?.name
          const filterName = question.filter ? `${question.name} (F)` : specialName
          return {
            id: question.id,
            name: filterName,
            singleValue: isSimulation ? false : question.type === 'radio',
            options: question.guideAnswers.map((answer) => {
              return {
                label: answer.texts[locale],
                value: answer.id,
                specialName: answer.specialName
              }
            })
          }
        })
        .filter((exists) => exists)
    }, [guide, locale, isSimulation])

    return (
      <Filters
        ref={forwardedRef}
        filters={filtersMemo}
        onChangeFilters={onChange}
        enableDynamicFilters
      />
    )
  }
)

AnswersOptions.displayName = 'AnswersOptions'

AnswersOptions.propTypes = {
  guide: object.isRequired,
  locale: string,
  onChange: func.isRequired
}

AnswersOptions.defaultProps = {}

export default AnswersOptions
