import { useApolloClient, gql } from '@apollo/client'
import { GUIDE_QUESTION_FRAGMENT } from '../../constants/gql/guide'

export default function useGetCachedGuideQuestion(questionId) {
  const client = useApolloClient()

  if (!questionId) {
    return false
  }

  return client.readFragment(
    {
      id: `GuideQuestion:${questionId}`,
      fragment: gql`
        fragment cachedGuideQuestion on GuideQuestion {
          ${GUIDE_QUESTION_FRAGMENT}
        }
      `
    },
    true
  )
}
