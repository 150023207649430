import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { MarketResearcherContextProvider } from './contexts'

import useFetchUser from './hooks/useFetchUser'

import { LayoutExternal } from './components/layout'
import Layout from './components/layout/layout'

import AcceptPage from './pages/accept.page'
import AdminAddSytemPage from './pages/admin-add-system.page'
import AdminAddSytemsPage from './pages/admin-add-systems.page'
import AdminAddUserPage from './pages/admin-add-user.page'
import AdminSpecialsPage from './pages/admin-specials.page'
import AdminAddVendorPage from './pages/admin-add-vendor.page'
import AdminAllFormsPage from './pages/admin-all-forms.page'
import AdminAllGuidesPage from './pages/admin-all-guides.page'
import AdminAllReviewsPage from './pages/admin-all-reviews'
import AdminAllBiPage from './pages/admin-all-bi.page'
import AdminSystemBiPage from './pages/admin-system-bi.page'
import AdminBwBiPage from './pages/admin-bw-bi.page'
import AdminAllUsersPage from './pages/admin-all-users.page'
import AdminLatestSystemsPage from './pages/admin-latest-systems.page'
import AdminDeployPage from './pages/admin-deploy.page'
import AdminEditUserPage from './pages/admin-edit-user.page'
import AdminEditVendorPage from './pages/admin-edit-vendor.page'
import AdminManageMarketResearchersPage from './pages/admin-manage-market-researchers.page'
import AdminManageGuidesPages from './pages/admin-manage-guides.page'
import AdminManageGuidePage from './pages/admin-manage-guide.page'
import AdminManageCommonQuestions from './pages/admin-manage-common-questions.page'
import AdminSimulatorGuidePage from './pages/admin-simulator-guide.page'
import AdminVendorOutboundsPage from './pages/admin-vendor-outbounds.page'
import BuyingIntentPage from './pages/buying-intent.page'
import HomePage from './pages/home.page'
import LeadsFormsPage from './pages/leads-forms.page'
import LeadsGuidePage from './pages/leads-guide.page'
import LegalPage from './pages/legal.page'
import PrivacyPolicyPage from './pages/legal/privacy-policy.page'
import TermsOfUsePage from './pages/legal/terms-of-use.page'
import LoginPage from './pages/login.page'
import NoVendorPage from './pages/no-vendor.page'
import SystemDataPage from './pages/system-data.page'
import SystemsReviewsPage from './pages/systems-reviews.page'
import IntegrationSettingsPage from './pages/integration-settings.page'
import RequestPasswordResetPage from './pages/request-password-reset.page'
import ResetPasswordPage from './pages/reset-password.page'
import SettingsPage from './pages/settings.page'
import UsersPage from './pages/users.page'
import UpdatePasswordPage from './pages/update-password.page'
import * as ga from './lib/ga'

// Market researcher
import LayoutMarketResearcher from './components/layout/layoutMarketResearcher'
import MarketResearcherCategoriesPage from './pages/market-researcher/mr-categories.page'
import MarketResearcherSystemsPage from './pages/market-researcher/mr-systems.page'
import MarketResearcherSystemPage from './pages/market-researcher/mr-system.page'

// Other
import ProtectedRoute from './routes/protectedRoutes'
import ProtectedRouteMR from './routes/protectedRoutesMR'

import AdminAddSystemPage from './pages/admin-add-system.page'
import AdminAllRedirects from './pages/admin-all-redirects'

const App = () => {
  const location = useLocation()
  useFetchUser()

  useEffect(() => {
    ga.pageview(window.location)
  }, [location])

  return (
    <Routes>
      <Route element={<LayoutExternal />}>
        <Route
          path="/accept"
          element={
            <ProtectedRoute allowNotAcceptedTerms allowMR>
              <AcceptPage />
            </ProtectedRoute>
          }
        />
        {/*<Route path="/create-account" element={<CreateAccountPage />} />*/}
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/no-vendor"
          element={
            <ProtectedRoute allowNoVendor allowNotAcceptedTerms allowFirstLogin>
              <NoVendorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-password"
          element={
            <ProtectedRoute allowNotAcceptedTerms allowFirstLogin allowMR>
              <UpdatePasswordPage />
            </ProtectedRoute>
          }
        />
        <Route path="/request-password-reset" element={<RequestPasswordResetPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Route>

      {/* User and admin routes */}
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-system"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAddSytemPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-system"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAddSystemPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-systems"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAddSytemsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-user"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAddUserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-add-vendor"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAddVendorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-all-forms"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAllFormsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-all-guides"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAllGuidesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-all-reviews"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAllReviewsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-all-bi"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAllBiPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-system-bi"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminSystemBiPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-bw-bi"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminBwBiPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-all-users"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAllUsersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-latest-systems"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminLatestSystemsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-vendor-outbounds/:vendorId"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminVendorOutboundsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-deploy"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminDeployPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-redirects"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminAllRedirects />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-edit-user"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminEditUserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-edit-vendor"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminEditVendorPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-simulator-guide-v2"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminSimulatorGuidePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin-specials"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminSpecialsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-manage-market-researchers"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminManageMarketResearchersPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin-manage-guides"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminManageGuidesPages />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin-manage-guides/common-questions"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminManageCommonQuestions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin-manage-guides/:guideId"
          element={
            <ProtectedRoute requireIsAdmin>
              <AdminManageGuidePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/buying-intent"
          element={
            <ProtectedRoute>
              <BuyingIntentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads-forms"
          element={
            <ProtectedRoute>
              <LeadsFormsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads-guide"
          element={
            <ProtectedRoute>
              <LeadsGuidePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/integration-settings"
          element={
            <ProtectedRoute>
              <IntegrationSettingsPage />
            </ProtectedRoute>
          }
        />

        <Route path="/legal" element={<LegalPage />} />
        <Route path="/legal/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/legal/terms-of-use" element={<TermsOfUsePage />} />

        <Route
          path="/system/:systemId"
          element={
            <ProtectedRoute>
              <SystemDataPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <ProtectedRoute>
              <UsersPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/reviews/your-reviews"
          element={
            <ProtectedRoute>
              <SystemsReviewsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* Market researcher routes */}
      <Route
        element={
          <ProtectedRouteMR>
            <MarketResearcherContextProvider>
              <LayoutMarketResearcher />
            </MarketResearcherContextProvider>
          </ProtectedRouteMR>
        }
      >
        <Route path="/market-researcher" element={<MarketResearcherCategoriesPage />} />

        <Route
          path="/market-researcher/:locale"
          element={<MarketResearcherCategoriesPage />}
        />

        <Route
          path="/market-researcher/:locale/:category"
          element={<MarketResearcherSystemsPage />}
        />

        <Route
          path="/market-researcher/:locale/:category/:systemId"
          element={<MarketResearcherSystemPage />}
        />
      </Route>
    </Routes>
  )
}

export default App
