import React from 'react'
import { bool, func, object, oneOfType, string } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useUpdateQuestionGeneric from '../../../../../hooks/manage-guides/useUpdateQuestionGeneric'

import Dialog from '../../../../shared/dialog'
import EditQuestionText from './edit-question-text'
import Label from '../../../../shared/label'
import Button from '../../../../shared/button'
import ManageAnswersList from './manage-answers-list'
import DeleteQuestionButton from './delete-question-btn'
import MultipleTypeSettings from './multiple-type-settings'

import CommonQuestionSelect from './common-question-select'
import FilterQuestionSelect from './filter-question-select'
import RequiredQuestionSelect from './required-question-select'
import TypeQuestionSelect from './type-question-select'
import EditableInput from '../../shared/editable-input'

const EditQuestionDialog = ({
  guideQuestion,
  locale,
  onClose,
  disableCommonSelect,
  forceShowSpecialName
}) => {
  const { updateQuestion, updateQuestionLoading } = useUpdateQuestionGeneric()
  const { t } = useTranslation('adminPages')
  const questionId = guideQuestion?.id
  const isOpen = !!guideQuestion

  if (!guideQuestion) {
    return null
  }

  const handleCancel = () => {
    onClose(false)
  }

  const handleGenericChange = (field, value) => {
    return updateQuestion(questionId, { [field]: value })
  }

  const renderFooter = () => {
    return (
      <div className="flex justify-between mt-5 sm:mt-6">
        <DeleteQuestionButton questionId={questionId || ''} onDeleted={onClose} />

        <Button onClick={handleCancel} mode="default">
          {t('content:close')}
        </Button>
      </div>
    )
  }

  const isCommon = !!guideQuestion.isCommon

  return (
    <>
      <Dialog
        title={t('adminManageGuide.guide.question.edit.title')}
        isOpen={isOpen}
        className="!max-w-5xl"
        renderFooter={renderFooter}
      >
        <div className="flex gap-2">
          <div className="flex flex-col flex-1 gap-4">
            <Label title={t('adminManageGuide.guide.question.edit.text')} marginless>
              <EditQuestionText
                id="text"
                questionId={questionId}
                texts={guideQuestion.texts}
                locale={locale}
                disabled={isCommon}
              />
            </Label>
            <Label title={t('adminManageGuide.guide.question.edit.shortText')} marginless>
              <EditQuestionText
                id="short"
                questionId={questionId}
                texts={guideQuestion.textsShort}
                locale={locale}
                disabled={isCommon}
              />
            </Label>
            <Label title={t('adminManageGuide.guide.question.edit.helpText')} marginless>
              <EditQuestionText
                id="help"
                questionId={questionId}
                texts={guideQuestion.textsHelp}
                locale={locale}
                disabled={isCommon}
              />
            </Label>

            <Label
              title={t('adminManageGuide.guide.question.edit.internalName')}
              marginless
            >
              <EditableInput
                id="name"
                onSave={handleGenericChange}
                value={guideQuestion.name}
                loading={updateQuestionLoading}
                disabled={!!guideQuestion.commonQuestionId}
              />
            </Label>
          </div>

          <div className="flex flex-col gap-4 w-72">
            <CommonQuestionSelect
              questionId={guideQuestion.id}
              commonQuestionId={guideQuestion.commonQuestionId}
              disabled={disableCommonSelect}
            />

            <RequiredQuestionSelect
              onChange={handleGenericChange}
              required={guideQuestion.required || false}
            />

            <FilterQuestionSelect
              onChange={handleGenericChange}
              filter={guideQuestion.filter}
            />

            <TypeQuestionSelect
              onChange={handleGenericChange}
              type={guideQuestion.type}
            />
          </div>
        </div>

        {guideQuestion.type === 'multiple' && (
          <MultipleTypeSettings
            onChange={handleGenericChange}
            loading={updateQuestionLoading}
            answerAlternatives={guideQuestion.answerAlternatives}
            answersHeadings={guideQuestion.answersHeadings}
            disabled={!!guideQuestion.commonQuestionId}
          />
        )}

        <hr className="my-6" />

        <div className="flex justify-between mb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('adminManageGuide.guide.question.edit.answers.title')}
          </h3>
        </div>

        <ManageAnswersList
          questionId={questionId || ''}
          forceShowSpecialName={forceShowSpecialName}
          questionName={guideQuestion?.name}
          questionFilter={guideQuestion?.filter}
          guideAnswers={guideQuestion?.guideAnswers}
          locale={locale}
          readOnly={guideQuestion.autogenerated || guideQuestion?.hasCommonAnswers}
          hideCreator={guideQuestion.autogenerated}
        />
      </Dialog>
    </>
  )
}

EditQuestionDialog.propTypes = {
  question: oneOfType([bool, object]),
  locale: string,
  onClose: func,
  disableCommonSelect: bool,
  forceShowSpecialName: bool
}

EditQuestionDialog.defaultProps = {}

export default EditQuestionDialog
