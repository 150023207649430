import React, { useState } from 'react'
import { object } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import useDeleteSiteData from '../../hooks/useDeleteSiteData'
import { useManageSystemContext } from '../../context'

import AdminField from '../../../shared/admin-field'
import Dialog from '../../../shared/dialog'
import Input from '../../../shared/input'
import Button from '../../../shared/button'

const DeleteSiteData = ({ siteData }) => {
  const { t } = useTranslation()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const { category, locale } = useParams()
  const { deleteSiteData, deleteSiteDataLoading } = useDeleteSiteData()
  const { activeTab } = useManageSystemContext()
  const navigate = useNavigate()
  const location = useLocation()
  const isLoading = deleteSiteDataLoading

  const handleDialogClose = () => {
    setConfirmOpen(false)
    setConfirmText('')
  }

  const handleSiteDataDeleted = ({ appDeleteSiteData }) => {
    const hasSiteDatas = appDeleteSiteData?.systemSiteData?.length > 0

    // If there are more system datas, reload is enough
    if (hasSiteDatas) {
      navigate(0) // Reload
    } else {
      // If there are no more siteDatas, redirect to "root"

      if (location.pathname.includes('/system/')) {
        // Redirect from admin view to main page
        navigate('/')
      } else {
        // Redirect from market researcher view to category
        navigate(`/market-researcher/${locale}/${category}`)
      }
    }
  }

  const handleDeleteSiteData = () => {
    if (!siteData.id) {
      return
    }

    deleteSiteData(siteData.id, handleSiteDataDeleted)
  }

  return (
    <AdminField>
      <Button mode="default" onClick={() => setConfirmOpen(true)}>
        {t('forms:system.countryForm.btnDeleteSiteData', {
          locale: activeTab.value
        })}
      </Button>

      <Dialog
        title={t('forms:system.countryForm.deleteSiteDataDialog.title', {
          locale: activeTab.value
        })}
        isOpen={confirmOpen}
        onClose={handleDialogClose}
        confirmDisabled={confirmText !== 'DELETE' || isLoading}
        onConfirm={handleDeleteSiteData}
        confirmText={t('content:delete')}
      >
        <Input
          label={t('forms:system.deleteDialog.inputLabel')}
          helperText={t('forms:system.countryForm.deleteSiteDataDialog.info')}
          onChange={setConfirmText}
          value={confirmText}
        />
      </Dialog>
    </AdminField>
  )
}

DeleteSiteData.propTypes = {
  siteData: object.isRequired
}

export default DeleteSiteData
