import React, { useState } from 'react'
import { bool } from 'prop-types'

import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useArchiveGuide from '../../../../hooks/manage-guides/useArchiveGuide'

import Button from '../../../shared/button'
import Dialog from '../../../shared/dialog'

const ArchiveGuide = ({ guideActive }) => {
  const { t } = useTranslation('adminPages')
  const navigate = useNavigate()
  const { guideId } = useParams()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { archiveGuide, archiveGuideLoading } = useArchiveGuide()

  const handleArchive = () => {
    setConfirmOpen(true)
  }

  const handleCancel = () => {
    setConfirmOpen(false)
  }

  const doArchive = () => {
    archiveGuide(guideId, () => {
      navigate('/admin-manage-guides')
    })
  }

  const renderCustomFooter = () => {
    return (
      <div className="flex justify-end mt-5 gap-3 sm:mt-6">
        <Button mode="default" onClick={handleCancel} disabled={archiveGuideLoading}>
          {guideActive ? t('content:close') : t('content:cancel')}
        </Button>

        {!guideActive && (
          <Button onClick={doArchive} loading={archiveGuideLoading}>
            {t('content:delete')}
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className="mt-8">
      <Button mode="error" onClick={handleArchive}>
        {t('adminManageGuide.guide.question.archive.button')}
      </Button>

      <Dialog
        isOpen={confirmOpen}
        title={t('adminManageGuide.guide.question.archive.button')}
        onClose={setConfirmOpen}
        onConfirm={doArchive}
        renderFooter={renderCustomFooter}
      >
        {guideActive ? (
          <span>
            {t('adminManageGuide.guide.question.archive.dialog.unpublishToArchive')}
          </span>
        ) : (
          <span>{t('adminManageGuide.guide.question.archive.dialog.unpublishText')}</span>
        )}
      </Dialog>
    </div>
  )
}

ArchiveGuide.propTypes = {
  guideActive: bool
}

ArchiveGuide.defaultProps = {}

export default ArchiveGuide
