import React from 'react'
import { gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import useRemoteGqlFilter from '../hooks/useRemoteGqlFilter'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import Button from '../components/shared/button'
import AdminAllFormsFilter from '../components/admin/admin-all-forms/filter'
import AdminFormsExport from '../components/admin/admin-all-forms/forms-export'
import LoadingSpinner from '../components/shared/loading-spinner'
import LeadsFormAdmin from '../components/leadsFormAdmin'

const QUERY = gql`
  query AdminFormLeads(
    $system: [String]
    $category: [String]
    $locale: [String]
    $fromDate: String
    $toDate: String
    $offset: Int
    $fetchCsvData: Boolean
  ) {
    appAdminFormLeads(
      system: $system
      category: $category
      locale: $locale
      fromDate: $fromDate
      toDate: $toDate
      offset: $offset
      fetchCsvData: $fetchCsvData
    ) {
      hasMore
      totalCount
      csvData
      data {
        id
        company
        companySize
        created
        email
        inquiryType
        isNew
        licenses
        name
        phone
        systemId
        systemName
        textField
        category
        locale
        vendor
        emailStatus {
          status
          score
        }
      }
    }
  }
`

function AdminAllFormsPage() {
  const { t } = useTranslation('adminPages')

  const {
    data: leadsData,
    error: leadsError,
    fetchMore: leadsFetchMore,
    fetchByLazyQuery: leadsFetchByLazyQuery,
    fetchMoreLoading,
    handleChangeFilters,
    filterChangeFetchLoading,
    initialFetchLoading
  } = useRemoteGqlFilter(QUERY)

  const leads = leadsData?.appAdminFormLeads?.data || []
  const hasMoreForms = leadsData?.appAdminFormLeads?.hasMore || false
  const totalLeadsCount = leadsData?.appAdminFormLeads?.totalCount || 0

  return (
    <>
      <PageHeader pageName={t('adminAllForms.header.title')} />

      <PageBody>
        <div className="flex flex-1 justify-between mb-5 gap-2">
          <div className="text-base text-gray-600">
            Resultat nuvarande filter:{' '}
            {filterChangeFetchLoading ? (
              <LoadingSpinner className="text-gray-900" />
            ) : (
              <span className="font-semibold">{totalLeadsCount}</span>
            )}
          </div>

          <AdminFormsExport onFetchByLazyQuery={leadsFetchByLazyQuery} />
        </div>

        <AdminAllFormsFilter onChangeFilters={handleChangeFilters} />
      </PageBody>

      <PageBody loading={initialFetchLoading || filterChangeFetchLoading}>
        {leadsError?.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}

        <LeadsFormAdmin leads={leads} />

        <div className="mt-4">
          <Button
            loading={fetchMoreLoading}
            disabled={!hasMoreForms}
            onClick={() => {
              leadsFetchMore({
                offset: leads.length
              })
            }}
          >
            {t('shared.button.fetchMore', {
              value: 10
            })}
          </Button>
        </div>
      </PageBody>
    </>
  )
}

export default AdminAllFormsPage
