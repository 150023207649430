import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Loader from '../components/loader'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import BiLead from '../components/admin/admin-list-bi-lead-item'

import GenerateCsvButton from '../components/shared/generate-csv-btn'
import ListToolbar from '../components/shared/list-toolbar'

const QUERY = gql`
  query BiLeads {
    appAdminBWBuyingIntentSignals {
      id
      name
      locale
      founded
      alexaRank
      employeeMin
      employeeMax
      linkedInCategory
      financialsMin
      financialsMax
      naceCategory
      city
      state
      orgId
      zip
      website
      url
      createdAt
      updatedAt
      pages
      sessions
    }
  }
`

function LeadsBiAdmin({ leads }) {
  const { t } = useTranslation()

  const csvData = {
    headers: [
      { label: 'Company', key: 'name' },
      { label: 'OrgId', key: 'orgId' },
      { label: 'Website', key: 'website' },
      { label: 'Intent', key: 'intent' },
      { label: 'Employees Min', key: 'employeeMin' },
      { label: 'Employees Max', key: 'employeeMax' },
      { label: 'Revenue Min', key: 'financialsMin' },
      { label: 'Revenue Max', key: 'financialsMax' },
      { label: 'LinkedIn category', key: 'linkedInCategory' },
      { label: 'NACE', key: 'naceCategory' }
    ],
    data: leads
  }

  if (!leads?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  const updatedLeadsObj = leads.map((lead) => {
    return {
      ...lead,
      pages: lead.pages,
      sessions: JSON.parse(lead.sessions),
      date: new Date(Number(lead.createdAt))
    }
  })

  return (
    <div>
      <ListToolbar>
        <GenerateCsvButton
          filename="buying-intents.csv"
          csvData={csvData}
          onClick={() => console.log('Exporting CSV')}
          gaActionName="export_buying_intents_csv"
        />
      </ListToolbar>
      <div className="flex flex-row">
        <div className="bg-white shadow overflow-hidden sm:rounded-md grow">
          <ul role="list" className="divide-y divide-gray-200">
            {updatedLeadsObj
              .sort((a, b) => b.date - a.date)
              .map((lead) => {
                return <BiLead key={lead.date} lead={lead} />
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

function AdminBwBiPage() {
  const { data: biData, loading, error } = useQuery(QUERY)

  if (error) {
    console.error(error)
  }

  const leads = biData?.appAdminBWBuyingIntentSignals

  return (
    <>
      <PageHeader pageName={'Buying Intent'}>
        <p>Egna köpsignaler som kommer från om &quot;för leverantörer&quot;</p>
      </PageHeader>

      <PageBody>
        {/* <p>Plus att ett filter för land ska vara med i framtiden här.</p> */}
        {error &&
          error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
        <div>{!loading && <LeadsBiAdmin leads={leads} />}</div>
        {loading && (
          <div className="text-center">
            <Loader />
          </div>
        )}
      </PageBody>
    </>
  )
}

export default AdminBwBiPage
