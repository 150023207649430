import React from 'react'
import PropTypes from 'prop-types'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

const Pagination = ({
  page,
  setPage,
  limit,
  setLimit,
  totalCount,
  limitOptions = [5, 10, 15, 20],
  className
}) => {
  const totalPages = Math.ceil(totalCount / limit)

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1)
    }
  }

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const handleLimitChange = (event) => {
    setLimit(Number(event.target.value))
    setPage(1) // Reset to first page when limit is changed
  }

  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 2

    if (page > 2) {
      pageNumbers.push(1)
      if (page > 3) {
        pageNumbers.push('...')
      }
    }

    const startPage = Math.max(1, page - 1)
    const endPage = Math.min(totalPages, page + maxPagesToShow - 2)

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push('...')
    }

    if (endPage < totalPages) {
      pageNumbers.push(totalPages)
    }

    return pageNumbers.map((pageNum, index) =>
      pageNum === '...' ? (
        <span key={index} className="mx-1">
          ...
        </span>
      ) : (
        <span
          key={index}
          onClick={() => setPage(pageNum)}
          className={`cursor-pointer mx-1 px-3 py-1 border rounded ${
            pageNum === page
              ? 'bg-blue-100 text-blue-600 border-blue-600 font-bold'
              : 'text-gray-700 border-gray-300'
          }`}
        >
          {pageNum}
        </span>
      )
    )
  }
  return (
    <div className={`flex justify-between items-center my-4 ${className}`}>
      {/* Limit Selector */}
      <div>
        <label
          htmlFor="limit"
          className="mr-2 text-sm text-gray-400 font-semibold uppercase"
        >
          {/*// TODO: Add translations to table component*/}
          Per page:
        </label>
        <select
          id="limit"
          value={limit}
          onChange={handleLimitChange}
          className="px-2 py-[2px] cursor-pointer border-blue-600 text-blue-600 border rounded w-16"
        >
          {limitOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center text-sm font-semibold uppercase">
        <ChevronLeftIcon
          onClick={handlePrev}
          className={`h-5 w-5 mr-2 cursor-pointer ${
            page === 1 ? 'text-gray-400' : 'text-blue-600'
          }`}
        />
        {renderPageNumbers()}
        <ChevronRightIcon
          onClick={handleNext}
          className={`h-5 w-5 ml-2 cursor-pointer ${
            page === totalPages ? 'text-gray-400' : 'text-blue-600'
          }`}
        />
      </div>
    </div>
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  limitOptions: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string
}

export default Pagination
