export const fixBoolean = (textOrBool) => {
  if (typeof textOrBool === 'boolean') {
    return textOrBool
  }

  if (textOrBool === 'true') {
    return true
  }

  if (textOrBool === 'false') {
    return false
  }

  return textOrBool
}
