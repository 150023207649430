import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'
import { links } from '../constants'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'

import classNames from 'classnames'

import { LockClosedIcon, ExclamationIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

function HomePage() {
  const { user } = useUserContext()
  const { t } = useTranslation('home')

  const [title, setTitle] = useState(null)

  const accessRights = user?.accessRights
  const admin = isAdmin(user?.role)
  const appRole = user?.appRole

  useEffect(() => {
    setTitle(`${user?.name?.split(' ')[0]}'s ${t('dashboard')}`)
  }, [])

  const hasAccessToGuide = admin || accessRights?.includes('SYSTEMGUIDE')
  const hasAccessToForms = admin || accessRights?.includes('FORMS')
  const hasAccessToBuyingIntent = admin || accessRights?.includes('BUYER_INTENT')
  const hasAccessToEditProfile = admin || ['EDITOR', 'ADMIN'].includes(appRole)

  return (
    <>
      <PageHeader pageName={title} />

      <PageBody>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {links.map((link, key) => {
            let showLockIcon = false

            if (link.link === '/leads-guide') {
              showLockIcon = !hasAccessToGuide
            }
            if (link.link === '/leads-forms') {
              showLockIcon = !hasAccessToForms
            }
            if (link.link === '/buying-intent') {
              showLockIcon = !hasAccessToBuyingIntent
            }
            if (link.link.includes('/profile')) {
              showLockIcon = !hasAccessToEditProfile
            }

            return (
              <div
                key={`link--${link.link}--${key}`}
                className={classNames({
                  'relative rounded-lg border border-gray-300 px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500': true,
                  'pointer-events-none bg-gray-100 text-gray-700':
                    link.disabled || showLockIcon,
                  'bg-white': !link.disabled && !showLockIcon
                })}
              >
                <div className="flex-shrink-0 border rounded-full p-2">
                  <link.icon
                    className={classNames('h-6 w-6 text-gray-400')}
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <Link to={link.link} className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">
                      {t(link.title)} &rarr;
                    </p>
                    <p className="text-sm text-gray-500 truncate">{t(link.subTitle)}</p>
                  </Link>
                </div>
                {(link.disabled || showLockIcon) && !link.underDevelopment && (
                  <div className="ml-auto">
                    <LockClosedIcon
                      className={classNames(
                        link.current
                          ? 'text-gray-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                  </div>
                )}
                {link.underDevelopment && (
                  <div className="ml-auto">
                    <ExclamationIcon
                      className={classNames('text-gray-400 flex-shrink-0 h-6 w-6')}
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </PageBody>
    </>
  )
}
export default HomePage
