import React from 'react'
import { bool, func, number, object, string } from 'prop-types'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'

import { PlusCircleIcon } from '@heroicons/react/outline'
import Chip from '../../../../shared/chip'
import QuestionAnswers from './answers'
import LoadingSpinner from '../../../../shared/loading-spinner'

const CHIPS_INSTR = [
  {
    key: 'autogenerated',
    textTid: 'adminManageGuide.guide.question.autogenerated',
    type: 'warning'
  },
  {
    key: 'isCommon',
    textTid: 'adminManageGuide.guide.question.common',
    type: 'primary'
  },
  {
    key: 'filter',
    textTid: 'adminManageGuide.guide.question.filter',
    type: 'warning'
  },
  {
    key: 'required',
    textTid: 'adminManageGuide.guide.question.required',
    type: 'success'
  },
  {
    key: 'type',
    type: 'outlined'
  }
]

const ConditionalLoading = ({ loading, children }) => {
  if (loading) {
    return <LoadingSpinner className="h-8 w-8" />
  }

  return children
}

const QuestionItem = ({
  className,
  question,
  itemOrder,
  locale,
  onEditQuestion,
  onAddQuestion,
  loading
}) => {
  const { t } = useTranslation('adminPages')
  const isCreatorMode = !question?.id
  const cxClassName = cx(
    'p-3 border border-gray-300 shadow-sm bg-white rounded flex flex-col cursor-pointer',
    className,
    {
      'items-center': loading
    }
  )

  const questionText = question?.texts?.[locale] || '-'
  const questionOrder = question?.order

  const handleClickCreate = () => {
    onAddQuestion(itemOrder)
  }

  const handleClickEdit = () => {
    onEditQuestion(question.id)
  }

  if (isCreatorMode) {
    return (
      <div className={cx(cxClassName, 'justify-center')} onClick={handleClickCreate}>
        <ConditionalLoading loading={loading}>
          <div className="flex items-center justify-center flex-col">
            <PlusCircleIcon className="w-6 text-gray-600" />
          </div>
        </ConditionalLoading>
      </div>
    )
  }

  return (
    <div className={cxClassName} onClick={handleClickEdit}>
      <ConditionalLoading loading={loading}>
        <div className="flex flex-col border-b pb-1 mb-1">
          <div className="flex justify-between">
            <span className="font-medium">
              {questionOrder ? `${questionOrder}. ${questionText}` : questionText}
            </span>
          </div>

          <span className="italic text-sm">{question.name}</span>

          <div className="flex items-start">
            {CHIPS_INSTR.map((chipData) => {
              if (!question[chipData.key]) {
                return null
              }

              if (
                chipData.forValues &&
                !chipData.forValues.some((value) => question[chipData.key] === value)
              ) {
                return null
              }

              const text = chipData.textTid ? t(chipData.textTid) : question[chipData.key]

              return <Chip key={chipData.key} type={chipData.type} text={text} />
            })}
          </div>
        </div>

        <QuestionAnswers
          type={question?.type}
          guideAnswers={question?.guideAnswers}
          locale={locale}
          order={questionOrder}
        />
      </ConditionalLoading>
    </div>
  )
}

QuestionItem.propTypes = {
  question: object,
  itemOrder: number,
  locale: string,
  onEditQuestion: func.isRequired,
  onAddQuestion: func,
  loading: bool
}

QuestionItem.defaultProps = {}

export default QuestionItem
