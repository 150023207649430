import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

const GQL_DELETE_MARKET_RESEARCHER = gql`
  mutation DeleteMarketResearcher($id: ID!) {
    mrDeleteResearcher(id: $id) {
      id
    }
  }
`

export default function useDeleteMarketResearcher() {
  const [deleteMarketResearcher] = useMutation(GQL_DELETE_MARKET_RESEARCHER)

  const deleteMarketResearcherCallback = useCallback(
    (id) => {
      return deleteMarketResearcher({
        variables: {
          id
        }
      })
    },
    [deleteMarketResearcher]
  )

  return {
    deleteMarketResearcher: deleteMarketResearcherCallback
  }
}
