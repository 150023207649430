const createValidator = () => {
  let errors = []
  let results = {}
  let value = ''

  return {
    /**
     * Initializes the validator with the input to be validated.
     *
     * @param {string} input - The input string to be validated.
     * @returns {Object} - Returns the current instance for chaining.
     */
    validate(input) {
      errors = []
      results = {}
      value = input
      return this
    },

    /**
     * Checks if the input has a minimum length.
     *
     * @param {number} minLength - The minimum required length for the input.
     * @param {string} [message] - Optional custom error message.
     * @returns {Object} - Returns the current instance for chaining.
     */
    hasMinLength(minLength, message) {
      if (value.length < minLength) {
        errors.push(message || `Input must be at least ${minLength} characters long`)
        results['minLength'] = false
      } else {
        results['minLength'] = true
      }
      return this
    },

    /**
     * Checks if the input contains at least a specified number of uppercase letters.
     *
     * @param {number} minCapitalLetters - The minimum number of uppercase letters required.
     * @param {string} [message] - Optional custom error message.
     * @returns {Object} - Returns the current instance for chaining.
     */
    hasCapitalLetters(minCapitalLetters, message) {
      const capitalLetterCount = (value.match(/[A-Z]/g) || []).length
      if (capitalLetterCount < minCapitalLetters) {
        errors.push(
          message ||
            `Input must contain at least ${minCapitalLetters} uppercase letter${
              minCapitalLetters > 1 ? 's' : ''
            }`
        )
        results['capitalLetters'] = false
      } else {
        results['capitalLetters'] = true
      }
      return this
    },

    /**
     * Checks if the input contains at least a specified number of lowercase letters.
     *
     * @param minLowerCaseLetters
     * @param {string} [message] - Optional custom error message.
     * @returns {Object} - Returns the current instance for chaining.
     */
    hasLowerCaseLetters(minLowerCaseLetters, message) {
      const lowerCaseLetterCount = (value.match(/[a-z]/g) || []).length
      if (lowerCaseLetterCount < minLowerCaseLetters) {
        errors.push(
          message ||
            `Input must contain at least ${minLowerCaseLetters} lowercase letter${
              minLowerCaseLetters > 1 ? 's' : ''
            }`
        )
        results['lowerCaseLetters'] = false
      } else {
        results['lowerCaseLetters'] = true
      }
      return this
    },

    /**
     * Checks if the input contains at least a specified number of numeric digits.
     *
     * @param {number} minNumbers - The minimum number of numeric digits required.
     * @param {string} [message] - Optional custom error message.
     * @returns {Object} - Returns the current instance for chaining.
     */
    hasNumbers(minNumbers, message) {
      const numberCount = (value.match(/\d/g) || []).length
      if (numberCount < minNumbers) {
        errors.push(
          message ||
            `Input must contain at least ${minNumbers} number${minNumbers > 1 ? 's' : ''}`
        )
        results['numbers'] = false
      } else {
        results['numbers'] = true
      }
      return this
    },

    /**
     * Checks if the input contains at least a specified number of special characters.
     *
     * @param {number} minSpecialCharacters - The minimum number of special characters required.
     * @param {string} [message] - Optional custom error message.
     * @returns {Object} - Returns the current instance for chaining.
     */
    hasSpecialCharacters(minSpecialCharacters, message) {
      const specialCharacterCount = (value.match(/[@$!%*?&#]/g) || []).length
      if (specialCharacterCount < minSpecialCharacters) {
        errors.push(
          message ||
            `Input must contain at least ${minSpecialCharacters} special character${
              minSpecialCharacters > 1 ? 's' : ''
            }`
        )
        results['specialCharacters'] = false
      } else {
        results['specialCharacters'] = true
      }
      return this
    },

    /**
     * Checks if string provided in "stringToMatch" param matches main input value
     *
     * @param valueToMatch
     * @param message
     */
    hasIdenticalMatch(valueToMatch, message) {
      if (value === valueToMatch) {
        results['identicalMatch'] = true
      } else {
        errors.push(message || `Compared values do not match.`)
        results['identicalMatch'] = false
      }
      return this
    },

    /**
     * Checks if the input passes all the validation checks.
     *
     * @returns {boolean} - Returns true if the input is valid (no errors), otherwise false.
     */
    isValid() {
      return errors.length === 0
    },

    /**
     * Retrieves the list of validation error messages.
     *
     * @returns {Array} - Returns an array of error messages.
     */
    getErrors() {
      return errors
    },

    /**
     * Retrieves the list of results for each validation rule.
     *
     * @returns {Object} - Returns an object with validation rule keys and boolean values.
     */
    getResults() {
      return results
    }
  }
}

export default createValidator
