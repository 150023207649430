import React, { useState } from 'react'

const PageContext = React.createContext({
  acceptedCookie: false,
  setAcceptedCookie: () => {}
})

export const PageContextProvider = ({ children }) => {
  // const { i18n } = useTranslation();
  // i18n.changeLanguage('SV'); // pageContext.lang
  // if (i18n.language !== value.lang) i18n.changeLanguage(value.lang);

  const getLocalStorage = () => {
    let acceptedCookie = false

    try {
      acceptedCookie = localStorage.getItem('cookieAccepted') === 'true' || false
    } catch (error) {
      console.warn('Error with get cookieAccepted')
    }

    return acceptedCookie
  }

  const setLocalStorage = () => {
    try {
      localStorage.setItem('cookieAccepted', true)
    } catch (error) {
      console.warn('Error with set cookieAccepted')
    }
  }

  const setAcceptedCookie = (hasAccepted) => {
    setLocalStorage()
    setState({ ...state, acceptedCookie: hasAccepted })
  }

  const initState = {
    acceptedCookie: getLocalStorage(),
    setAcceptedCookie: setAcceptedCookie
  }

  const [state, setState] = useState(initState)

  return <PageContext.Provider value={state}>{children}</PageContext.Provider>
}

export const usePageContext = () => React.useContext(PageContext)
