import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChevronRightIcon, CalendarIcon } from '@heroicons/react/solid'
import Flag from './shared/flag'
import EmailStatus from './shared/email/emailStatus'
import classNames from 'classnames'

const DATA_ROWS = [
  { dataKey: 'company', translationId: 'content:company' },
  { dataKey: 'name', translationId: 'content:name' },
  { dataKey: 'email', translationId: 'content:email' },
  { dataKey: 'licenses', translationId: 'content:licenses' },
  { dataKey: 'phone', translationId: 'content:phone' },
  { dataKey: 'textField', translationId: 'content:text' },
  { dataKey: 'vendor', translationId: 'Vendor' }
]

const FormLead = function ({ lead }) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <li>
      <div
        className={classNames('block cursor-pointer', {
          'hover:bg-gray-50': lead.vendor,
          'bg-orange-100 hover:bg-orange-200': !lead.vendor
        })}
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <div className="h-10 w-10 rounded-full bg-cover bg-center">
                <Flag locale={lead.locale} />
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-blue-600 truncate">
                  {lead.company}
                  {lead.emailStatus ? (
                    <EmailStatus
                      emailStatus={lead.emailStatus}
                      styleing="inline-flex align-top ml-2 h-5 w-5"
                    />
                  ) : null}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <time dateTime={lead.created}>
                    {lead.created.toLocaleString('sv').substr(0, 10)}
                  </time>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="flex text-sm">
                    <p className="font-medium truncate">{lead.systemName}</p>
                    <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                      i {lead.category}
                    </p>
                  </div>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {lead.inquiryType}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-10 py-5 bg-gray-50">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('content:contactInformation')}
            </h3>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              {DATA_ROWS.map((rowData, index) => {
                if (rowData.dataKey === 'vendor') {
                  return (
                    <div
                      className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                      key={index}
                    >
                      <dt className="text-sm font-medium text-gray-500">Vendor</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {lead?.[rowData.dataKey] || 'Missing vendor (for sales only)'}
                      </dd>
                    </div>
                  )
                }

                if (!lead?.[rowData.dataKey]) {
                  return null
                }

                return (
                  <div
                    className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"
                    key={index}
                  >
                    <dt className="text-sm font-medium text-gray-500">
                      {t(rowData.translationId)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {lead[rowData.dataKey]}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </div>
        </div>
      )}
    </li>
  )
}

export default function FormLeads({ leads }) {
  const { t } = useTranslation()

  if (!leads?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {leads.map((lead) => {
          return <FormLead key={`${lead.created}-${lead.systemName}`} lead={lead} />
        })}
      </ul>
    </div>
  )
}
