import React, { useEffect, useMemo, useState } from 'react'
import { array, func } from 'prop-types'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Label from '../../shared/label'
import Input from '../../shared/input'
import { Select } from '../../shared/select'
import Button from '../../shared/button'

const STATUS_OPTIONS = [
  { labelTid: 'marketResearcher:categories.filter.status.published', value: 'PUBLISHED' },
  { labelTid: 'marketResearcher:categories.filter.status.draft', value: 'DRAFT' },
  { labelTid: 'marketResearcher:categories.filter.status.all', value: 'ALL' }
]

const DEFAULT_FILTERS = {
  freeInput: '',
  status: 'ALL',
  sortBy: 'AZ'
}

const CategoriesFilter = ({ categories, onFilter }) => {
  const { t } = useTranslation()
  const { locale } = useParams()
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const sortOptionsMemo = useMemo(() => {
    return [
      { label: 'A-Z', value: 'AZ' },
      { label: 'Z-A', value: 'ZA' },
      {
        label: t('marketResearcher:categories.filter.sort.systemCountSite09'),
        value: 'COUNT_SITE_09'
      },
      {
        label: t('marketResearcher:categories.filter.sort.systemCountSite90'),
        value: 'COUNT_SITE_90'
      },
      {
        label: t('marketResearcher:categories.filter.sort.systemCountLocale09', {
          locale
        }),
        value: 'COUNT_LOCALE_09'
      },
      {
        label: t('marketResearcher:categories.filter.sort.systemCountLocale90', {
          locale
        }),
        value: 'COUNT_LOCALE_90'
      }
    ]
  }, [t, locale])

  useEffect(() => {
    if (!categories) {
      return
    }

    const { freeInput: freeInputFilter, status: statusFilter, sortBy } = filters

    const filterNormalized = freeInputFilter.toLowerCase()

    let filteredCategories = categories.filter((category) => {
      const categoryDataEn = category.locales['en_GB']
      const categoryDataLocale = category.locales[locale]

      const normalizedNameLocale = categoryDataLocale?.name?.toLowerCase()
      const normalizedNameEn = categoryDataEn?.name?.toLowerCase()

      const passFilterFreeSearch =
        normalizedNameLocale?.includes(filterNormalized) ||
        normalizedNameEn?.includes(filterNormalized)

      let passFilterStatus = statusFilter === 'ALL'

      if (statusFilter === 'PUBLISHED') {
        passFilterStatus = !!categoryDataLocale.publishedAt
      } else if (statusFilter === 'DRAFT') {
        passFilterStatus = !categoryDataLocale.publishedAt
      }

      return passFilterFreeSearch && passFilterStatus
    })

    // Sort
    if (sortBy === 'AZ') {
      filteredCategories = filteredCategories.sort((a, b) => {
        const aName = a.locales[locale]?.name?.toLowerCase()
        const bName = b.locales[locale]?.name?.toLowerCase()

        return aName.localeCompare(bName)
      })
    } else if (sortBy === 'ZA') {
      filteredCategories = filteredCategories.sort((a, b) => {
        const aName = a.locales[locale]?.name?.toLowerCase()
        const bName = b.locales[locale]?.name?.toLowerCase()

        return bName.localeCompare(aName)
      })
    } else if (sortBy === 'COUNT_SITE_09') {
      filteredCategories = filteredCategories.sort(
        (a, b) => a.systemsCount.site - b.systemsCount.site
      )
    } else if (sortBy === 'COUNT_LOCALE_09') {
      filteredCategories = filteredCategories.sort(
        (a, b) => a.systemsCount.locale - b.systemsCount.locale
      )
    } else if (sortBy === 'COUNT_SITE_90') {
      // TODO
      filteredCategories = filteredCategories.sort(
        (a, b) => b.systemsCount.site - a.systemsCount.site
      )
    } else if (sortBy === 'COUNT_LOCALE_90') {
      filteredCategories = filteredCategories.sort(
        (a, b) => b.systemsCount.locale - a.systemsCount.locale
      )
    }

    onFilter(filteredCategories)
  }, [categories, filters, onFilter, locale])

  const resetFilter = () => {
    setFilters(DEFAULT_FILTERS)
  }

  const hasAppliedFilter = useMemo(() => {
    return !isEqual(filters, DEFAULT_FILTERS)
  }, [filters])

  const handleChangeFilter = (filterName, value) => {
    setFilters((prevFilters) => {
      return { ...prevFilters, [filterName]: value }
    })
  }

  return (
    <div className="grid grid-cols-12 mb-8 gap-2">
      <div className="col-span-full md:col-span-5">
        <Label
          title={t('marketResearcher:categories.filter.label.textSearch')}
          marginless
        >
          <Input
            id="freeInput"
            placeholder={t('marketResearcher:categories.filter.label.textSearch')}
            onChange={handleChangeFilter}
            value={filters.freeInput}
          />
        </Label>
      </div>

      <div className="col-span-4 md:col-span-2">
        <Label title={t('content:status')} marginless>
          <Select
            id="status"
            options={STATUS_OPTIONS}
            onChange={handleChangeFilter}
            value={filters.status}
            isSearchable={false}
            rawChangeEvent={false}
          />
        </Label>
      </div>

      <div className="col-span-6 md:col-span-4">
        <Label title={t('content:sort')} marginless>
          <Select
            id="sortBy"
            options={sortOptionsMemo}
            onChange={handleChangeFilter}
            value={filters.sortBy}
            isSearchable={false}
            rawChangeEvent={false}
          />
        </Label>
      </div>

      <div className="col-span-2 md:col-span-1 items-end justify-center flex">
        <Button
          className="w-full"
          mode="default"
          onClick={resetFilter}
          disabled={!hasAppliedFilter}
        >
          {t('content:clear')}
        </Button>
      </div>
    </div>
  )
}

CategoriesFilter.propTypes = {
  categories: array,
  onFilter: func.isRequired
}

CategoriesFilter.defaultProps = {}

export default CategoriesFilter
