import React, { useCallback } from 'react'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import { useTranslation, Trans } from 'react-i18next'

import Loader from '../components/loader'
import ManageSystem from '../components/manageSystem'

import useFetchSystem from '../hooks/useFetchSystem'

function SystemDataPage() {
  const { t } = useTranslation('yourSystemProfile')
  const { system, systemLoading } = useFetchSystem()

  const setTabDisabled = useCallback((tab) => {
    return tab.categoryMissing
  }, [])

  return (
    <>
      <PageHeader pageName={t('data.header.title')}>
        <Trans
          ns="yourSystemProfile"
          i18nKey="data.header.subtitle"
          components={{ bold: <strong /> }}
        />
      </PageHeader>

      <PageBody>
        {systemLoading ? (
          <Loader />
        ) : (
          <ManageSystem
            mode="UPDATE"
            systemData={system}
            setTabDisabled={setTabDisabled}
          />
        )}
      </PageBody>
    </>
  )
}

export default SystemDataPage
