import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useCallback
} from 'react'
import { func } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useGenericFormManager from '../../hooks/useGenericFormManager'
import useFetchAvailableSites from '../../hooks/useFetchAvailableSites'

import useCreateMarketResearcher from '../../hooks/market-researcher/useCreateMarketResearcher'
import useEditMarketResearcher from '../../hooks/market-researcher/useEditMarketResearcher'

import Label from '../shared/label'
import Input from '../shared/input'
import { MultiSelect } from '../shared/select'

import {
  hasRequiredString,
  hasValidEmail,
  hasItemsInArray
} from '../../validators/general'
import ModalNotification from '../modalNotification'

const FIELD_NAME = 'name'
const FIELD_EMAIL = 'email'
const FIELD_LOCALES = 'marketResearcherLocales'

const FORM_INSTR = [
  {
    name: FIELD_NAME,
    validators: [
      { validator: hasRequiredString, error: 'forms:validation.general.required' }
    ]
  },
  {
    name: FIELD_EMAIL,
    validators: [
      { validator: hasRequiredString, error: 'forms:validation.general.required' },
      { validator: hasValidEmail, error: 'forms:validation.general.incorrectEmail' }
    ]
  },
  {
    name: FIELD_LOCALES,
    validators: [
      { validator: hasItemsInArray, error: 'forms:validation.general.arrayRequired' }
    ]
  }
]

/**
 * This component exposes two functions via ref
 * - setEditMarketResearcher: Prefills formData state with user information to be changed
 * - submitForm: Creates or edits market researcher (depending on if setEditMarketResearcher was called)
 */
const AdminMarketResearcherUserForm = forwardRef(({ mrUser, onDone, onLoading }, ref) => {
  const { t } = useTranslation()
  const [tempPassword, setTempPassword] = useState('')
  const { sitesOptions } = useFetchAvailableSites()
  const { formData, onChange, resetForm, setFormData, validationErrors, validate } =
    useGenericFormManager(FORM_INSTR, mrUser)

  const { createMarketResearcher, createMarketResearcherLoading } =
    useCreateMarketResearcher()

  const { editMarketResearcher, loadingEditMarketResearcher } = useEditMarketResearcher()
  const editingMode = !!mrUser

  // Notify parent about loading state change
  useEffect(() => {
    if (onLoading) {
      onLoading(createMarketResearcherLoading || loadingEditMarketResearcher)
    }
  }, [onLoading, createMarketResearcherLoading, loadingEditMarketResearcher])

  const handleSubmitForm = useCallback(() => {
    const isValid = validate()
    if (!isValid) {
      return
    }

    // Create
    if (!editingMode) {
      createMarketResearcher(formData, (data) => {
        setTempPassword(data.mrCreateUser?.tempPassword)
        resetForm()
      }).finally(() => {
        onDone()
      })
    } else {
      editMarketResearcher(formData).finally(() => {
        resetForm()
        onDone()
      })
    }
  }, [
    createMarketResearcher,
    editMarketResearcher,
    editingMode,
    formData,
    resetForm,
    validate,
    onDone
  ])

  useImperativeHandle(
    ref,
    () => ({
      setEditMarketResearcher: (mrUserData) => {
        setFormData(mrUserData)
      },
      submitForm: handleSubmitForm
    }),
    [setFormData, handleSubmitForm]
  )

  const getCommonFormProps = (fieldName) => {
    return {
      id: fieldName,
      value: formData[fieldName],
      errorText: t(validationErrors[fieldName])
    }
  }

  return (
    <>
      <Label title={t('content:name')}>
        <Input {...getCommonFormProps(FIELD_NAME)} onChange={onChange} />
      </Label>

      <Label title={t('content:email')}>
        <Input
          {...getCommonFormProps(FIELD_EMAIL)}
          onChange={onChange}
          disabled={editingMode}
        />
      </Label>

      <Label title={t('content:sites')}>
        <MultiSelect
          {...getCommonFormProps(FIELD_LOCALES)}
          options={sitesOptions}
          onChange={onChange}
          placeholder="placeholders.select.select"
          rawChangeEvent={false}
        />
      </Label>

      <ModalNotification
        title={t('adminAddUser.userSavedModal.title')}
        text={
          <div>
            {`${t('adminAddUser.userSavedModal.temporaryPassword')}: `}
            <div>
              <strong>{tempPassword}</strong>
            </div>
          </div>
        }
        visible={!!tempPassword}
        handleVisible={() => {
          setTempPassword('')
        }}
      />
    </>
  )
})

AdminMarketResearcherUserForm.displayName = 'AdminMarketResearcherUserForm'
AdminMarketResearcherUserForm.propTypes = {
  onLoading: func,
  onDone: func
}

AdminMarketResearcherUserForm.defaultProps = {}

export default AdminMarketResearcherUserForm
