import React from 'react'
import {
  Checkboxes,
  MultipleCheckboxes,
  Radiobuttons,
  RadiobuttonsRows,
  File,
  Wysiwyg,
  Textarea,
  Number,
  Text
} from './inputs'

const Fields = ({
  inputFields,
  fieldsData,
  lang,
  category,
  isAdmin,
  isBasic,
  handleFieldChange,
  tidData
}) => {
  return inputFields?.map((inputField) => {
    let values = fieldsData[inputField.id]

    if (inputField.freeOnly && !isBasic && !isAdmin) {
      return null
    }

    if (Object.hasOwnProperty.call(inputField, 'lang') && inputField.lang !== lang) {
      return null
    }

    /*
     * Example Tiddata object
     * 
     
    tidData = {
      nameTidData (mainField): {
        [fieldId]: {locale: 'sv_SE'} // tidValues
      },
      textTidData (mainField): {
        [fieldId]: {locale: 'sv_SE'} // tidValues
      }
    }
    */
    if (tidData && typeof tidData === 'object') {
      Object.entries(tidData).forEach(([mainField, tidDataObject]) => {
        Object.entries(tidDataObject).forEach(([fieldId, tidValues]) => {
          if (inputField.id === fieldId) {
            inputField[mainField] = tidValues
          }
        })
      })
    }

    const isSystemProps = inputField.id === 'systemProps'
    const isSystemAreas = inputField.id === 'systemAreas'
    const isSystemGuideProps = inputField.id === 'systemGuideProps'

    if (isSystemProps) {
      const hasSystemProps = !!fieldsData.systemProps?.[category]

      // We get values from db
      const inputFieldsValues =
        hasSystemProps &&
        Object.keys(fieldsData?.systemProps[category])
          .map((propKey) => {
            const propValue = fieldsData.systemProps[category][propKey]

            if (
              (typeof propValue === 'string' &&
                ['YES', 'NO', 'ADD'].includes(propValue)) ||
              propValue === null
            ) {
              return {
                id: propKey,
                title: propKey,
                text: null,
                value: propValue
              }
            }
            // else {
            //   // TODO: Look here of what we missing
            //   console.log({ propKey }, { propValue });
            // }
          })
          .filter((i) => i)

      inputField = {
        ...inputField,
        values: inputFieldsValues
      }

      if (!inputFieldsValues) {
        return null
      }

      return (
        <RadiobuttonsRows
          key={inputField.id}
          field={inputField}
          data={values}
          type="multiple"
          category={category}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (isSystemAreas) {
      const hasSystemAreas =
        !!fieldsData.systemAreas?.[category] &&
        Object.keys(fieldsData.systemAreas[category]).length >= 1

      // We get values from db
      const inputFieldsValues =
        hasSystemAreas &&
        Object.keys(fieldsData?.systemAreas?.[category])
          .map((propKey) => {
            const propValue = fieldsData.systemAreas[category][propKey]

            if (typeof propValue === 'boolean' || propValue === null) {
              return {
                id: propKey,
                title: propKey,
                text: null,
                value: propValue
              }
            }
            // else {
            //   // TODO: Look here of what we missing
            //   console.log({ propKey }, { propValue });
            // }
          })
          .filter((i) => i)

      inputField = {
        ...inputField,
        values: inputFieldsValues
      }

      if (!inputFieldsValues) {
        return null
      }

      return (
        <RadiobuttonsRows
          key={inputField.id}
          field={inputField}
          data={values}
          type="boolean"
          category={category}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (isSystemGuideProps) {
      const hasSystemGuideProps = !!fieldsData.systemGuideProps?.[category]

      // We get values from db
      const inputFieldsValues =
        hasSystemGuideProps &&
        Object.keys(fieldsData?.systemGuideProps[category])
          .map((propKey) => {
            const propValue = fieldsData.systemGuideProps?.[category][propKey]

            if (typeof propValue === 'boolean' || propValue === null) {
              return {
                id: propKey,
                title: propKey,
                text: null,
                value: propValue
              }
            } else {
              // TODO: Look here of what we missing
              console.log('MISSING:', { propKey }, { propValue })
            }
          })
          .filter((i) => i)

      inputField = {
        ...inputField,
        values: inputFieldsValues
      }

      if (!inputFieldsValues) {
        return null
      }

      return (
        <RadiobuttonsRows
          key={inputField.id}
          field={inputField}
          data={values}
          type="boolean"
          category={category}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (inputField.type === 'multiple') {
      return (
        <Checkboxes
          key={inputField.id}
          field={inputField}
          data={values}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (inputField.type === 'json') {
      return (
        <MultipleCheckboxes
          key={inputField.id}
          field={inputField}
          data={values}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (inputField.type === 'single') {
      return (
        <Radiobuttons
          key={inputField.id}
          field={inputField}
          data={values}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (inputField.type === 'file') {
      return <File field={inputField} key={inputField.id} />
    }

    if (inputField.type === 'wysiwyg') {
      return (
        <Wysiwyg
          key={inputField.id}
          field={inputField}
          data={values}
          isAdmin={isAdmin}
          isBasic={isBasic}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (inputField.type === 'textarea') {
      return (
        <Textarea
          key={inputField.id}
          field={inputField}
          data={values || ''}
          isAdmin={isAdmin}
          isBasic={isBasic}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    if (inputField.type === 'number') {
      return (
        <Number
          key={inputField.id}
          field={inputField}
          data={values || ''}
          step={inputField.step || 1}
          handleFieldChange={handleFieldChange}
        />
      )
    }

    return (
      <Text
        key={inputField.id}
        field={inputField}
        data={values}
        isAdmin={isAdmin}
        isBasic={isBasic}
        handleFieldChange={handleFieldChange}
      />
    )
  })
}

export default Fields
