import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const UPDATE_GUIDE_GQL = gql`
  mutation AdminUpdateGuide($id: ID!, $data: GuideInput!) {
    updateGuide(id: $id, data: $data) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useUpdateGuide() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateGuideMutation, { loading }] = useMutation(UPDATE_GUIDE_GQL)

  const updateGuide = useCallback(
    (guideId, data, onCompleted) => {
      return updateGuideMutation({
        variables: {
          id: guideId,
          data
        },
        onCompleted: ({ updateGuide }) => {
          showSuccessNotification()
          onCompleted?.(updateGuide)
        },
        onError: showErrorNotification
      })
    },
    [updateGuideMutation, showSuccessNotification, showErrorNotification]
  )

  return {
    updateGuide,
    updateGuideLoading: loading
  }
}
