import React from 'react'
import { func, object } from 'prop-types'

import useUpdateAnswerSpecialName from '../../../../../hooks/manage-guides/useUpdateAnswerSpecialName'

import EditableInput from '../../shared/editable-input'

const AnswerEditSpecialName = ({ readOnly, answer }) => {
  const { updateAnswerSpecialName, updateAnswerSpecialNameLoading } =
    useUpdateAnswerSpecialName()

  const handleSave = (answerId, updatedSpecialName) => {
    return updateAnswerSpecialName(answerId, updatedSpecialName)
  }

  return (
    <EditableInput
      id={answer.id}
      value={answer.specialName}
      onSave={handleSave}
      disabled={updateAnswerSpecialNameLoading || readOnly}
      loading={updateAnswerSpecialNameLoading}
    />
  )
}

AnswerEditSpecialName.propTypes = {
  answer: object.isRequired,
  onChange: func
}
AnswerEditSpecialName.defaultProps = {}

export default AnswerEditSpecialName
