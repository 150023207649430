import React, { useEffect, useState } from 'react'
import { bool, func, object, oneOf } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useCreateReviewTranslation from '../hooks/useCreateReviewTranslation'
import useEditReviewTranslation from '../hooks/useEditReviewTranslation'

import Dialog from '../../../shared/dialog'
import ReviewItemForm from '../item-form'

const EDIT_FIELDS = [
  'locale',
  'reviewRole',
  'reviewTitle',
  'reviewLikeMost',
  'reviewLikeLeast',
  'reviewBusinessCritical'
]

const EditCreateTranslationDialog = ({
  isOpen,
  review,
  onClose,
  mode,
  onReviewsChanged
}) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const { createReviewTranslation, createReviewTranslationLoading } =
    useCreateReviewTranslation()
  const { editReviewTranslation, editReviewTranslationLoading } =
    useEditReviewTranslation()

  const isEditMode = mode === 'EDIT'
  const editInitialized = !!formData

  useEffect(() => {
    if (editInitialized || !isEditMode) {
      return
    }

    const updatedFormData = EDIT_FIELDS.reduce((acc, prev) => {
      acc[prev] = review[prev]
      return acc
    }, {})

    setFormData(updatedFormData)
  }, [editInitialized, isEditMode, review])

  const handleFormChange = (fieldName, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [fieldName]: value
      }
    })
  }

  const handleSubmit = () => {
    if (mode === 'CREATE') {
      createReviewTranslation(review.id, formData, () => {
        onReviewsChanged()
        onClose(false)
      })
    } else {
      // Edit
      editReviewTranslation(review.id, formData, () => {
        onReviewsChanged()
        onClose(false)
      })
    }
  }

  return (
    <Dialog
      className="!max-w-5xl"
      isOpen={isOpen}
      title={isEditMode ? t('content:edit') : t('content:create')}
      confirmText={t('content:save')}
      onConfirm={handleSubmit}
      onClose={onClose}
      loading={createReviewTranslationLoading || editReviewTranslationLoading}
    >
      <div className="flex gap-4 flex-col">
        <ReviewItemForm review={review} />

        <hr />

        <ReviewItemForm
          review={review}
          formData={formData}
          onChange={handleFormChange}
          isEditing={true}
        />
      </div>
    </Dialog>
  )
}

EditCreateTranslationDialog.propTypes = {
  isOpen: bool,
  review: object,
  onClose: func.isRequired,
  mode: oneOf(['EDIT', 'CREATE'])
}

EditCreateTranslationDialog.defaultProps = {}

export default EditCreateTranslationDialog
