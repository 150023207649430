import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const UPDATE_ANSWER_TEXT_GQL = gql`
  mutation AdminUpdateAnswerText($answerId: ID!, $textField: TextFieldEnum, $data: Json!) {
    updateAnswerText(answerId: $answerId, textField: $textField, data: $data) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useUpdateAnswerText() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateAnswerText, { loading }] = useMutation(UPDATE_ANSWER_TEXT_GQL)

  const updateAnswerTextCallback = useCallback(
    (answerId, textField, data, onCompleted) => {
      return updateAnswerText({
        variables: {
          answerId,
          textField,
          data
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      })
    },
    [updateAnswerText, showSuccessNotification, showErrorNotification]
  )

  return {
    updateAnswerText: updateAnswerTextCallback,
    updateAnswerTextLoading: loading
  }
}
