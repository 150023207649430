import { useMutation, gql } from '@apollo/client'
import { useCallback } from 'react'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

const APPROVE_REVIEW = gql`
  mutation AppApproveReview($id: ID!, $approve: Boolean!) {
    appApproveReview(id: $id, approve: $approve) {
      id
      approved
    }
  }
`

export default function useApproveReview() {
  const { showErrorNotification } = useOverlayNotification()

  const [approveReviewMutation, { loading: approveReviewLoading }] = useMutation(
    APPROVE_REVIEW,
    {
      onError: (err) => {
        const error = `${err}`.split(':').reverse()[0]
        showErrorNotification({ text: error })
      }
    }
  )

  const approveReviewCallback = useCallback(
    (reviewDataId, approve) => {
      return approveReviewMutation({
        variables: {
          id: reviewDataId,
          approve
        }
      })
    },
    [approveReviewMutation]
  )

  return {
    approveReview: approveReviewCallback,
    approveReviewLoading
  }
}
