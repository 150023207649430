import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from './useOverlayNotification'

const UPDATE_CATEGORY_SITE_DATA_GQL = gql`
  mutation UpdateSystemCategorySiteData(
    $systemSiteDataId: ID!
    $changes: [AppEditSystemCategorySiteDataInput]
  ) {
    appEditSystemCategorySiteData(systemSiteDataId: $systemSiteDataId, changes: $changes)
  }
`

export default function useUpdateSystemCategorySiteData() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [updateSystemCategorySiteDataMutation, { loading }] = useMutation(
    UPDATE_CATEGORY_SITE_DATA_GQL
  )

  const updateSystemCategorySiteData = useCallback(
    (systemSiteDataId, changes, onCompleted) => {
      const requestData = {
        variables: {
          systemSiteDataId,
          changes
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification,
        update: (cache, { data: { appEditSystemCategorySiteData: resOk } }) => {
          if (resOk) {
            cache.modify({
              id: cache.identify({ __typename: 'SystemSiteData', id: systemSiteDataId }),
              fields: {
                systemCategorySiteData: (prevState) => {
                  return prevState.map((prevData) => {
                    const foundChange = changes.find((newChange) => {
                      return prevData.category === newChange.category
                    })

                    if (foundChange) {
                      return {
                        ...prevData,
                        ...foundChange
                      }
                    }

                    return prevData
                  })
                }
              }
            })
          }
        }
      }

      return updateSystemCategorySiteDataMutation(requestData)
    },
    [updateSystemCategorySiteDataMutation, showSuccessNotification, showErrorNotification]
  )

  return {
    updateSystemCategorySiteData,
    updateSystemCategorySiteDataLoading: loading
  }
}
