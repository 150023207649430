import React, { useMemo } from 'react'
import { gql } from '@apollo/client'

import { useTranslation } from 'react-i18next'
import useRemoteGqlFilter from '../hooks/useRemoteGqlFilter'

import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import AdminGuidesExport from '../components/admin/admin-all-guides/guides-export'
import AdminAllGuidesFilter from '../components/admin/admin-all-guides/filter'
import LeadsGuideAdmin from '../components/leadsGuideAdmin'
import LoadingSpinner from '../components/shared/loading-spinner'
import Button from '../components/shared/button'

const QUERY = gql`
  query AdminGuideResults(
    $system: [String]
    $category: [String]
    $site: [String]
    $locale: String
    $formDone: Boolean
    $fromDate: String
    $toDate: String
    $offset: Int
    $fetchCsvData: Boolean
  ) {
    appAdminGetGuideResults(
      system: $system
      category: $category
      site: $site
      locale: $locale
      formDone: $formDone
      fromDate: $fromDate
      toDate: $toDate
      offset: $offset
      fetchCsvData: $fetchCsvData
    ) {
      totalCount
      hasMore
      csvData
      data {
        id
        category
        companySize
        email
        newEmail
        ended
        phone
        company
        name
        guideAnswersData {
          questionId
          questionText
          answerText
          specialName
        }
        role
        locale
        formDone
        emailStatus {
          email
          status
          score
          comment
        }
        newEmailStatus {
          email
          status
          score
          comment
        }
        resultSystemsWithPoints {
          points
          guideRating
          systemV2 {
            id
            systemSiteData {
              systemName
              locale
              customerType
            }
          }
        }
      }
    }
  }
`

function AdminAllGuidesPage() {
  const { t, i18n } = useTranslation('adminPages')

  const gqlVariablesMemo = useMemo(() => {
    return {
      locale: i18n.language
    }
  }, [i18n.language])

  const {
    data: guideData,
    error: guideError,
    fetchMore: guideFetchMore,
    fetchByLazyQuery: guideFetchByLazyQuery,
    fetchMoreLoading,
    handleChangeFilters,
    filterChangeFetchLoading,
    initialFetchLoading
  } = useRemoteGqlFilter(QUERY, gqlVariablesMemo)

  const leads = guideData?.appAdminGetGuideResults?.data || []
  const totalLeadsCount = guideData?.appAdminGetGuideResults?.totalCount || 0
  const hasMoreLeads = guideData?.appAdminGetGuideResults?.hasMore || false

  return (
    <>
      <PageHeader pageName={t('adminAllGuides.header.title')} />

      <PageBody>
        <div className="flex flex-1 justify-between mb-5 gap-2">
          <div className="text-base text-gray-600">
            Resultat nuvarande filter:{' '}
            {filterChangeFetchLoading ? (
              <LoadingSpinner className="text-gray-900" />
            ) : (
              <span className="font-semibold">{totalLeadsCount}</span>
            )}
          </div>

          <AdminGuidesExport onFetchByLazyQuery={guideFetchByLazyQuery} />
        </div>

        <AdminAllGuidesFilter onChangeFilters={handleChangeFilters} />
      </PageBody>

      <PageBody loading={initialFetchLoading || filterChangeFetchLoading}>
        {guideError?.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}

        <LeadsGuideAdmin leads={leads} />

        <div className="mt-4">
          <Button
            loading={fetchMoreLoading}
            disabled={!hasMoreLeads}
            onClick={() => {
              guideFetchMore({
                offset: leads.length
              })
            }}
          >
            {t('shared.button.fetchMore', {
              value: 10
            })}
          </Button>
        </div>
      </PageBody>
    </>
  )
}

export default AdminAllGuidesPage
