import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import useOverlayNotification from '../useOverlayNotification'

const MR_CREATE_SYSTEM_GQL = gql`
  mutation CreateSystemMR($data: CreateSystemInputMR!) {
    mrCreateSystem(data: $data) {
      id
    }
  }
`
export default function useCreateSystem() {
  const { locale, category } = useParams()
  const navigate = useNavigate()
  const { showErrorNotification } = useOverlayNotification()

  const [mrCreateSystemMutation, { loading }] = useMutation(MR_CREATE_SYSTEM_GQL)

  const mrCreateSystem = useCallback(
    (systemName, systemId, createdByLocale) => {
      return mrCreateSystemMutation({
        variables: {
          data: {
            systemName,
            systemId,
            createdByLocale,
            locale,
            category
          }
        },
        onCompleted: ({ mrCreateSystem }) => {
          const resSystemId = mrCreateSystem.id

          navigate(`/market-researcher/${locale}/${category}/${resSystemId}`)
        },
        onError: (error) => {
          showErrorNotification({ text: error?.message })
        }
      })
    },
    [mrCreateSystemMutation, navigate, locale, category, showErrorNotification]
  )

  return { mrCreateSystem, loading }
}
