import React from 'react'
import styled from 'styled-components'
import { CircleFlag } from 'react-circle-flags'
import { string } from 'prop-types'

const StyledCircleFlag = styled(CircleFlag)`
  height: ${({ $height }) => $height};
`

const Flag = ({ className, locale, height }) => {
  const getCssFlagClass = () => {
    return locale.split('_')[1]?.toLowerCase()
  }

  if (!locale) {
    return null
  }

  // Information about unknown prop console errror.
  // https://github.com/styled-components/styled-components/issues/4071
  return (
    <StyledCircleFlag
      className={className}
      countryCode={getCssFlagClass()}
      $height={height}
    />
  )
}

Flag.propTypes = {
  className: string,
  locale: string.isRequired,
  height: string
}

Flag.defaultProps = {}

export default Flag
