import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useFormFields } from '../hooks'
import Fields from '../components/form/fields'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import Loader from '../components/loader'
import { Select } from '../components/form/inputs'

import useOverlayNotification from '../hooks/useOverlayNotification'
import useFetchOperationalCountries from '../hooks/useFetchOperationalCountries'

import { EDIT_USER_FORM } from '../constants/user-form-fields'

import useFieldsSystemsValues from '../hooks/fields/useSystemsValues'

const GET_VENDORS = gql`
  query Vendors {
    appAdminCompanies {
      id
      company
      accessRights
      userName
      appLocales
      systemsV2 {
        id
        defaultCategory
        systemSiteData {
          locale
          systemName
        }
      }
      buyingIntentBoost
      buyingIntentBoostCategories
      pipedriveId
    }
  }
`

const GET_USERS = gql`
  query Users($vendorId: ID) {
    appAdminUsers(vendorId: $vendorId) {
      users {
        id
        name
        email
        disabled
        appLocales
        appRole
        systemsV2 {
          id
          defaultCategory
          systemSiteData {
            locale
            systemName
          }
        }
      }
    }
  }
`

const SAVE_USER = gql`
  mutation AppUpdateUser($id: ID!, $data: Json) {
    appUpdateUser(id: $id, data: $data) {
      id
      appRole
    }
  }
`

function AdminEditUserPage() {
  const { t } = useTranslation('adminPages')
  const [allVendors, setAllVendors] = useState([])
  const [availableUsers, setAvailableUsers] = useState([])
  const [selectedVendorId, setSelectedVendorId] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [formInputs, setFormInputs] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [fieldsData, handleFieldChange, saveInitialState] = useFormFields({})
  const { showSuccessNotification } = useOverlayNotification()
  const { usersMarketRegionsValues } = useFetchOperationalCountries()
  const { getSortedSystemValues } = useFieldsSystemsValues()

  const selectedVendorObject = useMemo(() => {
    if (!allVendors || !selectedVendorId) {
      return null
    }

    return allVendors.find((vendorObj) => vendorObj.id === selectedVendorId)
  }, [allVendors, selectedVendorId])

  const { userloading, userError } = useQuery(GET_USERS, {
    variables: { vendorId: selectedVendorId },
    onCompleted: ({ appAdminUsers }) => {
      setSelectedUserId(null)
      setAvailableUsers(
        appAdminUsers?.users.map((user) => {
          return {
            ...user,
            title: user.name
          }
        }) || []
      )
    }
  })

  // Set form data
  useEffect(() => {
    if (!selectedVendorObject) {
      return
    }

    const updatedInputs = EDIT_USER_FORM.map((inputObj) => {
      if (inputObj.id === 'appLocales') {
        inputObj.values = usersMarketRegionsValues
      }

      if (inputObj.id === 'systems') {
        inputObj.values = getSortedSystemValues(selectedVendorObject.systemsV2)
      }

      return inputObj
    })

    setFormInputs(updatedInputs)
  }, [getSortedSystemValues, selectedVendorObject, usersMarketRegionsValues])

  useEffect(() => {
    if (!selectedUserId) {
      return
    }

    const selectedUserObj = availableUsers?.find(
      (userObj) => userObj.id === selectedUserId
    )

    const systemIdsArrayUser = selectedUserObj?.systemsV2?.map(
      (systemObj) => systemObj.id
    )

    const updatedUserObj = {
      ...selectedUserObj,
      systems: systemIdsArrayUser
    }

    saveInitialState(updatedUserObj)
  }, [selectedUserId, availableUsers, saveInitialState])

  const { loading, error } = useQuery(GET_VENDORS, {
    onCompleted: (data) => {
      const allVendors = data.appAdminCompanies.map((vendorObj) => {
        return {
          ...vendorObj,
          title: vendorObj.company
        }
      })

      setSelectedUserId(null)
      setAllVendors(allVendors)
    }
  })

  // Save to db first time a box has been toggle
  const [updateUser] = useMutation(SAVE_USER, {
    onError(err) {
      const error = `${err}`.split(':').reverse()[0]
      setErrorMsg(error)
      console.log({ error })
    },
    onCompleted(data) {
      showSuccessNotification()
      console.log('done mutation', data)
    }
  })

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (errorMsg) {
      setErrorMsg(null)
    }

    const { name, email, disabled, appLocales, systems, appRole } = fieldsData

    updateUser({
      variables: {
        id: selectedUserId,
        data: {
          name,
          email,
          disabled,
          appLocales,
          systemsV2: systems,
          appRole
        }
      }
    })
  }

  return (
    <>
      <PageHeader pageName={t('adminEditUser.header.title')}>
        <p>{t('adminEditUser.header.subtitle')}</p>
      </PageHeader>

      <PageBody>
        {error?.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}

        {loading && (
          <div className="text-center">
            <Loader />
          </div>
        )}

        <div>
          {!loading && !error && (
            <div>
              <form className="space-y-8" onSubmit={handleFormSubmit}>
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <Select
                      field={{
                        id: 'vendor',
                        nameTid: 'content:company',
                        textTid: 'adminPages:adminEditUser.selectCompanyText',
                        type: 'select',
                        values: allVendors
                      }}
                      data={selectedVendorId}
                      handleFieldChange={(e) => {
                        setSelectedVendorId(e.target.value)
                      }}
                    />

                    {!!availableUsers?.length && (
                      <Select
                        field={{
                          id: 'user',
                          nameTid: 'content:user',
                          textTid: 'adminPages:adminEditUser.selectUserText',
                          type: 'select',
                          values: availableUsers
                        }}
                        data={selectedVendorId}
                        handleFieldChange={(e) => {
                          setSelectedUserId(e.target.value)
                        }}
                      />
                    )}
                  </div>
                </div>
                {/* div with rounded corners and some margin */}
                {selectedUserId &&
                  !userloading &&
                  !userError &&
                  formInputs &&
                  !!Object.keys(fieldsData).length && (
                    <div className="p-3 pt-1 mt-8 border-l-4 rounded-md">
                      <Fields
                        inputFields={formInputs}
                        fieldsData={fieldsData}
                        handleFieldChange={handleFieldChange}
                      />
                      <div className="pt-5">
                        <div className="flex justify-end">
                          {errorMsg && <div className="text-red-800">{errorMsg}</div>}
                          <button
                            type="submit"
                            disabled={loading}
                            className={classNames(
                              'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                              {
                                'disabled:opacity-50': loading
                              }
                            )}
                          >
                            {t('content:save')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
              </form>
            </div>
          )}
        </div>
      </PageBody>
    </>
  )
}

export default AdminEditUserPage
