import React, { useState } from 'react'

import Nav from './nav'
import NavSide from './navSide'
import CookieConsent from '../cookieConsent'
import { Outlet } from 'react-router-dom'

import '../../styles/globals.css'

function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div>
        <NavSide sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="md:pl-64 flex flex-col flex-1">
          <Nav setSidebarOpen={setSidebarOpen} />

          <main className="flex-1">
            <div className="py-6">
              <Outlet />
            </div>
          </main>
        </div>
      </div>

      <CookieConsent />
    </>
  )
}

Layout.propTypes = {}

export default Layout
