import { bool, func, string } from 'prop-types'
import React from 'react'

const Checkbox = ({ id, text, label, onChange, checked, rawChangeEvent, disabled }) => {
  const handleChange = (event) => {
    if (rawChangeEvent) {
      if (id) {
        onChange(id, event)
      } else {
        onChange(event)
      }
    } else {
      if (id) {
        onChange(id, event.target.checked)
      } else {
        onChange(event.target.checked)
      }
    }
  }

  return (
    <div className="relative mr-4 py-1 flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          name={id}
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
      <div className="ml-2 text-sm">
        <label className="font-medium text-gray-700">{label}</label>
        {text && <p className="text-gray-500">{text}</p>}
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  id: string,
  subtext: string,
  text: string,
  onChange: func,
  checked: bool,
  rawChangeEvent: bool,
  disabled: bool
}

Checkbox.defaultProps = {
  rawChangeEvent: true
}

export default Checkbox
