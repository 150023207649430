import React from 'react'
import { string } from 'prop-types'
import { LinkIcon, BadgeCheckIcon } from '@heroicons/react/outline'

import { useTranslation } from 'react-i18next'

const Link = ({ url, text }) => (
  <div className="flex gap-1 items-center">
    <LinkIcon className="w-4 h-4 text-gray-500 " />
    <a
      className="text-sm text-gray-600! hover:underline pointer"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  </div>
)

const CountryFormProfileLinks = ({ domain, systemNameUrl, customerType }) => {
  const { t } = useTranslation()

  const systemProfileUrl = `https://${domain}/system/${systemNameUrl}`
  const writerReviewUrl = `${systemProfileUrl}/write-review`

  return (
    <div className="flex flex-col">
      <Link url={systemProfileUrl} text={t('forms:system.bwProfileLink')} />
      <Link url={writerReviewUrl} text={t('forms:system.bwLeaveReviewLink')} />
    </div>
  )

  /*
  return (
    <div className="flex gap-4 items-center bg-gray-200 p-3 rounded shadow-sm">
      <div className="flex flex-1">
        <div className="text-sm text-gray-700 flex gap-1 items-center">
          <BadgeCheckIcon className="w-4 h-4 text-gray-500" />
          <span className="font-medium">Kundtyp:</span> {customerType}
        </div>
      </div>
      <div className="flex gap-2">
        <Link url={systemProfileUrl} text="Vår profil" />
        <Link url={writerReviewUrl} text="Lämna omdöme" />
      </div>
    </div>
  )
  */
}

CountryFormProfileLinks.propTypes = {
  domain: string.isRequired,
  systemNameUrl: string.isRequired,
  customerType: string.isRequired
}

CountryFormProfileLinks.defaultProps = {}

export default CountryFormProfileLinks
