import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import useOverlayNotification from '../../../../hooks/useOverlayNotification'

import { FRAGMENT_REVIEW_TEXT } from '../../../../constants/gql/systems'

const DELETE_REVIEW_COMMENT_GQL = gql`
  ${FRAGMENT_REVIEW_TEXT}

  mutation AdminDeleteReviewComment($reviewDataId: ID!) {
    appDeleteReviewComment(reviewDataId: $reviewDataId) {
      ...ReviewTextsFragment
    }
  }
`

export default function useDeleteReviewComment() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()

  const [appDeleteReviewComment, { loading }] = useMutation(DELETE_REVIEW_COMMENT_GQL)

  const deleteReviewComment = useCallback(
    (reviewDataId, onCompleted) => {
      return appDeleteReviewComment({
        variables: {
          reviewDataId
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted && onCompleted()
        },
        onError: (error) => {
          showErrorNotification({ text: error.message })
        }
      })
    },
    [appDeleteReviewComment, showSuccessNotification, showErrorNotification]
  )

  return {
    deleteReviewComment,
    deleteReviewCommentLoading: loading
  }
}
