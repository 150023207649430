import { useMemo } from 'react'

import useGetCategoryName from './useGetCategoryName'

const EMPTY_ARR = []

export default function useCategoriesOptions(sort) {
  const { allCategoriesMap, allCategoriesLoading, getCategoryName } = useGetCategoryName()

  const categoriesOptionsMemo = useMemo(() => {
    if (!allCategoriesMap) {
      return EMPTY_ARR
    }

    const unsortedCategoriesOptions = Object.keys(allCategoriesMap).map((categoryKey) => {
      return {
        value: categoryKey,
        label: getCategoryName(categoryKey)
      }
    })

    if (sort) {
      return unsortedCategoriesOptions.sort((a, b) => {
        const aLabel = a.label?.trim()?.toLowerCase() || ''
        const bLabel = b.label?.trim()?.toLowerCase() || ''

        return aLabel.localeCompare(bLabel)
      })
    }

    return unsortedCategoriesOptions
  }, [allCategoriesMap, getCategoryName, sort])

  return {
    categoriesOptions: categoriesOptionsMemo,
    categoriesOptionsLoading: allCategoriesLoading
  }
}
