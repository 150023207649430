import React from 'react'

export function PageHeader({
  headerClassName,
  pageName,
  headerRightComponent,
  children
}) {
  if (!pageName && !children) {
    return null
  }
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-12">
      {headerRightComponent ? (
        <div className={headerClassName}>
          {pageName && (
            <h1 className="text-2xl font-semibold text-gray-900">{pageName}</h1>
          )}

          {headerRightComponent}
        </div>
      ) : (
        <>
          {pageName && (
            <h1 className="mb-2 text-2xl font-semibold text-gray-900">{pageName}</h1>
          )}
        </>
      )}

      {children}
    </div>
  )
}
