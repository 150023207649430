import { useQuery } from '@apollo/client'
import { useUserContext } from '../contexts'
import { isAdmin } from '../utils'

const EMPTY_OBJ = {}

export default function useAdminQuery(GQL_ADMIN_QUERY, gqlOptions, fetchPolicy) {
  const { user } = useUserContext()
  const hasAdmin = isAdmin(user?.role)

  const { variables: additionalVariables, ...restGqlOptions } = gqlOptions || EMPTY_OBJ

  return useQuery(GQL_ADMIN_QUERY, {
    fetchPolicy: fetchPolicy ? fetchPolicy : hasAdmin ? 'no-cache' : 'cache-first',
    variables: {
      userId: user.userId || null,
      companyId: user.companyId || null,
      systemId: user.systemId || null,
      ...additionalVariables
    },
    onError: (error) => {
      console.log('Error in useAdminQuery', error)
    },
    ...restGqlOptions
  })
}
