import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import Chip from '../../../chip'

const SelectedItem = ({ label, labelTid, value, onDelete, type, disabled }) => {
  const { t } = useTranslation()
  const labelText = labelTid ? t(labelTid) : label

  const handleDelete = () => {
    if (disabled) {
      return
    }

    onDelete(value)
  }

  return (
    <Chip text={labelText} type={type}>
      <div className="flex flex-auto flex-row-reverse">
        <svg
          onClick={handleDelete}
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={cx(
            'feather feather-x hover:text-blue-700 rounded-full w-4 h-4 ml-2',
            {
              'cursor-pointer': !disabled
            }
          )}
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </Chip>
  )
}

export default SelectedItem
