import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

import { useParams } from 'react-router-dom'
import useOverlayNotification from '../useOverlayNotification'

import { GUIDE_FRAGMENT } from '../../constants/gql/guide'

const REORDER_ANSWERS_GQL = gql`
  mutation AdminReorderAnswers($guideId: ID, $answersIds: [ID!]!) {
    reorderAnswers(guideId: $guideId, answersIds: $answersIds) {
      ${GUIDE_FRAGMENT}
    }
  }
`

export default function useReorderAnswers() {
  const { showSuccessNotification, showErrorNotification } = useOverlayNotification()
  const { guideId } = useParams()

  const [reorderAnswers, { loading }] = useMutation(REORDER_ANSWERS_GQL)

  const reorderAnswersCallback = useCallback(
    (answersIds, onCompleted) => {
      return reorderAnswers({
        variables: {
          guideId,
          answersIds
        },
        onCompleted: () => {
          showSuccessNotification()
          onCompleted?.()
        },
        onError: showErrorNotification
      })
    },
    [reorderAnswers, guideId, showSuccessNotification, showErrorNotification]
  )

  return {
    reorderAnswers: reorderAnswersCallback,
    reorderAnswersLoading: loading
  }
}
