import React from 'react'
import { array, func } from 'prop-types'

import AssetsRenderer from './assets-renderer'
import Dropzone from './dropzone'

const ASSET_LOGO = 'LOGO'

const LogoSection = ({ onChange, assets }) => {
  return (
    <div className="flex gap-3 flex-wrap mt-2">
      <AssetsRenderer
        assetType={ASSET_LOGO}
        assets={assets}
        onChange={onChange}
        hideOrderControls
        hideCountries
      />

      {!assets.length && (
        <Dropzone assetType={ASSET_LOGO} onDrop={onChange} multiple={false} />
      )}
    </div>
  )
}

LogoSection.propTypes = {
  onChange: func.isRequired,
  assets: array.isRequired
}

LogoSection.defaultProps = {}

export default LogoSection
