import React from 'react'

import MultiSelectComponent from './src/multiselect'
import SelectComponent from './src/select'
import ListItemBase from './src/shared/list-item-base'

import { SelectContextProvider } from './src/context'

/**
 *
 * @param props {
 *  freeInput: Allows select to act as input and wont clear the state on removing focus
 *  onInputChange: Ran when input state changes
 *  autoFocus: Autofocus input
 *  disabled: boolean
 *  loading: boolean
 *  onDropdownOpen: fn
 * }
 */
export const MultiSelect = (props) => {
  const {
    providedInputRef,
    onInputChange,
    onDropdownOpen,
    freeInput,
    closeDropdownOnSelect
  } = props

  return (
    <SelectContextProvider
      providedInputRef={providedInputRef}
      onInputChange={onInputChange}
      onDropdownOpen={onDropdownOpen}
      freeInput={freeInput}
      closeDropdownOnSelect={closeDropdownOnSelect}
      options={props.options}
      value={props.value}
      type="MULTI"
    >
      <MultiSelectComponent {...props} />
    </SelectContextProvider>
  )
}

export const Select = (props) => {
  const {
    providedInputRef,
    onInputChange,
    onDropdownOpen,
    freeInput,
    closeDropdownOnSelect
  } = props

  return (
    <SelectContextProvider
      providedInputRef={providedInputRef}
      onInputChange={onInputChange}
      onDropdownOpen={onDropdownOpen}
      freeInput={freeInput}
      closeDropdownOnSelect={closeDropdownOnSelect}
      options={props.options}
      value={props.value}
      type="SINGLE"
    >
      <SelectComponent {...props} />
    </SelectContextProvider>
  )
}

export { ListItemBase }
