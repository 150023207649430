import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Loader from '../components/loader'
import { PageHeader } from '../components/page-header'
import { PageBody } from '../components/page-body'
import BiLead from '../components/admin/admin-list-bi-lead-item'

const QUERY = gql`
  query BiLeads($category: String, $offset: Int, $limit: Int) {
    appAdminBuyingIntentSignals(category: $category, offset: $offset, limit: $limit) {
      id
      name
      locale
      founded
      alexaRank
      employeeMin
      employeeMax
      linkedInCategory
      financialsMin
      financialsMax
      naceCategory
      city
      state
      orgId
      zip
      website
      url
      createdAt
      updatedAt
      pages
      sessions
    }
  }
`

function LeadsBiAdmin({ leads }) {
  const { t } = useTranslation()

  if (!leads?.length) {
    return <div>{t('content:warning.noData')}</div>
  }

  const updatedLeadsObj = leads.map((lead) => {
    return {
      ...lead,
      sessions: JSON.parse(lead.sessions),
      date: new Date(Number(lead.createdAt))
    }
  })

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {updatedLeadsObj
          .sort((a, b) => b.date - a.date)
          .map((lead) => {
            return <BiLead key={lead.date} lead={lead} />
          })}
      </ul>
    </div>
  )
}

function AdminAllBiPage() {
  const {
    data: biData,
    loading,
    error
  } = useQuery(QUERY, {
    variables: {
      offset: 0,
      limit: 10
    }
  })

  if (error) {
    console.error(error)
  }

  const leads = biData?.appAdminBuyingIntentSignals

  return (
    <>
      <PageHeader pageName={'Buying Intent'}>
        <p>Alla leads senaste 7 dagarna</p>
      </PageHeader>

      <PageBody>
        {error &&
          error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
        <div>{!loading && <LeadsBiAdmin leads={leads} />}</div>
        {loading && (
          <div className="text-center">
            <Loader />
          </div>
        )}
      </PageBody>
    </>
  )
}

export default AdminAllBiPage
