import React, { useState } from 'react'
import { func } from 'prop-types'

import { useTranslation } from 'react-i18next'
import useCreateGuide from '../../../hooks/manage-guides/useCreateGuide'
import useGenericFormManager from '../../../hooks/useGenericFormManager'

import Dialog from '../../shared/dialog'
import Button from '../../shared/button'
import GuideGeneralForm, { GUIDE_CREATE_FORM_INSTR } from './guide-general-form'

const CreateGuideDialog = ({ onGuideCreated }) => {
  const { t } = useTranslation('adminPages')
  const [open, setOpen] = useState(false)
  const { createGuide, createGuideLoading } = useCreateGuide()

  const { formData, onChange, validate, changedFields, resetForm, validationErrors } =
    useGenericFormManager(GUIDE_CREATE_FORM_INSTR)

  const handleSubmit = () => {
    if (!validate()) {
      return
    }

    createGuide(changedFields, onGuideCreated)
  }

  const handleCancel = () => {
    resetForm()
    setOpen(false)
  }

  const renderCustomFooter = () => {
    return (
      <div className="flex justify-end mt-5 gap-3 sm:mt-6">
        <Button mode="default" onClick={handleCancel} disabled={createGuideLoading}>
          {t('content:cancel')}
        </Button>

        <Button onClick={handleSubmit} loading={createGuideLoading}>
          {t('content:save')}
        </Button>
      </div>
    )
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        {t('adminManageGuide.guides.createGuide')}
      </Button>

      <Dialog
        isOpen={open}
        title={t('adminManageGuide.guides.createGuide')}
        renderFooter={renderCustomFooter}
      >
        <div className="grid grid-flow-row gap-4">
          <GuideGeneralForm
            formData={formData}
            onChange={onChange}
            validationErrors={validationErrors}
          />
        </div>
      </Dialog>
    </>
  )
}

CreateGuideDialog.propTypes = {
  onGuideCreated: func
}

CreateGuideDialog.defaultProps = {}

export default CreateGuideDialog
